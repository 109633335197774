import styled from 'styled-components/macro';
import vars from 'src/styles/variables';

interface LabelProps {
  hasError?: boolean;
}

export const Label = styled.label<LabelProps>`
  /* text-transform: uppercase; */ /* <-- keep just in case */
  font-size: 0.625rem;
  margin-bottom: 0.25rem;
  font-weight: bold;
  color: ${vars.$gray_9};
  ${(props) => {
    return props.hasError && `color: ${vars.$danger}`;
  }}
`;
Label.displayName = 'Label';

export default Label;
