import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowUp as falArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp';
import { faChartNetwork as falChartNetwork } from '@fortawesome/pro-light-svg-icons/faChartNetwork';
import { faHexagon as falHexagon } from '@fortawesome/pro-light-svg-icons/faHexagon';
import { faTimes as farTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faAngleDoubleLeft as farAngleDoubleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleLeft';
import { faAngleDoubleRight as farAngleDoubleRight } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleRight';
import { faCopy as farCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faEllipsisH as farEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH';
import { faExternalLinkSquareAlt as farExternalLinkSquareAlt } from '@fortawesome/pro-regular-svg-icons/faExternalLinkSquareAlt';
import { faFrown as farFrown } from '@fortawesome/pro-regular-svg-icons/faFrown';
import { faPaperPlane as farPaperPlane } from '@fortawesome/pro-regular-svg-icons/faPaperPlane';
import { faSidebar as farSidebar } from '@fortawesome/pro-regular-svg-icons/faSidebar';
import { faSmile as farSmile } from '@fortawesome/pro-regular-svg-icons/faSmile';
import { faAnalytics as fasAnalytics } from '@fortawesome/pro-solid-svg-icons/faAnalytics';
import { faBan as fasBan } from '@fortawesome/pro-solid-svg-icons/faBan';
import { faBars as farBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faBracketsCurly as fasBracketsCurly } from '@fortawesome/pro-solid-svg-icons/faBracketsCurly';
import { faCalendarAlt as fasCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import { faCameraMovie as fasCameraMovie } from '@fortawesome/pro-solid-svg-icons/faCameraMovie';
import { faCarBuilding as fasCarBuilding } from '@fortawesome/pro-solid-svg-icons/faCarBuilding';
import { faCheck as fasCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCheckCircle as fasCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faChevronCircleDown as fasChevronCircleDown } from '@fortawesome/pro-solid-svg-icons/faChevronCircleDown';
import { faChevronDown as fasChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronLeft as fasChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight as fasChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faCog as fasCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faCommentAltEdit as fasCommentAltEdit } from '@fortawesome/pro-solid-svg-icons/faCommentAltEdit';
import { faDigitalTachograph as fasDigitalTachograph } from '@fortawesome/pro-solid-svg-icons/faDigitalTachograph';
import { faEdit as fasEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
import { faEnvelope as fasEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faExclamationTriangle as fasExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faExternalLinkAlt as fasExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons/faExternalLinkAlt';
import { faEye as fasEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faFileCsv as fasFileCsv } from '@fortawesome/pro-solid-svg-icons/faFileCsv';
import { faFileExcel as fasFileExcel } from '@fortawesome/pro-solid-svg-icons/faFileExcel';
import { faFileImport as fasFileImport } from '@fortawesome/pro-solid-svg-icons/faFileImport';
import { faFileUpload as fasFileUpload } from '@fortawesome/pro-solid-svg-icons/faFileUpload';
import { faFilter as fasFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faImages as fasImages } from '@fortawesome/pro-solid-svg-icons/faImages';
import { faInfoCircle as fasInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faLock as fasLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faMapMarkerAlt as fasMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import { faMapMarkerEdit as fasMapMarkerEdit } from '@fortawesome/pro-solid-svg-icons/faMapMarkerEdit';
import { faMedal as fasMedal } from '@fortawesome/pro-solid-svg-icons/faMedal';
import { faMoneyCheckEdit as fasMoneyCheckEdit } from '@fortawesome/pro-solid-svg-icons/faMoneyCheckEdit';
import { faPlus as fasPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faQuestionCircle as fasQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faRedo as fasRedo } from '@fortawesome/pro-solid-svg-icons/faRedo';
import { faReply as fasReply } from '@fortawesome/pro-solid-svg-icons/faReply';
import { faReplyAll as fasReplyAll } from '@fortawesome/pro-solid-svg-icons/faReplyAll';
import { faSave as fasSave } from '@fortawesome/pro-solid-svg-icons/faSave';
import { faSearch as fasSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faShieldAlt as fasShieldAlt } from '@fortawesome/pro-solid-svg-icons/faShieldAlt';
import { faSignOut as fasSignOut } from '@fortawesome/pro-solid-svg-icons/faSignOut';
import { faSitemap as fasSitemap } from '@fortawesome/pro-solid-svg-icons/faSitemap';
import { faSort as fasSort } from '@fortawesome/pro-solid-svg-icons/faSort';
import { faSortAmountDown as fasSortAmountDown } from '@fortawesome/pro-solid-svg-icons/faSortAmountDown';
import { faSortAmountUp as fasSortAmountUp } from '@fortawesome/pro-solid-svg-icons/faSortAmountUp';
import { faSpinnerThird as fasSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird';
import { faStar as fasStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faSync as fasSync } from '@fortawesome/pro-solid-svg-icons/faSync';
import { faTicketAlt as fasUserTicket } from '@fortawesome/pro-solid-svg-icons/faTicketAlt';
import { faTimes as fasTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faTrashAlt as fasTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt';
import { faUser as fasUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUserChart as fasUserChart } from '@fortawesome/pro-solid-svg-icons/faUserChart';
import { faUserCheck as fasUserCheck } from '@fortawesome/pro-solid-svg-icons/faUserCheck';
import { faUserCircle as fasUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle';
import { faUserCrown as fasUserCrown } from '@fortawesome/pro-solid-svg-icons/faUserCrown';
import { faUserPlus as fasUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus';
import { faUsersCog as fasUsersCog } from '@fortawesome/pro-solid-svg-icons/faUsersCog';

export const fontAwesomeConfig = {
  init: (): void => {
    library.add(
      fasAnalytics,
      fasBracketsCurly,
      fasBan,
      fasCalendarAlt,
      fasCameraMovie,
      fasCarBuilding,
      fasCheck,
      fasCheckCircle,
      fasChevronCircleDown,
      fasChevronDown,
      fasChevronLeft,
      fasChevronRight,
      fasCog,
      fasCommentAltEdit,
      fasDigitalTachograph,
      fasEdit,
      fasEnvelope,
      fasExclamationTriangle,
      fasExternalLinkAlt,
      fasEye,
      fasFileCsv,
      fasFileExcel,
      fasFileImport,
      fasFileUpload,
      fasFilter,
      fasImages,
      fasInfoCircle,
      fasLock,
      fasMapMarkerAlt,
      fasMapMarkerEdit,
      fasMedal,
      fasMoneyCheckEdit,
      fasPlus,
      fasQuestionCircle,
      fasRedo,
      fasReply,
      fasReplyAll,
      fasSave,
      fasSearch,
      fasShieldAlt,
      fasSignOut,
      fasSitemap,
      fasSpinnerThird,
      fasStar,
      fasSync,
      fasTimes,
      fasTrashAlt,
      fasUser,
      fasUserChart,
      fasUserCheck,
      fasUserCircle,
      fasUserCrown,
      fasUserPlus,
      fasUsersCog,
      farAngleDoubleLeft,
      farAngleDoubleRight,
      farCopy,
      farEllipsisH,
      farExternalLinkSquareAlt,
      farPaperPlane,
      falChartNetwork,
      falHexagon,
      falArrowUp,
      fasSort,
      fasSortAmountUp,
      fasSortAmountDown,
      farBars,
      farTimes,
      fasUserTicket,
      farSidebar,
      farSmile,
      farFrown
    );
  },
};
