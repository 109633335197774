import React, { PropsWithChildren, ReactNode } from 'react';
import { components } from 'react-select';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';

// ------------------------
// Two or Three line select option
// ------------------------

interface SelectCustomItemProps {
  primaryLabel: string | ReactNode;
  secondaryLabel?: string | ReactNode;
  tertiaryLabel?: string | ReactNode;
  className?: string;
  [key: string]: any;
}

const StyledItemOption = styled.div`
  display: flex;
  flex-direction: column;
  span:not(:first-child) {
    font-size: 80%;
    color: ${vars.$gray_8};
  }
`;

export const SelectCustomMenu = (props: PropsWithChildren<any>): JSX.Element => {
  return (
    <>
      <components.Menu {...props}>{props.children}</components.Menu>
    </>
  );
};

export const SelectCustomOption = (props: PropsWithChildren<any>): JSX.Element => {
  return (
    <>
      <components.Option {...props}>{props.children}</components.Option>
    </>
  );
};

export const SelectCustomItem = (props: SelectCustomItemProps): JSX.Element => {
  const { primaryLabel, secondaryLabel, tertiaryLabel, ...rest } = props;
  return (
    <StyledItemOption {...rest}>
      <span>{primaryLabel}</span>
      {secondaryLabel && <span>{secondaryLabel}</span>}
      {tertiaryLabel && <span>{tertiaryLabel}</span>}
    </StyledItemOption>
  );
};
