import React, { ReactElement } from 'react';

interface KenectLogoProps {
  height?: string;
}

const KenectLogo = ({ height = '100%' }: KenectLogoProps): ReactElement => {
  return (
    <svg height={height} viewBox="0 0 226 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_364_159)">
        <path d="M194.343 0.650051V7.61935H206.358V39.3504H213.863V7.61935H225.878V0.650051H194.343Z" fill="#1477F8" />
        <path
          d="M185.919 28.2648C182.731 31.2403 179.845 33.0853 175.312 33.0853C168.412 33.0853 163.209 27.4598 163.209 19.9997V19.8911C163.209 12.4934 168.412 6.91471 175.312 6.91471C179.032 6.91471 182.22 8.3346 185.649 11.5196L185.95 11.7997L190.743 6.27452L190.469 6.0126C187.359 3.03994 183.131 0 175.366 0C164.002 0 155.432 8.59817 155.432 19.9997V20.1084C155.432 25.5094 157.423 30.5196 161.038 34.2151C164.687 37.946 169.68 40 175.095 40C183.108 40 187.507 36.5443 190.752 33.1636L191.019 32.884L186.201 28.0007L185.919 28.2648Z"
          fill="#1477F8"
        />
        <path
          d="M128.301 32.5981V23.2136H147.298V16.4615H140.47H128.301H120.85V39.3501H150.003V32.5981H128.301Z"
          fill="#1477F8"
        />
        <path d="M128.301 7.40213H140.47H149.733V0.650086H120.85V7.40213H128.301Z" fill="#1477F8" />
        <path
          d="M104.56 26.0926L84.8579 0.649837H78.0953V39.3502H85.4393V13.1487L105.736 39.3502H111.904V0.649837H104.56V26.0926Z"
          fill="#1477F8"
        />
        <path d="M49.2879 7.40213H61.9157H70.719V0.650086H41.8359V7.40213H49.2879Z" fill="#1477F8" />
        <path
          d="M49.2879 32.5981V23.2136H68.2831V16.4615H61.9157H49.2879H41.8359V39.3501H70.9896V32.5981H49.2879Z"
          fill="#1477F8"
        />
        <path
          d="M13.931 14.1042L11.3095 16.7482L8.88684 19.1915L28.9595 39.4515H38.9669L13.931 14.1042Z"
          fill="#1477F8"
        />
        <path d="M21.2561 16.8139L37.3873 0.549446H27.375L16.2599 11.7564L21.2561 16.8139Z" fill="#1477F8" />
        <path d="M7.50264 0.586365H0V39.4141H7.50264V0.586365Z" fill="#1477F8" />
      </g>
      <defs>
        <clipPath id="clip0_364_159">
          <rect width="225.878" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KenectLogo;
