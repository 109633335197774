import React, { ReactElement, CSSProperties, ReactNode } from 'react';
import AnimateHeight, { AnimateHeightProps } from 'react-animate-height';
import styled from 'styled-components/macro';

export interface AccordionProps extends AnimateHeightProps {
  children: ReactNode;
  id: string;
  className?: string;
  positionAbsolute?: boolean;
  duration?: number;
  height?: string | number;
  style?: CSSProperties;
}

/**
 * ### Accordion is a pre-configured extension of the react-animate-height `<AnimateHeight>`
 * It has default values applied and has an option to correctly set `position: absolute;` if necessary.
 *
 * #### It is a replacement for react-bootstrap's Collapse component.
 * Visibility is based on height, which must be controlled locally in the component that uses Accordion
 *
 * There's no real way to make it straightforward out of the box,
 * so take a look at the react-animate-height readme and view examples on storybook.
 *
 */
export class Accordion extends React.Component<AccordionProps, {}> {
  static displayName = 'Accordion';
  static defaultProps = {
    duration: 250,
    style: {},
  };

  render(): ReactElement {
    const { duration, children, height, positionAbsolute, style, className, id } = this.props;

    const positionAbsoluteStyle: CSSProperties = {
      position: 'absolute',
      zIndex: 250,
      ...style,
    };

    return (
      <AnimateHeight
        id={id}
        className={className}
        style={positionAbsolute ? positionAbsoluteStyle : style}
        duration={duration}
        height={height}
      >
        {children}
      </AnimateHeight>
    );
  }
}

export default styled(Accordion)``;
