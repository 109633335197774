import React from 'react';
import DataTable, { TableProps, TableStyles, createTheme } from 'react-data-table-component';
import { css } from 'styled-components/macro';
import vars from 'src/styles/variables';

interface LocalTableProps extends TableProps<any> {
  customStyles?: TableStyles;
}

export const Table = (props: LocalTableProps): React.ReactElement => {
  const { columns, data, customStyles, ...rest } = props;

  return (
    <DataTable
      columns={columns}
      data={data}
      theme="erc"
      customStyles={{
        ...customStyles,
      }}
      {...rest}
    />
  );
};

/**
 * INJECTED INTO GLOBALSTYLES.STYLED.TSX
 */
export const RTDCustomStyles = css`
  .rdt_Table {
    .rdt_TableHeadRow {
      min-height: 40px;

      .rdt_TableCol {
        line-height: 1rem;
        .rdt_TableCol_Sortable {
          line-height: 1rem;
          &:active,
          &:focus {
            color: ${vars.$greenlight};
          }
        }
        &:last-of-type {
          justify-content: flex-end;
        }
        &:not(:last-of-type) {
          /* border-right: 1px solid rgba(0, 0, 0, 0.12); */
        }
      }
    }
    .rdt_TableRow {
      min-height: 51px;
    }
    .rdt_TableCell {
      padding: 1rem;
      justify-content: flex-start;
      &:last-of-type {
        justify-content: flex-end;
      }
    }
  }
`;

/**
 * THEME OVERRIDES FOR REACT-DATA-TABLE-COMPONENT
 * https://github.com/jbetancur/react-data-table-component#defining-your-own-theme-using-createtheme
 *
 */
createTheme('erc', {
  text: {
    primary: vars.$gray_9,
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  background: {
    default: 'transparent',
  },
  context: {
    background: '#e3f2fd',
    text: 'rgba(0, 0, 0, 0.87)',
  },
  divider: {
    default: 'rgba(0,0,0,.12)',
  },
  button: {
    default: 'rgba(0,0,0,.54)',
    focus: 'rgba(0,0,0,.12)',
    hover: 'rgba(0,0,0,.12)',
    disabled: 'rgba(0, 0, 0, .18)',
  },
  sortFocus: {
    default: vars.$greenlight,
  },
  selected: {
    default: '#e3f2fd',
    text: 'rgba(0, 0, 0, 0.87)',
  },
  highlightOnHover: {
    default: '#EEEEEE',
    text: 'rgba(0, 0, 0, 0.87)',
  },
  striped: {
    default: '#FAFAFA',
    text: 'rgba(0, 0, 0, 0.87)',
  },
});
