import { TagFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/tag_friendly';
import { Tag as TagsEnums } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/tags_pb';
import { ReviewResponseTag } from 'src/async/types';

export const tagsData = Object.keys(TagFriendly.friendlyMap)
  .filter(
    (key) =>
      TagFriendly.friendlyMap[key] != TagsEnums.UNKNOWN &&
      TagFriendly.friendlyMap[key] != TagsEnums.OVER_LIMIT &&
      TagFriendly.friendlyMap[key] != TagsEnums.NO_RESPONSE &&
      TagFriendly.friendlyMap[key] != TagsEnums.FAILURE_PROFILE_TURNED_OFF &&
      TagFriendly.friendlyMap[key] != TagsEnums.FAILURE_NO_API_ACCESS
  )
  .map((key) => ({
    value: key,
    id: TagFriendly.friendlyMap[key],
  }));

export function populateStateTags(
  selectedTags?: Record<string, any>,
  tagsFriendly?: ReviewResponseTag[],
  item?: Record<string, any>
): {
  tempTagsFriendly: ReviewResponseTag[];
  tempSelectedStates: { [x: string]: any };
  isExist: ReviewResponseTag | undefined;
} {
  const tempSelectedStates = { ...selectedTags };
  let tempTagsFriendly: ReviewResponseTag[] = tagsFriendly as ReviewResponseTag[];
  const isExist = tempTagsFriendly && tempTagsFriendly.find((tfriendly) => tfriendly.value === item?.value);
  if (isExist) {
    tempTagsFriendly = tempTagsFriendly.filter((tf) => {
      if (tf.value !== item?.value) {
        return true;
      }
      delete tempSelectedStates[item?.id];
      return false;
    });
  } else {
    tempTagsFriendly.push(item as any);
    tempSelectedStates[item?.id] = item;
  }
  return { tempTagsFriendly, tempSelectedStates, isExist };
}

export function populateTagsData(
  setTagsFriendly: React.Dispatch<React.SetStateAction<ReviewResponseTag[]>>,
  setSelectedTags: React.Dispatch<React.SetStateAction<Record<string, any> | undefined>>,
  reviewResponseTags?: ReviewResponseTag[] | undefined | null
): void {
  const selectedTags: Record<string, any> = {};
  const tagsFriendly: any[] = [];

  reviewResponseTags?.forEach((tag) => {
    const value = TagFriendly.toFriendly(tag.value);
    if (TagFriendly.friendlyMap[value] != TagsEnums.UNKNOWN && TagFriendly.friendlyMap[value] != TagsEnums.OVER_LIMIT) {
      selectedTags[tag.value] = { ...tag, value, id: tag.value };
      tagsFriendly.push({ ...tag, value, id: tag.value });
    }
  });

  setTagsFriendly(tagsFriendly);
  setSelectedTags(selectedTags);
}
