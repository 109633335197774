import { GET_REVIEW_RESPONSE_TICKET, CREATE_REVIEW_RESPONSE_APPROVAL } from 'src/graph';
import { useGraphMutation } from 'src/hooks';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useGraphMutations(reviewUlid?: string) {
  const { mutationMethod: createApproval } = useGraphMutation({
    mutation: CREATE_REVIEW_RESPONSE_APPROVAL,
    options: {
      refetchQueries: [
        {
          query: GET_REVIEW_RESPONSE_TICKET,
          variables: {
            reviewUlid,
          },
        },
      ],
    },
    errorType: 'error',
  });
  return {
    createApproval,
  };
}
