import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { RESPONSE_PLACES_KEY, RESPONSE_PLACE_KEY } from '../queryKeys.utils';
import { ResponsePlace } from '../types/api';
import { EditResponsePlaceVariables, ResponsePlaceVariables, SharedMutationResult } from '../types/async';
import {
  getResponsePlace,
  listResponsePlaces,
  createResponsePlace,
  activateResponsePlace,
  deactivateResponsePlace,
  deleteResponsePlace,
  editResponsePlace,
} from './responsePlaces';

export function useGetResponsePlace(placeUlid: string): UseQueryResult<ResponsePlace> {
  return useQuery({
    queryKey: [RESPONSE_PLACE_KEY, placeUlid],
    queryFn: () => getResponsePlace(placeUlid),
  });
}

export function useListResponsePlaces(responseOrganizationUlid: string): UseQueryResult<ResponsePlace[]> {
  return useQuery({
    queryKey: [RESPONSE_PLACES_KEY, responseOrganizationUlid],
    queryFn: () => listResponsePlaces(responseOrganizationUlid),
    refetchOnWindowFocus: false,
    enabled: Boolean(responseOrganizationUlid),
  });
}

export function useCreateResponsePlace(): SharedMutationResult<ResponsePlace, ResponsePlaceVariables> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createResponsePlace,
    onSuccess: (_, { responseOrganizationUlid }) => {
      queryClient.invalidateQueries([RESPONSE_PLACES_KEY, responseOrganizationUlid]);
    },
  });
}

export function useEditResponsePlace(): SharedMutationResult<ResponsePlace, EditResponsePlaceVariables> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editResponsePlace,
    onSuccess: (_, { placeUlid }) => {
      queryClient.invalidateQueries([RESPONSE_PLACE_KEY, placeUlid]);
    },
  });
}

export function useActivateResponsePlace(): SharedMutationResult<ResponsePlace, ResponsePlaceVariables> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: activateResponsePlace,
    onSuccess: (_, { placeUlid }) => {
      queryClient.invalidateQueries([RESPONSE_PLACE_KEY, placeUlid]);
    },
  });
}

export function useDeactivateResponsePlace(): SharedMutationResult<ResponsePlace, ResponsePlaceVariables> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deactivateResponsePlace,
    onSuccess: (_, { placeUlid }) => {
      queryClient.invalidateQueries([RESPONSE_PLACE_KEY, placeUlid]);
    },
  });
}

export function useDeleteResponsePlace(): SharedMutationResult<unknown, ResponsePlaceVariables> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteResponsePlace,
    onSuccess: () => {
      queryClient.invalidateQueries([RESPONSE_PLACES_KEY]);
    },
  });
}
