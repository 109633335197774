import styled from 'styled-components/macro';
import vars from 'src/styles/variables';

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${vars.$spacing} ${vars.$spacing_medium_large};
  border: ${vars.$border};
  border-top: 0;
`;

CardFooter.displayName = 'Card.Footer';
export default CardFooter;
