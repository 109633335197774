import { darken, lighten } from 'color2k';
import styled, { css } from 'styled-components/macro';
import { Avatar } from 'src/components';
import vars from 'src/styles/variables';
import { enumValues, getChannelColor, getTicketStatusTypeColor } from 'src/utils';

/**
 * -------------------
 * REVIEW ITEM
 * -------------------
 */
interface ReviewItemWrapperProps {
  active?: boolean;
  onClick: (e: Event) => void;
  locked?: boolean;
  hasFlag?: boolean;
  updated?: boolean;
  deleted?: boolean;
  remove?: boolean;
}

export const UpdatedFlag = styled.div<{ hasFlag: boolean; updated: boolean; deleted: boolean }>`
  background-color: ${({ updated, deleted }) =>
    updated ? vars.$greenlight : deleted ? vars.$redlight : 'transparent'};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  width: ${({ hasFlag }) => (hasFlag ? '0.875rem' : '0')};
  display: flex;
  align-items: center;
  transition: all 150ms;
  span {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    letter-spacing: 1px;
    font-size: 11px;
    line-height: 12px;
    color: white;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusText = styled.div`
  /* font-weight: bold; */
  line-height: normal;
`;

export const StatusCircle = styled.div<{ ticketStatusType: typeof enumValues.TicketStatusType[number] }>`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${(props) => getTicketStatusTypeColor(props.ticketStatusType, 'dark')};
  margin-right: 0.35rem;
`;

export const ReviewItemTop = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  padding: 0.7rem 0.5rem;
`;

export const ReviewItemBottom = styled.div<{ ticketStatusType: typeof enumValues.TicketStatusType[number] }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0 1px 2px rgb(0, 0, 0, 0.12);
  /* color: ${(props) => getTicketStatusTypeColor(props.ticketStatusType, 'dark')};
  background-color: ${(props) => getTicketStatusTypeColor(props.ticketStatusType, 'light')}; */
  font-size: 0.65rem;
  padding: 0.1rem 0.5rem;
  border-bottom-right-radius: ${vars.$border_radius};
  border-bottom-left-radius: ${vars.$border_radius};
  transition: all 150ms, color 0ms;
`;

export const ChannelIconWrapper = styled.div<{ channel?: string | number | null; withAvatar?: boolean }>`
  position: relative;
  display: flex;
  width: 2rem;
  height: 2rem;
  line-height: 1.1rem;
  padding: 0.5rem 0;
  border-radius: 50%;
  font-size: 62%;
  text-align: center;
  margin-right: ${(props) => {
    return props.withAvatar ? '2rem' : '0.75rem';
  }};
  background-color: ${(props): string => {
    return getChannelColor(props.channel);
  }};

  .asdf {
    margin: 50px;
    padding: 50px;
    border: 1px solid red;
  }
`;

export const ReviewItemWrapper = styled.div<ReviewItemWrapperProps>`
  display: flex;
  position: relative;
  padding-left: ${({ hasFlag }) => (hasFlag ? '0.875rem' : '0rem')};
  flex-direction: column;
  border-radius: ${vars.$border_radius};
  margin-bottom: 0.75rem;
  box-shadow: ${vars.$shadow_1};
  transition: all 150ms, box-shadow 150ms cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;

  &:hover {
    box-shadow: ${vars.$shadow_2};
  }
  &:active {
    background-color: ${darken(vars.$greenpale, 0.025)};
  }

  pointer-events: ${(props) => {
    return props.locked && 'none';
  }};

  ${(props) => {
    return (
      props.active &&
      css`
        background-color: ${vars.$greenlight};
        box-shadow: none;
        color: ${vars.$white};
        cursor: default;
        transition: all 150ms;

        &:hover {
          cursor: default;
          box-shadow: none;
        }
        &:active {
          background-color: ${vars.$greenlight};
        }

        ${UpdatedFlag} {
          background-color: ${vars.$green};
          span {
            color: ${vars.$greenpale};
          }
        }

        ${ReviewItemBottom} {
          background-color: ${lighten(vars.$greenlight, 0.1)};
          color: ${vars.$white};
        }

        ${StatusCircle} {
          background-color: ${vars.$white};
        }

        ${ChannelIconWrapper} {
          background-color: ${vars.$white};
          svg {
            path: {
              fill: ${darken(vars.$red, 0.15)} !important;
            }
          }
        }
      `
    );
  }}

  ${(props) => {
    return (
      props.updated &&
      !props.active &&
      css`
        background-color: ${darken(vars.$greenpale, 0.025)};
      `
    );
  }}

${(props) => {
    return (
      props.active &&
      props.deleted &&
      css`
        background-color: ${darken(vars.$red, 0.15)};

        ${Avatar} {
          background-color: ${darken(vars.$red, 0.15)};
        }

        ${UpdatedFlag} {
          background-color: ${darken(vars.$red, 0.35)};
          span {
            color: ${vars.$white};
          }
        }
        ${ReviewItemBottom} {
          background-color: ${vars.$red};
          color: ${vars.$white};
        }
      `
    );
  }}
`;

export const Overlay = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
`;

export const StarsDateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DateWrapper = styled.div`
  font-size: ${vars.$font_size_extra_extra_sm};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0.25rem;
`;

export const ReviewItemLeft = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
`;

export const ReviewItemRight = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  flex-flow: column nowrap;
`;

export const TitleWrapper = styled.div`
  display: flex;
`;

const ReviewItemText = styled.div`
  margin: 0;
  width: 100%;
  height: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Location = styled(ReviewItemText)`
  font-size: 1rem;
`;

export const Customer = styled(ReviewItemText)`
  font-size: 0.75rem;
  line-height: 1.2rem;
`;

export const ServiceType = styled.div<{ active?: boolean }>`
  /* margin: 0 8px 0 0 !important; */
  padding: 3px 5px 3px 6px;
  text-transform: uppercase;
  font-size: 0.65rem;
  height: 1.1rem;
  line-height: 0.75rem;
  letter-spacing: 0.03125rem;
  font-weight: 400;
  color: white;
  background-color: ${vars.$greenlight};

  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: ${vars.$border_radius};
  border-bottom-right-radius: ${vars.$border_radius};

  ${(props) => {
    /* prettier-ignore */
    return props.active && css`
      background-color: white;
      border-top-left-radius: 0.2rem;
      color: ${vars.$greenlight};
    `;
  }};
`;
