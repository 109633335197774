import React, { ReactNode, useState } from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';
import { Accordion } from 'src/components';
import vars from 'src/styles/variables';
import ButtonLink from './ButtonLink';

interface ItemProps {
  children: React.ReactElement | ReactNode;
  title: string | React.ReactElement;
  id: string;
  preventOpen?: boolean;
}

interface AccordionListComponentProps {
  children: React.ReactElement<ItemProps> | React.ReactElement<ItemProps>[];
  title?: string;
  icon?: string;
  titlePadding?: string;
  action?: {
    onClick: (e: any) => void;
    content?: string;
    icon?: IconName;
    iconRight?: IconName;
    color?: string;
  };
}

const ItemWrapper = styled.div`
  border-bottom: 1px solid ${vars.$gray_e};
  &:last-of-type {
    border-bottom: 0;
  }
`;
const ItemContent = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
`;
const ItemToggle = styled(ItemContent)<{ isOpen: boolean }>`
  cursor: pointer;
  justify-content: space-between;
  svg {
    transition: transform 300ms;
    transform: ${(props) => {
      return props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)';
    }};
  }
`;
const CollapsableList = styled.div`
  display: flex;
  flex-direction: column;
  color: ${vars.$gray_9};
`;

const ListTitle = styled(ItemContent)<{ titlePadding?: string }>`
  justify-content: space-between;
  border-bottom: 1px solid ${vars.$gray_e};
  ${({ titlePadding }) => titlePadding && `padding: ${titlePadding}`}
`;

const AccordionContent = styled.div``;

const Item = (props: ItemProps) => {
  const { title, children, id, preventOpen } = props;

  const [height, setHeight] = useState<number | string>(0);
  const isOpen = height === 'auto';
  function setOpen() {
    !preventOpen && setHeight(height === 0 ? 'auto' : 0);
  }

  React.useEffect(() => {
    preventOpen && setHeight(0);
  }, [preventOpen]);

  return (
    <ItemWrapper>
      <ItemToggle
        className="toggle_accordion"
        onClick={setOpen}
        isOpen={isOpen}
        aria-expanded={height !== 0}
        aria-controls={id}
      >
        {title && title}
        {!preventOpen && <FontAwesomeIcon icon="chevron-down" />}
      </ItemToggle>
      <Accordion height={height} id={id}>
        <AccordionContent>{children}</AccordionContent>
      </Accordion>
    </ItemWrapper>
  );
};

class AccordionListComponent extends React.Component<AccordionListComponentProps> {
  static Item = Item;
  static Title = ListTitle;

  static displayName = 'AccordionList';

  render(): React.ReactNode {
    const { children, title, icon, action, titlePadding, ...rest } = this.props;

    return (
      <>
        <CollapsableList {...rest}>
          {title && (
            <ListTitle titlePadding={titlePadding}>
              <span>
                {icon && <FontAwesomeIcon size="sm" icon={icon as IconName} />}
                {title}
              </span>
              {action && (
                <ButtonLink
                  color={action.color}
                  icon={action.icon}
                  iconRight={action.iconRight}
                  content={action.content}
                  onClick={action.onClick}
                />
              )}
            </ListTitle>
          )}
          {children}
        </CollapsableList>
      </>
    );
  }
}

export const AccordionList = styled(AccordionListComponent)``;

export default AccordionList;
