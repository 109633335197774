import { AxiosResponse } from 'axios';
import axios from 'src/axios';
import {
  ResponseOrganization,
  PlaceSuggestion,
  UserSuggestion,
  ResponseOrganizationVariables,
  SuggestResponseOrganizationVariables,
} from '../types';

export async function getResponseOrganization(responseOrganizationUlid: string): Promise<ResponseOrganization> {
  const { data } = await axios.get(
    `${process.env.REACT_APP_GO_API}/response-organizations/${responseOrganizationUlid}`
  );
  return data;
}

export async function suggestResponseOrganizationPlaces(
  variables: SuggestResponseOrganizationVariables
): Promise<PlaceSuggestion[]> {
  const { responseOrganizationUlid, query } = variables;
  const { data } = await axios.get(
    `${process.env.REACT_APP_GO_API}/response-organizations/${responseOrganizationUlid}/suggest-places?query=${query}`
  );
  return data;
}

export async function suggestResponseOrganizationUsers(
  variables: SuggestResponseOrganizationVariables
): Promise<UserSuggestion[]> {
  const { responseOrganizationUlid, query } = variables;
  const { data } = await axios.get(
    `${process.env.REACT_APP_GO_API}/response-organizations/${responseOrganizationUlid}/suggest-users?query=${query}`
  );
  return data;
}

export async function listResponseOrganizations(): Promise<ResponseOrganization[]> {
  const { data } = await axios.get(`${process.env.REACT_APP_GO_API}/response-organizations`);
  return data;
}

export function createResponseOrganization(
  variables: ResponseOrganizationVariables
): Promise<AxiosResponse<ResponseOrganization>> {
  const { name } = variables;
  return axios.post(`${process.env.REACT_APP_GO_API}/response-organizations`, {
    name,
  });
}
