import React from 'react';
import styled, { css } from 'styled-components/macro';
import vars from 'src/styles/variables';
import { CommentBox, NoteSection } from '../ResponseNotes/ResponseNotes.styled';

export const ApproveRejectWrapper = styled.div<{ currentUserHasAlreadyApproved: boolean }>`
  ${({ currentUserHasAlreadyApproved }) =>
    !currentUserHasAlreadyApproved &&
    css`
      border: ${vars.$border};
      border-radius: ${vars.$border_radius};
      background-color: white;
      padding: 0.25rem 1rem 1rem;
      margin-top: -1rem;
      margin-right: 0.75rem;
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}

  + ${NoteSection} {
    margin-top: 1rem;
  }

  ${CommentBox} {
    margin-bottom: -0.5rem;
  }
`;

export const ApproveDeniedButtons = styled.div`
  display: flex;
  justify-content: center;
`;
