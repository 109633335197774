/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from 'src/axios';
import { ReviewResponseTicketCollection, ReviewResponseTicket } from '../types/api';
import { ListResponseTicketVariables } from '../types/async';

function prepareFiltersFromData(data: ReviewResponseTicketCollection) {
  return data.records.map((record) => ({
    ...record,
    // @ts-ignore
    channel: parseInt(record.channel) as typeof enumValues.ChannelType[number],
    // @ts-ignore
    type: parseInt(record.type) as typeof enumValues.ReviewType[number],
    // @ts-ignore
    status: parseInt(record.status) as typeof enumValues.TicketStatusType[number],
  }));
}

export async function listReviewResponseTicketsInbox(
  variables: ListResponseTicketVariables,
  pageParam = 1
): Promise<ReviewResponseTicketCollection> {
  const { responseOrganizationUlid, filters, pagination } = variables;
  const { data } = await axios.post(`${process.env.REACT_APP_GO_API}/review-response-tickets`, {
    responseOrganizationUlid,
    filters,
    pagination: {
      page: pageParam,
      perPage: pagination.perPage,
    },
    query: variables.query,
  });

  return { meta: data.meta, records: prepareFiltersFromData(data) };
}

export async function getReviewResponseTicket(responseTicketUlid: string): Promise<ReviewResponseTicket> {
  const { data } = await axios.get(`${process.env.REACT_APP_GO_API}/review-response-tickets/${responseTicketUlid}`);
  return data;
}
