import { createGlobalStyle } from 'styled-components/macro';
import { ModalCard, ModalHeading, ModalCardBody, ModalFooter } from 'src/components/Modal/Modal.styled';
import { RTDCustomStyles } from 'src/components/Table';
import vars from './variables';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    scroll-behavior: smooth;
    background-color: ${vars.$gray_fa};
    color: ${vars.$gray_6};
    font-family: 'Open Sans', sans-serif, 'Helvetica Neue', Arial, Helvetica;
  }

  /* for some reason these need to be not-nested */
  body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
  }
  body ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0px;
  }
  body ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 0 !important;
    background: rgba(0, 0, 0, 0.25);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }

  /* --------------------------------- */
  /* FOR JEST VALIDATION ONLY          */
  /* DO NOT DELETE                     */
  /* --------------------------------- */
  .testing_class_name {
    border: 1px solid rebeccapurple;
  }

  /* --------------------------------- */
  /* REACT-DATA-TABLE-COMPONENT-STYLES */
  /* --------------------------------- */
  ${RTDCustomStyles}

  /* ------------------ */
  /* REACT-MODAL STYLES */
  /* ------------------ */
  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Content {
    position: absolute;
    top: 2.5rem;
    left: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    background-color: transparent;

    @media (max-width: ${vars.$md}) {
      ${ModalCard},
      ${ModalHeading},
      ${ModalFooter} {
        border-radius: 0;
        border: none;
      }

      ${ModalCardBody} {
        border-left: 0;
        border-right: 0;
      }

      top: 4rem;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 0;
      border: 0;
      width: 100%;
    }
  }

  .ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 300ms;
    opacity: 0;
    box-shadow: ${vars.$deep_shadow};
  }
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;

export default GlobalStyle;
