import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { REVIEW_RESPONSE_TICKET_KEY } from '../queryKeys.utils';
import { ReviewResponseTicket } from '../types/api';
import { getReviewResponseTicket } from './responseTickets';

export function useGetReviewResponseTicket(responseTicketUlid: string): UseQueryResult<ReviewResponseTicket> {
  return useQuery({
    queryKey: [REVIEW_RESPONSE_TICKET_KEY, responseTicketUlid],
    queryFn: () => getReviewResponseTicket(responseTicketUlid),
    enabled: Boolean(responseTicketUlid),
  });
}
