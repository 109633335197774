import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetResponsePlace } from 'src/async';
import { ResponsePlace } from 'src/async/types';
import { ErrorBlock, Alert, SubNavbar, ScrollWrapper } from 'src/components';
import {
  LIST_RESPONSE_APPROVERS,
  LIST_RESPONSE_PLACE_PROFILE_CHANNELS,
  LIST_RESPONSE_NOTIFICATION_RECIPIENTS,
} from 'src/graph';
import { withDataFetcher } from 'src/hooks';
import { LocationSettingsRouteParams } from 'src/views/Settings/SettingsTypes';
import { LoadingSubsectionPlaceholder } from '../../settings.styled';
import {
  ActivationSettings,
  ApprovalSettings,
  ApproverManagement,
  ChannelManagement,
  RedAlertSettings,
  ResponseTemplate,
  SignatureManagement,
  SavedNotification,
  NotificationManagement,
  ResponseSettings,
} from './components';

const ApproverManagementWrapper = withDataFetcher(ApproverManagement);
const ChannelManagementWrapper = withDataFetcher(ChannelManagement);
const NotificationManagementWrapper = withDataFetcher(NotificationManagement);

const LocationSettingsPage = (props: { params: LocationSettingsRouteParams }): React.ReactElement => {
  const { params } = props;
  const [activationError, setActivationError] = React.useState<string>();

  // * Controls SubNavbar SavedNotification state
  const [saving, setSaving] = React.useState(false);

  const {
    data: responsePlaceData,
    isLoading: isResponsePlaceDataLoading,
    isError: isResponsePlaceDataError,
    error: responsePlaceDataError,
  } = useGetResponsePlace(params.location);

  if (isResponsePlaceDataLoading || !responsePlaceData || !responsePlaceData.settings)
    return <SubNavbar icon="spinner-third" spin title="Loading..." />;
  if (!responsePlaceData && isResponsePlaceDataError) return <ErrorBlock error={responsePlaceDataError} />;

  const { placeName, settings }: ResponsePlace = responsePlaceData;

  const { approval_settings } = settings;
  return (
    <>
      <SubNavbar title={placeName || 'UNKNOWN'} backButton={`/settings/location-settings/${params.organization}`}>
        <SavedNotification saving={saving} />
      </SubNavbar>
      <ScrollWrapper>
        <ActivationSettings
          params={params}
          responsePlaceData={responsePlaceData}
          setActivationError={setActivationError}
          setSaving={setSaving}
          isResponsePlaceDataLoading={isResponsePlaceDataLoading}
        />
        {activationError && (
          <Alert>
            <>
              <h4>{'Cannot activate at this time, please resolve the following error'}</h4>
              <FontAwesomeIcon icon="ban" />
              {activationError}
            </>
          </Alert>
        )}
        <ResponseSettings
          params={params}
          responsePlaceData={responsePlaceData}
          setSaving={setSaving}
          isResponsePlaceDataLoading={isResponsePlaceDataLoading}
        />
        <ApprovalSettings
          params={params}
          responsePlaceData={responsePlaceData}
          setSaving={setSaving}
          isResponsePlaceDataLoading={isResponsePlaceDataLoading}
        />
        {approval_settings.require_type != 0 && approval_settings.require_type != 3 && (
          <ApproverManagementWrapper
            params={params}
            query={LIST_RESPONSE_APPROVERS}
            queryOptions={{ variables: { placeUlid: params.location } }}
            setSaving={setSaving}
            customLoadingMessage={<LoadingSubsectionPlaceholder />}
          />
        )}
        {approval_settings.require_type == 3 && (
          <NotificationManagementWrapper
            params={params}
            query={LIST_RESPONSE_NOTIFICATION_RECIPIENTS}
            queryOptions={{ variables: { placeUlid: params.location } }}
            setSaving={setSaving}
            customLoadingMessage={<LoadingSubsectionPlaceholder />}
          />
        )}
        <SignatureManagement params={params} setSaving={setSaving} />
        <ChannelManagementWrapper
          params={params}
          query={LIST_RESPONSE_PLACE_PROFILE_CHANNELS}
          queryOptions={{ variables: { placeUlid: params.location } }}
          setSaving={setSaving}
          responsePlaceData={responsePlaceData}
          customLoadingMessage={<LoadingSubsectionPlaceholder />}
        />
        <ResponseTemplate params={params} setSaving={setSaving} />
        <RedAlertSettings
          params={params}
          responsePlaceData={responsePlaceData}
          setSaving={setSaving}
          isResponsePlaceDataLoading={isResponsePlaceDataLoading}
        />
      </ScrollWrapper>
    </>
  );
};
export default LocationSettingsPage;
