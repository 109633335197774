import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation } from 'react-router-dom';
import { lighten } from 'color2k';
import styled, { css } from 'styled-components/macro';
import vars from 'src/styles/variables';
import { removeStyleAttributes } from 'src/utils';

interface WysiwygProps {
  name?: string;
  id?: string;
  value: string;
  onChange: (args: any) => void;
}

const toolbarButtonMixin = (color: string) => {
  return css`
    color: ${color};
    .ql-stroke {
      stroke: ${color};
    }
    .ql-fill {
      fill: ${color};
    }
  `;
};

const PReset = css`
  p {
    margin: 0 !important;
    font-size: 1rem !important;
    padding: 0;
  }
`;

const WysiwygWrapper = styled.div<{ locationSettings?: boolean }>`
  color: ${vars.$gray_9};
  ${({ locationSettings }) =>
    locationSettings &&
    css`
      .ql-tooltip {
        transform: translate(142px, 10px);
      }
    `}
  .ql-toolbar {
    border-color: ${vars.$border_color};
    border-top-left-radius: ${vars.$border_radius};
    border-top-right-radius: ${vars.$border_radius};
    button {
      ${toolbarButtonMixin(vars.$gray_9)}
      &.ql-active {
        ${toolbarButtonMixin(vars.$greenlight)}
      }
      &:hover {
        ${toolbarButtonMixin(vars.$greenlight)}
      }
    }
    .ql-picker {
      &.ql-expanded {
        .ql-picker-options {
          color: ${vars.$gray_9};
          .ql-picker-item {
            &:hover {
              color: ${vars.$greenlight};
            }
          }
        }
      }
      .ql-picker-label {
        ${toolbarButtonMixin(vars.$gray_9)}
        &:hover {
          ${toolbarButtonMixin(vars.$greenlight)}
        }
      }
    }
  }
  .ql-container {
    border-color: ${vars.$border_color};
    border-bottom-left-radius: ${vars.$border_radius};
    border-bottom-right-radius: ${vars.$border_radius};
    .ql-editor {
      color: ${vars.$gray_7};
      min-height: 8rem;

      ${PReset}

      a {
        color: ${vars.$greenlight};
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          color: ${lighten(vars.$greenlight, 0.1)};
        }
      }
    }
  }
  .ql-blank::before {
    color: ${vars.$gray_9};
    top: 15px;
  }
`;

const ContentRenderer = styled.div`
  ${PReset}
`;

export const WysiwygContentRenderer = (props: { content?: string | null }): JSX.Element => {
  return (
    <ContentRenderer
      className="ql-editor"
      dangerouslySetInnerHTML={{
        __html: props.content || '',
      }}
    />
  );
};

/**
 * # react-quill
 * https://github.com/zenoamaro/react-quill
 *
 * The default theme is theme="snow".
 *
 * The props `value` and `onChange` are required,
 *   primarily to enable full functaionality in Formik via InputElement
 */
const Wysiwyg = (props: WysiwygProps): JSX.Element => {
  const { value, onChange, ...rest } = props;
  const location = useLocation();

  return (
    <WysiwygWrapper locationSettings={location.pathname.indexOf('location-settings') > -1}>
      <ReactQuill
        value={value}
        onChange={(content) => {
          return onChange(removeStyleAttributes(content));
        }}
        {...rest}
      />
    </WysiwygWrapper>
  );
};

export default Wysiwyg;
