import { lighten, darken } from 'color2k';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';
import Button from './Button';

const ButtonLink = styled(Button).attrs({
  blank: true,
})<{ hoverColor?: string }>`
  padding: 0;
  font-size: ${vars.$font_size_sm};
  color: ${(props) => (props.color ? props.color : vars.$gray_9)};
  &:hover {
    color: ${(props) =>
      props.hoverColor ? lighten(props.hoverColor, 0.1) : props.color ? lighten(props.color, 0.1) : vars.$greenlight};
  }
  &:active {
    color: ${(props) => (props.hoverColor ? darken(props.hoverColor, 0.08) : darken(vars.$greenlight, 0.08))};
  }
`;

export default ButtonLink;
