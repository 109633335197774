import { darken, desaturate, lighten, toHex } from 'color2k';
import { defaultRebootTheme } from 'styled-reboot';

export const colorVars = {
  $greendark: '#204a31',
  $green: '#438262',
  $greenlight: '#5dc094',
  $greenlighter: toHex(lighten('#5dc094', 0.15)),
  $greenpale: '#f0f9f5',
  $infodark: toHex(desaturate(darken('#2296f3', 0.15), 0.2)),
  $info: '#2296f3',
  $infolight: toHex(lighten('#2296f3', 0.15)),
  $blue: '#71c8fe',
  $yellowdarker: toHex(desaturate(darken('#F2AB27', 0.1), 0.1)),
  $yellowdark: '#F2AB27',
  $yellow: '#eedf35',
  $yellowlight: toHex(lighten('#eedf35', 0.15)),
  $red: '#f16b6b',
  $redlight: toHex(lighten('#f16b6b', 0.15)),
  $redlighter: '#f8d7da',
  $redlighterborder: '#f5c6cb',
  $graylight: '#cccbcb',
  $graymid: '#999999',

  $transparent: 'rgba(0,0,0,0)',
  $white: '#ffffff',
  $gray_fa: '#fafafa', // gray_100
  $gray_f4: '#f4f4f4',
  $gray_e: '#eeeeee', // gray_200
  $gray_d: '#dddddd', // gray_300
  $gray_c: '#cccccc', // gray_400
  $gray_b: '#bbbbbb',
  $gray_a: '#aaaaaa', // gray_500
  $gray_9: '#999999',
  $gray_8: '#888888',
  $gray_7: '#777777',
  $gray_6: '#666666', // gray_600
  $gray_5: '#555555',
  $gray_4: '#444444', // gray_700
  $gray_3: '#333333', // gray_800
  $gray_2: '#222222', // gray_900
  $gray_1: '#111111',
  $black: '#000000',
};

export const rebootTheme = {
  ...defaultRebootTheme,
  // overrides
  linkHoverDecoration: 'underline',
  linkColor: colorVars.$greenlight,
  linkHoverColor: lighten(colorVars.$greenlight, 0.1),
  linkDecoration: 'inherit',
  labelMarginBottom: '0',
};

export const themeVars = {
  $primary: colorVars.$greenlight,
  $secondary: colorVars.$graylight,
  $danger: colorVars.$red,
  $success: colorVars.$greenlight,
  $muted: colorVars.$gray_9,

  $body_color: colorVars.$gray_6,
};

export const channelVars = {
  $facebook: '#1877f2',
  $yelp: '#d32323',
  $google: '#4285f4',
  $carfax: '#555555',
  $carscom: '#522280',
  $dealerrater: '#f97412',
  $cargurus: '#ff3333',
};

export const spacingVars_numbers = {
  $spacing_0: 0,
};

export const spacingVars = {
  $spacing_none: '0',
  $spacing_extra_extra_small: '0.10rem',
  $spacing_extra_small: '0.25rem',
  $spacing_small: '0.5rem',
  $spacing_medium: '0.75rem',
  $spacing_medium_lg: '0.9rem',

  $spacing: '1rem',
  $spacing_medium_large: '1.5rem',

  $spacing_large: '2rem',
  $spacing_extra_large: '4rem',
  $spacing_extra_extra_large: '8rem',
  $spacing_extra_extra_extra_large: '16rem',
};
export const zIndexes = {
  $dropdown: 2,
};
export const breakpointVars = {
  $xs: '22rem',
  $sm: '36rem',
  $md: '48rem',
  $lg: '62rem',
  $xl: '75rem',
};

export const containerMaxWidths = {
  /**spacing_large
   * Using defaults from bootstrap-4
   *   sm: 540px  --> 33.75rem
   *   md: 720px  --> 45rem
   *   lg: 960px  --> 60rem
   *   xl: 1140px --> 71.25rem
   **/
  $container_sm: '33.75rem',
  $container_md: '45rem',
  $container_lg: '60rem',
  $container_xl: '71.25rem',
};

export const miscVars = {
  $debug: 'border: 1px solid red; * { border: 1px solid red; }',
  $forceWrap: 'overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; hyphens: auto;',
};

export const borderVars = {
  $border_radius: '0.25rem',
  $border_radius_circle: '1rem',
  $border: `1px solid ${colorVars.$gray_d}`,
  $border_color: colorVars.$gray_d,
};

export const shadowVars = {
  $shallow_shadow: '0 2px 4px rgba(0, 0, 0, 0.125)',
  $deep_shadow: '0 10px 10px -6px rgba(0,0,0,.1)',
  $deep_shadow_md: '0px 3px 13px -5px rgba(0, 0, 0, 0.75)',
  $shadow_light: ' 0px 1px 6px 2px rgba(0, 0, 0, 0.13)',
  $shadow_dark: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',

  $shadow_1: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.15)',
  $shadow_2: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.125)',
  $shadow_3: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.125)',
  $shadow_4: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.1)',
  $shadow_5: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.1)',
};

export const fontVars_numbers = {
  $font_size_raw: 1,
  $font_size_sm_raw: 0.875,
  $font_size_lg_raw: 1.25,
  $font_weight_normal: 400,
  $line_height_base: 1.4375,
};

export const fontVars = {
  // $font_family: '"Lato", sans-serif',
  $font_color: colorVars.$gray_3,
  $font_size_global: '14px',
  $font_size: '1rem',
  $font_size_sm: '0.875rem',
  $font_size_extra_sm: '0.8rem',
  $font_size_extra_extra_sm: '0.6rem',
  $font_size_lg: '1.25rem',
  $font_size_extra_lg: '1.5rem',
  $font_size_extra_extra_lg: '2rem',
};

export const theme = {
  /**
   * These are the defaults from reflexbox (via system-ui)
   *   sm: 40em  --> 640px
   *   md: 52em  --> 832px
   *   lg: 64em  --> 1,024px
   *
   * Using defaults from bootstrap-4
   *   sm: 576px  --> 36rem
   *   md: 768px  --> 48rem
   *   lg: 992px  --> 62rem
   *   xl: 1200px --> 75rem
   **/
  breakpoints: [breakpointVars.$sm, breakpointVars.$md, breakpointVars.$lg, breakpointVars.$xl],
  space: [
    spacingVars.$spacing_none, // 0
    spacingVars.$spacing_extra_small, // 1
    spacingVars.$spacing_small, // 2
    spacingVars.$spacing_medium, // 3
    spacingVars.$spacing, // 4
    spacingVars.$spacing_large, // 5
    spacingVars.$spacing_extra_large, // 6
    spacingVars.$spacing_extra_extra_large, // 7
    spacingVars.$spacing_extra_extra_extra_large, // 8
  ],
};

export const vars = {
  ...colorVars,
  ...themeVars,
  ...fontVars_numbers,
  ...fontVars,
  ...channelVars,
  ...spacingVars,
  ...miscVars,
  ...borderVars,
  ...shadowVars,
  ...breakpointVars,
  ...containerMaxWidths,
  ...zIndexes,
};

export default vars;
