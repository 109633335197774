import React from 'react';
import { Placement } from '@popperjs/core';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';
import ConditionalWrapper from '../ConditionalWrapper';
import Tooltip from '../Tooltip';

type ToggleProps = {
  onChange: (e: React.ChangeEvent<any>) => void;
  htmlFor?: string;
  hasError?: boolean;
  checked?: boolean;
  disabled?: boolean;
  name?: string;
  id?: string;
  value?: string;
  labelRight?: string | React.ReactElement;
  labelLeft?: string | React.ReactElement;
  sliderWidth?: number;
  sliderHeight?: number;
  width?: number;
  height?: number;
  translate?: any;
  testId?: string;
  tooltip?: string;
  tooltipPlacement?: Placement;
};

export const ToggleContainer = styled.label`
  display: flex;
  align-items: center;
`;

const InputContainer = styled.label<Pick<ToggleProps, 'width' | 'height'>>`
  position: relative;
  display: inline-block;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  > input {
    display: none;
  }
`;

type InputProps = Pick<ToggleProps, 'translate' | 'hasError'>;

export const ToggleInput = styled.input<InputProps>`
  &:checked + span {
    background-color: ${({ hasError }) => {
      return hasError ? vars.$redlight : vars.$greenlight;
    }};
  }
  &:disabled + span {
    background-color: ${({ hasError }) => {
      return hasError ? vars.$redlight : vars.$secondary;
    }};
    opacity: 0.4;
    cursor: not-allowed;
  }
  &:disabled:checked + span {
    background-color: ${({ hasError }) => {
      return hasError ? vars.$redlight : vars.$greenlight;
    }};
    opacity: 0.4;
    cursor: not-allowed;
  }
  &:focus + span {
    box-shadow: 0 0 1px #2196f3;
  }
  &:checked + span:before {
    -webkit-transform: translateX(${({ translate }) => translate}px);
    -ms-transform: translateX(${({ translate }) => translate}px);
    transform: translateX(${({ translate }) => translate}px);
  }
`;

type SliderProps = Pick<ToggleProps, 'sliderHeight' | 'sliderWidth' | 'hasError'>;

const Slider = styled.span<SliderProps>`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ hasError }) => {
    return hasError ? vars.$redlight : vars.$secondary;
  }};
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 34px;
  &:before {
    position: relative;
    border-radius: 50%;
    content: '';
    height: ${({ sliderHeight }) => sliderHeight}px;
    width: ${({ sliderWidth }) => sliderWidth}px;
    left: 4px;
    background-color: ${({ hasError }) => {
      return hasError ? vars.$red : 'white';
    }};
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }
`;

export const ToggleLabel = styled.span<Pick<ToggleProps, 'height' | 'disabled' | 'hasError' | 'htmlFor'>>`
  font-size: 0.85rem;
  padding: 0 0.5rem;
  line-height: 1;
  height: ${({ height }) => height}px;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  color: ${({ hasError }) => {
    return hasError ? vars.$red : vars.$gray_7;
  }};
  opacity: ${({ disabled }) => {
    return disabled ? 0.4 : 1;
  }};
  svg {
    margin-left: 0.5rem;
    margin-top: 1px;
    height: ${({ height }) => height}px;
    color: ${({ hasError }) => {
      return hasError ? vars.$red : vars.$greenlight;
    }};
  }
`;

/**
 * Toggle from react-styled-toggle
 * @description Ripped from https://github.com/guillaumemorin/react-styled-toggle, fixed typing bugs, made better - KB
 */
const Toggle = ({
  onChange,
  hasError,
  checked,
  disabled,
  width,
  height,
  translate,
  name,
  id,
  value,
  labelRight,
  labelLeft,
  sliderWidth,
  sliderHeight,
  testId,
  tooltip,
  tooltipPlacement,
}: ToggleProps): React.ReactElement => {
  return (
    <ConditionalWrapper
      condition={Boolean(tooltip)}
      wrapper={(children) => (
        <Tooltip content={tooltip || ''} placement={tooltipPlacement || 'auto'}>
          {children}
        </Tooltip>
      )}
    >
      <ToggleContainer>
        {labelLeft && (
          <ToggleLabel htmlFor={id} height={height}>
            {labelLeft}
          </ToggleLabel>
        )}
        <InputContainer width={width} height={height}>
          <ToggleInput
            type="checkbox"
            data-testid={testId}
            name={name}
            id={id}
            value={value}
            checked={Boolean(value || checked)}
            disabled={disabled}
            translate={translate}
            onChange={onChange}
            hasError={hasError}
          />
          <Slider sliderWidth={sliderWidth} sliderHeight={sliderHeight} hasError={hasError} />
        </InputContainer>
        {labelRight && (
          <ToggleLabel htmlFor={id} disabled={disabled} hasError={hasError} height={height}>
            {labelRight}
          </ToggleLabel>
        )}
      </ToggleContainer>
    </ConditionalWrapper>
  );
};

// Toggle.defaultProps = {
//   bgChecked_default: vars.$greenlight,
//   bgUnchecked_default: vars.$secondary,
//   bgButton_default: '#fff',
//   sliderWidth: 26,
//   sliderHeight: 26,
//   width: 60,
//   height: 34,
//   translate: 26,
// };

Toggle.defaultProps = {
  width: 30,
  height: 14,
  sliderWidth: 8,
  sliderHeight: 8,
  translate: 14,
};

export default styled(Toggle)``;
