import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { ImageProps } from 'src/types';

const Google = ({ width = '100%', active, deleted }: ImageProps): ReactElement => (
  <svg
    x="0"
    y="0"
    version="1"
    width={width}
    height={width}
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    enableBackground="new 0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={deleted && active ? '#eb2424' : active ? '#5dc094' : '#ffffff'}
      d="M63.48 32.75C63.48 51.01 50.98 64 32.52 64c-17.7 0-32-14.3-32-32s14.3-32 32-32c8.62 0 15.87 3.16 21.46 8.37l-8.71 8.37C33.87 5.75 12.68 14.01 12.68 32c0 11.16 8.92 20.21 19.83 20.21 12.67 0 17.42-9.08 18.17-13.79H32.52V27.41h30.46c.3 1.64.5 3.21.5 5.34z"
    />
  </svg>
);

Google.propTypes = {
  width: PropTypes.string,
  active: PropTypes.bool,
};

export default Google;
