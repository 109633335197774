import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { RESPONSE_ORGANIZATIONS_KEY, RESPONSE_ORGANIZATION_KEY } from '../queryKeys.utils';
import { ResponseOrganization } from '../types/api';
import { ResponseOrganizationVariables, SharedMutationResult } from '../types/async';
import {
  createResponseOrganization,
  getResponseOrganization,
  listResponseOrganizations,
} from './responseOrganizations';

export function useGetResponseOrganization(responseOrganizationUlid: string): UseQueryResult<ResponseOrganization> {
  return useQuery({
    queryKey: [RESPONSE_ORGANIZATION_KEY, responseOrganizationUlid],
    queryFn: () => getResponseOrganization(responseOrganizationUlid),
  });
}

export function useListResponseOrganizations(enabled = true): UseQueryResult<ResponseOrganization[]> {
  return useQuery({
    queryKey: [RESPONSE_ORGANIZATIONS_KEY],
    queryFn: listResponseOrganizations,
    refetchOnWindowFocus: false,
    enabled,
  });
}

export function useCreateResponseOrganization(): SharedMutationResult<
  ResponseOrganization,
  ResponseOrganizationVariables
> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createResponseOrganization,
    onSuccess: () => {
      queryClient.invalidateQueries([RESPONSE_ORGANIZATIONS_KEY]);
    },
  });
}
