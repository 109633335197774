import React from 'react';
import { ResponseSignature } from '@friendemic/erc-graph';
import { ReviewTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_type_friendly';
import { ReviewType } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/types_pb';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { MixedSchema } from 'yup/lib/mixed';
import { Ulid } from 'src/async/types';
import { Row, Column, InputElement } from 'src/components';
import { InputElementFormConfig } from 'src/types';
import { enumValues, selectOptions } from 'src/utils';

export type FormValues = {
  content: string;
  signsReviewType: typeof enumValues.ReviewType[number];
  ulid: Ulid;
  placeUlid: Ulid;
};

export const initialFormValues: FormValues = {
  content: '',
  signsReviewType: 1,
  ulid: '',
  placeUlid: '',
};

export const validationSchema = (
  existingSignatures: ResponseSignature[],
  activeEditingSignature: Partial<ResponseSignature>
): MixedSchema =>
  Yup.object({
    content: Yup.string().required('Required'),
    signsReviewType: Yup.number()
      /**
       ** This is a just-in-case catch-all
       */
      .test('ban-ReviewType.ALL', (value, { path, createError }) => {
        return value === ReviewType.ALL
          ? createError({
              path: path,
              message: 'All is no longer a valid option, please use General instead',
            })
          : true;
      })
      .test('signature-with-reviewtype-already-exists', (value, { path, createError }) => {
        /**
         ** Yup requires test to return false in order to display error,
         **   but Array.some returns true if signatures already exist with the
         **   selected ReviewType. So this short circuits that system and returns
         **   error if exists === true, but returns true if exists === false....
         */
        return existingSignatures
          .filter((signature) => {
            //? Remove the current editing signature from being considered for validation duplicates
            return signature.ulid !== activeEditingSignature.ulid;
          })
          .some((signature) => {
            //? Check if some of the array already have the chosen ReviewType
            return signature.signsReviewType === value;
          })
          ? createError({
              path: path,
              message: value ? `A signature for ${ReviewTypeFriendly.toFriendly(value)} already exists` : undefined,
            })
          : true;
      })
      .required('Required'),
  });

export const formConfig: InputElementFormConfig = {
  content: {
    name: 'content',
    label: 'Signature',
    placeholder: 'First Last\nJob Title\nLocation Name\nemail@domain.com\n(123) 456-7890',
    border: true,
    type: 'textarea',
  },
  signsReviewType: {
    name: 'signsReviewType',
    label: 'Signs Review',
    type: 'select',
    menuPosition: 'fixed',
    options: selectOptions.ReviewType,
    border: true,
  },
};

const InputsKeys = Object.keys(formConfig);
export const RenderForm = (props: FormikProps<FormValues>): React.ReactElement => {
  return (
    <Row>
      {InputsKeys.map((input) => (
        <Column width={[1]} key={input}>
          <InputElement {...formConfig[input]} />
        </Column>
      ))}
    </Row>
  );
};
