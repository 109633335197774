import {
  CREATE_RESPONSE_NOTIFICATION_RECIPIENT,
  EDIT_RESPONSE_NOTIFICATION_RECIPIENT,
  LIST_RESPONSE_NOTIFICATION_RECIPIENTS,
} from 'src/graph';
import { useGraphMutation } from 'src/hooks';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useGraphMutations(location: string) {
  const { mutationMethod: createResponseNotificationRecipient, loading: createResponseNotificationRecipientLoading } =
    useGraphMutation({
      mutation: CREATE_RESPONSE_NOTIFICATION_RECIPIENT,
      options: {
        refetchQueries: [{ query: LIST_RESPONSE_NOTIFICATION_RECIPIENTS, variables: { placeUlid: location } }],
      },
      errorType: 'warn',
    });

  const { mutationMethod: editResponseNotificationRecipient, loading: editResponseNotificationRecipientLoading } =
    useGraphMutation({
      mutation: EDIT_RESPONSE_NOTIFICATION_RECIPIENT,
      options: {
        refetchQueries: [{ query: LIST_RESPONSE_NOTIFICATION_RECIPIENTS, variables: { placeUlid: location } }],
      },
      errorType: 'warn',
    });
  return {
    createResponseNotificationRecipient,
    createResponseNotificationRecipientLoading,
    editResponseNotificationRecipient,
    editResponseNotificationRecipientLoading,
  };
}
