import React from 'react';
import { ReviewResponseTicket } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_pb';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components/macro';
import { REVIEW_RESPONSE_TICKET_KEY } from 'src/async/queryKeys.utils';
import { Button } from 'src/components';
import { CLOSE_REVIEW_RESPONSE_TICKET, GET_REVIEW_RESPONSE_TICKET } from 'src/graph';
import { useGraphMutation } from 'src/hooks';
import vars from 'src/styles/variables';
import { updateGraphMutation, toast } from 'src/utils';

const OverLimitWrapper = styled.div`
  width: 100%;
  box-shadow: 3px -3px 6px rgba(0, 0, 0, 0.16);
  z-index: 1;
  padding: 1rem;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const OverLimitMessage = styled.div`
  margin-bottom: 0.75rem;
  font-weight: bold;
  font-size: 135%;
  text-align: center;
  color: ${vars.$gray_9};
`;

const OverLimitActions = styled.div`
  @media (max-width: ${vars.$md}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    button:first-of-type {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }
`;

export const OverLimit = (props: {
  placeName: string;
  reviewUlid: string;
  setShowOverLimit: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const { placeName, setShowOverLimit, reviewUlid } = props;

  const queryClient = useQueryClient();

  // ! closeReviewResponseTicket mutation for over limit
  const { mutationMethod: closeReviewResponseTicket, loading: closeReviewResponseTicketLoading } = useGraphMutation({
    mutation: CLOSE_REVIEW_RESPONSE_TICKET,
    options: {
      refetchQueries: [
        {
          query: GET_REVIEW_RESPONSE_TICKET,
          variables: {
            reviewUlid: reviewUlid,
          },
        },
      ],
      onCompleted: () => {
        queryClient.invalidateQueries([REVIEW_RESPONSE_TICKET_KEY, reviewUlid]);
      },
    },
    errorType: 'error',
  });

  async function handleConfirmOverLimit() {
    const response = await updateGraphMutation(
      {
        variables: {
          reviewUlid: reviewUlid,
          input: {
            note: 'Over Limit',
            reason: ReviewResponseTicket.CloseTicketReason.NO_RESPONSE,
          },
        },
        mutationName: 'closeReviewResponseTicket',
      },
      closeReviewResponseTicket
    );
    if (response) {
      toast({
        type: 'success',
        message: 'No Response Needed',
      });
    }
  }

  return (
    <OverLimitWrapper>
      <OverLimitMessage>
        <div>{`${placeName} is Over Limit`}</div>
      </OverLimitMessage>
      <OverLimitActions>
        <Button btnSm mr={2} disabled={closeReviewResponseTicketLoading} onClick={handleConfirmOverLimit}>
          {'Confirm'}
        </Button>
        <Button btnSm secondary disabled={closeReviewResponseTicketLoading} onClick={() => setShowOverLimit(false)}>
          {'Needs Response'}
        </Button>
      </OverLimitActions>
    </OverLimitWrapper>
  );
};

export default OverLimit;
