import { ResponseSignature } from '@friendemic/erc-graph';
import { AxiosResponse } from 'axios';
import axios from 'src/axios';
import { SignatureVariables } from '../types/async';

export async function listSignatures(placeUlid: string): Promise<ResponseSignature[]> {
  const { data } = await axios.get(`${process.env.REACT_APP_GO_API}/response-places/${placeUlid}/response-signatures`);
  return data;
}

export function createSignature(variables: SignatureVariables): Promise<AxiosResponse<ResponseSignature>> {
  return axios.post(`${process.env.REACT_APP_GO_API}/response-places/${variables.placeUlid}/response-signatures`, {
    ...variables.input,
  });
}

export function editSignature(variables: SignatureVariables): Promise<AxiosResponse<ResponseSignature>> {
  return axios.put(
    `${process.env.REACT_APP_GO_API}/response-places/${variables.placeUlid}/response-signatures/${variables.signatureUlid}`,
    {
      ...variables.input,
    }
  );
}

export function deleteSignature(variables: SignatureVariables): Promise<AxiosResponse<unknown>> {
  return axios.delete(
    `${process.env.REACT_APP_GO_API}/response-places/${variables.placeUlid}/response-signatures/${variables.signatureUlid}`
  );
}
