import React from 'react';
import { darken } from 'color2k';
import styled, { css } from 'styled-components/macro';
import { ButtonLink, Button, FormGroup } from 'src/components';
import vars from 'src/styles/variables';

export const NoteSectionWrapper = styled.div<{ responseApproval?: boolean }>`
  /* create new stacking context: https://stackoverflow.com/a/10822168/3864870*/
  z-index: 1;
  position: relative;

  @media (min-width: ${vars.$md}) {
    margin-top: 1rem;
    margin-left: 3rem;

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 2.25rem;
      height: calc(100% - 2.6rem);
      box-shadow: inset 2px -2px 0 ${vars.$gray_e}; /* Must use px to avoid subpixel rendering issue */
      display: block;
      top: 0;
      left: -1rem;
    }
  }

  ${({ responseApproval }) =>
    responseApproval &&
    css`
      padding-bottom: 0.5rem;
      margin-bottom: 1rem;
      @media (min-width: ${vars.$md}) {
        &:after {
          height: calc(100% - 1rem);
        }
      }
    `};
`;
export const NoteSectionInner = styled.div`
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: ${vars.$spacing_medium};
`;
export const NoteSection = styled.div`
  display: flex;
  &:not(:last-of-type) {
    margin-bottom: ${vars.$spacing_medium};
  }
`;
export const NoteDeleteBtn = styled(ButtonLink).attrs({
  hoverColor: vars.$gray_5,
})`
  margin-left: 0rem;
  color: ${vars.$gray_e};
  opacity: 0;
  transition: all 100ms ease-out;
  font-size: 100%;
  ${NoteSection}:hover & {
    text-decoration: undefline;
    color: ${vars.$gray_6};
    margin-left: 0.5rem;
    opacity: 1;
  }
  &:hover {
    color: ${vars.$gray_8} !important;
  }
  &:active {
    color: ${vars.$danger} !important;
  }
`;
export const NoteSectionLeft = styled.div`
  padding-right: ${vars.$spacing_small};
  flex-basis: 2.375rem;
`;
export const NoteSectionRight = styled.div<{ flexDirection?: string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 80%;
`;
export const NoteApprovedDenied = styled.div<{ approvalActionType?: number }>`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ approvalActionType }) => (approvalActionType === 3 ? vars.$danger : vars.$greenlight)};
  line-height: 1.75rem;
  margin-bottom: ${vars.$spacing_extra_small};
`;
export const NoteAttribution = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
`;
export const NoteFromName = styled.div`
  font-weight: bold;
  ${vars.$forceWrap}
`;
export const NoteFromDate = styled.div`
  margin-left: ${vars.$spacing_small};
  ${vars.$forceWrap}
`;
export const NoteContent = styled.div``;
export const CommentBox = styled.div`
  display: flex;
  align-items: baseline;
  ${FormGroup} {
    flex-grow: 1;
  }
`;

export const SubmitCommentButton = styled(Button).attrs({
  blank: true,
  title: 'Submit',
  icon: ['far', 'paper-plane'],
})`
  color: ${vars.$gray_c};
  transition: color 150ms ease-in-out;
  font-size: 150%;
  flex-shrink: 0;
  &:hover {
    color: ${vars.$greenlight};
  }
  &:active {
    color: ${darken(vars.$greenlight, 0.08)};
  }
`;
export const DeleteTextWrapper = styled.span`
  display: inline-block;
  margin-left: 0.5rem;
  background-color: ${vars.$red};
  color: ${vars.$white};
  padding: 0 ${vars.$spacing_extra_small};
  border-radius: 30px;
`;
