import { MutationFunctionOptions, FetchResult, ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { CaptureContext } from '@sentry/types';
import { toast } from 'src/utils';

type UpdateGraphMutationValues = {
  variables: any;
  mutationName: string;
  onCompleted?: () => void;
};

export async function updateGraphMutation(
  values: UpdateGraphMutationValues,
  mutationMethod: (
    options?: MutationFunctionOptions<any, Record<string, any>, any, any> | undefined
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
): Promise<void | FetchResult<any, Record<string, any>, Record<string, any>>> {
  return await mutationMethod({
    ...values,
  })
    .then((response) => {
      return response;
    })
    .catch((err: ApolloError) => {
      if (err.networkError) {
        console.error(err.networkError);
      }
      if (err) {
        Sentry.captureException(err, buildSentryContext(values));
      }
      toast({ type: 'error', message: err.message });
    });
}

function buildSentryContext(values: Record<string, any>) {
  const sentryCaptureContext: CaptureContext = {
    contexts: {
      GraphQL: {
        name: values.name,
        type: 'updateGraphMutation',
        queryOptions: JSON.stringify(values.variables),
      },
    },
  };

  return sentryCaptureContext;
}
