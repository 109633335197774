import React, { ReactElement, useState } from 'react';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResponseUser } from '@friendemic/erc-graph';
import * as _ from 'lodash';
import { ParsedQuery } from 'query-string';
import { AccordionList, Form, Checkbox } from 'src/components';
import {
  DEFAULT_FILTERS,
  EMPTY_FILTERS,
  FilterType,
  FilterTypePartial,
  FilterTypeQueryString,
  useStateValue,
} from 'src/context';
import { LIST_RESPONSE_ORGANIZATION_USERS } from 'src/graph';
import { vars } from 'src/styles/variables';
import { buildFilterDropDownData, getSectionItem, spacesToUnderscores, underscoresToSpaces, useUrl } from 'src/utils';
import { FilterDropdownWrapper, FilterItem, FilterDropdownHelperText } from './FilterDropDown.styled';

interface FilterDropDownProps {
  isOpen: boolean;
  filterParams: FilterTypeQueryString;
  handleChangeFilterParams: (params: FilterTypeQueryString) => void;
  // handlefilterchange?: (...args: any[]) => void;
}
type FilterDropDownDataType = typeof buildFilterDropDownData;

function filterChange(array: FilterDropDownDataType, qs: ParsedQuery<string>) {
  const options: Record<string, Array<any>> = EMPTY_FILTERS;
  array.forEach((item) => {
    const queryItem = qs[item.id];
    if (queryItem != undefined) {
      if (queryItem instanceof Array) {
        queryItem.forEach((qsItem: any) => {
          const index = item.items.findIndex((i) => underscoresToSpaces(qsItem) == i.name);
          if (index > -1) {
            item.items[index].isChecked = true;
            options[item.id].push(item.items[index].inputValue);
          }
        });
      } else {
        const index = item.items.findIndex((i) => underscoresToSpaces(queryItem) == i.name);
        if (index > -1) {
          item.items[index].isChecked = true;
          options[item.id].push(item.items[index].inputValue);
        }
      }
    }
  });

  return { array, options };
}

function getStars(len: number, index: number) {
  const elements = [];
  for (let i = 0; i < len - index; i++) {
    elements.push(
      <FontAwesomeIcon color={index > 2 ? vars.$danger : vars.$yellow} icon="star" key={`${i}-${index}`} size="sm" />
    );
  }
  return elements;
}

const FilterDropDown = (props: FilterDropDownProps): ReactElement => {
  const { isOpen, filterParams, handleChangeFilterParams } = props;
  const { location, params, queryString } = useUrl();

  const [{ userRoles, currentUser }] = useStateValue();
  const [filterDropdownData, setFilterDropdownData] = useState<FilterDropDownDataType>(
    _.cloneDeep(buildFilterDropDownData)
  );

  const [activeOptions, setActiveOptions] = useState<FilterTypePartial>(DEFAULT_FILTERS);

  const { data: usersData, loading } = useQuery(LIST_RESPONSE_ORGANIZATION_USERS, {
    variables: { responseOrganizationUlid: params.organization },
    skip: !params.organization || !currentUser,
  });

  React.useEffect(() => {
    if (!usersData || loading) {
      return;
    }
    const qs = queryString.parse(location.search, { arrayFormat: 'comma' });

    const nonAssigneeFilters = _.cloneDeep(buildFilterDropDownData).filter((f) => f.id !== 'assigneeUlid');
    const newState = [
      ...nonAssigneeFilters,
      {
        id: 'assigneeUlid',
        title: 'ASSIGNED TO',
        items: [
          {
            inputValue: 'UNASSIGNED',
            type: 'checkbox',
            name: 'UNASSIGNED',
            checkboxlabel: 'UNASSIGNED',
            isChecked: userRoles.isResponseUser,
            // disabled: false,
          },
          ...usersData.listResponseOrganizationUsers.map((responseUser: ResponseUser) =>
            getSectionItem(
              responseUser.userUlid,
              responseUser.userUlid,
              (responseUser.user && responseUser.user.name) || 'unknown',
              userRoles.isResponseUser && responseUser.userUlid === currentUser.userUlid
            )
          ),
        ],
      },
    ];
    const { array, options } = filterChange(newState, qs);
    setActiveOptions({ ...(options as FilterType) });
    setFilterDropdownData(array);
  }, [currentUser, userRoles, queryString, usersData, loading, location.search]);

  React.useEffect(() => {
    if (!usersData || loading) {
      return;
    }
    const qs = queryString.parse(location.search, { arrayFormat: 'comma' });

    const nonAssigneeFilters = _.cloneDeep(buildFilterDropDownData).filter((f) => f.id !== 'assigneeUlid');
    const newState = [
      ...nonAssigneeFilters,
      {
        id: 'assigneeUlid',
        title: 'ASSIGNED TO',
        items: [
          {
            inputValue: 'UNASSIGNED',
            type: 'checkbox',
            name: 'UNASSIGNED',
            checkboxlabel: 'UNASSIGNED',
            isChecked: userRoles.isResponseUser,
            // disabled: false,
          },
          ...usersData.listResponseOrganizationUsers.map((responseUser: ResponseUser) =>
            getSectionItem(
              responseUser.userUlid,
              responseUser.userUlid,
              (responseUser.user && responseUser.user.name) || 'unknown',
              userRoles.isResponseUser && responseUser.userUlid === currentUser.userUlid
            )
          ),
        ],
      },
    ];

    const { array } = filterChange(newState, qs);
    if (Object.keys(filterParams).length === 0) {
      setFilterDropdownData(array);
      setActiveOptions(DEFAULT_FILTERS);
    }
  }, [filterParams, usersData, loading, currentUser, userRoles, location.search, queryString]);

  function handleChange(e: React.MouseEvent<HTMLElement, any>) {
    e.stopPropagation();
    e.preventDefault();

    const qs = queryString.parse(location.search, { arrayFormat: 'comma' });

    const qsItemsObjs: Record<string, any> = { ...filterParams };
    if (e.currentTarget && e.currentTarget.dataset.item) {
      const { item } = e.currentTarget.dataset;
      const splitItem = item.split('~');
      const id = splitItem[0].trim();
      const name = splitItem[1].trim();
      const inputValue = splitItem[2].trim();
      const tempState = [...filterDropdownData];
      const index = tempState.findIndex((item) => item.id === id);
      const tempItem = tempState[index];
      const options = Object.assign({}, activeOptions);
      tempItem.items.forEach((tItem) => {
        if (tItem.name === name) {
          tItem.isChecked = !tItem.isChecked;
          let active: any[] | undefined = activeOptions[id as keyof typeof activeOptions];
          if (tItem.isChecked) {
            if (qsItemsObjs[id]) {
              if (qsItemsObjs[id] instanceof Array) {
                qsItemsObjs[id].push(spacesToUnderscores(name));
              } else {
                qsItemsObjs[id] = [qsItemsObjs[id], spacesToUnderscores(name)];
              }
            } else {
              qsItemsObjs[id] = [spacesToUnderscores(name)];
            }
            active?.push(inputValue);
          } else {
            active = active?.filter((f) => f != inputValue);
            if (!(qsItemsObjs[id] instanceof Array)) {
              delete qsItemsObjs[id];
              delete qs[id];
            } else qsItemsObjs[id] = qsItemsObjs[id].filter((i: string) => underscoresToSpaces(i) != name);
          }
          options[id as keyof typeof options] = active;
        }
      });
      const newQs = { ...qs, ...qsItemsObjs, changed: true };
      handleChangeFilterParams(newQs);

      setActiveOptions(options);
      tempState[index] = tempItem;
      setFilterDropdownData(tempState);
    }
  }

  return (
    <FilterDropdownWrapper isOpen={params.organization != undefined && isOpen}>
      <AccordionList>
        <Form>
          <FilterDropdownHelperText>
            {'Select options to filter reviews. All reviews will be visible until a selection is made.'}
          </FilterDropdownHelperText>
          {filterDropdownData.map((item) => (
            <AccordionList.Item title={item.title} id={item.id} key={item.title}>
              {item.items.map((fItem, i: number) => {
                return (
                  <FilterItem
                    key={`${item.id}-${fItem.name}-${fItem.inputValue}`}
                    rating={item.id === 'rating'}
                    onClick={!fItem.disabled ? handleChange : () => null}
                    data-item={`${item.id}~${fItem.name}~${fItem.inputValue}`}
                  >
                    <Checkbox>
                      <input
                        id={fItem.name}
                        type="checkbox"
                        data-testid="input-checkbox"
                        checked={fItem.isChecked}
                        onChange={() => null}
                        disabled={fItem.disabled}
                      />
                      <FontAwesomeIcon icon={['fas', 'check']} title="check" />
                      <label htmlFor={fItem.name}>{fItem.checkboxlabel}</label>
                    </Checkbox>
                    {item.id === 'rating' && <span>{getStars(item.items.length, i)}</span>}
                  </FilterItem>
                );
              })}
            </AccordionList.Item>
          ))}
        </Form>
      </AccordionList>
    </FilterDropdownWrapper>
  );
};

export default FilterDropDown;
