import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components/macro';
import { MainLogo, MainLogoPictogram } from 'src/assets/images';
import vars from 'src/styles/variables';
import Avatar from '../Avatar';
import Button from '../Button';

export const LogoWrapper = styled.div`
  align-self: stretch;
  height: 3.5rem;
  position: relative;
  margin-bottom: 0.75rem;
`;

export const LogoSm = styled(MainLogoPictogram)`
  fill: white;
  height: auto;
  width: 2.35rem;
  position: absolute;
  top: 1.25rem;
  right: 1.5rem;
  left: 0.85rem;
  transform: scale(0.5);
  transition: all 500ms ease;
`;

export const LogoLg = styled(MainLogo)`
  height: 1.35rem;
  position: absolute;
  top: 1.6rem;
  right: 1.25rem;
  left: 1.25rem;
  transition: all 500ms ease;
`;

export const SidebarSection = styled.div`
  width: 100%;
`;

export const SidebarMenu = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const SharedMenuItemStyles = css`
  display: flex;
  height: auto;
  width: 100%;
  align-items: center;
  height: 3rem;
  transition: all 500ms ease;
  box-shadow: inset 0 0 0 0.01px ${vars.$greendark};
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.5);
  padding-left: 1.35rem;
  padding-right: 0.5rem;
  border: none;

  /* Active class comes default in navLink */
  &.active,
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: inset 3px 0 0 0 ${vars.$greenlight};
    color: white;
  }
`;

export const CollapseSidebarButton = styled(Button)`
  ${SharedMenuItemStyles}
`;

export const SidebarMenuItem = styled(NavLink)`
  ${SharedMenuItemStyles}

  &.active {
    pointer-events: none;
  }
`;

export const AvatarMenuItem = styled(SidebarMenuItem).attrs({
  as: 'div',
})`
  pointer-events: none;
  padding-left: 1rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
  ${Avatar} {
    transition: all 500ms ease;
  }
`;

export const VersionNumber = styled.div`
  position: absolute;
  bottom: 0.2rem;
  /* left: 1rem; */
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.25);
  transition: all 500ms ease;
  pointer-events: all;

  &:hover {
    cursor: pointer;
    color: white;
  }
`;

export const MenuIcon = styled(FontAwesomeIcon).attrs({
  size: 'lg',
  fixedWidth: true,
})``;

export const SidebarIcon = styled(FontAwesomeIcon).attrs({
  size: 'lg',
  fixedWidth: true,
})<{ collapsed: string }>`
  ${({ collapsed }) =>
    collapsed === 'true'
      ? css`
          /* transform: rotate(90deg) translateY(-2px); */
          /* transition: transform 500ms ease; */
        `
      : css`
          /* transform: rotate(0deg); */
          /* transition: transform 500ms ease; */
        `}
`;

export const MenuItemTitle = styled.div`
  margin-left: 0.7rem;
  font-size: 1rem;
  transition: opacity 500ms ease;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SideBarItem = styled.div`
  width: 100%;
`;

const CondensedSidebarStyles = css`
  width: 4rem;
  min-width: 4rem;

  ${LogoSm} {
    opacity: 1;
    transform: scale(1);
  }
  ${LogoLg} {
    opacity: 0;
  }
  ${AvatarMenuItem} {
    padding-left: 0.7rem;
    ${Avatar} {
      transition: all 500ms ease;
      height: 40px;
      width: 40px;
      min-width: 40px;
    }
  }
  ${MenuItemTitle} {
    opacity: 0;
  }
  ${VersionNumber} {
    opacity: 0;
  }
`;

const ExpandedSidebarStyles = css`
  width: 16.5rem;
  min-width: 16.5rem;

  ${LogoSm} {
    opacity: 0;
  }
  ${LogoLg} {
    opacity: 1;
  }
  ${MenuItemTitle} {
    opacity: 1;
  }
  ${VersionNumber} {
    opacity: 1;
  }
`;

export const SidebarWrapper = styled.div<{ collapsed: boolean }>`
  position: relative;
  background-color: ${vars.$greendark};
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  transition: all 500ms ease;
  overflow: hidden;

  a {
    color: inherit;
  }

  ${MenuIcon}, ${SidebarIcon} {
    width: 1.15rem !important;
    height: 1.15rem !important;
  }

  ${({ collapsed }) =>
    collapsed
      ? css`
          ${CondensedSidebarStyles}
        `
      : css`
          ${ExpandedSidebarStyles}
        `}
`;
