import React from 'react';
import { ApolloCache, InMemoryCache, MutationHookOptions } from '@apollo/client';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResponseMessage } from '@friendemic/erc-graph';
import { ChannelTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/channel_type_friendly';
import { ReviewTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_type_friendly';
import * as FullStory from '@fullstory/browser';
import { useQueryClient } from '@tanstack/react-query';
import { Formik, Form, FormikProps } from 'formik';
import styled from 'styled-components/macro';
import { LIST_REVIEW_RESPONSE_TICKETS_KEY, REVIEW_RESPONSE_TICKET_KEY } from 'src/async/queryKeys.utils';
import { ReviewResponseTicket, ResponseSignature, Review } from 'src/async/types';
import { InputElement, Button } from 'src/components';
import { useStateValue } from 'src/context';
import {
  GET_REVIEW_RESPONSE_TICKET,
  CREATE_REVIEW_RESPONSE_SUBMISSION,
  ASSIGN_REVIEW_RESPONSE_TICKET,
  COMPLETE_REVIEW_RESPONSE_SUBMISSION,
  GENERATE_RESPONSE_MESSAGES,
} from 'src/graph';
import { getRecords } from 'src/hooks';
import { useGraphMutation } from 'src/hooks/withDataFetcher';
import { toast } from 'src/utils';
import { buildFullStoryTicketEvent } from 'src/utils/fullStoryUtils';
import { buildSubmitButtonText } from '../../utils';
import { CardTemplate } from '../CardTemplate';
import { parseCommentBody, findAppropriateSignature, buildTemplateSelectOptions } from './utils';
import { AssignedToOverlay, WriteResponseCardWrapper } from './WriteResponseCard.styled';
import { RenderForm, validationSchema, initialFormValues, FormValues } from './WriteResponseCardForm';

const PositiveResponse = styled(Button)`
  font-size: 1.25rem;
`;
const NegativeResponse = styled(Button)`
  font-size: 1.25rem;
`;

export const WriteResponseCard = (props: { ticket: ReviewResponseTicket }): JSX.Element => {
  const { channel, review, placeName } = props.ticket;
  const { ticket } = props;
  const [{ currentUser }] = useStateValue();
  const { customer } = review as Review;
  const channelName = ChannelTypeFriendly.toFriendly(channel);
  const friendlyReviewType = ReviewTypeFriendly.toFriendly(ticket.type);
  const responseTemplates = ticket.responseTemplates || [];

  const [selectedOption, setSelectedOption] = React.useState<any>(null);
  const [showSelect, setShowSelect] = React.useState(true);
  const [signatureMatchingTicketReviewType, setSignatureMatchingTicketReviewType] = React.useState<ResponseSignature>();
  const [disableSignatureToggle, setDisableSignatureToggle] = React.useState(true);

  const [assignedTo, setAssignedTo] = React.useState(ticket.assigneeUserName);
  const [isAssignedToMe, setIsAssignedToMe] = React.useState(false);
  const [suggestedComment, setSuggestedComment] = React.useState('');
  const [positiveLoading, setPositiveLoading] = React.useState(false);
  const [negativeLoading, setNegativeLoading] = React.useState(false);

  const queryClient = useQueryClient();

  const refetch_review_response_ticket_options: MutationHookOptions<any, Record<string, any>> = {
    refetchQueries: [
      {
        query: GET_REVIEW_RESPONSE_TICKET,
        variables: {
          reviewUlid: ticket.reviewUlid,
        },
      },
    ],
  };

  const { mutationMethod: createReviewResponseSubmission, loading: createReviewResponseSubmissionLoading } =
    useGraphMutation({
      mutation: CREATE_REVIEW_RESPONSE_SUBMISSION,
      options: refetch_review_response_ticket_options,
    });

  const { mutationMethod: completeReviewResponseSubmission } = useGraphMutation({
    mutation: COMPLETE_REVIEW_RESPONSE_SUBMISSION,
    options: refetch_review_response_ticket_options,
  });

  const { mutationMethod: assignReviewResponseTicket, loading: assignReviewResponseTicketLoading } = useGraphMutation({
    mutation: ASSIGN_REVIEW_RESPONSE_TICKET,
    options: refetch_review_response_ticket_options,
  });

  React.useEffect(() => {
    setAssignedTo(ticket.assigneeUserName);
    setIsAssignedToMe(ticket.assigneeUserName ? ticket.assigneeUlid === currentUser.user.ulid : false);
    setSignatureMatchingTicketReviewType(findAppropriateSignature(ticket));
    setDisableSignatureToggle(!Boolean(findAppropriateSignature(ticket)));
  }, [ticket, signatureMatchingTicketReviewType, currentUser]);

  // * Handles setting the selected template's contents into the textArea
  async function handleSelectOnChange(
    selection: any,
    { setFieldValue, validateField, values, resetForm }: FormikProps<FormValues>
  ) {
    if (selection) {
      setSelectedOption(selection);

      const foundTemplate = responseTemplates.find((x) => {
        return x.ulid === selection.value;
      });

      // * wait for formik setFieldValue to finish, else validateField will race and not see the new comment value
      await setFieldValue('comment', foundTemplate?.body || '');
      validateField('comment');
      setShowSelect(false);
    } else {
      setSelectedOption(null);
      resetForm({
        values: {
          ...initialFormValues,
          attach_signature: disableSignatureToggle ? false : values.attach_signature,
        },
      });
    }
  }

  // * Controls the whether to show the Clear button or Select
  // * Clears the Select on any change to the textArea
  function handleTextAreaOnChange(e: React.ChangeEvent<any>) {
    if (e.target.value.length) {
      setShowSelect(false);
      setSelectedOption(null);
    } else {
      setShowSelect(true);
      setSelectedOption(null);
    }
  }

  // * Controls the Suggested Response Message
  async function generateResponseMessage(
    sentiment: string,
    locationName: string,
    customerName: string,
    channelName: string
  ) {
    const res = await getRecords({
      queryString: GENERATE_RESPONSE_MESSAGES,
      options: {
        variables: {
          sentiment: sentiment,
          locationName: locationName,
          customerName: customerName,
          channelName: channelName,
        },
        fetchPolicy: 'network-only',
      },
    });
    const { data }: { data: { generateResponseMessage: ResponseMessage } } = res;
    if (data) {
      if (data.generateResponseMessage?.content) {
        setSuggestedComment(data.generateResponseMessage.content || '');
      } else {
        toast({ type: 'error', message: 'There was a problem suggesting a response' });
      }
    }
    setPositiveLoading(false);
    setNegativeLoading(false);
  }

  // * Resets the textArea & Select onClick of the Clear button
  function handleClearForm({ values, resetForm }: FormikProps<FormValues>) {
    setShowSelect(true);
    setSelectedOption(null);
    setSuggestedComment('');
    resetForm({
      values: {
        ...initialFormValues,
        attach_signature: disableSignatureToggle ? false : values.attach_signature,
      },
    });
  }

  async function assignToMe() {
    if (ticket?.reviewUlid) {
      await assignReviewResponseTicket({
        variables: {
          assigneeUlid: currentUser.user.ulid,
          reviewUlid: ticket.reviewUlid,
        },
        onCompleted: () => {
          queryClient.invalidateQueries([LIST_REVIEW_RESPONSE_TICKETS_KEY]);
          queryClient.invalidateQueries([REVIEW_RESPONSE_TICKET_KEY, ticket.reviewUlid]);
        },
        // * Updates ReviewType, then updates the cached Inbox data set for that item
        update: (cache: ApolloCache<InMemoryCache>, mutationResult) => {
          cache.modify({
            id: 'ROOT_QUERY',
            fields: {
              listReviewResponseTickets(tickets, { readField }) {
                const records = tickets.records.map((thisTicket: ReviewResponseTicket) => {
                  if (readField('reviewUlid', thisTicket) === ticket.reviewUlid) {
                    return {
                      ...thisTicket,
                      assigneeUser: mutationResult.data.assignReviewResponseTicket.record.assigneeUser,
                    };
                  }
                  return thisTicket;
                });
                return { ...tickets, records };
              },
              getReviewResponseTicket(thisTicket: ReviewResponseTicket) {
                if (thisTicket.reviewUlid === ticket.reviewUlid) {
                  return {
                    ...thisTicket,
                    assigneeUser: mutationResult.data.assignReviewResponseTicket.record.assigneeUser,
                  };
                }
              },
            },
          });
        },
      })
        .then(async (response) => {
          if (response.data.assignReviewResponseTicket && response.data.assignReviewResponseTicket.errors) {
            console.info(JSON.stringify(response.data, null, 2));
            setAssignedTo(undefined);
            toast({ type: 'error', message: 'This ticket cannot be assigned at this time' });
          } else {
            setAssignedTo(currentUser.user.name);
            toast({ type: 'success', message: 'You have been assigned this ticket' });
          }
        })
        .catch((err) => {
          if (err.networkError) {
            console.error(err.networkError.result);
          }
          toast({ type: 'error', message: err.message });
        });
    } else {
      toast({ type: 'error', message: 'There was a problem assigning this ticket, please try again' });
    }
  }

  async function handleSubmitSubmission(values: FormValues, submitThenPublish?: boolean) {
    const commentWithSignature = parseCommentBody(values, ticket, signatureMatchingTicketReviewType);
    const response = await createReviewResponseSubmission({
      variables: {
        input: {
          comment: commentWithSignature,
          reviewUlid: ticket.reviewUlid,
        },
      },
      onCompleted: () => {
        queryClient.invalidateQueries([REVIEW_RESPONSE_TICKET_KEY, ticket.reviewUlid]);
      },
    });
    if (submitThenPublish && response.data.createReviewResponseSubmission) {
      await completeReviewResponseSubmission({
        variables: {
          reviewResponseSubmissionUlid: response.data.createReviewResponseSubmission.recordUlid,
        },
        onCompleted: () => {
          queryClient.invalidateQueries([REVIEW_RESPONSE_TICKET_KEY, ticket.reviewUlid]);
        },
      });
    }

    // FS CUSTOM EVENT
    FullStory.isInitialized() &&
      FullStory.event('ERC - API Event - Response Published', buildFullStoryTicketEvent(ticket));
  }

  return (
    <WriteResponseCardWrapper hideOverlay={isAssignedToMe}>
      {/* Keep it if requirements changes to remvoe re-assign functionality */}
      {/* <AssignedToOverlay onClick={() => (!Boolean(assignedTo) ? assignToMe() : null)} isAssigned={Boolean(assignedTo)}> */}
      <AssignedToOverlay
        onClick={assignToMe}
        isAssigned={Boolean(assignedTo)}
        isLoading={assignReviewResponseTicketLoading}
        data-cy="assignOverlay"
      >
        {assignReviewResponseTicketLoading ? (
          <div>
            <FontAwesomeIcon size="sm" icon="spinner-third" spin />
            <span>{'Loading...'}</span>
          </div>
        ) : !assignedTo ? (
          <div>{'Click to assign to yourself'}</div>
        ) : (
          <>
            <div>{`Assigned to ${assignedTo || 'UNKNOWN'}`}</div>
            <div>{'(Click to reassign)'}</div>
          </>
        )}
      </AssignedToOverlay>
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={(values) => {
          // !FLAG async await handleSubmitSubmission in this onSubmit could solve the spinning publish button problem
          const submitThenPublish = !ticket.publishingDetails?.canAutoPublish && ticket.publishingDetails?.canPublish;
          handleSubmitSubmission(values, submitThenPublish);
        }}
      >
        {(props: FormikProps<FormValues>) => {
          return (
            <Form>
              <CardTemplate
                config={{
                  title: 'Write Response',
                  icon: 'reply-all',
                  headingRight: showSelect ? (
                    <>
                      <NegativeResponse
                        btnSm
                        ml={2}
                        danger
                        transparent={!negativeLoading}
                        tooltip="Generate response for a negative review"
                        placement="top"
                        icon={negativeLoading ? faSpinnerThird : ['far', 'frown']}
                        spin={Boolean(negativeLoading)}
                        id="NegativeResponseBtn"
                        onClick={() => {
                          generateResponseMessage('negative', `${placeName}`, `${customer}`, `${channelName}`);
                          setNegativeLoading(true);
                        }}
                      />
                      <PositiveResponse
                        btnSm
                        mr={2}
                        transparent={!positiveLoading}
                        tooltip="Generate response for a positive review"
                        placement="top"
                        icon={positiveLoading ? faSpinnerThird : ['far', 'smile']}
                        spin={Boolean(positiveLoading)}
                        id="PositiveResponseBtn"
                        onClick={() => {
                          generateResponseMessage('positive', `${placeName}`, `${customer}`, `${channelName}`);
                          setPositiveLoading(true);
                        }}
                      />
                      <InputElement
                        type="select"
                        name="response_template_select"
                        border
                        placeholder="Select A Template..."
                        id="SelectTemplateDropdown"
                        options={buildTemplateSelectOptions(responseTemplates)}
                        handleSelectOnChange={(selection: any) => handleSelectOnChange(selection, props)}
                        value={selectedOption}
                      />
                    </>
                  ) : (
                    <Button btnSm outline secondary content="Clear" onClick={() => handleClearForm(props)} />
                  ),
                }}
              >
                <RenderForm
                  {...props}
                  parsedCommentBody={parseCommentBody(props.values, ticket, signatureMatchingTicketReviewType)}
                  handleTextAreaOnChange={handleTextAreaOnChange}
                  signatureToggleLabel={`Attach ${
                    signatureMatchingTicketReviewType
                      ? ReviewTypeFriendly.toFriendly(signatureMatchingTicketReviewType.signsReviewType)
                      : friendlyReviewType
                  } Signature`}
                  disableSignatureToggle={disableSignatureToggle}
                  disableSignatureToggleTooltip={`A ${friendlyReviewType} signature is not available`}
                  submitButtonText={buildSubmitButtonText(ticket.publishingDetails)}
                  ticket={ticket}
                  isSubmitting={createReviewResponseSubmissionLoading}
                  suggestedComment={suggestedComment}
                />
              </CardTemplate>
            </Form>
          );
        }}
      </Formik>
    </WriteResponseCardWrapper>
  );
};

export default WriteResponseCard;
