import React, { ReactElement } from 'react';
import { useModal } from 'react-modal-hook';
import { Place } from '@friendemic/erc-graph';
import { Formik, FormikProps, Form } from 'formik';
import { useListSignatures, useCreateSignature, useEditSignature, useDeleteSignature } from 'src/async';
import { Column, List, Modal, Row, Table } from 'src/components';
import { toast } from 'src/utils';
import { LoadingSubsectionPlaceholder, SettingsSubsection } from 'src/views/Settings/settings.styled';
import { LocationSettingsRouteParams } from 'src/views/Settings/SettingsTypes';
import { initialFormValues, validationSchema, RenderForm, FormValues } from './SignatureManagementForm';
import { columns, buildData } from './table';

type SignatureManagementProps = {
  params: LocationSettingsRouteParams;
  place?: Place;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

const SignatureManagement = (props: SignatureManagementProps): ReactElement => {
  const { params, setSaving } = props;

  // Data object to use in table
  const [tableData, setTableData] = React.useState<any>();

  const { data: signatureData, isLoading } = useListSignatures(params.location);

  const [activeEditingSignature, setActiveEditingSignature] = React.useState<FormValues>(initialFormValues);

  const { mutateAsync: createSignature, isLoading: isCreateSignatureLoading } = useCreateSignature();
  const { mutateAsync: editSignature, isLoading: isEditSignatureLoading } = useEditSignature();
  const { mutateAsync: deleteSignature, isLoading: isDeleteSignatureLoading } = useDeleteSignature();

  React.useEffect(() => {
    setSaving(isCreateSignatureLoading || isEditSignatureLoading || isDeleteSignatureLoading);
  }, [setSaving, isCreateSignatureLoading, isEditSignatureLoading, isDeleteSignatureLoading]);

  async function handleAddSignature(values: FormValues) {
    const { content, signsReviewType } = values;
    await createSignature(
      {
        placeUlid: params.location,
        input: {
          content,
          signsReviewType,
          includeLocationName: false,
        },
      },
      {
        onSuccess: () => {
          setActiveEditingSignature(initialFormValues);
          hideAddSignatureModal();
        },
        onError: (err) => {
          console.error(err);
          toast({ type: 'error', message: err.response?.data?.message || err.message });
          setActiveEditingSignature(initialFormValues);
          hideAddSignatureModal();
        },
      }
    );
  }

  async function handleEditSignature(values: FormValues) {
    const { content, signsReviewType, ulid } = values;
    const foundSignature = signatureData?.find((s) => s.ulid === ulid);
    await editSignature(
      {
        placeUlid: params.location,
        signatureUlid: ulid,
        input: {
          content,
          signsReviewType,
          includeLocationName: foundSignature ? foundSignature.includeLocationName : false,
        },
      },
      {
        onSuccess: async () => {
          setActiveEditingSignature(initialFormValues);
          hideAddSignatureModal();
        },
        onError: (err) => {
          console.error(err);
          toast({ type: 'error', message: err.response?.data?.message || err.message });
          setActiveEditingSignature(initialFormValues);
          hideAddSignatureModal();
        },
      }
    );
  }

  async function handleDeleteSignature(signatureUlid: string, signsReviewTypeFriendly: string) {
    await deleteSignature(
      {
        placeUlid: params.location,
        signatureUlid: signatureUlid,
      },
      {
        onSuccess: async () => {
          toast({
            type: 'error',
            message: `Deleted "${signsReviewTypeFriendly}" signature`,
          });
          setActiveEditingSignature(initialFormValues);
          hideAddSignatureModal();
        },
        onError: (err) => {
          console.error(err);
          toast({ type: 'error', message: err.response?.data?.message || err.message });
          setActiveEditingSignature(initialFormValues);
          hideAddSignatureModal();
        },
      }
    );
  }

  // !FLAG - INFINITE LOOP IF BAD URL
  React.useEffect(() => {
    signatureData &&
      setTableData(buildData(signatureData, setActiveEditingSignature, showAddSignatureModal, handleDeleteSignature));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [signatureData]);

  const [showAddSignatureModal, hideAddSignatureModal] = useModal(() => {
    const addNew = activeEditingSignature === initialFormValues;
    return (
      <Formik
        initialValues={activeEditingSignature}
        validationSchema={validationSchema(signatureData || [], activeEditingSignature)}
        onSubmit={(values) => {
          return addNew ? handleAddSignature(values) : handleEditSignature(values);
        }}
      >
        {(props: FormikProps<FormValues>) => {
          const { submitForm, isSubmitting } = props;
          return (
            <Modal
              hideModal={hideAddSignatureModal}
              title={addNew ? 'Add Signature' : 'Edit Signature'}
              icon={addNew ? 'plus' : 'edit'}
              overflowVisible
              compact
              footer
              cancelable
              submitDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              submittable={addNew ? 'Add' : 'Save'}
              onClose={() => {
                setActiveEditingSignature(initialFormValues);
              }}
              handleSubmit={() => {
                return submitForm();
              }}
            >
              <Form>
                <RenderForm {...props} />
              </Form>
            </Modal>
          );
        }}
      </Formik>
    );
  }, [signatureData, activeEditingSignature]);

  if (isLoading) return <LoadingSubsectionPlaceholder />;

  return (
    <SettingsSubsection>
      <Row>
        <Column>
          <List
            title="Signature Management"
            icon="money-check-edit"
            action={{
              onClick: showAddSignatureModal,
              icon: 'plus',
              content: 'Add',
            }}
          >
            <li>
              <Table
                noHeader
                columns={columns}
                data={tableData}
                customStyles={{
                  cells: {
                    style: {
                      padding: '1rem',
                      alignItems: 'flex-start',
                    },
                  },
                }}
              />
            </li>
          </List>
        </Column>
      </Row>
    </SettingsSubsection>
  );
};

export default SignatureManagement;
