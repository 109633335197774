import React from 'react';
import { useModal } from 'react-modal-hook';
import { OptionTypeBase } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResponseAssignee, ResponseUser, ResponsePlace } from '@friendemic/erc-graph';
import { Formik, Form, FormikProps } from 'formik';
import styled from 'styled-components/macro';
import { ScrollWrapper, SubNavbar, Row, Column, Button, Table, Modal } from 'src/components';
import { useStateValue } from 'src/context';
import {
  client,
  LIST_RESPONSE_ASSIGNEES,
  CREATE_RESPONSE_ASSIGNEE,
  LIST_RESPONSE_PLACES,
  EDIT_RESPONSE_ASSIGNEE,
} from 'src/graph';
import { getRecords } from 'src/hooks';
import { toast } from 'src/utils';
import { SettingsSubsection } from '../../settings.styled';
import OrganizationAsyncSelect from './OrganizationAsyncSelect';
import { buildData, columns } from './table';
import { RenderForm, FormValues, initialFormValues, validationSchema } from './TicketManagementForm';

export interface Place {
  name: string;
  ulid: string;
}
export interface Assignee {
  id: number;
  places: Place[];
  autoAssign: string;
  placeUlids: string[];
  userUlid: string;
  email: string;
}
export const Loader = styled(Column)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChooseOrganizationMessage = styled.p`
  display: flex;
  padding: 1rem;
  justify-content: center;
`;

const TicketManagement = (props: { listResponseOrganizationUsers?: ResponseUser[] }): JSX.Element => {
  const [organizationSelection, setOrganizationSelection] = React.useState<OptionTypeBase>({
    value: '',
    label: '',
  });
  const [locationSelection, setLocationSelection] = React.useState<OptionTypeBase>({
    value: '',
    label: '',
  });
  const [{ userRoles, currentUser }] = useStateValue();
  const { listResponseOrganizationUsers } = props;
  const [users, setUsers] = React.useState<ResponseUser[]>(listResponseOrganizationUsers || []);
  const [activeEditingUser, setActiveEditingUser] = React.useState<FormValues>(initialFormValues);
  const [loading, setLoading] = React.useState(false);
  const [places, setPlaces] = React.useState<any>([]);
  const [placesTags, setPlacesTags] = React.useState<any[]>([]);
  const [tableData, setTableData] = React.useState<any>([]);
  const [assignee, setAssignee] = React.useState<any>();
  const [assignees, setAssignees] = React.useState<any>([]);
  const [loader, setLoader] = React.useState(false);
  const [activeUser, setActiveUser] = React.useState<any>();
  React.useEffect(() => {
    if (userRoles.isOrganizationManager) {
      setOrganizationSelection({
        value: currentUser.responseOrganization?.ulid,
        label: currentUser.responseOrganization?.name,
      });
    }
  }, [userRoles, currentUser]);
  React.useEffect(() => {
    setUsers(listResponseOrganizationUsers || []);
  }, [listResponseOrganizationUsers]);

  const [showAddUserModal, hideAddUserModal] = useModal(() => {
    const addNew = activeEditingUser === initialFormValues;
    return (
      <Formik
        initialValues={activeEditingUser}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          let placeAlreadyAssigned = false;
          let placeName = '';
          let userName = '';
          const placeUlids = placesTags.map((placeTag) => {
            const found = assignees.find(
              (assignee: Assignee) =>
                assignee.placeUlids.includes(placeTag.ulid) && assignee.userUlid !== values.add_organization_user_ulid
            );
            if (found) {
              placeAlreadyAssigned = true;
              placeName = placeTag.name;
              userName = found.autoAssign;
            }
            return placeTag.ulid;
          });
          if (placeAlreadyAssigned) {
            toast({ type: 'error', message: `${placeName} is already assigned to ${userName}.` });
            return;
          }
          const res = await client.mutate({
            mutation: addNew ? CREATE_RESPONSE_ASSIGNEE : EDIT_RESPONSE_ASSIGNEE,
            variables: {
              input: {
                placeUlids,
                userUlid: values.add_organization_user_ulid,
                responseOrgUlid: organizationSelection.value,
              },
            },
          });
          if (
            addNew &&
            res.data &&
            (res.data.createResponseAssignee.errors || res.data.createResponseAssignee.statusCode === 500)
          ) {
            toast({ type: 'error', message: 'Something went wrong, please try again.' });
          } else if (
            !addNew &&
            res.data &&
            (res.data.editResponseAssignee.errors || res.data.editResponseAssignee.statusCode === 500)
          ) {
            toast({ type: 'error', message: 'Something went wrong, please try again.' });
          } else {
            const newAssignee: Assignee = {
              id: tableData.length + 1,
              places: placesTags,
              autoAssign: activeUser ? activeUser.name : assignee.label.props.children[0].props.children,
              placeUlids: placeUlids,
              userUlid: values.add_organization_user_ulid,
              email: activeUser ? activeUser.email : assignee.label.props.children[1].props.children,
            };
            let modifiedAssignees = [...assignees];
            const findIndex = modifiedAssignees.findIndex((a) => a.userUlid === newAssignee.userUlid);
            if (findIndex > -1) {
              newAssignee.id = modifiedAssignees[findIndex].id;
              if (addNew) {
                const tempPlaces: Place[] = [...modifiedAssignees[findIndex].places, ...newAssignee.places];
                const newPlaces: Place[] = [];
                tempPlaces.forEach((place: Place) => {
                  const found = newPlaces.find((p: Place) => p.ulid === place.ulid);
                  if (!found) {
                    newPlaces.push(place);
                  }
                });
                const newPlacesUlids = newPlaces.map((place) => place.ulid);
                newAssignee.places = newPlaces;
                newAssignee.placeUlids = newPlacesUlids;
              }
              modifiedAssignees[findIndex] = newAssignee;
            } else {
              modifiedAssignees.push(newAssignee);
            }
            modifiedAssignees = modifiedAssignees.filter((item: Assignee) => item.places.length > 0 && item.autoAssign);
            await setAssignees(modifiedAssignees);
            setTableData(
              buildData(
                modifiedAssignees,
                setTableData,
                showAddUserModal,
                setActiveEditingUser,
                setPlacesTags,
                setActiveUser,
                organizationSelection.value,
                modifiedAssignees,
                setAssignees
              )
            );
            toast({
              type: 'success',
              message: `${addNew ? 'Added' : 'Updated'} ${newAssignee.autoAssign} as Assignee.`,
            });
          }
          setPlacesTags([]);
          hideAddUserModal();
        }}
      >
        {(props: FormikProps<FormValues>) => {
          const { submitForm, isSubmitting } = props;
          return (
            <Modal
              hideModal={hideAddUserModal}
              title={addNew ? 'Auto-assign User' : 'Edit Auto-assign User'}
              icon={addNew ? 'plus' : 'edit'}
              overflowVisible
              compact
              footer
              cancelable
              submitDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              submittable={addNew ? 'Add' : 'Save'}
              onClose={() => {
                setActiveEditingUser(initialFormValues);
                setPlacesTags([]);
              }}
              handleSubmit={() => {
                return submitForm();
              }}
            >
              <Form>
                <RenderForm
                  organizationSelection={organizationSelection}
                  locationSelection={locationSelection}
                  setLocationSelection={(e) => {
                    setLocationSelection(e);
                  }}
                  placesTags={placesTags}
                  setPlacesTags={(placesTags) => setPlacesTags(placesTags)}
                  places={places}
                  users={users}
                  addNew={addNew}
                  setAssignee={(assignee: string) => {
                    setAssignee(assignee);
                  }}
                  activeUser={activeUser}
                  {...props}
                />
              </Form>
            </Modal>
          );
        }}
      </Formik>
    );
  }, [activeEditingUser, assignee, places, placesTags, setPlacesTags, activeUser]);

  async function fetchAssignees(orgUlid: string) {
    setLoader(true);
    const res = await getRecords({
      queryString: LIST_RESPONSE_ASSIGNEES,
      options: { variables: { orgUlid }, fetchPolicy: 'network-only' },
    });
    const { data }: { data: { listResponseAssignees: ResponseAssignee[] } } = res;
    if (data) {
      const resData: Assignee[] = data.listResponseAssignees.map((a: ResponseAssignee, index: number) => ({
        id: index,
        places: a.places ? a.places : [],
        autoAssign: a.user ? a.user.name : '',
        placeUlids: a.placeUlids,
        userUlid: a.userUlid,
        email: a.user ? a.user.email : '',
      }));
      const modifiedAssignees = resData.filter((item: Assignee) => item.places && item.autoAssign);
      setAssignees(modifiedAssignees);
      setTableData(
        buildData(
          modifiedAssignees,
          setTableData,
          showAddUserModal,
          setActiveEditingUser,
          setPlacesTags,
          setActiveUser,
          orgUlid,
          modifiedAssignees,
          setAssignees
        )
      );
      setLoader(false);
    } else {
      setTableData(buildData([]));
      setLoader(false);
    }
  }

  // Pre-fetch assignees and places if organizationSSelection is already set, re-fetch on change from OrganizationAsyncSelect
  React.useEffect((): void => {
    if (organizationSelection.value) {
      fetchAssignees(organizationSelection.value);
      fetchPlaces(organizationSelection.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSelection]);

  async function fetchPlaces(orgUlid: string) {
    const res = await getRecords({
      queryString: LIST_RESPONSE_PLACES,
      options: { variables: { responseOrganizationUlid: orgUlid }, fetchPolicy: 'network-only' },
    });

    const { data }: { data: { listResponsePlaces: ResponsePlace[] } } = res;

    if (data) {
      const resData = data.listResponsePlaces.map((a: ResponsePlace) => ({
        label: a.placeName || a.place?.name || 'UNKNOWN',
        value: a.placeUlid,
      }));
      const filteredData = resData.filter((item) => item.label && item.value);
      setPlaces(filteredData);
    } else {
      setPlaces([]);
    }
  }

  return (
    <>
      <SubNavbar icon="money-check-edit" title="Ticket Management" />
      <ScrollWrapper>
        <SettingsSubsection>
          <Row>
            <Column>
              <OrganizationAsyncSelect
                onChange={async (selection: any) => {
                  setLoading(true);
                  await setOrganizationSelection(selection || { value: '', label: '' });
                  if (selection) {
                    setTableData(buildData([]));
                  }
                  setLoading(false);
                  // setPlaceSelection({ value: '', label: '' });
                }}
                value={organizationSelection.value ? organizationSelection : undefined}
                // ! FLAG - disable dropdown for organization manager until organization suggester is allowed for that userrole
                isDisabled={userRoles.isResponseUser || userRoles.isOrganizationManager}
              />
              {!organizationSelection.value && (
                <ChooseOrganizationMessage>{'Please select an Organization...'}</ChooseOrganizationMessage>
              )}
            </Column>
          </Row>
          <Row>
            {organizationSelection && organizationSelection.value && !loading && (
              <Column flex="1" width="auto" textAlign={'right'}>
                <Button
                  icon="plus"
                  onClick={async () => {
                    await setActiveUser(null);
                    await setActiveEditingUser(initialFormValues);
                    showAddUserModal();
                  }}
                  data-cy="add-user"
                >
                  {'Add User'}
                </Button>
              </Column>
            )}
          </Row>
          <Row>
            {loader ? (
              <Loader>
                <FontAwesomeIcon size="lg" icon="spinner-third" spin />
              </Loader>
            ) : organizationSelection && organizationSelection.value ? (
              <Column>
                <Table noHeader columns={columns} data={tableData} />
              </Column>
            ) : null}
          </Row>
        </SettingsSubsection>
      </ScrollWrapper>
    </>
  );
};
export default TicketManagement;
