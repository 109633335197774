import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useStateValue } from 'src/context';
import { RenderForm, validationSchema, initialFormValues } from './PersonalInfoForm';

export const PersonalInfo = (props: {}): React.ReactElement => {
  const [formValues, setFormValues] = useState({
    ...initialFormValues,
  });
  const [{ currentUser }] = useStateValue();

  useEffect(() => {
    if (currentUser) {
      const {
        user: { email, name },
      } = currentUser;

      const [first, last = ''] = name.split(' ');

      setFormValues({
        ...initialFormValues,
        firstName: first,
        lastName: last,
        email: email,
      });
    }
  }, [currentUser]);

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.info(values);
      }}
    >
      {(props) => {
        return (
          <Form>
            <RenderForm {...props} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default PersonalInfo;
