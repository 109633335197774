import React from 'react';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';

interface AutoSave {
  callback?: (args?: any) => void;
  delay?: number;
}
const AutoSave = (props: AutoSave): null | any => {
  const { delay = 300, callback } = props;
  const formik = useFormikContext();

  const { errors }: { [key: string]: any } = formik;
  const { submitForm } = formik;

  const isErrors = errors && Object.keys(errors);
  // const [lastSaved, setLastSaved] = useState<any>(null);
  const debouncedSubmit = React.useCallback(
    () =>
      debounce(
        () =>
          submitForm().then(() => {
            // setLastSaved(new Date().toISOString())
          }),
        delay
      ),
    [delay, submitForm]
  );

  React.useEffect(() => {
    let isError = false;
    if (isErrors.length > 0) {
      isErrors.forEach((err: string) => {
        if (errors[err]) {
          isError = true;
        }
      });
    }
    const isCallable = formik.dirty && !isError;
    if (isCallable && !callback) {
      debouncedSubmit()();
    } else if (isCallable && callback) {
      callback();
    }

    return () => {
      debouncedSubmit().cancel();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [debouncedSubmit, formik.values]);
  // Uncomment to check different behavior

  // let result = null;

  // if (Boolean(formik.isSubmitting)) {
  //   result = 'saving...';
  // } else if (Object.keys(formik.errors).length > 0) {
  //   result = `ERROR: ${formik.errors}`;
  // } else if (lastSaved !== null) {
  //   result = `Last Saved: ${lastSaved}`;
  // }
  // return <>{result}</>;
  return null;
};

export default AutoSave;
