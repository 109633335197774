import React, { ReactElement } from 'react';
import { Formik, FormikProps } from 'formik';
import merge from 'lodash/merge';
import { useEditResponsePlace } from 'src/async';
import { ResponsePlace } from 'src/async/types';
import { Row, Column, List } from 'src/components';
import { FormikResetForm } from 'src/types';
import { toast } from 'src/utils';
import { LoadingSubsectionPlaceholder, SettingsSubsection } from 'src/views/Settings/settings.styled';
import { LocationSettingsRouteParams } from 'src/views/Settings/SettingsTypes';
import { RenderForm, FormValues, initialFormValues, validationSchema } from './ApprovalSettingsForm';

const ApprovalSettings = (props: {
  params: LocationSettingsRouteParams;
  responsePlaceData?: ResponsePlace;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  isResponsePlaceDataLoading: boolean;
}): ReactElement => {
  const { params, responsePlaceData, setSaving, isResponsePlaceDataLoading } = props;
  const [formValues, setFormValues] = React.useState(initialFormValues);

  const { mutateAsync: editResponsePlace, isLoading: isEditResponsePlaceLoading } = useEditResponsePlace();

  React.useEffect(() => {
    if (responsePlaceData?.settings?.approval_settings) {
      setFormValues({
        ...initialFormValues,
        require_type: responsePlaceData.settings.approval_settings.require_type,
        approve_after_24_hours: responsePlaceData.settings.approval_settings.approve_after_24_hours,
        approvals_needed_for: responsePlaceData.settings.approval_settings.approvals_needed_for,
      });
    }
  }, [responsePlaceData]);

  React.useEffect(() => {
    setSaving(isEditResponsePlaceLoading);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isEditResponsePlaceLoading]);

  async function handleUpdateSettings(values: FormValues, resetForm: FormikResetForm) {
    await editResponsePlace(
      {
        placeUlid: params.location,
        settings: merge(responsePlaceData?.settings, {
          approval_settings: {
            approve_after_24_hours: values.approve_after_24_hours,
            require_type: values.require_type,
            approvals_needed_for: values.approvals_needed_for,
          },
        }),
      },
      {
        onSuccess: () => {
          resetForm({ values });
        },
        onError: (err) => {
          console.error(err);
          toast({ type: 'error', message: err.response?.data?.message || err.message });
        },
      }
    );
  }

  if (isResponsePlaceDataLoading) return <LoadingSubsectionPlaceholder />;

  return (
    <SettingsSubsection>
      <Row>
        <Column>
          <List title="Approval Settings" icon="money-check-edit">
            <li>
              <Formik
                initialValues={formValues}
                enableReinitialize
                validateOnMount
                validationSchema={validationSchema}
                validateOnBlur
                validateOnChange
                onSubmit={(values, { resetForm }) => {
                  handleUpdateSettings(values, resetForm);
                }}
              >
                {(props: FormikProps<FormValues>) => {
                  return <RenderForm {...props} />;
                }}
              </Formik>
            </li>
          </List>
        </Column>
      </Row>
    </SettingsSubsection>
  );
};

export default ApprovalSettings;
