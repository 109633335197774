import React from 'react';
import * as Yup from 'yup';
import { Row, Column, InputElement } from 'src/components';
import { InputElementFormConfig } from 'src/types';
import { allowed_template_variables, checkAgainstWhitelist } from 'src/utils/templateVars';

export type FormValues = typeof initialFormValues;

export const initialFormValues = {
  title: '',
  body: 'Hello {reviewer}. Thank you for choosing {location}, we are happy that you had a great experience with our service representative.',
};

/* eslint-disable @typescript-eslint/ban-ts-comment, no-template-curly-in-string */
export const buildValidationSchema = (
  invalidVariables: string[],
  setInvalidVariables: React.Dispatch<React.SetStateAction<string[]>>
): Yup.AnyObjectSchema => {
  const invalidVariablesMessage = `${invalidVariables.join(' ')} ${
    invalidVariables.length === 1 ? 'is not a valid variable.' : 'are not valid variables.'
  }`;

  return Yup.object({
    title: Yup.string().required('Required'),
    body: Yup.string()
      .test({
        params: { invalidVariablesMessage },
        name: 'response-template-body-validation',
        /* @ts-ignore */
        message: '${invalidVariablesMessage}',
        test: (value) => {
          // match anything within {curly} brackets into array
          const curlyRegEx = /{([^}]*)}/g;
          const variables = value && value.length ? value.match(curlyRegEx) : [];
          const matched = variables
            ? variables.filter((el: string) => {
                return allowed_template_variables.indexOf(el) === -1;
              })
            : [];
          setInvalidVariables(matched);
          return checkAgainstWhitelist(variables);
        },
      })
      .required('Required'),
  });
};

export const formConfig: InputElementFormConfig = {
  title: {
    label: 'Title',
    name: 'title',
    placeholder: 'Response Template Title',
    border: true,
  },
  body: {
    type: 'textarea',
    name: 'body',
    label: 'Response Template',
    placeholder: 'Response Template Body',
    border: true,
  },
};

export const RenderForm = (props: {}): React.ReactElement => {
  return (
    <Row>
      <Column>
        <InputElement {...formConfig.title} />
      </Column>
      <Column>
        <InputElement {...formConfig.body} />
      </Column>
    </Row>
  );
};
