import React from 'react';
import { NavLink } from 'react-router-dom';
import { darken, lighten } from 'color2k';
import { Flex } from 'reflexbox';
import styled, { css } from 'styled-components/macro';
import { Button, Row, Column, List } from 'src/components';
import vars from 'src/styles/variables';

const SettingsSection = styled(Flex)`
  overflow: hidden;
  flex-direction: column;
  color: ${vars.$muted};

  a {
    color: inherit;
  }
`;

export const SettingsSectionLeft = styled(SettingsSection)`
  flex: 0 0 20rem;
  border-right: ${vars.$border};
  background-color: white;
  box-shadow: ${vars.$shallow_shadow};
  z-index: 1;
`;

export const SettingsSubsection = styled.div`
  background-color: white;
  padding: 1rem;
  margin-bottom: 1rem;
  /* box-shadow: ${vars.$shallow_shadow}; */
  border: ${vars.$border};

  &:last-of-type {
    margin-bottom: 3rem;
  }
`;

export const SettingsSectionRight = styled(SettingsSection)`
  flex: 1 1 auto;
`;

export const SettingsListItem = styled(NavLink)<{ active?: boolean; internalListItem?: boolean }>`
  cursor: pointer;
  padding: 1.25rem;
  border-radius: 0.25rem;
  transition: all 200ms;
  user-select: none;
  svg {
    margin-right: 0.5rem;
  }

  /* Active class comes default in navLink */
  &.active {
    background-color: ${vars.$greenlight};
    color: white;
    pointer-events: none;
  }

  &:hover {
    background-color: ${vars.$greenpale};
  }
  &:active {
    background-color: ${darken(vars.$greenpale, 0.025)};
  }
`;

export const SettingsInternalNav = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;

  ${SettingsListItem} {
    background-color: white;
    margin-bottom: 0.5rem;
    box-shadow: ${vars.$shallow_shadow};

    &:hover {
      background-color: ${vars.$greenlight};
      color: white;
    }
    &:active {
      background-color: ${lighten(vars.$greenlight, 0.025)};
    }
  }
`;

// prettier-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const LocationSettingsListItem = styled(({ placeIsActive, ...rest }) => <SettingsListItem {...rest} />)<{placeIsActive: boolean;}>`
  display: flex;
  position: relative;
  flex-direction: column;
  span:not(:first-of-type) {
    font-size: 75%;
  }
`;

function buildFlagBoxShadow(color: string) {
  return `
    -1px 1px 0px 0 ${color},
    -1px 0px 0px 0 ${color},
    0px 1px 0px ${color}
  `;
}
function buildFlagBoxShadowDelete(color: string) {
  return `
  0px -1px 0px 0 ${color},
  -1px 0px 0px 0 ${color},
  0px 0px 0px ${color}
  `;
}

export const IsActiveFlag = styled.div<{ isActive: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 3.5rem;
  line-height: 1;
  padding: 0.2rem 0.25rem 0.35rem;
  font-size: ${vars.$font_size_extra_sm};
  text-align: center;
  border-bottom-left-radius: ${vars.$border_radius};
  border-top-right-radius: 0.2rem;
  transition: all 200ms;
  box-shadow: ${buildFlagBoxShadow('transparent')};

  ${({ isActive }) => {
    return isActive
      ? css`
          background-color: ${vars.$greenlight};
          color: white;

          ${LocationSettingsListItem}:hover && {
            box-shadow: ${buildFlagBoxShadow('white')};
          }
        `
      : css`
          background-color: rgba(0, 0, 0, 0.015);
          color: rgba(0, 0, 0, 0.2);
          box-shadow: ${buildFlagBoxShadow('rgba(0, 0, 0, 0.075)')};

          ${LocationSettingsListItem}:hover && {
            border-color: transparent;
            box-shadow: ${buildFlagBoxShadow(lighten(vars.$greenlight, 0.15))};
            background-color: transparent;
            color: ${lighten(vars.$greenlight, 0.25)};
          }
        `;
  }}
`;
export const DeleteLocationItemWrapper = styled(Button)`
  color: ${vars.$white};
  position: absolute;
  bottom: 0;
  background-color: ${vars.$danger};
  outline: none;
  border: none;
  right: 0;
  border-radius: 0rem;
  border-top-left-radius: ${vars.$border_radius};
  border-bottom-right-radius: 0.2rem;
  font-size: ${vars.$font_size_extra_sm};
  width: 0rem;
  text-align: center;
  transition: all 0.2s ease;
  padding: 0;
  ${LocationSettingsListItem}:hover && {
    box-shadow: ${buildFlagBoxShadowDelete('white')};
    padding: 0.2rem 0.25rem 0.2rem;
    width: 3.5rem;
  }
`;

export const LoadingSubsectionPlaceholder = (props: {}): JSX.Element => {
  return (
    <SettingsSubsection>
      <Row>
        <Column>
          <List title="Loading..." icon="spinner-third" spin>
            <li></li>
          </List>
        </Column>
      </Row>
    </SettingsSubsection>
  );
};
