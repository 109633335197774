import { User, Place, ResponseOrganization, ResponsePlace } from '@friendemic/erc-graph';
import { filterStatusToTicketStatusMap } from '@friendemic/erc-graph/build/constants';
import {
  ReviewResponseTicket as TicketStatusType,
  // ReviewResponseTicketFilters,
} from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_pb';
import { SessionUser } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/session_user_pb';
import { InboxResponseTicket, Ulid } from 'src/async/types';
import { currentUserFixture, responsePlaces, active_ticket_fixture } from 'src/utils/fixtures';
import { enumValues } from 'src/utils/helpers';
import * as types from './actionTypes';

const { Type: TicketStatus } = TicketStatusType.Status;

export type FilterType = {
  rating: (1 | 2 | 3 | 4 | 5)[];
  channels: typeof enumValues.ChannelType[number][];
  status: typeof enumValues.TicketStatusType[number][];
  tags: typeof enumValues.TagType[number][];
  submittedAt: typeof enumValues.SubmittedAtType[number][];
  assigneeUlid: Array<Ulid>;
  placeUlid: Array<Ulid>;
};

export const EMPTY_FILTERS: FilterTypePartial = {
  channels: [],
  rating: [],
  status: [],
  tags: [],
  submittedAt: [],
  assigneeUlid: [],
  placeUlid: [],
};

export const DEFAULT_FILTERS: FilterTypePartial = {
  channels: [],
  rating: [],
  status: [TicketStatus.UNKNOWN, TicketStatus.REVIEW_UPDATED],
  tags: [],
  submittedAt: [],
  assigneeUlid: [],
  placeUlid: [],
};

export const ALL_SEARCH_QUERY_KEYS = Object.keys({
  ...EMPTY_FILTERS,
  changed: undefined,
  places: undefined,
  reviewUlid: undefined,
});

export type FilterTypePartial = Partial<FilterType>;

export type FilterTypeQueryString = {
  [Property in keyof FilterType]?: Array<string>;
} & { changed?: boolean };

export type CurrentUser = {
  userUlid: string;
  user: User;
  responseOrganizationUlid?: string;
  responseOrganization?: ResponseOrganization;
  placeUlid?: string;
  userRole: SessionUser.SessionUserRoleType;
  readOnly: boolean;
  responseApproverUlid?: string;
};

export type UserRoles = {
  isAdmin: boolean;
  isResponseUser: boolean;
  isOrganizationManager: boolean;
  isApprover: boolean;
};

export type Actions =
  | { type: typeof types.SET_ACTIVE_PLACE; place: Place | undefined }
  | { type: typeof types.SET_ACTIVE_TICKET; ticket: InboxResponseTicket | undefined }
  | { type: typeof types.SET_USER; currentUser: CurrentUser }
  | { type: typeof types.RESET_ACTIVE_FILTERS_OPTONS }
  | { type: typeof types.SET_ACTIVE_ORGANIZATION; organization: ResponseOrganization | undefined }
  | { type: typeof types.SET_USER_ROLE; userRoles: UserRoles }
  | { type: typeof types.SET_SIDEBAR_COLLAPSED; sideBarCollapsed: boolean };

export type AnyObject = Record<string, any>;

export type AppStateType = {
  activeTicket?: InboxResponseTicket;
  activePlace?: Place;
  currentUser: CurrentUser;
  activeOrganization?: ResponseOrganization;
  userRoles: UserRoles;
  selectedPlaces: (ResponsePlace & Place)[];
  statusMapping: Record<number, never[] | TicketStatusType.Status.Type[]>;
  sideBarCollapsed: boolean;
};

export const initialState: Partial<AppStateType> = {
  userRoles: {
    isResponseUser: false,
    isOrganizationManager: false,
    isAdmin: false,
    isApprover: false,
  },
  selectedPlaces: [],
  statusMapping: filterStatusToTicketStatusMap,
  sideBarCollapsed: false,
};
export const fixtureState = {
  activeTicket: active_ticket_fixture,
  activePlace: responsePlaces[0],
  currentUser: currentUserFixture,
  activeOrganization: null,
  sideBarCollapsed: false,
  selectedPlaces: [],
  userRoles: {
    isAdmin: true,
  },
};

export const appReducer = (state: AppStateType, action: Actions): AppStateType => {
  switch (action.type) {
    case types.SET_ACTIVE_PLACE:
      return { ...state, activePlace: action.place };
    case types.SET_ACTIVE_ORGANIZATION:
      return { ...state, activeOrganization: action.organization };
    case types.SET_ACTIVE_TICKET:
      return { ...state, activeTicket: action.ticket };
    case types.SET_USER:
      return { ...state, currentUser: action.currentUser };
    case types.SET_USER_ROLE:
      return { ...state, userRoles: action.userRoles };
    case types.SET_SIDEBAR_COLLAPSED:
      return { ...state, sideBarCollapsed: action.sideBarCollapsed };
    default:
      return state;
  }
};
