/* eslint-disable import/order */
import React from 'react';
import { Facebook, Google, Yelp, Carfax, Carscom, DealerRater, CarGurus } from 'src/assets/images';
import vars from 'src/styles/variables';

import { ResponseUser } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/response_user_pb';
import { SessionUser } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/session_user_pb';
import { ApprovalRequiredTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/approval_required_type_friendly';
import { ActionFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_submission_action_friendly';
import { ChannelTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/channel_type_friendly';
import { FeedbackTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/feedback_type_friendly';
import { ResponseUserRoleTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/response_user_response_user_role_type_friendly';
import { TypeFriendly as TicketStatusTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_status_type_friendly';
import {
  ReviewResponseTicketFilters,
  ReviewResponseTicket as ReviewResponseTicketStatuses,
} from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_pb';
import { StatusFriendly as TicketFiltersStatusTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_filters_status_friendly';
import { ReviewTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_type_friendly';
import { TagFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/tag_friendly';
import { SubmittedAtFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_filters_submitted_at_friendly';
import { isValid, formatDistanceStrict, differenceInHours } from 'date-fns';
import { CloseTicketReasonFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_close_ticket_reason_friendly';
import queryString from 'query-string';
import { useHistory, useLocation, useParams, ExtractRouteParams } from 'react-router';
import { User } from '@friendemic/erc-graph';
import { client, GET_USER } from 'src/graph';
import _, { unescape } from 'lodash';
import { desaturate, hsla, parseToHsla } from 'color2k';
import { ALL_SEARCH_QUERY_KEYS, EMPTY_FILTERS, FilterType, FilterTypePartial } from 'src/context';

const { SessionUserRoleType } = SessionUser;

/**
 * @description Returns the first letter of the First and Last names
 * @example
 * getInitials("A Name That May Have Multiple Spaces"); // returns "AS"
 * getInitials() // return "?"
 */
export const getInitials = (name?: string | null): string => {
  /* prettier-ignore */
  const splitName =
    name
      ? name.replace(/\s{2,}/g, ' ').trim()
        .split(' ')
        .map((n) => n[0].toUpperCase())
        .join('')
      : '?';

  return splitName.length > 2 ? splitName[0] + splitName[splitName.length - 1] : splitName;
};

export function getChannelIcon(
  channel?: string | number | null,
  active?: boolean,
  deleted?: boolean
): React.ReactElement | string {
  const channelNormalized = typeof channel === 'string' ? channel?.toLowerCase().split('.').join('') : channel;
  switch (channelNormalized) {
    case 0:
    case 'carfax':
      return <Carfax active={active} deleted={deleted} />;
    case 1:
    case 'cargurus':
      return <CarGurus active={active} deleted={deleted} />;
    case 2:
    case 'carscom':
      return <Carscom active={active} deleted={deleted} />;
    case 3:
    case 'dealerrater':
      return <DealerRater active={active} deleted={deleted} />;
    case 4:
    case 'facebook':
      return <Facebook active={active} deleted={deleted} />;
    case 5:
    case 'google':
      return <Google active={active} deleted={deleted} />;
    case 6:
    case 'yelp':
      return <Yelp active={active} deleted={deleted} />;
    default:
      return '?';
  }
}

export function getChannelEnum(channel: string): number {
  switch (channel.toLowerCase()) {
    case 'carfax':
      return 0;
    case 'cargurus':
      return 1;
    case 'carscom':
      return 2;
    case 'dealerrater':
      return 3;
    case 'facebook':
      return 4;
    case 'google':
      return 5;
    case 'yelp':
      return 6;
    default:
      return 7;
  }
}

export function getChannelColor(channel?: string | number | null): string {
  const channelNormalized = typeof channel === 'string' ? channel?.toLowerCase().split('.').join('') : channel;
  switch (channelNormalized) {
    case 0:
    case 'carfax':
      return vars.$carfax;
    case 1:
    case 'cargurus':
      return vars.$cargurus;
    case 2:
    case 'carscom':
      return vars.$carscom;
    case 3:
    case 'dealerrater':
      return vars.$dealerrater;
    case 4:
    case 'facebook':
      return vars.$facebook;
    case 5:
    case 'google':
      return vars.$google;
    case 6:
    case 'yelp':
      return vars.$yelp;
    default:
      return vars.$gray_3;
  }
}

const {
  UNKNOWN,
  UNAVAILABLE,
  REVIEW_UPDATED,
  RESPONSE_SUBMITTED,
  EDITS_REQUESTED,
  APPROVED_PENDING_PUBLISH,
  PUBLISHED,
  COMPLETED,
  COMPLETED_EXTERNAL,
} = ReviewResponseTicketStatuses.Status.Type;

function lightenColor(color: string): string {
  const convert = parseToHsla(color);
  return hsla(convert[0], convert[1], 0.975, convert[3]);
}

const lightenedGreen = lightenColor(vars.$greenlight);
const lightenedYellow = lightenColor(vars.$yellowdarker);
const lightenedBlue = lightenColor(vars.$info);
const lightenedRed = lightenColor(vars.$red);

export function getTicketStatusTypeColor(
  ticketStatusType: typeof enumValues.TicketStatusType[number],
  variant: 'light' | 'dark'
): string {
  switch (ticketStatusType) {
    case 0:
    case 2:
    case 11:
    case 12:
    case UNKNOWN:
    case REVIEW_UPDATED:
    case EDITS_REQUESTED:
    case APPROVED_PENDING_PUBLISH:
      return variant === 'light' ? lightenedGreen : vars.$green;
    case 10:
    case RESPONSE_SUBMITTED:
      return variant === 'light' ? lightenedRed : desaturate(vars.$red, 0.25);
    case 30:
    case PUBLISHED:
      return variant === 'light' ? lightenedYellow : desaturate(vars.$yellowdarker, 0.25);

    case 1:
    case 31:
    case 32:
    case UNAVAILABLE:
    case COMPLETED:
    case COMPLETED_EXTERNAL:
      return variant === 'light' ? lightenedBlue : vars.$infodark;

    default:
      return 'red';
  }
}

export const getTicketStatusTypeFriendlyOverrides = (
  ticketStatusType: typeof enumValues.TicketStatusType[number]
): string => {
  switch (ticketStatusType) {
    case 0:
    case UNKNOWN:
      return 'New';
    default:
      return TicketStatusTypeFriendly.toFriendly(ticketStatusType);
  }
};

/**
 * Custom URLs to use for when manual publishing is required.
 *
 * * The default urls are to the read-only reviews on the given channel.
 * * Manually published channels require the user to login to a specific area in order to respond.
 */
export function buildExternalLinkUrl(channel: string, url: string): string {
  switch (channel.toLowerCase()) {
    case 'yelp':
      return 'https://biz.yelp.com/login';
    case 'dealerrater':
      return 'https://www.dealerrater.com/login';
    case 'cargurus':
      return 'https://www.cargurus.com/Cars/authentication/renderRegisterLoginForm.action?pid=signin-register-nav&redirectUrl=%2F';
    default:
      return url;
  }
}

const { Status: FilterStatus } = ReviewResponseTicketFilters;
const { Type: TicketStatus } = ReviewResponseTicketStatuses.Status;

/**
 * Convert enums into select dropdown options {value: string; label: string;}
 *
 * * Every available enum should be added here
 *   * This object builds a reference story on Storybook.
 *   * Most are not used as select options, but it's a good way to keep track of enums without referencing another codebase (@friendemic/friendemic-api-node).
 *
 * * https://erc.development.friendemic.com/storybook/index.html?path=/story/global-enums--enums
 *
 * @example
 * REFERENCE:
 * Previous Bucketing Logic
 * const filters_bucket_map = {
 *   [UNKNOWN]: [],
 *   [ACTIVE]: [
 *     UNKNOWN,
 *     EDITS_REQUESTED,
 *     APPROVED_PENDING_PUBLISH,
 *   ],
 *   [PENDING]: [
 *     RESPONSE_SUBMITTED
 *   ],
 *   [PENDING_PUBLISH]: [
 *     PUBLISHED
 *   ],
 *   [DONE]: [
 *     UNAVAILABLE,
 *     COMPLETED,
 *     COMPLETED_EXTERNAL,
 *   ],
 *   [UPDATED]: [
 *     UPDATED
 *   ],
 * };
 */
export const selectOptions: Record<string, { label: string; value: number }[]> = {
  ApprovalRequiredType: selectConverter(ApprovalRequiredTypeFriendly.friendlyMap),
  ApprovalActionType: selectConverter(ActionFriendly.friendlyMap),
  ChannelType: selectConverter(ChannelTypeFriendly.friendlyMap),
  FeedbackType: selectConverter(FeedbackTypeFriendly.friendlyMap),
  ResponseUserRoleType: selectConverter(ResponseUserRoleTypeFriendly.friendlyMap),
  // Manually order status filters
  TicketFiltersStatusTypes: [
    {
      label: TicketFiltersStatusTypeFriendly.toFriendly(FilterStatus.NEW),
      value: TicketStatus.UNKNOWN,
    },
    {
      label: TicketFiltersStatusTypeFriendly.toFriendly(FilterStatus.UPDATED),
      value: TicketStatus.REVIEW_UPDATED,
    },
    {
      label: TicketFiltersStatusTypeFriendly.toFriendly(FilterStatus.EDITS_REQUESTED),
      value: TicketStatus.EDITS_REQUESTED,
    },
    {
      label: TicketFiltersStatusTypeFriendly.toFriendly(FilterStatus.APPROVED_PENDING_PUBLISH),
      value: TicketStatus.APPROVED_PENDING_PUBLISH,
    },
    {
      label: TicketFiltersStatusTypeFriendly.toFriendly(FilterStatus.RESPONSE_SUBMITTED),
      value: TicketStatus.RESPONSE_SUBMITTED,
    },
    {
      label: TicketFiltersStatusTypeFriendly.toFriendly(FilterStatus.PUBLISHED),
      value: TicketStatus.PUBLISHED,
    },
    {
      label: TicketFiltersStatusTypeFriendly.toFriendly(FilterStatus.UNAVAILABLE),
      value: TicketStatus.UNAVAILABLE,
    },
    {
      label: TicketFiltersStatusTypeFriendly.toFriendly(FilterStatus.COMPLETED),
      value: TicketStatus.COMPLETED,
    },
    {
      label: TicketFiltersStatusTypeFriendly.toFriendly(FilterStatus.COMPLETED_EXTERNAL),
      value: TicketStatus.COMPLETED_EXTERNAL,
    },
  ],
  TicketStatusType: selectConverter(TicketStatusTypeFriendly.friendlyMap),
  ReviewType: selectConverter(ReviewTypeFriendly.friendlyMap).filter((option) => {
    return option.label !== 'All';
  }),
  TagType: selectConverter(TagFriendly.friendlyMap),
  SubmittedAtType: selectConverter(SubmittedAtFriendly.friendlyMap),
  CloseTicketReasonType: selectConverter(CloseTicketReasonFriendly.friendlyMap),
};
export function selectConverter<T>(input: T): { label: string; value: number }[] {
  return Object.entries(input).map((e) => ({ label: e[0], value: e[1] }));
}

/**
 * Hardcoded enum values for use in typescript.
 */
export const enumValues = {
  ApprovalRequiredType: [0, 1, 2, 3] as const, // ApprovalRequiredType
  ApprovalActionType: [0, 1, 2, 3, 4] as const, // ReviewResponseSubmission.Action
  ChannelType: [0, 1, 2, 3, 4, 5, 6] as const, // ChannelType
  FeedbackType: [0, 1, 2, 4] as const, // FeedbackType
  ResponseUserRoleType: [0, 1, 2] as const, // ResponseUser.ResponseUserRoleType
  TicketFiltersStatusTypes: [0, 5, 6, 7, 8, 9, 10, 11, 12, 13] as const, // ReviewResponseTicketFilters.Status
  TicketStatusType: [0, 1, 2, 10, 11, 12, 30, 31, 32] as const, // ReviewResponseTicket.Status.Type
  ReviewType: [1, 2, 3] as const, // ReviewType
  TagType: [0, 1, 2, 30, 40, 50, 51, 52, 53, 54, 55, 60, 61] as const, // Tag
  SubmittedAtType: [0, 1, 2, 4, 5, 6, 7] as const, // ReviewResponseTicketFilters.SubmittedAt
  CloseTicketReasonType: [0] as const, // ReviewResponseTicket.CloseTicketReason
};

/**
 * Lookup utility to determine key based on number value
 * @param object { [key: string]: number }
 * @param value number
 * @example
 * getKeyByValue({'Some Formatted Value': 2}, 2) // returns 'Some Formatted Value'
 */
export function getKeyByValue(object: { [key: string]: number }, value: number): string | undefined {
  return Object.keys(object).find((key) => object[key] === value);
}

/**
 * Returns a string of the form '1 day ago' or '2 hours ago', etc
 *
 * @param date Date
 * @returns https://date-fns.org/v2.28.0/docs/formatDistanceStrict
 * @example
 * getFormattedDate(new Date('2020-01-01T00:00:00.000Z'))
 */
export const getFormatedDate = (date: string | number): string => {
  const newDate = new Date(date);
  if (!isValid(newDate)) return 'DATE UNKNOWN';

  return formatDistanceStrict(new Date(), newDate);
};

/**
 * Returns the number of hours between `date` argument and now.
 *
 * @param date Date
 * @returns https://date-fns.org/v2.28.0/docs/differenceInHours
 * @example
 * getHoursDiff(new Date('2020-01-01T00:00:00.000Z'))
 */
export const getHoursDiff = (date: Date): number => {
  return differenceInHours(new Date(), date);
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * Get the enum value for SubmittedAtFriendly based on the number of hours since the passed date argument.
 * - Written using SubmittedAtFriendly.toEnum for function readability.
 * - Get the difference in hours between now and the given date
 *   - Match that hour difference to the SubmittedAt Enum
 *
 * @param date Date
 * @returns Enum value for SubmittedAt
 * @example
 * matchSubmittedAtEpochToEnum(new Date('June 02, 1989 00:00:00')) // returns 7
 */
export function matchSubmittedAtEpochToEnum(date: number | Date) {
  const hoursDiff = differenceInHours(new Date(), date);
  if (hoursDiff < 8) return SubmittedAtFriendly.toEnum('0-8 hours') || 0;
  if (hoursDiff < 16) return SubmittedAtFriendly.toEnum('8-16 hours') || 1;
  if (hoursDiff < 24) return SubmittedAtFriendly.toEnum('16-24 hours') || 2;
  if (hoursDiff < 48) return SubmittedAtFriendly.toEnum('24-48 hours') || 4;
  if (hoursDiff < 7 * 24) return SubmittedAtFriendly.toEnum('2-7 days') || 5;
  if (hoursDiff < 30 * 24) return SubmittedAtFriendly.toEnum('7-30 days') || 6;
  return SubmittedAtFriendly.toEnum('30-45 days') || 7;
}
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */

/**
 * Converts underscores to spaces in a string
 * @example underscoresToSpaces("camel_case_string") // => "camel case string"
 */
export function underscoresToSpaces(str: string): string {
  return str.split('_').join(' ');
}

/**
 * Converts spaces to underscore in a string
 * @example spacesToUnderscores("space separated string") // => "space_separated_string"
 */
export function spacesToUnderscores(str: string): string {
  return str.split(' ').join('_');
}

/**
 * This function became necessary thanks to a handful of bugs to-do with commas in names and
 * a fringe error that occurs when the comma seperating ratings is encoded.
 * See https://friendemic.atlassian.net/browse/ERC-719
 */
export function sanitizeQueryString(search: string): queryString.ParsedQuery<string> {
  /* Manually double encode a comma (%2C -> %252C). Prevents crash: */
  /* See https://friendemic.atlassian.net/browse/ERC-716 */
  const doubleEncodeCommas = search.split('%2C').join('%252C');

  /* Unescape HTML entities that sometimes appear in queryStrings */
  /* See https://sentry.io/organizations/friendemic/issues/3253083349/events/?project=6273307&referrer=issue-stream */
  const replaceHTMLEntities = unescape(doubleEncodeCommas);

  const qs = queryString.parse(replaceHTMLEntities, { arrayFormat: 'comma' });

  /* The object to be returned from the loop*/
  const sanitizedQs: { [key: string]: any } = {};

  /* Loop through available keys and modify places & rating if necessary */
  Object.keys(qs).map((x) => {
    /* If the key is not in the list of valid search query keys, skip loop step */
    if (!ALL_SEARCH_QUERY_KEYS.includes(x)) return;

    if (x === 'places') {
      const { places: originalPlaces } = qs;
      /* Check if isArray */
      /* If each array iteration does NOT contain "__", it's wrong and the whole thing needs to be joined */
      if (Array.isArray(originalPlaces)) {
        const arr = (originalPlaces as Array<string>) || [];
        let isPlacesMisformatted = false;

        arr.map((y) => y.indexOf('__') === -1 && (isPlacesMisformatted = true));

        isPlacesMisformatted ? (sanitizedQs.places = arr.join('%2C')) : (sanitizedQs.places = originalPlaces);
      } else {
        /* else, just return the original */
        sanitizedQs.places = originalPlaces;
      }
    } else if (typeof qs[x] === 'string') {
      const currentVal = qs[x] as string;
      /* Check for an encoded comma in string. If yes, split to array */
      if (currentVal.indexOf('%2C') > -1) {
        sanitizedQs[x] = currentVal.split('%2C');
      } else {
        /* else, just return the original */
        sanitizedQs[x] = currentVal;
      }
    } else {
      /* set all other unmodified search values */
      sanitizedQs[x] = qs[x];
    }
  });

  /* Return sanitized Qs */
  return sanitizedQs;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUrl() {
  const location = useLocation();
  const qs = sanitizeQueryString(location.search);

  const ACTIVE_FILTERS = { ...EMPTY_FILTERS };

  const history = useHistory();
  const params: ExtractRouteParams<string> = useParams();
  const filtersOptions: Record<string, any> = {};
  const { reviewUlid, changed, ...rest } = qs;
  rest &&
    Object.keys(rest).forEach((queryItem: string) => {
      const item = qs[queryItem];
      if (item) {
        const filterItem = buildFilterDropDownData.find((fd) => fd.id === queryItem);
        if (item instanceof Array) {
          if (queryItem === 'places') {
            filtersOptions.placeUlid = item.map((i) => _.last(i.split('__')));
            ACTIVE_FILTERS.placeUlid = filtersOptions.placeUlid;
          } else {
            filtersOptions[queryItem] = item.map((i) => {
              const tempItem = filterItem?.items.find(
                (fItem) => fItem.name.toLowerCase() == underscoresToSpaces(i).toLowerCase()
              )?.inputValue;
              return tempItem != undefined
                ? !Number.isNaN(Number(tempItem))
                  ? Number(tempItem)
                  : tempItem
                : underscoresToSpaces(i);
            });
            ACTIVE_FILTERS[queryItem as keyof FilterType] = filtersOptions[queryItem];
          }
        } else {
          if (queryItem === 'places') {
            filtersOptions.placeUlid = [_.last(item.split('__'))];
            ACTIVE_FILTERS.placeUlid = filtersOptions.placeUlid;
          } else {
            const tempItem = filterItem?.items.find(
              (fItem) => fItem.name.toLowerCase() == underscoresToSpaces(item).toLowerCase()
            )?.inputValue;
            filtersOptions[queryItem] =
              tempItem != undefined
                ? [!Number.isNaN(Number(tempItem)) ? Number(tempItem) : tempItem]
                : [underscoresToSpaces(item)];
            ACTIVE_FILTERS[queryItem as keyof FilterType] = filtersOptions[queryItem];
          }
        }
      }
    });
  return {
    queryOptions: qs,
    filtersOptions,
    queryString,
    location,
    history,
    params,
    changed: changed,
    reviewUlid: reviewUlid,
    activeFilters: ACTIVE_FILTERS,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getSectionItem(inputName: string, value: string | number, label?: string, isChecked = false) {
  return {
    type: 'checkbox',
    name: inputName,
    inputValue: `${value}` as any,
    checkboxlabel: label || '',
    isChecked,
    disabled: false,
  };
}

const { ChannelType, TagType, TicketFiltersStatusTypes, SubmittedAtType } = selectOptions;

export const buildFilterDropDownData: {
  id: string;
  title: string;
  items: {
    type: string;
    name: string;
    inputValue: any;
    checkboxlabel: string;
    isChecked: boolean;
    disabled: boolean;
  }[];
}[] = [
  {
    id: 'channels',
    title: 'CHANNELS',
    items: ChannelType.map((iterator) => {
      return getSectionItem(iterator.label, iterator.value, iterator.label);
    }),
  },
  {
    id: 'rating',
    title: 'RATING',
    items: [5, 4, 3, 2, 1].map((iterator) => {
      return getSectionItem(`${iterator}`, iterator);
    }),
  },
  {
    id: 'status',
    title: 'STATUS',
    items: TicketFiltersStatusTypes.map((status) => {
      return getSectionItem(status.label, status.value, status.label);
    }),
  },
  {
    id: 'tags',
    title: 'TAGS',
    items: TagType.map((tag) => getSectionItem(tag.label, tag.value, tag.label)),
  },
  {
    id: 'submittedAt',
    title: 'SUBMITTED',
    items: SubmittedAtType.map((i) => getSectionItem(i.label, i.value, i.label)),
  },
  {
    id: 'assigneeUlid',
    title: 'ASSIGNED TO',
    items: [],
  },
];

export async function fetchUserByUlid(input: string): Promise<{ data: { getUser: User } }> {
  const res = await client.query({
    query: GET_USER,
    variables: { userUlid: input },
  });

  return res;
}

/**
 * Remove style="color: red;" or style='color: red;' from any string
 */
export function removeStyleAttributes(content?: string): string {
  return content?.replace(/\s*style=(["'])(.*?)\1/gim, '') || '';
}

/**
 * Workaround for react-quill default behavior of adding a variation of <p><br></p> to empty editor
 */
export function checkIfRedAlertIsEmpty(body: string): boolean {
  switch (body) {
    case '<p><br></p>':
    case '<h1><br></h1>':
    case '<h2><br></h3>':
    case '<h3><br></h3>':
      return true;
    default:
      return false;
  }
}

/**
 * Remove extra spaces in a string split by underscores. Removes last item from array (ULID)
 * @see https://friendemic.atlassian.net/browse/ERC-730
 * @example
 * sanitizePlaceName(SOME___LONG___PLACE__________NAME__ULID) // => "SOME LONG PLACE NAME"
 */
export function sanitizePlaceName(place: string): string {
  /* split by  '__' to isolate ULID */
  const splitArray = place.split('__');
  /* return everything except last element of array (removes ULID) */
  const removedUlid = splitArray.splice(0, splitArray.length - 1);
  /* rejoin array with spaces (can potentialy contain multiple spaces) */
  const rejoinPlaceName = underscoresToSpaces(removedUlid.join(' '));
  /* Remove extra spaces in final string */
  const removedExtraSpaces = rejoinPlaceName.replace(/\s\s+/g, ' ');

  return removedExtraSpaces;
}

/**
 * Add "Approver" to Friendly User Role output
 */
export const friendlyUserRole = (userRole: SessionUser.SessionUserRoleType): string =>
  userRole === SessionUserRoleType.APPROVER
    ? 'Approver'
    : ResponseUserRoleTypeFriendly.toFriendly(userRole as unknown as ResponseUser.ResponseUserRoleType);

/**
 * Converts the filter object's number[] values to string[]
 * - Except for ratings, which are left as number[]
 *
 * Ignoring ts(7053) - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'FilterType'.
 */
export function prepareFiltersForRequest(filters: FilterTypePartial): {
  [key: string]: string[] | number[] | undefined;
} {
  const newFilters = {};
  Object.keys(filters).map((x) => {
    x === 'rating'
      ? //@ts-expect-error ts(7053)
        (newFilters[x] = filters[x])
      : //@ts-expect-error ts(7053)
        (newFilters[x] = filters[x].map(String));
  });
  return newFilters;
}
