import React from 'react';
import { Link } from 'react-router-dom';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';
import Button from '../Button';

const StyledIcon = styled(FontAwesomeIcon)``;

const SubNavbarWrapper = styled.div<{ isPlaceActive?: boolean }>`
  border-bottom: ${vars.$border};
  padding: 1rem;
  background-color: white;
  color: ${vars.$muted};
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    display: flex;
  }

  > div {
    display: flex;
    align-items: center;
  }

  /* This component will only ever have two children, align the 2nd to the right if it exists */
  div:nth-child(2) {
    text-align: right;
  }

  ${Button} {
    margin-right: 0.75rem;
    font-size: 120%;
    border: 0;
    color: ${vars.$gray_a};
    &:hover {
      color: ${vars.$gray_9};
    }
    &:active {
      color: ${vars.$body_color};
    }
  }

  ${StyledIcon} {
    margin-right: 0.375rem;
    font-size: 0.75rem;
    height: 0.85rem;
  }
`;

type SubNavbarProps = {
  icon?: string | string[];
  title: string;
  backButton?: string;
  children?: JSX.Element;
  isPlaceActive?: boolean;
  spin?: boolean;
};

const SubNavbar = ({ icon, title, backButton, children, isPlaceActive, spin }: SubNavbarProps): React.ReactElement => {
  return (
    <SubNavbarWrapper isPlaceActive={isPlaceActive}>
      <div>
        {backButton && (
          <Link to={backButton}>
            <Button blank>
              <FontAwesomeIcon icon="chevron-left" />
            </Button>
          </Link>
        )}
        {icon && <StyledIcon spin={spin} icon={icon as IconName} />}
        {title}
      </div>
      {children && <div>{children}</div>}
    </SubNavbarWrapper>
  );
};

export default SubNavbar;
