import React from 'react';
import { ApolloCache, InMemoryCache } from '@apollo/client';
import { TypeFriendly as TicketStatusTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_status_type_friendly';
import { ReviewTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_type_friendly';
import { useQueryClient } from '@tanstack/react-query';
import styled, { css } from 'styled-components/macro';
import { REVIEW_RESPONSE_TICKET_KEY } from 'src/async/queryKeys.utils';
import { ReviewResponseTicket } from 'src/async/types';
import { DropDown, Button } from 'src/components';
import { useStateValue } from 'src/context';
import { EDIT_REVIEW_RESPONSE_TICKET } from 'src/graph';
import { useGraphMutation } from 'src/hooks';
import vars from 'src/styles/variables';
import { toast } from 'src/utils';
import {
  SectionHead,
  ResponseConsoleSectionTitle,
  AssignedToFlag,
  TicketStatusDropdownWrapper,
  dropdownButtonMixin,
} from '../ResponseConsole.styled';

type DropdownDataShape = {
  name: string;
  selected: boolean;
};

const DropdownTrigger = styled(Button)<{ isDisabled: boolean }>`
  ${dropdownButtonMixin}
  padding-right: 0.25rem;
  padding-left: 0.6rem;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      padding-right: 0.6rem;
      /* box-shadow: none; */
      padding: 0.4rem;
    `};
`;

const ItemWrapper = styled.div<{ selected: boolean }>`
  font-size: ${vars.$font_size_extra_sm};
  color: ${vars.$font_color};
`;

const DropdownItem = (props: Record<string, any>) => {
  const {
    data: { name, selected },
  } = props;
  return <ItemWrapper selected={selected}>{name}</ItemWrapper>;
};

export const ReviewTypeDropdown = (props: { ticketReviewType: number; reviewUlid: string }): React.ReactElement => {
  const { ticketReviewType, reviewUlid } = props;
  const [{ userRoles }] = useStateValue();
  const [reviewTypeDropdownData, setReviewTypeDropdownData] = React.useState<DropdownDataShape[]>([]);
  const [selectedItem, setSelectedItem] = React.useState<DropdownDataShape>();

  const queryClient = useQueryClient();

  const { mutationMethod: editReviewResponseTicket, error: editError } = useGraphMutation({
    mutation: EDIT_REVIEW_RESPONSE_TICKET,
  });

  if (editError) {
    toast({ type: 'warn', message: editError });
  }

  React.useEffect(() => {
    const filtered = Object.keys(ReviewTypeFriendly.friendlyMap).filter((item) => {
      return item !== 'All';
    });

    const builtDropdownOptions = filtered.map((name: string) => {
      return { name: name, selected: name === ReviewTypeFriendly.toFriendly(ticketReviewType) };
    });

    setReviewTypeDropdownData(builtDropdownOptions);
    setSelectedItem(builtDropdownOptions.find((item) => item.selected) || undefined);
  }, [ticketReviewType]);

  const updateForSelectedReviewType = (name: string) => {
    const updatedArr = reviewTypeDropdownData.map((item) => {
      return item.name === name ? { ...item, selected: true } : { ...item, selected: false };
    });

    return updatedArr;
  };

  const handleSelect = async (item: DropdownDataShape) => {
    const updatedData = updateForSelectedReviewType(item.name);
    const extractedSelectedItem = updatedData.find((item) => item.selected) || { name: 'All', selected: true };
    setReviewTypeDropdownData(updatedData);
    setSelectedItem(extractedSelectedItem);

    await editReviewResponseTicket({
      variables: {
        reviewUlid: reviewUlid,
        input: {
          type: ReviewTypeFriendly.toEnum(extractedSelectedItem.name),
        },
      },
      onCompleted: () => {
        queryClient.invalidateQueries([REVIEW_RESPONSE_TICKET_KEY, reviewUlid]);
      },
      // * Updates ReviewType, then updates the cached Inbox data set for that item
      update: (cache: ApolloCache<InMemoryCache>, mutationResult) => {
        cache.modify({
          id: 'ROOT_QUERY',
          fields: {
            listReviewResponseTickets(tickets, { readField }) {
              const records = tickets.records.map((ticket: ReviewResponseTicket) => {
                if (readField('reviewUlid', ticket) === reviewUlid) {
                  return {
                    ...ticket,
                    type: mutationResult.data.editReviewResponseTicket.record.type,
                  };
                }
                return ticket;
              });
              return { ...tickets, records };
            },
            getReviewResponseTicket(ticket: ReviewResponseTicket) {
              if (ticket.reviewUlid === reviewUlid) {
                return { ...ticket, type: mutationResult.data.editReviewResponseTicket.record.type };
              }
            },
          },
        });
      },
    });
  };

  return (
    <TicketStatusDropdownWrapper data-cy="ticket-review-type">
      <DropDown
        selected={selectedItem}
        dropdownPosition="right"
        headerComp={{
          component: DropdownTrigger,
          props: {
            onClick: () => null,
            content: selectedItem ? selectedItem.name : 'Review Type',
            isDisabled: userRoles.isApprover,
            ...(userRoles.isApprover ? {} : { iconRight: 'chevron-down' }),
          },
        }}
        onSelect={(e) => handleSelect(e)}
        data={reviewTypeDropdownData}
        component={DropdownItem}
      />
    </TicketStatusDropdownWrapper>
  );
};

const ResponseTicketTitleBar = (props: {
  ticketReviewType: number;
  clearActiveReview: () => void;
  status: number;
  reviewUlid: string;
  assigneeUserName?: string | null;
}): JSX.Element => {
  return (
    <>
      <SectionHead>
        <ResponseConsoleSectionTitle>
          <ResponseConsoleSectionTitle>{TicketStatusTypeFriendly.toFriendly(props.status)}</ResponseConsoleSectionTitle>
        </ResponseConsoleSectionTitle>
        {/* <ReviewTypeDropdown ticketReviewType={props.ticketReviewType} reviewUlid={props.reviewUlid || 'UNKNOWN'} /> */}
      </SectionHead>
      <AssignedToFlag isAssigned={Boolean(props.assigneeUserName)}>
        {props.assigneeUserName ? `${props.assigneeUserName || 'UNKNOWN'}` : 'Unassigned'}
      </AssignedToFlag>
      {/* <CloseTicketButton onClick={props.clearActiveReview} /> */}
    </>
  );
};

export default ResponseTicketTitleBar;
