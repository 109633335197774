import React from 'react';
import ReactDOM from 'react-dom';
import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { unregisterServiceWorker } from './serviceWorkerRegistration';

/**
 * Initialize FullStory
 *
 */
const isFullStoryEnabled = false;
if (isFullStoryEnabled && process.env.REACT_APP_FULLSTORY_ORG_ID) {
  FullStory.init({
    orgId: process.env.REACT_APP_FULLSTORY_ORG_ID,
    devMode: process.env.REACT_APP_ENVIRONMENT !== 'production',
  });
}

/**
 * Initialize Sentry
 * - Sentry Tracing Sample Rate - check if environment variable is set and is not "NaN". If NaN, set to 0 (off).
 *
 */
const tracesSampleRateEnvVar = process.env.REACT_APP_SENTRY_TRACE_RATE as string;
const tracesSampleRate = isNaN(parseFloat(tracesSampleRateEnvVar)) ? 0 : parseFloat(tracesSampleRateEnvVar);
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing(), new SentryFullStory('erc-frontend')],
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_RELEASE,

  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'xhr' && breadcrumb.data?.url?.includes('fullstory')) {
      return null;
    }

    if (breadcrumb.category === 'ui.click' || breadcrumb.category === 'ui.input') {
      const { target } = hint?.event;
      const aria = target.ariaLabel;
      const dataCy = target.getAttribute('data-cy');
      const ariaMessage = aria ? `[aria-label="${aria}"]` : '';
      const dataCyMessage = dataCy ? `[data-cy="${dataCy}"]` : '';
      const messageBackup = breadcrumb.message;

      const seperator = Boolean(ariaMessage || dataCyMessage) ? ' --- ' : '';

      breadcrumb.message = `${ariaMessage}${dataCyMessage}${seperator}${messageBackup}`;
    }
    return breadcrumb;
  },

  beforeSend: (event: Sentry.Event, hint) => {
    const error = hint?.originalException as Error;
    /* Do not send error with the exact error.message of string 'undefined' */
    if (error && error.message && error.message.match(/^undefined$/i)) {
      return null;
    }
    return event;
  },

  ignoreErrors: ['No result for', 'Not Found', 'ResizeObserver loop limit exceeded'],
  tracesSampleRate: tracesSampleRate,
});

/**
 * Removes existing service worker and clears cache
 *
 */
unregisterServiceWorker();

/**
 * ! Disabled serviceWorker
 * Manages:
 * - saving assets in cache
 * - if new assets are available, the user will be prompted to reload the page
 *
 * Side effects:
 *   - most users don't immediately get the latest version after reload
 */ /*
import { ServiceWorkerProvider } from './ServiceWorkerProvider';
import registerServiceWorker from './serviceWorkerRegistration';
registerServiceWorker({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event: any) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
ReactDOM.render(
  <ServiceWorkerProvider>
    <App />
  </ServiceWorkerProvider>,
  document.getElementById('root')
);
*/

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
