import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChannelTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/channel_type_friendly';
import { Tag } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/tags_pb';
import { useQueryClient } from '@tanstack/react-query';
import { REVIEW_RESPONSE_TICKET_KEY } from 'src/async/queryKeys.utils';
import { ReviewResponseTicket, Review, ReviewResponseTag } from 'src/async/types';
import { Pill, Column, RatingStars, DropDown, Tooltip, ConditionalWrapper, Checkbox } from 'src/components';
import { useStateValue } from 'src/context';
import { getChannelColor, getChannelIcon } from 'src/utils';
import { ReviewTypeDropdown } from '../../ResponseTicket/ResponseTicketTitleBar';
import { CardTemplate } from '../CardTemplate';
import { MetaWrapper, MetaTitle, MetaText } from '../ReviewResponse.styled';
import {
  Title,
  ExternalLink,
  RatingWrapper,
  IndividualPillWrapper,
  TagsWrapper,
  AddTagButton,
  FilterItem,
} from './ReviewCard.styled';
import { useGraphMutations } from './useGraphMutations';
import { tagsData, populateStateTags, populateTagsData } from './utils';

const DropdownTagItems = (props: Record<string, any>): JSX.Element => {
  const {
    data: { value, id },
    selected,
  } = props;
  return (
    <FilterItem className={selected && 'selected'} disabled={id === Tag.NO_RESPONSE && !selected}>
      <Checkbox>
        <input id={value} type="checkbox" data-testid="input-checkbox" checked={selected} onChange={() => null} />
        <FontAwesomeIcon icon={['fas', 'check']} title="check" />

        <label htmlFor={value}>{value?.length > 20 ? `${value.slice(0, 20)}...` : value}</label>
      </Checkbox>
    </FilterItem>
  );
};

export const ReviewCard = (props: { ticket: ReviewResponseTicket }): JSX.Element => {
  const { channel, reviewUlid, review, reviewResponseTags, type, rating, placeName } = props.ticket;
  const { comment, submittedAt, collectedAt, customer, url } = review as Review;

  const [{ userRoles }] = useStateValue();
  const [selectedTags, setSelectedTags] = useState<Record<string, any>>();
  const [tagsFriendly, setTagsFriendly] = useState<ReviewResponseTag[]>([]);
  const { createResponseTag, deleteResponseTag } = useGraphMutations(reviewUlid);
  const channelColor = getChannelColor(channel);
  const channelIcon = getChannelIcon(channel);
  const channelName = ChannelTypeFriendly.toFriendly(channel);

  const queryClient = useQueryClient();

  useEffect(() => {
    populateTagsData(setTagsFriendly, setSelectedTags, reviewResponseTags);
  }, [props.ticket, reviewResponseTags]);

  async function filterOnSelect(item?: Record<string, any>) {
    const isTagExist = reviewResponseTags?.find((t) => t.value == item?.id);
    const { tempSelectedStates, tempTagsFriendly, isExist } = populateStateTags(selectedTags, tagsFriendly, item);
    if (!isExist) {
      await createResponseTag({
        variables: {
          input: {
            reviewUlid: reviewUlid,
            value: item?.id,
          },
        },
        onCompleted: () => {
          queryClient.invalidateQueries([REVIEW_RESPONSE_TICKET_KEY, reviewUlid]);
        },
      });
    } else {
      if (isTagExist)
        await deleteResponseTag({
          variables: {
            input: {
              reviewUlid: reviewUlid,
              ulid: isTagExist?.ulid,
            },
          },
          onCompleted: () => {
            queryClient.invalidateQueries([REVIEW_RESPONSE_TICKET_KEY, reviewUlid]);
          },
        });
    }
    setTagsFriendly(tempTagsFriendly);
    setSelectedTags(tempSelectedStates);
  }

  return (
    <CardTemplate
      config={{
        title: channelName,
        icon: channelIcon,
        color: channelColor,
        date: submittedAt,
        dateTitle: 'Submitted at',
        collectedAt: collectedAt,
        collectedAtTitle: 'Collected at',
      }}
    >
      <Title dangerouslySetInnerHTML={{ __html: `${placeName || 'UNKNOWN'}` }} />
      {/* @Todo: Uncomment when real data is available */}
      {/* <MetaWrapper>
        <Column width={[1, 1 / 2]}>
          <MetaTitle>{'TicketStatusType'}</MetaTitle>
          <MetaText>{TypeFriendly.toFriendly(status)}</MetaText>
        </Column>
      </MetaWrapper> */}
      <MetaWrapper>
        <Column width={[1, 1 / 2]}>
          <MetaTitle>{'from'}</MetaTitle>
          <MetaText dangerouslySetInnerHTML={{ __html: `${customer || 'UNKONWN'}` }} />
        </Column>
        <Column width={[1, 1 / 2]} mt={[5, 0]}>
          <MetaTitle>{'Rating'}</MetaTitle>
          <RatingWrapper>
            <MetaText>{rating}</MetaText>
            <RatingStars rating={rating || 0} />
          </RatingWrapper>
        </Column>
      </MetaWrapper>
      <MetaWrapper>
        <Column width={[1]}>
          <MetaTitle>
            <ConditionalWrapper
              condition={Boolean(url)}
              wrapper={(children) => (
                <Tooltip content={`View original on ${channelName}`}>
                  <ExternalLink target="_BLANK" href={url || ''}>
                    {children}
                    <FontAwesomeIcon icon="external-link-alt" />
                  </ExternalLink>
                </Tooltip>
              )}
            >
              <>{'Review'}</>
            </ConditionalWrapper>
          </MetaTitle>
          <MetaText isEmpty={!Boolean(comment)}>
            {comment ? (
              <p dangerouslySetInnerHTML={{ __html: `${comment}` }} />
            ) : (
              <p>{'This reviewer did not leave a comment'}</p>
            )}
          </MetaText>
        </Column>
      </MetaWrapper>
      <TagsWrapper>
        <ReviewTypeDropdown ticketReviewType={type} reviewUlid={reviewUlid || 'UNKNOWN'} />
        {!userRoles.isApprover && (
          <DropDown
            isMulti
            headerComp={{
              component: AddTagButton,
              props: {
                icon: 'plus',
                text: 'Add Tag',
              },
            }}
            onSelect={(e) => filterOnSelect(e)}
            data={tagsData
              .sort(function (a, b) {
                const textA = a.value.toUpperCase();
                const textB = b.value.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
              .map((tag) => {
                return tag;
              })}
            component={DropdownTagItems}
            itemPadding={'0px'}
            multiActiveOptions={selectedTags}
          />
        )}
        {Boolean(tagsFriendly && tagsFriendly.length) && (
          <>
            {tagsFriendly?.map(
              (tag) =>
                tag.value !== 'UNKNOWN' && (
                  <IndividualPillWrapper key={tag.value}>
                    <Pill
                      small
                      dismissible={userRoles.isApprover ? undefined : true}
                      onDismissClick={() => filterOnSelect(tag)}
                    >
                      {tag.value}
                    </Pill>
                  </IndividualPillWrapper>
                )
            )}
          </>
        )}
      </TagsWrapper>
    </CardTemplate>
  );
};

export default ReviewCard;
