import React from 'react';
import { ResponseOrganization } from '@friendemic/erc-graph';
import { format, subDays } from 'date-fns';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { InputElement, Column, Row } from 'src/components';
import { useStateValue } from 'src/context';
import { LIST_RESPONSE_ORGANIZATIONS } from 'src/graph';
import { getRecords } from 'src/hooks';
import { InputElementFormConfig } from 'src/types';

export type FormValues = typeof initialFormValues;

export const initialFormValues = {
  responseOrganizationUlid: '',
  startDate: subDays(new Date(), 7),
  endDate: new Date(),
};

export const validationSchema = Yup.object({
  responseOrganizationUlid: Yup.string().required('Required'),
  startDate: Yup.string().required('Required'),
  endDate: Yup.string().required('Required'),
});

export const formConfig: InputElementFormConfig = {
  responseOrganizationUlid: {
    border: true,
    label: 'Organization',
    placeholder: 'Select An Organization...',
    defaultOptions: true,
    name: 'responseOrganizationUlid',
  },
  startDate: {
    border: true,
    label: 'Start Date',
    placeholder: 'Start Date',
    name: 'startDate',
    type: 'dateInput',
  },
  endDate: {
    border: true,
    label: 'End Date',
    placeholder: 'End Date',
    name: 'endDate',
    type: 'dateInput',
  },
};

export const RenderForm = (props: FormikProps<FormValues>): React.ReactElement => {
  const [startDate, setStartDate] = React.useState(subDays(new Date(), 7));
  const [endDate, setEndDate] = React.useState(new Date());
  const [{ userRoles, activeOrganization }] = useStateValue();

  React.useEffect(() => {
    if (userRoles.isOrganizationManager && activeOrganization) {
      props.setFieldValue('responseOrganizationUlid', activeOrganization.ulid || 'UNKNOWN');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoles, activeOrganization]);

  function formatDate(date: Date) {
    return format(date, 'PPPP');
  }

  /**
   * * Only runs if !userRoles.isOrganizationManager
   */
  async function fetchOrganizations() {
    const res = await getRecords({
      queryString: LIST_RESPONSE_ORGANIZATIONS,
    });
    const { data }: { data: { listResponseOrganizations: ResponseOrganization[] } } = res;
    if (data) {
      return data.listResponseOrganizations.map((a: ResponseOrganization) => ({
        label: a.name,
        value: a.ulid,
      }));
    }
    return [];
  }

  return (
    <>
      <Row>
        <Column width={[1]}>
          {userRoles.isOrganizationManager && activeOrganization ? (
            <InputElement
              {...formConfig.responseOrganizationUlid}
              disabled
              type="select"
              value={{ label: activeOrganization.name, value: activeOrganization.ulid }}
            />
          ) : (
            <InputElement
              {...formConfig.responseOrganizationUlid}
              loadOptions={fetchOrganizations}
              type="select-async"
              filterOption={(candidate, input) => {
                return candidate.data.__isNew__ || candidate.label.toLowerCase().includes(input);
              }}
            />
          )}
        </Column>
      </Row>
      <Row>
        <Column width={[1, 1 / 2]}>
          <InputElement
            {...formConfig.startDate}
            maxDate={endDate}
            handleChange={(val) => setStartDate(val)}
            helperText={formatDate(startDate)}
          />
        </Column>
        <Column width={[1, 1 / 2]}>
          <InputElement
            {...formConfig.endDate}
            minDate={startDate}
            maxDate={new Date()}
            handleChange={(val) => setEndDate(val)}
            helperText={formatDate(endDate)}
          />
        </Column>
      </Row>
    </>
  );
};
