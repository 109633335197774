import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { ResponseNotificationRecipient } from '@friendemic/erc-graph';
import review_type_friendly from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_type_friendly.js';
import { ActionsCell } from 'src/components';
import { client, DELETE_RESPONSE_NOTIFICATION_RECIPIENT } from 'src/graph';
import { toast } from 'src/utils';
import { LocationSettingsRouteParams } from 'src/views/Settings/SettingsTypes';
import { DataRow } from '../../../AdminManagement/table';
import { FormValues } from './NotificationManagementForm';

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Recipient',
    selector: (row: Record<string, any>): any => row.recipient,
    sortable: true,
  },
  {
    name: 'Notify for',
    selector: (row: Record<string, any>): any => row.notifyFor,
    sortable: true,
    grow: 0,
    minWidth: '104px',
  },
  // {
  //   name: 'Required Recipient',
  //   selector: (row: Record<string, any>): any => row.required,
  //   sortable: true,
  //   grow: 0,
  // },
  {
    name: 'Actions',
    width: '6rem',
    sortable: false,
    center: true,
    grow: 0,
    cell: ActionsCell,
  },
];

export function buildData(
  recipients: ResponseNotificationRecipient[],
  setRecipients: React.Dispatch<React.SetStateAction<ResponseNotificationRecipient[]>>,
  setActiveEditingRecipient: React.Dispatch<React.SetStateAction<FormValues>>,
  params: LocationSettingsRouteParams,
  showAddRecipientModal: () => void
): any[] {
  return recipients
    ? recipients.map((recipient: ResponseNotificationRecipient, i: number) => {
        const currentRecipientName = recipient.user ? recipient.user.name : 'UNKNOWN';

        return {
          recipient: currentRecipientName,
          notifyFor: review_type_friendly.ReviewTypeFriendly.toFriendly(recipient.reviewType),
          // required: recipient.required ? 'Yes' : 'No',
          actions: [
            {
              action: 'delete',
              icon: 'trash-alt',
              tooltip: 'Delete',
              onClick: async () => {
                const res = await client.mutate({
                  mutation: DELETE_RESPONSE_NOTIFICATION_RECIPIENT,
                  variables: { placeUlid: params.location, ulid: recipient.ulid },
                });
                if (
                  res.data.deleteResponseNotificationRecipient &&
                  res.data.deleteResponseNotificationRecipient.errors
                ) {
                  toast({ type: 'error', message: 'Something went wrong, please try again.' });
                } else {
                  const modifiedRecipients = [...recipients];
                  modifiedRecipients.splice(i, 1);
                  setRecipients(modifiedRecipients);
                  toast({ type: 'error', message: `Removed ${currentRecipientName} as Recipient` });
                }
              },
            },
            {
              action: 'edit',
              icon: 'edit',
              tooltip: 'Edit',
              onClick: () => {
                setActiveEditingRecipient({
                  add_recipient_ulid: recipient.ulid,
                  add_recipient_review_type: recipient.reviewType,
                  // add_recipient_required: recipient.required,
                });
                showAddRecipientModal();
              },
            },
          ],
        };
      })
    : [];
}
