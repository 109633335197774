import { ResponseTemplate } from '@friendemic/erc-graph';
import { useQuery, useMutation, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { RESPONSE_TEMPLATES_KEY } from '../queryKeys.utils';
import { SharedMutationResult, TemplateVariables } from '../types/async';
import { createTemplate, deleteTemplate, editTemplate, listTemplates } from './templates';

export function useListTemplates(placeUlid: string): UseQueryResult<ResponseTemplate[]> {
  return useQuery({
    queryKey: [RESPONSE_TEMPLATES_KEY, placeUlid],
    queryFn: () => listTemplates(placeUlid),
  });
}

export function useCreateTemplate(): SharedMutationResult<ResponseTemplate, TemplateVariables> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createTemplate,
    onSuccess: (_, { placeUlid }) => {
      queryClient.invalidateQueries([RESPONSE_TEMPLATES_KEY, placeUlid]);
    },
  });
}

export function useEditTemplate(): SharedMutationResult<ResponseTemplate, TemplateVariables> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editTemplate,
    onSuccess: (_, { placeUlid }) => {
      queryClient.invalidateQueries([RESPONSE_TEMPLATES_KEY, placeUlid]);
    },
  });
}

export function useDeleteTemplate(): SharedMutationResult<unknown, TemplateVariables> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteTemplate,
    onSuccess: (_, { placeUlid }) => {
      queryClient.invalidateQueries([RESPONSE_TEMPLATES_KEY, placeUlid]);
    },
  });
}
