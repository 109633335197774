import React from 'react';
// import { Formik, Form, FormikProps } from 'formik';
// import { Modal } from 'src/components';
// import { initialFormValues, FormValues, RenderForm, validationSchema } from './NoResponseNeededForm';
import { Formik, FormikProps, Form } from 'formik';
import * as Yup from 'yup';
import { InputElement, Column, Row, Modal } from 'src/components';
import { InputElementFormConfig } from 'src/types';

type NoResponseNeededProps = {
  hideModal: () => void;
  handleNoResponseNeeded: (values: FormValues) => void;
};

export type FormValues = typeof initialFormValues;

const initialFormValues = {
  noteContent: '',
};

const formConfig: InputElementFormConfig = {
  noteContent: {
    label: 'Note',
    placeholder: 'Reason for not responding',
    name: 'noteContent',
    type: 'textarea',
    border: true,
  },
};

const NoResponseNeededModal = (props: NoResponseNeededProps): JSX.Element => {
  const { hideModal, handleNoResponseNeeded } = props;
  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={Yup.object({
        noteContent: Yup.string().required('Required'),
      })}
      onSubmit={(values) => {
        handleNoResponseNeeded(values);
      }}
    >
      {(props: FormikProps<FormValues>) => {
        const { submitForm, isSubmitting } = props;
        return (
          <Modal
            hideModal={hideModal}
            title={'No Response Needed'}
            compact
            footer
            cancelable
            submittable
            submitDisabled={isSubmitting}
            isSubmitting={isSubmitting}
            handleSubmit={submitForm}
          >
            <Form>
              <Row>
                <Column>
                  <InputElement {...formConfig.noteContent} />
                </Column>
              </Row>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default NoResponseNeededModal;
