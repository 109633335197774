import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { IconDefinition, IconName } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResponseOrganization } from '@friendemic/erc-graph';
import { InputGroup } from 'src/components';
import {
  DropDownTitleWrapper,
  DropDownPrimaryTitle,
  DropDownSecondaryTitle,
  DropdownHeaderWrapper,
} from './DropDown.styled';

interface DropDownHeaderProps {
  onReset?: (...args: any) => void;
  primaryTitle?: string | null;
  secondaryTitle?: string | null;
  isOpen: boolean;
  isCloseIcon?: boolean;
  leftIcon?: { icon: string | null | IconDefinition; showDefault: boolean };
  updateState: Dispatch<SetStateAction<any>>;
  data: any[];
}

const DropDownHeader = (props: DropDownHeaderProps): ReactElement<{ organizations: ResponseOrganization[] }> => {
  const { secondaryTitle, primaryTitle, isCloseIcon, leftIcon, isOpen, onReset, updateState } = props;
  let iconLeft: IconDefinition | string | null = 'car-building';
  iconLeft = leftIcon && !leftIcon?.showDefault ? null : iconLeft;
  if (leftIcon?.icon && !leftIcon.showDefault) {
    iconLeft = leftIcon.icon;
  }
  return (
    <DropdownHeaderWrapper
      isopen={isOpen}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        updateState({ isdropOpen: !isOpen, dataToRender: props.data });
      }}
    >
      {iconLeft && (
        <FontAwesomeIcon data-lefticon="iconleft" icon={iconLeft as IconName} style={{ boxSizing: 'inherit' }} />
      )}
      <InputGroup>
        <div data-header="dropdown_head">
          <DropDownTitleWrapper>
            {primaryTitle && <DropDownPrimaryTitle>{primaryTitle}</DropDownPrimaryTitle>}
            {secondaryTitle && (
              <DropDownSecondaryTitle primaryTitle={primaryTitle}>
                {/* decoded string, see https://friendemic.atlassian.net/browse/ERC-716 */}
                {decodeURIComponent(secondaryTitle)}
              </DropDownSecondaryTitle>
            )}
          </DropDownTitleWrapper>
          {!isCloseIcon ? (
            <FontAwesomeIcon
              icon={'chevron-down'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                updateState({ isdropOpen: !isOpen, search: '', dataToRender: props.data });
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faTimes}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                updateState({ isdropOpen: false, secondarySelected: undefined, primarySelected: undefined });
                onReset && onReset(e.currentTarget, 'clear');
              }}
            />
          )}
        </div>
      </InputGroup>
    </DropdownHeaderWrapper>
  );
};

export { DropDownHeader };
