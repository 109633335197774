import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { faUndo } from '@fortawesome/pro-solid-svg-icons/faUndo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StatusFriendly as TicketFiltersStatusTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_filters_status_friendly';
import { ReviewResponseTicketFilters } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_pb';
import { SessionUser } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/session_user_pb';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';
import { useListResponseOrganizations, useListResponsePlaces } from 'src/async';
import { LIST_REVIEW_RESPONSE_TICKETS_KEY } from 'src/async/queryKeys.utils';
import { listReviewResponseTicketsInbox } from 'src/async/responseTickets';
import { InboxResponseTicket, ResponseOrganization, ResponsePlace } from 'src/async/types';
import {
  Button,
  ErrorBlock,
  InboxPaginationLoader,
  NoDataMessage,
  OrganizationSelector,
  Search,
  Tooltip,
  FilterDropDown,
} from 'src/components';
import { getActivePlaces } from 'src/components/OrganizationSelector/utils';
import { FilterType, FilterTypePartial, FilterTypeQueryString, useStateValue } from 'src/context';
import * as types from 'src/context/actionTypes';
import { prepareFiltersForRequest, spacesToUnderscores, useUrl } from 'src/utils';
import { ReviewItem, NewTicketsSubscriptionCounter } from '../components';
import {
  InboxSectionCountWrapper,
  InboxTicketsCountWrapper,
  ResponseConsoleHeaderWrapper,
  ResponseConsoleListWrapper,
  ResponseConsoleSection,
  SectionHead,
  ResponseConsoleSectionTitle,
  NoMoreDataWrapper,
  NoMoreDataTextWrapper,
  ResponseInboxFilterView,
  FilterBarButton,
  InvisibleInboxPaginationTrigger,
} from '../ResponseConsole.styled';

// !FLAG - Disabled subscriptions from apollo graphql
/* import { subscribeToDeletedTickets, subscribeToAssigneeChanged } from './subscriptions'; */

const { SessionUserRoleType } = SessionUser;

type ResponseInboxProps = {
  defaultFilters: FilterTypePartial;
};
type PaginationMeta = {
  currentPage: number;
  lastPage: number;
  perPage: number;
  total: number;
};

const ResponseInbox = (props: ResponseInboxProps): JSX.Element => {
  // LOCAL ------------------------------
  const { filtersOptions: urlFilters, changed } = useUrl();
  const [{ currentUser }, dispatch] = useStateValue();
  const location = useLocation();
  const history = useHistory();
  const qs = queryString.parse(location.search);
  const queryClient = useQueryClient();
  // ------------------------------------

  // REFS -------------------------------
  const searchRef = useRef<HTMLInputElement | null>(null);
  const orgId = useRef<string | undefined>(undefined);
  const locationId = useRef<string | undefined>(undefined);
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollObserverElem = useRef(null);
  // ------------------------------------

  // USEPARAMS --------------------------
  const params = useParams<{
    location?: string;
    organization?: string;
  }>();
  // ------------------------------------

  /**
   * URL vanity filter statuses default state
   */
  const defaultFriendlyFilterStatusesArray = [
    spacesToUnderscores(TicketFiltersStatusTypeFriendly.toFriendly(ReviewResponseTicketFilters.Status.NEW)),
    spacesToUnderscores(TicketFiltersStatusTypeFriendly.toFriendly(ReviewResponseTicketFilters.Status.UPDATED)),
  ];

  // PRIMARY STATE OBJECT ---------------
  const [state, setState] = useState<{
    filterDropdownOpen: boolean;
    sortState: string;
    isFilters: boolean;
    tickets: InboxResponseTicket[];
    filterApplied: boolean;
    filtersQs: FilterTypeQueryString;
  }>({
    tickets: [],
    filterDropdownOpen: false,
    sortState: 'desc',
    isFilters: false,
    filterApplied: false,
    filtersQs: { status: defaultFriendlyFilterStatusesArray },
  });
  // ------------------------------------

  // ORGANIZATION SELECTOR STATE --------
  const [organizationSelectorState, setOrganizationSelectorState] = useState<{
    organizations: ResponseOrganization[];
    activeOrganization: ResponseOrganization | null;
    places: ResponsePlace[];
  }>({ organizations: [], places: [], activeOrganization: null });
  // ------------------------------------

  // PAGINATION STATE -------------------
  const [paginationState, setPaginationState] = useState<PaginationMeta>({
    currentPage: 1,
    lastPage: 1,
    perPage: 20,
    total: 0,
  });
  // ------------------------------------

  // TICKET STATE -----------------------
  const [dataTickets, setDataTickets] = useState<InboxResponseTicket[]>([]);
  // ------------------------------------

  // USE INFINITE QUERY -----------------
  const {
    data: dataTicketsPaginated,
    isLoading: loadingTickets,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
    isInitialLoading: isInitialLoadingTickets,
    isFetching,
  } = useInfiniteQuery({
    queryKey: [LIST_REVIEW_RESPONSE_TICKETS_KEY],
    queryFn: async ({ pageParam = 1 }) => {
      return await listReviewResponseTicketsInbox(
        {
          responseOrganizationUlid: params.organization || '',
          pagination: { page: pageParam, perPage: 20 },
          filters: prepareFiltersForRequest(urlFilters),
          query: searchRef.current?.value?.trim() || '',
        },
        pageParam
      );
    },
    enabled: Boolean(params.organization) && Boolean(currentUser),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.currentPage < lastPage.meta.lastPage) {
        return lastPage.meta.currentPage + 1;
      } else {
        return undefined;
      }
    },
  });
  // ------------------------------------

  // CONVERT PAGINATED DATA TO ARRAY ----
  useEffect(() => {
    if (dataTicketsPaginated && dataTicketsPaginated.pages) {
      const [lastItem] = dataTicketsPaginated.pages.slice(-1);
      setPaginationState(lastItem.meta);
      setDataTickets(dataTicketsPaginated.pages.flatMap((page) => page.records));
    }
  }, [dataTicketsPaginated]);
  // ------------------------------------

  /** ---------------------------
   * # SUBSCRIPTIONS instantiation
   * * Start listening for subscription events
   */
  //? useEffect(() => {
  //?   subscribeToAssigneeChanged(subscribeToMore, params);
  //?   subscribeToDeletedTickets(subscribeToMore, params);
  //?   // eslint-disable-next-line react-hooks/exhaustive-deps
  //? }, [params.organization, dataTickets]);

  /** ---------------------------
   * # LIST_RESPONSE_ORGANIZATIONS
   * * React-Query
   * * Fetches organizations
   */
  const { data: responseOrganizationsData, isLoading: isResponseOrganizationsLoading } = useListResponseOrganizations(
    !(currentUser.responseOrganizationUlid && currentUser.responseOrganizationUlid !== '') ||
      currentUser.userRole === SessionUserRoleType.SYSTEMADMIN
  );

  /** ---------------------
   * # LIST_RESPONSE_PLACES
   * * React-Query
   * * Fetches places for the selected organization
   */
  const { data: responsePlacesData, isLoading: isResponsePlacesLoading } = useListResponsePlaces(
    params.organization || ''
  );

  /**
   * UseEffect to set the organizationSelectorState
   */
  useEffect(() => {
    if (isInitialLoadingTickets) return;
    if (currentUser.userRole === SessionUserRoleType.SYSTEMADMIN && isResponseOrganizationsLoading) return;

    const localState = Object.assign({}, organizationSelectorState);

    // ADMIN & ORGMANAGER -----------------
    if (params.organization && currentUser.userRole === SessionUserRoleType.SYSTEMADMIN) {
      let selectedOrg: ResponseOrganization | undefined = undefined;
      if (currentUser.responseOrganization) {
        selectedOrg = currentUser.responseOrganization as ResponseOrganization;
      } else if (responseOrganizationsData) {
        selectedOrg = responseOrganizationsData.find((org: ResponseOrganization) => org.ulid === params.organization);
      }
      if (selectedOrg) {
        localState.activeOrganization = selectedOrg;
        localState.organizations.push(selectedOrg);
      }
    } else if (params.organization && currentUser.responseOrganization) {
      localState.activeOrganization = currentUser.responseOrganization as ResponseOrganization;
    }
    // ------------------------------------

    // SET ORGANIZATIONS INTO RETURN OBJECT --------------------------
    if (responseOrganizationsData) {
      localState.organizations = responseOrganizationsData;
    }
    // ------------------------------------

    // SET RESPONSEPLACES INTO RETURN OBJECT
    if (responsePlacesData) {
      const places = responsePlacesData || [];
      localState.places = getActivePlaces(places, true);
      if (localState.organizations.length === 0 && currentUser.responseOrganization) {
        localState.activeOrganization = currentUser.responseOrganization as ResponseOrganization;
      }
    }
    // ------------------------------------

    // SET ACTIVE ORGANIZATION ------------
    if (localState.activeOrganization) {
      // localState.activeOrganization = getOrganizationWithActiveResponsePlaces(localState.activeOrganization);
      dispatch({ type: types.SET_ACTIVE_ORGANIZATION, organization: localState.activeOrganization });
    }
    // ------------------------------------

    // localState.organizations = localState.organizations.map(getOrganizationWithActiveResponsePlaces);
    setOrganizationSelectorState(localState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    responseOrganizationsData,
    responsePlacesData,
    params.organization,
    currentUser,
    loadingTickets,
    isInitialLoadingTickets,
    isResponseOrganizationsLoading,
    isResponsePlacesLoading,
  ]);

  /** -----------------
   * # REFETCH tickets:
   * * Complete re-fetch
   * * updates cached variables for listReviewResponseTickets query
   */
  async function refetch_listReviewResponseTickets() {
    if (!params.organization) {
      await queryClient.setQueryData([LIST_REVIEW_RESPONSE_TICKETS_KEY], []);
      await setDataTickets([...(dataTickets || [])]);
      return;
    }

    // Reset paginationState
    await setPaginationState({ ...paginationState, currentPage: 1 });

    // Invalidate query and set query data to empty array
    await queryClient.invalidateQueries({ queryKey: [LIST_REVIEW_RESPONSE_TICKETS_KEY] });
    await queryClient.setQueryData([LIST_REVIEW_RESPONSE_TICKETS_KEY], []);

    refetch();
  }

  /**
   * Detect changes in params.organization and refetch tickets
   */
  useEffect(() => {
    refetch_listReviewResponseTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.organization]);

  /** -------------------
   * # FETCHMORE tickets:
   * * Pagination for lazy scroll
   * * Fetches the next page of tickets
   */
  const fetchNextPage_listReviewResponseTickets = useCallback(
    async (entries) => {
      const [target] = entries;
      if (target.isIntersecting) {
        if (!params.organization) return;
        await fetchNextPage();
        setPaginationState((meta) => ({ ...meta, currentPage: paginationState.currentPage + 1 }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchNextPage, hasNextPage]
  );

  /**
   * INTERSECTION OBSERVER for pagination
   * * When any amount of the scrollObserverElem ref is in view, fetch the next page
   * * Watches <InvisibleInboxPaginationTrigger />
   */
  useEffect(() => {
    const element = scrollObserverElem.current;
    const option = { threshold: 0 };
    if (!element) return;

    const observer = new IntersectionObserver(fetchNextPage_listReviewResponseTickets, option);
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [fetchNextPage, hasNextPage, fetchNextPage_listReviewResponseTickets]);

  /**
   * UseEffect to set the current tickets' state
   */
  useEffect(() => {
    if (dataTickets) {
      if (params.organization != orgId.current || !searchRef.current?.value) {
        if (searchRef.current?.value) searchRef.current.value = '';
        orgId.current = params.organization as string;
      }
      if (params.location !== locationId.current) {
        locationId.current = params.location as string;
      }
    }
  }, [params.organization, params.location, dataTickets, isFetchingNextPage]); //! FLAG - NOT SURE IF ISFETCHINGNEXTPAGE NEEDS TO BE HERE, REPLACES NETWORKSTATUS FROM APOLLO

  useEffect(() => {
    if (qs.reviewUlid && dataTickets) {
      const foundTicket = dataTickets.find((rev: InboxResponseTicket) => rev.reviewUlid === qs.reviewUlid);
      if (foundTicket) {
        dispatch({
          type: types.SET_ACTIVE_TICKET,
          ticket: foundTicket,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTickets]);

  /**
   * Search Input
   */
  function handleSearch() {
    refetch_listReviewResponseTickets();
  }

  /**
   * UNUSED - Component is hidden from DOM
   */
  function sortTickets(tickets: InboxResponseTicket[], newSortState: string) {
    if (newSortState === 'asc') {
      tickets.sort((a, b) => b.submittedAt - a.submittedAt);
    } else {
      tickets.sort((a, b) => a.submittedAt - b.submittedAt);
    }
    setDataTickets(tickets);
    setState({ ...state, tickets: tickets, sortState: newSortState });
  }

  /**
   * Sets changes made in the filters dropdown to state
   */
  function handleChangeFilterParams(params: FilterTypeQueryString) {
    setState((currentState) => ({ ...currentState, filtersQs: params, filterApplied: false }));
  }

  /**
   * Applies selected filters to query to URL
   */
  function handleApplyFilters() {
    history.push(
      `${location.pathname}${
        Object.keys(state.filtersQs).length
          ? `?${queryString.stringify(state.filtersQs, { arrayFormat: 'comma' })}`
          : ''
      }`
    );
    setState((currentState) => ({ ...currentState, filterDropdownOpen: false, filterApplied: true }));
  }

  /**
   * Resets filters to default values
   */
  function handleResetFilters() {
    history.push(`${location.pathname}?changed=true&status=${defaultFriendlyFilterStatusesArray.join(',')}`);
    scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch({
      type: 'SET_ACTIVE_TICKET',
      ticket: undefined,
    });

    setState((currentState) => ({
      ...currentState,
      filterApplied: true,
      filtersQs: {
        changed: true,
        status: defaultFriendlyFilterStatusesArray,
      },
    }));
    refetch_listReviewResponseTickets();
  }

  /**
   * Refetches tickets when location.search has been updated
   */
  useEffect(() => {
    const isFilters = Object.keys(urlFilters).filter((k) => urlFilters[k as keyof FilterType].length > 0).length > 0;
    if (changed) {
      scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
      setState({
        ...state,
        isFilters,
      });
      refetch_listReviewResponseTickets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  /**
   * Show error block if error
   */
  if (!dataTickets && error) return <ErrorBlock error={error} />;

  const isFiltersLoading = loadingTickets || isInitialLoadingTickets || isFetching || isFetchingNextPage;

  return (
    <ResponseConsoleSection flex="0 0 20rem" ref={scrollRef}>
      <ResponseConsoleHeaderWrapper>
        <SectionHead>
          <InboxSectionCountWrapper>
            <ResponseConsoleSectionTitle>{'Inbox'}</ResponseConsoleSectionTitle>
            {params.organization && <InboxTicketsCountWrapper>{`(${paginationState.total})`}</InboxTicketsCountWrapper>}
          </InboxSectionCountWrapper>
          <ResponseInboxFilterView>
            <FilterBarButton
              icon={state.sortState === 'asc' ? 'sort-amount-up' : 'sort-amount-down'}
              btnSm
              hidden //! FLAG - Remove when BE sorting is finished
              title="Sort by Date"
              onClick={() => {
                if (params.organization !== undefined) {
                  const tickets = [...dataTickets];
                  const newSortState = state.sortState === 'desc' ? 'asc' : 'desc';
                  sortTickets(tickets, newSortState);
                }
              }}
            />
            <FilterBarButton
              btnSm
              tooltip="Reset Filters"
              icon={isFiltersLoading ? 'spinner-third' : faUndo}
              disabled={!params.organization || isFiltersLoading}
              spin={isFiltersLoading}
              onClick={handleResetFilters}
            />
            <FilterBarButton
              btnSm
              tooltip="Apply Filters"
              icon={isFiltersLoading ? 'spinner-third' : 'check'}
              disabled={!params.organization || !state.filtersQs.changed || state.filterApplied || isFiltersLoading}
              spin={isFiltersLoading}
              onClick={handleApplyFilters}
            />
            <Button
              btnSm
              icon="filter"
              tooltip="Filters"
              onClick={() => setState((state) => ({ ...state, filterDropdownOpen: !state.filterDropdownOpen }))}
              disabled={!params.organization}
            />
          </ResponseInboxFilterView>
        </SectionHead>
        <FilterDropDown
          isOpen={state.filterDropdownOpen}
          filterParams={state.filtersQs}
          handleChangeFilterParams={handleChangeFilterParams}
        />
        <OrganizationSelector
          showOrganizations={currentUser.userRole === SessionUserRoleType.SYSTEMADMIN}
          data={organizationSelectorState}
          onReset={() => {
            history.push('/response-console');
          }}
          onSelect={(obj: ResponseOrganization) => {
            if (obj.responsePlacesCount) {
              return history.push(`/response-console/${obj.ulid}`);
            }
          }}
        />
        <Search datatype="search" placeholder="Search customer" onDebounceChange={handleSearch} ref={searchRef} />
      </ResponseConsoleHeaderWrapper>
      <ResponseConsoleListWrapper>
        {dataTickets.length ? (
          <>
            {dataTickets.map((x: InboxResponseTicket): JSX.Element => {
              return (
                <ReviewItem
                  key={`${x?.reviewUlid}-${x?.submittedAt}`}
                  ticket={x}
                  deleted={x.title === 'deleted'}
                  show_bottom_bar={false}
                />
              );
            })}
          </>
        ) : (
          <NoDataMessage>
            {Boolean(params.organization) && Boolean(currentUser) && Boolean(!dataTickets.length && loadingTickets) ? (
              <>
                <FontAwesomeIcon
                  onClick={() => scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' })}
                  icon={'spinner-third'}
                  size="sm"
                  spin
                />
                {'Loading...'}
              </>
            ) : (
              'No data available...'
            )}
          </NoDataMessage>
        )}
      </ResponseConsoleListWrapper>
      {/* InboxPaginationLoader only shows during pagination */}
      <InvisibleInboxPaginationTrigger ref={scrollObserverElem} />
      <InboxPaginationLoader loading={isFetchingNextPage} hideFast={!hasNextPage} />
      {/* If the end of the data set has been reached, display NoMoreDataWrapper */}
      {Boolean(dataTickets.length && !hasNextPage) && (
        <NoMoreDataWrapper>
          <NoMoreDataTextWrapper>{'No more data...'}</NoMoreDataTextWrapper>
          <Tooltip content="Back to top">
            <FontAwesomeIcon
              onClick={() => scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' })}
              icon={['fal', 'arrow-up']}
              size="sm"
            />
          </Tooltip>
        </NoMoreDataWrapper>
      )}
      {/* The NewTicketsCounter component implements useSubscription REVIEW_RESPONSE_TICKET_CREATE_SUBSCRIPTION */}
      {params?.organization && <NewTicketsSubscriptionCounter organization={params.organization} />}
    </ResponseConsoleSection>
  );
};

export default ResponseInbox;
