import styled from 'styled-components/macro';

export const Form = styled.form`
  input,
  select,
  textarea {
    margin-bottom: 1rem;
  }
`;
Form.displayName = 'Form';

export default Form;
