import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { ImageProps } from 'src/types';

const MainLogoPictogram = ({ width = '100%', id, className }: ImageProps): ReactElement => (
  <svg
    x="0"
    y="0"
    id={id}
    version="1"
    width={width}
    height={width}
    xmlSpace="preserve"
    viewBox="0 0 17 12"
    enableBackground="new 0 0 17 12"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      id="curve_1"
      d="M11.66 7.12c-.27.27-.65.44-1.06.44-.84.01-1.52-.65-1.53-1.49v-.03c0-.8-.31-1.56-.88-2.13-.58-.56-1.33-.88-2.14-.88-1.66 0-3.02 1.35-3.02 3.02v4.38c0 .84-.68 1.52-1.52 1.52-.83 0-1.51-.68-1.51-1.52V6.05C0 2.71 2.71 0 6.05 0s6.05 2.71 6.05 6.05c0 .42-.17.8-.44 1.07z"
    />
    <path
      id="curve_2"
      d="M14.86 10.18a6.023 6.023 0 01-4.28 1.77c-3.34 0-6.05-2.71-6.05-6.05a1.52 1.52 0 113.04 0c0 1.66 1.35 3.02 3.02 3.02 1.66 0 3.02-1.35 3.02-3.02a1.52 1.52 0 113.04 0c-.02 1.67-.7 3.18-1.79 4.28z"
    />
  </svg>
);

MainLogoPictogram.propTypes = {
  width: PropTypes.string,
  id: PropTypes.string,
};

export default MainLogoPictogram;
