import React from 'react';
import { TableColumn, TableRow } from 'react-data-table-component';
import { ResponseUser } from '@friendemic/erc-graph';
import { ActionsCell } from 'src/components';
import { Action } from 'src/components/Table/ActionsCell';
import { client, DELETE_ADMIN_RESPONSE_USER } from 'src/graph';
import { toast } from 'src/utils';
import { FormValues } from './AdminManagementForm';

export interface DataRow extends TableRow {
  title: string;
  director: string;
  year: string;
  actions?: Action[];
}

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Admin',
    selector: (row: Record<string, any>): any => row.admin,
    sortable: true,
  },
  {
    name: 'Read\u00a0Only',
    selector: (row: Record<string, any>): any => row.readOnly,
    sortable: true,
    grow: 0,
  },
  {
    name: 'Actions',
    width: '6rem',
    sortable: false,
    center: true,
    grow: 0,
    cell: ActionsCell,
  },
];

export function buildData(
  admins: ResponseUser[],
  setAdmins: React.Dispatch<React.SetStateAction<ResponseUser[]>>,
  setActiveEditingAdmin: React.Dispatch<React.SetStateAction<FormValues>>,
  showAddAdminModal: () => void
): any[] {
  return admins
    ? admins.map((admin: ResponseUser, i: number) => {
        const currentAdminName = admin.user ? admin.user.name : 'UNKNOWN';

        return {
          admin: currentAdminName,
          readOnly: admin.readOnly ? 'Yes' : 'No',
          actions: [
            {
              action: 'delete',
              icon: 'trash-alt',
              tooltip: 'Delete',
              onClick: async () => {
                const res = await client.mutate({
                  mutation: DELETE_ADMIN_RESPONSE_USER,
                  variables: { userUlid: admin.userUlid },
                });

                if (res.data.deleteAdminResponseUser && res.data.deleteAdminResponseUser.errors) {
                  console.info(res.data.deleteAdminResponseUser.errors);
                  toast({ type: 'error', message: 'Something went wrong, please try again.' });
                } else {
                  const modifiedAdmins = [...admins];
                  modifiedAdmins.splice(i, 1);
                  setAdmins(modifiedAdmins);
                  toast({ type: 'error', message: `Removed ${currentAdminName} as Admin` });
                }
              },
            },
            {
              action: 'edit',
              icon: 'edit',
              tooltip: 'Edit',
              onClick: () => {
                setActiveEditingAdmin({
                  add_admin_ulid: admin.userUlid,
                  add_admin_readOnly: admin.readOnly,
                });
                showAddAdminModal();
              },
            },
          ],
        };
      })
    : [];
}
