import { lighten } from 'color2k';
import styled, { css } from 'styled-components/macro';
import vars from 'src/styles/variables';
import ErrorSpan from './ErrorSpan';
import Input from './Input';
import InputGroup from './InputGroup';
import Label from './Label';

interface FormGroupProps {
  hasError?: boolean;
  children: any | any[];
  noValidation?: boolean;
}

export const FormGroup = styled.div<FormGroupProps>`
  margin-bottom: ${(props) => {
    if (props.noValidation) {
      return '0';
    }

    // check if errorspan exists in FormGroup
    // FLAG - performance hit?
    /* prettier-ignore */
    return props.children.length && props.children.map( (x: any) => {
      return x && x.type ? x.type.displayName : 'foo'
    }).includes( 'ErrorSpan' ) ? '0' : '1rem'
  }};

  input,
  select,
  textarea {
    margin: 0 !important;
  }

  ${(props) => {
    return (
      props.hasError &&
      css`
        ${Label} {
          color: ${vars.$danger} !important;
        }
        ${Input} {
          border-color: ${vars.$danger} !important;
          color: ${vars.$danger} !important;
          &::placeholder {
            color: ${lighten(vars.$danger, 0.15)} !important;
          }
        }
        ${InputGroup} {
          svg {
            color: ${vars.$danger};
          }
        }
        ${ErrorSpan} {
          display: block;
          visibility: visible;
        }
      `
    );
  }}
`;

FormGroup.displayName = 'FormGroup';

export default FormGroup;
