import { gql } from '@apollo/client';

export const REVIEW_RESPONSE_TICKET_FRAGMENT = gql`
  fragment ReviewResponseFragment on ReviewResponseTicket {
    submittedAt
    placeUlid
    reviewUlid
    channel
    assigneeUlid
    responseOrganizationUlid
    status
    type
    canAutoPublish
    publishingDetails {
      canAutoPublish
      canPublish
      isApprovalRequired
      isApproved
      isPublished
      shouldApproveAfter24
      isOverLimit
    }
    assigneeUser {
      name
      ulid
    }
    responsePlace {
      responseApprovers {
        ulid
        placeUlid
        userUlid
        reviewType
        required
        user {
          name
          email
          ulid
        }
      }
      responseSignatures {
        ulid
        placeUlid
        content
        signsReviewType
        includeLocationName
      }
      responseTemplates {
        ulid
        title
        body
      }
      settings {
        redAlert {
          body
          show
        }
        approvalSettings {
          requireType
          approveAfter24
          approvalsNeededFor
        }
        channelSettings {
          approvalsNeededFor
          responseLimit
        }
      }
      place {
        name
        organization {
          name
        }
      }
    }
    review {
      ulid
      title
      rating
      externalId
      url
      submittedAt
      comment
      harvestProfile {
        channel
        ulid
      }
      profileUlid
      customer
      respondedAt
      submittedAt
      collectedAt
    }
    reviewResponseTags {
      ulid

      createdAt
      value
    }
    reviewResponseNotes {
      ulid
      userUlid
      deletedAt
      system
      responseUser {
        userUlid
        userRole
        responseOrganizationUlid
        user {
          name
        }
      }
      content
      notableUlid
      notableType
      createdAt
    }
    reviewResponseSubmissions {
      ulid
      reviewUlid
      submittedAt
      responseUser {
        readOnly
        responseOrganizationUlid
        user {
          name
          email
          ulid
        }
      }
      action
      comment
      reviewResponseApprovals {
        reviewResponseSubmissionUlid
        responseApproverUlid
        action
        comment
        createdAt
        responseApprover {
          ulid
          placeUlid
          userUlid
          user {
            ulid
            email
            name
          }
        }
      }
    }
  }
`;
export const REVIEW_RESPONSE_PLACES_FRAGMENT = gql`
  fragment ReviewResponsePlaces on ReviewResponseTicket {
    responsePlace {
      responseApprovers {
        ulid
        placeUlid
        userUlid
        reviewType
        required
        user {
          name
          email
          ulid
        }
      }
      responseSignatures {
        ulid
        placeUlid
        content
        signsReviewType
        includeLocationName
      }
      responseTemplates {
        ulid
        title
        body
      }
      settings {
        redAlert {
          body
          show
        }
        approvalSettings {
          requireType
          approveAfter24
          approvalsNeededFor
        }
        channelSettings {
          approvalsNeededFor
          responseLimit
        }
      }
      place {
        name
        organization {
          name
        }
      }
    }
  }
`;

export const REVIEW_RESPONSE_NOTES_FRAGMENT = gql`
  fragment ReviewResponseNotes on ReviewResponseTicket {
    reviewResponseNotes {
      ulid
      userUlid
      system
      responseUser {
        userUlid
        userRole
        responseOrganizationUlid
        user {
          name
        }
      }
      content
      notableUlid
      notableType
      createdAt
    }
  }
`;

export const REVIEW_RESPONSE_SUBMISSIONS_FRAGMENT = gql`
  fragment ReviewResponseSubmissions on ReviewResponseTicket {
    reviewResponseSubmissions {
      ulid
      reviewUlid
      submittedAt
      responseUser {
        readOnly
        responseOrganizationUlid
        user {
          name
          email
          ulid
        }
      }
      action
      comment
      reviewResponseApprovals {
        reviewResponseSubmissionUlid
        responseApproverUlid
        action
        comment
        createdAt
        responseApprover {
          ulid
          placeUlid
          userUlid
          user {
            ulid
            email
            name
          }
        }
      }
    }
  }
`;
export const REVIEW_FRAGMENT = gql`
  fragment ReviewFragment on ReviewResponseTicket {
    review {
      ulid
      title
      rating
      externalId
      url
      submittedAt
      comment
      harvestProfile {
        channel
        ulid
      }
      profileUlid
      customer
      respondedAt
      submittedAt
      collectedAt
    }
  }
`;
