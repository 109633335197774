import React from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { Card } from 'src/components';
import { CardTemplateWrapper, HeadingLeft, HeadingRight, DateWrapper, HeadingIcon } from './CardTemplate.styled';

type CardTemplateProps = {
  config: {
    icon?: React.ReactElement | string;
    title: string;
    date?: number | null;
    dateTitle?: string;
    collectedAt?: number | null;
    collectedAtTitle?: string;
    color?: string;
    headingRight?: JSX.Element;
    alert?: string | JSX.Element;
  };
  footerComponent?: React.ReactNode | null;
  children: JSX.Element | JSX.Element[] | React.ReactElement;
  // channel: 0 | 1 | 2 | 3 | 4 | 5 | 6;
};

export const CardTemplate = (props: CardTemplateProps): JSX.Element => {
  const {
    config: { icon, title, date, dateTitle, collectedAt, collectedAtTitle, color, headingRight, alert },
    footerComponent,
    children,
  } = props;

  return (
    <CardTemplateWrapper>
      <Card>
        <Card.Heading white={Boolean(color)} headingColor={color}>
          <HeadingLeft>
            {icon && (
              <HeadingIcon>{typeof icon === 'string' ? <FontAwesomeIcon icon={icon as IconName} /> : icon}</HeadingIcon>
            )}
            <span>{title}</span>
          </HeadingLeft>
          <HeadingRight>
            {headingRight ? (
              headingRight
            ) : (
              <>
                {date ? (
                  <DateWrapper>
                    {dateTitle && <div>{dateTitle}</div>}
                    {date && <div>{format(new Date(date), 'MM/dd/yyyy')}</div>}
                  </DateWrapper>
                ) : undefined}
                {collectedAt ? (
                  <DateWrapper>
                    {collectedAtTitle && <div>{collectedAtTitle}</div>}
                    {collectedAt && <div>{format(new Date(collectedAt), 'MM/dd/yyyy')}</div>}
                  </DateWrapper>
                ) : undefined}
              </>
            )}
          </HeadingRight>
        </Card.Heading>
        {alert && <Card.Alert>{alert}</Card.Alert>}
        <Card.Body>{children}</Card.Body>
        {footerComponent && <Card.Footer>{footerComponent}</Card.Footer>}
      </Card>
    </CardTemplateWrapper>
  );
};

export default CardTemplate;
