import React, { ReactElement } from 'react';
import { Formik, FormikProps } from 'formik';
import merge from 'lodash/merge';
import { useEditResponsePlace } from 'src/async';
import { ResponsePlace } from 'src/async/types';
import { Row, Column, List } from 'src/components';
import { FormikResetForm } from 'src/types';
import { toast, removeStyleAttributes, checkIfRedAlertIsEmpty } from 'src/utils';
import { LoadingSubsectionPlaceholder, SettingsSubsection } from 'src/views/Settings/settings.styled';
import { LocationSettingsRouteParams } from 'src/views/Settings/SettingsTypes';
import { RenderForm, FormValues, initialFormValues, validationSchema } from './RedAlertSettingsForm';

const RedAlertSettings = (props: {
  params: LocationSettingsRouteParams;
  responsePlaceData?: ResponsePlace;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  isResponsePlaceDataLoading: boolean;
}): ReactElement => {
  const { params, responsePlaceData, setSaving, isResponsePlaceDataLoading } = props;
  const [formValues, setFormValues] = React.useState(initialFormValues);
  const { mutateAsync: editResponsePlace, isLoading: isEditResponsePlaceLoading } = useEditResponsePlace();

  React.useEffect(() => {
    if (responsePlaceData?.settings?.red_alert) {
      setFormValues({
        show: responsePlaceData.settings.red_alert.show,
        body: removeStyleAttributes(responsePlaceData.settings.red_alert.body || ''),
      });
    }
  }, [responsePlaceData]);

  React.useEffect(() => {
    setSaving(isEditResponsePlaceLoading);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isEditResponsePlaceLoading]);

  async function handleUpdateSettings(values: FormValues, resetForm: FormikResetForm) {
    const redAlertIsEmpty = checkIfRedAlertIsEmpty(values.body);
    await editResponsePlace(
      {
        placeUlid: params.location,
        settings: merge({}, responsePlaceData?.settings, {
          red_alert: {
            show: !redAlertIsEmpty,
            body: !redAlertIsEmpty ? removeStyleAttributes(values.body || '') : '',
          },
        }),
      },
      {
        onSuccess: () => {
          resetForm({ values });
        },
        onError: (err) => {
          console.error(err);
          toast({ type: 'error', message: err.response?.data?.message || err.message });
        },
      }
    );
  }

  if (isResponsePlaceDataLoading) return <LoadingSubsectionPlaceholder />;

  return (
    <SettingsSubsection>
      <Row>
        <Column>
          <List
            title="Red Alert"
            icon="money-check-edit"
            subtitle={
              formValues.show
                ? 'Red Alert will appear at the top of Response Console'
                : 'Red Alert will not appear, no text to display'
            }
          >
            <li>
              <Formik
                initialValues={formValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values, { resetForm }) => {
                  handleUpdateSettings(values, resetForm);
                }}
              >
                {(props: FormikProps<FormValues>) => {
                  return <RenderForm {...props} />;
                }}
              </Formik>
            </li>
          </List>
        </Column>
      </Row>
    </SettingsSubsection>
  );
};

export default RedAlertSettings;
