import { gql } from '@apollo/client';

export const REVIEW_RESPONSE_TAG_ADDED_SUBSCRIPTION = gql`
  subscription OnReviewResponseTagAdd($reviewUlid: ID!) {
    reviewResponseTagAdded(reviewUlid: $reviewUlid) {
      ulid
      reviewUlid
      value
      createdAt
    }
  }
`;
export const REVIEW_RESPONSE_TAG_Delete_SUBSCRIPTION = gql`
  subscription OnReviewResponseTagDelete($reviewUlid: ID!) {
    reviewResponseTagRemoved(reviewUlid: $reviewUlid)
  }
`;

export const REVIEW_RESPONSE_Note_Add_SUBSCRIPTION = gql`
  subscription OnReviewResponseNoteAdd($reviewUlid: ID!) {
    reviewResponseNoteAdded(reviewUlid: $reviewUlid) {
      ulid
      userUlid
      content
      createdAt
      notableType
      notableUlid
      # responseUser {
      #   userUlid
      #   userRole
      #   responseOrganizationUlid
      # }
    }
  }
`;

export const REVIEW_RESPONSE_NOTE_Delete_SUBSCRIPTION = gql`
  subscription reviewResponseNoteRemoved($reviewUlid: ID!) {
    reviewResponseNoteRemoved(reviewUlid: $reviewUlid)
  }
`;

export const REVIEW_RESPONSE_TICKET_CREATE_SUBSCRIPTION = gql`
  subscription OnReviewResponseTicketAdd($responseOrganizationUlid: ID!) {
    reviewResponseTicketCreated(responseOrganizationUlid: $responseOrganizationUlid) {
      reviewUlid
      # responseOrganizationUlid
      # createdAt
      # status
      # submittedAt
      # review {
      #   updatedAt
      #   collectedAt
      #   customer
      #   rating
      #   harvestProfile {
      #     channel
      #   }
      # }
      # place {
      #   name
      # }
      # assigneeUser {
      #   name
      # }
    }
  }
`;

export const REVIEW_RESPONSE_TICKET_UPDATE_SUBSCRIPTION = gql`
  subscription OnReviewResponseTicketUpdate($responseOrganizationUlid: ID!, $reviewUlid: ID) {
    reviewResponseTicketUpdated(responseOrganizationUlid: $responseOrganizationUlid, reviewUlid: $reviewUlid) {
      reviewUlid
      responseOrganizationUlid
    }
  }
`;

export const REVIEW_RESPONSE_TICKET_DELETE_SUBSCRIPTION = gql`
  subscription OnReviewResponseTicketDelete($responseOrganizationUlid: ID!, $reviewUlid: ID) {
    reviewResponseTicketDeleted(responseOrganizationUlid: $responseOrganizationUlid, reviewUlid: $reviewUlid) {
      reviewUlid
    }
  }
`;

export const REVIEW_RESPONSE_TICKET_ASSIGNEE_CHANGED_SUBSCRIPTION = gql`
  subscription OnReviewResponseTicketAssigneeChange($responseOrganizationUlid: ID!, $reviewUlid: ID) {
    reviewResponseTicketAssigneeChanged(responseOrganizationUlid: $responseOrganizationUlid, reviewUlid: $reviewUlid) {
      reviewUlid
      responseOrganizationUlid
      # assigneeUlid
      assigneeUser {
        name
        ulid
      }
    }
  }
`;
