import React from 'react';
import { useLocation, useParams } from 'react-router';
import { ActionFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_submission_action_friendly';
import * as FullStory from '@fullstory/browser';
import { darken } from 'color2k';
import { Formik, Form, FormikProps } from 'formik';
import queryString from 'query-string';
import styled, { css } from 'styled-components/macro';
import * as Yup from 'yup';
import { ReviewResponseApproval, ReviewResponseSubmission } from 'src/async/types';
import { Avatar, Button } from 'src/components';
import { useStateValue } from 'src/context/appContext';
import vars from 'src/styles/variables';
import { getInitials, getFormatedDate, enumValues } from 'src/utils';
import {
  NoteSectionWrapper,
  NoteSection,
  NoteSectionLeft,
  NoteSectionRight,
  NoteApprovedDenied,
  NoteAttribution,
  NoteFromName,
  NoteFromDate,
  NoteContent,
} from '../ResponseNotes/ResponseNotes.styled';
import { initialFormValues, RenderForm, FormValues } from './ResponseApprovalsForm';
import { ApproveRejectWrapper, ApproveDeniedButtons } from './ResponseSubmissions.styled';
import { useGraphMutations } from './useGraphMutations';

const EdidRequestButton = styled(Button)`
  text-transform: uppercase;
`;

const ApproveButton = styled(Button)`
  text-transform: uppercase;
  ${({ secondary }) =>
    secondary &&
    css`
      &:hover {
        background-color: ${vars.$greenlight} !important;
        border-color: ${vars.$greenlight} !important;
        color: white !important;
      }
      &:active {
        background-color: ${vars.$green} !important;
        border-color: ${vars.$green} !important;
        color: white !important;
      }
    `}
`;

const ResponseApprovals = (props: {
  submissions: ReviewResponseSubmission[];
  approvals: ReviewResponseApproval[];
}): JSX.Element => {
  const [{ currentUser, userRoles }] = useStateValue();
  const [reqEdit, setReqEdit] = React.useState(false);
  const { submissions, approvals } = props;
  const location = useLocation();
  const routeParams = useParams<{ reviewUlid?: string }>();
  const qs = queryString.parse(location.search);
  const [currentUserHasAlreadyApproved, setCurrentUserHasAlreadyApproved] = React.useState<boolean>(true);
  const [mutableApprovals, setMutableApprovals] = React.useState<ReviewResponseApproval[]>(approvals);
  const [approvalActionType, setApprovalActionType] = React.useState<typeof enumValues.ApprovalActionType[number]>(1);

  const { createApproval } = useGraphMutations(routeParams.reviewUlid || (qs.reviewUlid as string));
  // ? Create approver mutation is necessary here
  // const { mutationMethod: createApprover } = useGraphMutation({
  //   mutation: CREATE_RESPONSE_APPROVER,
  // options: {
  //   refetchQueries: [
  //     {
  //       query: GET_REVIEW_RESPONSE_TICKET,
  //       variables: {
  //         reviewUlid: ticket.reviewUlid,
  //       },
  //     },
  //   ],
  // },
  //   errorType: 'error',
  // });
  React.useEffect(() => {
    /**
     * * Return reset mutatableApprovals back to props.approvals
     * *   Prevents approval state from persisting when switching to a different ticket
     *
     */
    setCurrentUserHasAlreadyApproved(
      Boolean(
        approvals?.some((approval) => {
          return approval.responseApproverUlid === currentUser.user.ulid;
        })
      )
    );
    return setMutableApprovals(approvals);
  }, [approvals, currentUser.user.ulid]);

  async function handleSubmit(values: FormValues) {
    const approval = submissions?.length ? submissions[submissions.length - 1] : null;

    if (approval) {
      const approvalObj = {
        reviewResponseSubmissionUlid: approval.ulid,
        responseApproverUlid: currentUser.responseApproverUlid,
        action: approvalActionType,
        comment: values.approvalComment,
      };
      // ? How to apply this one
      // const response = await createApprover({
      //   variables: {
      //     input: {
      //       ...approvalObj,
      //     },
      //   },
      // });
      await createApproval({
        variables: {
          input: {
            ...approvalObj,
          },
        },
      });

      // FS CUSTOM EVENT
      FullStory.isInitialized() &&
        FullStory.event('ERC - API Event - Response Approvals', {
          actionType_str: ActionFriendly.toFriendly(approvalActionType),
          actionType_int: approvalActionType,
        });
    }
  }

  return (
    <>
      <NoteSectionWrapper responseApproval>
        {userRoles.isApprover && mutableApprovals.length === 0 && (
          <ApproveRejectWrapper currentUserHasAlreadyApproved={currentUserHasAlreadyApproved}>
            <Formik
              initialValues={initialFormValues}
              validationSchema={Yup.object({
                approvalComment:
                  approvalActionType === ActionFriendly.toEnum('Approved')
                    ? Yup.string()
                    : Yup.string().required('Required'),
              })}
              validateOnBlur={false}
              onSubmit={(values, { resetForm }) => {
                /**
                 * * Use Date().toISOString() as an in-state unique id.
                 * * Real ULIDs are fetched and populated on re-fetch
                 */
                handleSubmit(values);
                const date = new Date().toISOString();
                setMutableApprovals((prevApprovals) => [
                  ...prevApprovals,
                  {
                    ulid: date,
                    action: approvalActionType,
                    comment: values.approvalComment,
                    createdAt: Date.parse(date),
                    reviewResponseSubmissionUlid: date,
                    responseApproverUlid: date,
                    responseApproverName: currentUser.user.name,
                    responseApproverEmail: currentUser.user.email,
                  },
                ]);
                setCurrentUserHasAlreadyApproved(true);
                setReqEdit(false);
                console.info(values);
                resetForm({});
              }}
            >
              {(props: FormikProps<FormValues>) => {
                return (
                  !currentUserHasAlreadyApproved && (
                    <Form>
                      {reqEdit && <RenderForm {...props} />}
                      <ApproveDeniedButtons>
                        <ApproveButton
                          mr="4"
                          mt="4"
                          btnLg
                          secondary={reqEdit}
                          onClick={async () => {
                            await setApprovalActionType(ActionFriendly.toEnum('Approved'));
                            return props.submitForm();
                          }}
                        >
                          {'Approve'}
                        </ApproveButton>
                        <EdidRequestButton
                          secondary={!reqEdit}
                          danger={reqEdit}
                          btnLg
                          mt="4"
                          onClick={async () => {
                            if (reqEdit) {
                              await setApprovalActionType(ActionFriendly.toEnum('Denied'));
                              return props.submitForm();
                            } else setReqEdit(true);
                          }}
                        >
                          {reqEdit ? 'Submit Feedback' : 'Request Edits'}
                        </EdidRequestButton>
                      </ApproveDeniedButtons>
                    </Form>
                  )
                );
              }}
            </Formik>
          </ApproveRejectWrapper>
        )}
        {mutableApprovals.map((approval) => {
          const { comment, action, responseApproverName, createdAt } = approval as ReviewResponseApproval;

          const denied = action === 3;
          const approved = action === 2;

          const avatarConfig = {
            backgroundColor: denied ? vars.$danger : approved ? vars.$greenlight : undefined,
            shadowColor: denied ? darken(vars.$danger, 0.08) : approved ? darken(vars.$greenlight, 0) : undefined,
            color: denied || approved ? 'white' : undefined,
          };

          // ! what exactly should the key be?
          // ! ReviewResponseApproval uses reviewResponseSubmissionUlid which I think is submission.ulid
          return (
            <NoteSection key={Math.ceil(Math.random() * 100).toString()}>
              <NoteSectionLeft>
                <Avatar {...avatarConfig}>{getInitials(responseApproverName)}</Avatar>
              </NoteSectionLeft>
              <NoteSectionRight>
                <NoteApprovedDenied approvalActionType={action}>{ActionFriendly.toFriendly(action)}</NoteApprovedDenied>
                <NoteAttribution>
                  <NoteFromName>{`${responseApproverName || 'UNKNOWN'}`}</NoteFromName>
                  <NoteFromDate>{getFormatedDate(createdAt)}</NoteFromDate>
                </NoteAttribution>
                <NoteContent>{comment}</NoteContent>
              </NoteSectionRight>
            </NoteSection>
          );
        })}
      </NoteSectionWrapper>
    </>
  );
};

export default ResponseApprovals;
