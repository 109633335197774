import styled, { css } from 'styled-components/macro';
import { Button } from 'src/components';
import { PillWrapper } from 'src/components/Pill';
import { RatingStarsContainer } from 'src/components/RatingStars';
import vars from 'src/styles/variables';
import { dropdownButtonMixin } from '../../ResponseConsole.styled';

export const Title = styled.div`
  font-size: 1.42rem;
  margin-bottom: 1.42rem;
  text-transform: uppercase;
`;

export const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  ${RatingStarsContainer} {
    margin: 0 0 0 0.4rem;
  }
`;

export const ExternalLink = styled.a`
  transition: all 100ms ease-out;
  color: ${vars.$gray_b};
  svg {
    margin-left: 0.25rem;
    height: 0.6875rem;
    vertical-align: baseline;
  }
`;

export const IndividualPillWrapper = styled.div`
  margin-bottom: 0.75rem;
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${PillWrapper} {
    /* inline-flex just works for this */
    display: inline-flex;
  }
`;

export const AddTagButton = styled(Button)`
  ${dropdownButtonMixin}
  margin-right: 0.5rem;
  padding-left: 0.35rem;
`;

export const FilterItem = styled.div<{ rating?: boolean; disabled: boolean }>`
  cursor: pointer;
  width: 100%;
  padding: ${vars.$spacing_small} ${vars.$spacing_extra_small};
  ${({ disabled }) => {
    return (
      disabled &&
      css`
        background-color: ${vars.$gray_e};
      `
    );
  }}
  > label {
    margin-left: 1rem;
    /* width: 100%; */
  }
  & label label {
    padding-left: 0;
    display: flex;
    align-items: center;
  }
  & label label::before {
    display: inline-block;
    position: static;
    margin-right: 1.5rem;
  }
  &.selected input ~ label {
    color: ${vars.$white};
  }
  &:hover label {
    color: ${vars.$white};
    cursor: inherit;
  }
`;
