import { parse } from 'query-string';
import { ResponseOrganization, ResponsePlace } from 'src/async/types';

export function getActivePlaces(data: ResponsePlace[], active?: boolean): ResponsePlace[] {
  if (active) {
    return data.filter((p) => p.active === active);
  }
  return data;
}

// Utility Method used to update the global context
export function populateDataFromQuery(
  data: ResponseOrganization[],
  responseOrganizationUlid?: string
): Record<string, any> {
  let queryOrg;
  let queryPlace;
  const qs = parse(location.search);

  if (responseOrganizationUlid) {
    queryOrg = data?.find((p: ResponseOrganization) => p.ulid === responseOrganizationUlid);
  }
  if (queryOrg && qs.location) {
    queryPlace = queryOrg.responsePlaces?.find((p: ResponsePlace) => p.placeUlid === qs.location);

    if (queryPlace) {
      const { place } = queryPlace;
      queryPlace = place;
    }
  }
  return { queryOrg, queryPlace };
}
