import nl2br from 'react-nl2br';
import { ReviewResponseTicket } from 'src/async/types';

interface VarDefinition {
  name: string[];
  description: string;
  replacement: (ticket: ReviewResponseTicket) => string;
}

/**
 * Template variable object array
 * - supports multiple variations for the same variable definition.
 */
export const templateVarsRaw: VarDefinition[] = [
  {
    name: ['r', 'reviewer', 'c', 'customer'],
    description: 'Reviewer name',
    replacement: (ticket: ReviewResponseTicket): string => {
      return ticket.review?.customer || '';
    },
  },
  {
    name: ['r.f', 'reviewer.firstname', 'c.f', 'customer.firstname'],
    description: 'Reviewer first name',
    replacement: (ticket: ReviewResponseTicket): string => {
      const firstName = ticket.review?.customer?.split(' ')[0];
      if (!firstName) return '';

      return `${firstName[0].toUpperCase()}${firstName.slice(1)}`;
    },
  },
  {
    name: ['loc', 'location'],
    description: 'Location name',
    replacement: (ticket: ReviewResponseTicket): string => {
      return ticket.placeName || '';
    },
  },
];

/**
 * Build usable array of all variables w/ diplicates
 */
export const templateVars = templateVarsRaw
  .map((x) => {
    return x.name.map((y) => {
      return {
        ...x,
        name: y,
      };
    });
  })
  .flat();

/**
 * Build renderable array of variables.
 * - The modal's table doesn't need duplicate rows,
 * - combine the name array of variables to render in same row as alias
 */
export const templateVarsToRender = templateVarsRaw.map((def) => {
  const addBracketsToVariables = def.name.map((x) => `{${x}}`);
  return {
    label: def.description,
    value: nl2br(addBracketsToVariables.join('\n')),
  };
});

/**
 * Replace body text variables with values
 */
export const parseText = (str: string, ticket: ReviewResponseTicket): string => {
  let newStr = str;
  templateVars.forEach((def) => {
    newStr = newStr.replace(new RegExp(`\{${def.name}\}`, 'gi'), def.replacement(ticket));
  });

  return newStr;
};

/**
 * Whitelist of variables allowed in ResponseTemplate
 */
export const allowed_template_variables = templateVars.map((def) => {
  return `{${def.name}}`;
});

/**
 * @param array
 * @param whitelist allowed_template_variables
 * @example
 * checkAgainstWhitelist(['{location}', '{asdf}']) // returns false
 * checkAgainstWhitelist(['{location}', '{reviewer}']) // returns true
 */
export function checkAgainstWhitelist(array: RegExpMatchArray | null, whitelist = allowed_template_variables): boolean {
  /* prettier-ignore */
  return array
    ? array.every((elem: string) => {
      return whitelist.includes(elem);
    })
    : true;
}
