import styled from 'styled-components/macro';
import vars from 'src/styles/variables';

export const Container = styled.div`
  margin: 0 auto;
  padding-left: ${vars.$spacing_small};
  padding-right: ${vars.$spacing_small};

  transition: width 300ms ease-in-out, height 300ms ease-in-out;

  &:after,
  &:before {
    display: table;
    content: ' ';
  }

  @media (max-width: ${vars.$md}) {
    width: 100%;
  }

  @media (min-width: ${vars.$md}) {
    width: ${vars.$container_md};
  }

  @media (min-width: ${vars.$lg}) {
    width: ${vars.$container_lg};
  }

  @media (min-width: ${vars.$xl}) {
    width: ${vars.$container_xl};
  }
`;
Container.displayName = 'Container';

export default Container;
