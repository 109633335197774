import React from 'react';
import { ReviewType } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/types_pb';
import { ReviewResponseTicket, ResponseSignature, ResponseTemplate } from 'src/async/types';
import { parseText } from 'src/utils/templateVars';
import { FormValues } from './WriteResponseCardForm';

// * Build signature using available data, attach newlines only if data is available
export function buildSignature(signature: ResponseSignature): string {
  return `\n\n${signature.content}`;
}

// * Find/replace any variables if they exist in text
export function parseCommentBody(
  values: FormValues,
  ticket: ReviewResponseTicket,
  signature: ResponseSignature | undefined
): string {
  const { comment, attach_signature } = values;
  return attach_signature && signature
    ? parseText(comment, ticket) + buildSignature(signature)
    : parseText(comment, ticket);
}

// * Find the appropriate signature for ReviewType, defaults to General if available
export function findAppropriateSignature(ticket: ReviewResponseTicket): ResponseSignature | undefined {
  const responseSignatures = ticket.responseSignatures || [];
  const foundSignature = responseSignatures.find((x) => {
    return x.signsReviewType === ticket.type;
  });
  const generalSignature = responseSignatures.find((x) => {
    return x.signsReviewType === ReviewType.GENERAL;
  });

  return foundSignature || generalSignature;
}

// * Build options for select from ticket.responsePlace.responseTickets
export function buildTemplateSelectOptions(
  responseTemplates: ResponseTemplate[] = []
): { label: string; value: string }[] {
  return responseTemplates.map((template) => {
    return {
      label: template.title,
      value: template.ulid,
    };
  });
}
