import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';
import vars from 'src/styles/variables';
import Accordion from '../Accordion';
import CardAlert from './CardAlert';
import CardBody from './CardBody';
import CardFooter from './CardFooter';
import CardHeading from './CardHeading';

interface LocalProps {
  ref?: React.MutableRefObject<null>;
  as?: any;
  debug?: boolean;
  compact?: boolean;
  bgTransparent?: boolean;
  shadow?: boolean;
  noBorder?: boolean;
  children: ReactNode | ReactNode[];
}

// eslint-disable-next-line space-in-parens
const CardStyledComponent = styled.div<LocalProps>`
  display: flex;
  flex-direction: column;
  border-radius: ${vars.$border_radius};
  box-shadow: ${(props) => (props.shadow ? vars.$shadow_1 : 'inherit')};
  background-color: white;

  > :first-child {
    border-top-left-radius: ${vars.$border_radius};
    border-top-right-radius: ${vars.$border_radius};
  }

  > :last-child {
    border-bottom-right-radius: ${vars.$border_radius};
    border-bottom-left-radius: ${vars.$border_radius};
  }

  ${CardBody} {
    p {
      margin: 0em 0em 1rem;
      font-size: 0.85rem;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  ${(props) =>
    props.noBorder &&
    css`
      ${CardHeading},
      ${CardBody},
      ${CardAlert},
      ${CardFooter} {
        border: none;
      }
    `}

  ${Accordion} {
    > div > :last-child {
      border-bottom-right-radius: ${vars.$border_radius};
      border-bottom-left-radius: ${vars.$border_radius};
    }
  }

  ${(props) => {
    return (
      props.debug &&
      css`
        * {
          border: 1px solid red;
        }
      `
    );
  }}

  ${(props) => {
    return (
      props.bgTransparent &&
      css`
        background-color: transparent;
        box-shadow: none;
      `
    );
  }}
`;

class CardComponent extends React.Component<LocalProps> {
  static Heading = CardHeading;
  static Alert = CardAlert;
  static Body = CardBody;
  static Footer = CardFooter;

  static displayName = 'Card';

  render(): ReactNode {
    const { children, ...rest } = this.props;

    return (
      <>
        <CardStyledComponent {...rest}>{children}</CardStyledComponent>
      </>
    );
  }
}

export const Card = styled(CardComponent)``;

export default Card;
