import styled, { css } from 'styled-components/macro';
import vars from 'src/styles/variables';
import Button from '../Button';
import Card from '../Card';

/**
 * ModalCardWrapper fixes a scroll problem that happens
 * when modal has prop `compact` but the content is longer
 * than the page height.
 */
export const ModalCardWrapper = styled.div`
  height: 100%;
`;

/**
 * ModalCard is an extension of `Card`
 *
 * The prop `compact` shrinks the modal
 */
export const ModalCard = styled(Card)<{ compact?: boolean }>`
  ${(props) => {
    if (props.compact) {
      return css`
        margin: 0 auto;
        max-width: 35rem;
        min-height: 20rem;
        max-height: 100%;

        @media (max-width: ${vars.$md}) {
          width: 100%;
          max-width: 100%;
          height: 100%;
        }

        @media (min-width: ${vars.$lg}) {
          max-width: 40rem;
        }
      `;
    } else {
      return css`
        height: 100%;
      `;
    }
  }};
`;

export const ModalCardBody = styled(Card.Body)<{ noPadding?: boolean; overflowVisible?: boolean }>`
  flex: 1;
  overflow: ${(props) => (props.overflowVisible ? 'visible' : 'auto')};
  padding: ${(props) => (props.noPadding ? 0 : '1.5rem')};
`;
export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: ${vars.$spacing_medium};
    margin-bottom: 1px;
    font-size: 110%;
  }
  span {
    font-size: 125%;
  }
`;
export const NoContentSpacer = styled.div`
  padding: 1px;
`;
export const CloseButton = styled(Button)`
  font-size: 110%;
  margin-right: -${vars.$spacing_small};
  &:hover {
    color: ${vars.$secondary};
  }
`;
const HeadingFooter = css`
  background-color: white;
  color: ${vars.$gray_9};
  padding: 0.75rem 1.5rem;
`;
export const ModalHeading = styled(Card.Heading)`
  ${HeadingFooter}
`;
export const ModalAlert = styled(Card.Alert)``;
export const ModalFooter = styled(Card.Footer)`
  ${HeadingFooter}
  background-color: ${vars.$gray_fa};
`;
export const FooterRight = styled.div`
  ${Button} {
    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
`;
