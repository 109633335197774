import React from 'react';
import { darken, desaturate, lighten } from 'color2k';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';

const Alert = styled.div`
  /* Softer Version, less saturated
  color: ${desaturate(darken(vars.$red, 0.1), 0.5)};
  background-color: ${lighten(vars.$redlight, 0.075)};
  border: 1px solid ${vars.$redlight}; */

  /* Darker Version, matches toast */
  color: white;
  background-color: ${vars.$red};
  border: 1px solid ${desaturate(darken(vars.$red, 0.05), 0.1)};

  padding: ${vars.$spacing};
  margin-bottom: ${vars.$spacing};
  box-shadow: ${vars.$shallow_shadow};

  > svg {
    margin-right: 0.5rem;
  }
`;

export default Alert;
