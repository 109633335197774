import styled from 'styled-components/macro';
import vars from 'src/styles/variables';

const CardBody = styled.div<{ noPadding?: boolean }>`
  padding: ${(props) => (props.noPadding ? 0 : `${vars.$spacing} ${vars.$spacing_medium_large}`)};
  border: ${vars.$border};
`;

CardBody.displayName = 'Card.Body';
export default CardBody;
