import _ from 'lodash';
import { ReviewResponseTicket } from 'src/async/types';
import {
  reviewResponseTicket,
  assigneeResponseUser,
  responsePlaces,
  reviewResponseTags,
  reviewResponseNotes,
  reviewResponseSubmissions,
} from './reusable.fixture';
import { approvalRequiredTypes_fixture, ListTicketsObjectType, buildCanAutoPublish } from './utils';

export * from './buildReviewResponseTicket.fixture';
export * from './utils';
export * from './reusable.fixture';

export const listReviewResponseTicketsFixtures = {
  ...approvalRequiredTypes_fixture,
  ...buildCanAutoPublish(true),
  ...buildCanAutoPublish(false),
} as ListTicketsObjectType;

export const getReviewResponseTicketFixture: ReviewResponseTicket = _.merge({}, reviewResponseTicket, {
  assigneeResponseUser,
  responsePlace: responsePlaces[0],
  reviewResponseTags,
  reviewResponseNotes,
  reviewResponseSubmissions,
});
