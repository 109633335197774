import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { ActionsCell, Pill } from 'src/components';
import { client, DELETE_RESPONSE_ASSIGNEE } from 'src/graph';
import { toast } from 'src/utils';
import { Assignee } from 'src/views/Settings/components/TicketManagement/index';
import { DataRow } from '../AdminManagement/table';
import { FormValues, IndividualPillWrapper, LocationSelected } from './TicketManagementForm';

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Auto-assigned User',
    width: '10rem',
    selector: (row: Record<string, any>): any => row.autoAssign,
    sortable: true,
  },
  {
    name: 'Location',
    selector: (row: Record<string, any>): any => row.location,
    sortable: false,
  },
  {
    name: 'Actions',
    width: '6rem',
    sortable: false,
    center: true,
    grow: 0,
    cell: ActionsCell,
  },
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function buildData(
  assignees?: Assignee[],
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setTableData?: any,
  showAddUserModal?: () => void,
  setActiveEditingUser?: React.Dispatch<React.SetStateAction<FormValues>>,
  setPlacesTags?: (tags: any[]) => void,
  setActiveUser?: (user: any) => void,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  orgUlid?: string,
  assigneesData?: Assignee[],
  setAssignees?: (assignees: Assignee[]) => void
): any[] {
  return assignees
    ? assignees.map((assignee, i) => {
        return {
          autoAssign: assignee.autoAssign,
          location: (
            <LocationSelected width={[1]}>
              {assignee.places.map((place, j) => (
                <IndividualPillWrapper key={j}>
                  <Pill key={j} small>
                    {place.name}
                  </Pill>
                </IndividualPillWrapper>
              ))}
            </LocationSelected>
          ),
          actions: [
            {
              action: 'delete',
              icon: 'trash-alt',
              tooltip: 'Delete',
              onClick: async () => {
                const res = await client.mutate({
                  mutation: DELETE_RESPONSE_ASSIGNEE,
                  variables: { orgUlid, userUlid: assignee.userUlid },
                });
                if (res && res.data && res.data.deleteResponseAssignee && res.data.deleteResponseAssignee.errors) {
                  toast({ type: 'error', message: 'Something went wrong, please try again.' });
                } else {
                  const modifiedAssignees = assigneesData ? [...assigneesData] : [];
                  modifiedAssignees.splice(i, 1);
                  setAssignees && (await setAssignees(modifiedAssignees));
                  setTableData(
                    buildData(
                      modifiedAssignees,
                      setTableData,
                      showAddUserModal,
                      setActiveEditingUser,
                      setPlacesTags,
                      setActiveUser,
                      orgUlid,
                      modifiedAssignees,
                      setAssignees
                    )
                  );
                  toast({ type: 'success', message: `Removed ${assignee.autoAssign} as Assignee` });
                }
              },
            },
            {
              action: 'edit',
              icon: 'edit',
              tooltip: 'Edit',
              onClick: async () => {
                setActiveEditingUser &&
                  setActiveEditingUser({
                    add_organization_user_ulid: assignee.userUlid,
                  });
                setActiveUser && setActiveUser({ name: assignee.autoAssign, email: assignee.email });
                setPlacesTags && setPlacesTags(assignee.places);
                showAddUserModal && showAddUserModal();
              },
            },
          ],
        };
      })
    : [];
}
