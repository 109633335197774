import { AxiosResponse } from 'axios';
import axios from 'src/axios';
import { ResponsePlace } from '../types/api';
import { EditResponsePlaceVariables, ResponsePlaceVariables } from '../types/async';

export async function getResponsePlace(placeUlid: string): Promise<ResponsePlace> {
  const { data } = await axios.get(`${process.env.REACT_APP_GO_API}/response-places/${placeUlid}`);
  return data;
}

export async function listResponsePlaces(responseOrganizationUlid: string): Promise<ResponsePlace[]> {
  const { data } = await axios.get(
    `${process.env.REACT_APP_GO_API}/response-places?response_organization_ulid=${responseOrganizationUlid}`
  );
  return data;
}

export function createResponsePlace(variables: ResponsePlaceVariables): Promise<AxiosResponse<ResponsePlace>> {
  const { placeUlid, responseOrganizationUlid } = variables;
  return axios.post(`${process.env.REACT_APP_GO_API}/response-places`, {
    placeUlid,
    responseOrganizationUlid,
  });
}

export function editResponsePlace(variables: EditResponsePlaceVariables): Promise<AxiosResponse<ResponsePlace>> {
  const { placeUlid, settings } = variables;
  return axios.put(`${process.env.REACT_APP_GO_API}/response-places/${placeUlid}`, {
    settings,
  });
}

export function activateResponsePlace(variables: ResponsePlaceVariables): Promise<AxiosResponse<ResponsePlace>> {
  const { placeUlid, startDate } = variables;
  return axios.post(`${process.env.REACT_APP_GO_API}/response-places/${placeUlid}/activate`, {
    startDate,
  });
}

export function deactivateResponsePlace(variables: ResponsePlaceVariables): Promise<AxiosResponse<ResponsePlace>> {
  const { placeUlid } = variables;
  return axios.post(`${process.env.REACT_APP_GO_API}/response-places/${placeUlid}/deactivate`);
}

export function deleteResponsePlace(variables: ResponsePlaceVariables): Promise<AxiosResponse<unknown>> {
  const { placeUlid } = variables;
  return axios.delete(`${process.env.REACT_APP_GO_API}/response-places/${placeUlid}`);
}
