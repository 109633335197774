import React from 'react';
import { useModal } from 'react-modal-hook';
import { OptionTypeBase, ValueType } from 'react-select';
import { ResponseUser } from '@friendemic/erc-graph';
import { Formik, Form, FormikProps } from 'formik';
import styled from 'styled-components/macro';
import { FormGroup, Input, InputGroup, Row, Column, Button, Table, Modal, Label } from 'src/components';
import { useStateValue } from 'src/context';
import { LIST_RESPONSE_ORGANIZATION_USERS } from 'src/graph';
import { withDataFetcher } from 'src/hooks/withDataFetcher';
import { toast, fetchUserByUlid } from 'src/utils';
import OrganizationAsyncSelect from './OrganizationAsyncSelect';
import PageTemplate from './PageTemplate';
import { buildData, columns } from './table';
import { useGraphMutations } from './useGraphMutations';
import { RenderForm, FormValues, initialFormValues, validationSchema } from './UserManagementForm';

const ChooseOrganizationMessage = styled.p`
  display: flex;
  padding: 1rem;
  justify-content: center;
`;

const UserManagement = (props: {
  listResponseOrganizationUsers?: ResponseUser[];
  organizationSelection: OptionTypeBase;
  setOrganizationSelection: React.Dispatch<React.SetStateAction<OptionTypeBase>>;
}): JSX.Element => {
  const [{ userRoles }] = useStateValue();
  const { listResponseOrganizationUsers, organizationSelection, setOrganizationSelection } = props;
  const [tableData, setTableData] = React.useState<any>();
  const [users, setUsers] = React.useState<ResponseUser[]>(listResponseOrganizationUsers || []);
  const [activeEditingUser, setActiveEditingUser] = React.useState<FormValues>(initialFormValues);

  const { createError, createResponseUser, editError, editResponseUser } = useGraphMutations(
    organizationSelection.value
  );

  if (createError) {
    toast({ type: 'warn', message: createError });
  }

  if (editError) {
    toast({ type: 'warn', message: editError });
  }

  React.useEffect(() => {
    setUsers(listResponseOrganizationUsers || []);
  }, [listResponseOrganizationUsers]);

  React.useEffect(() => {
    setTableData(buildData(users, setUsers, setActiveEditingUser, showAddUserModal));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [users]);

  function handleFilter(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.currentTarget;
    if (!listResponseOrganizationUsers) {
      return setUsers([]);
    }

    const filtered = listResponseOrganizationUsers.filter((userMatch: ResponseUser) => {
      return (
        (userMatch && userMatch.user && userMatch.user.ulid.toLowerCase().includes(value.toLowerCase())) ||
        (userMatch && userMatch.user && userMatch.user.email.toLowerCase().includes(value.toLowerCase())) ||
        (userMatch && userMatch.user && userMatch.user.name.toLowerCase().includes(value.toLowerCase()))
      );
    });
    setUsers(filtered);
  }

  /**
   * -------------------
   * CRUD - ADD USER
   * -------------------
   */
  async function handleAddUser(values: FormValues) {
    const {
      data: { getUser },
    } = await fetchUserByUlid(values.add_organization_user_ulid);

    await createResponseUser({
      variables: {
        input: {
          responseOrganizationUlid: organizationSelection.value,
          userUlid: values.add_organization_user_ulid,
          readOnly: values.add_organization_user_readOnly,
          userRole: values.add_organization_user_userRole,
        },
      },
    })
      .then(async (response) => {
        if (response.data.createResponseUser && response.data.createResponseUser.errors) {
          console.info(JSON.stringify(response.data, null, 2));
          toast({ message: 'Something went wrong, please try again.', type: 'error' });
        } else {
          const newValues = {
            responseOrganizationUlid: organizationSelection.value,
            userRole: values.add_organization_user_userRole,
            userUlid: values.add_organization_user_ulid,
            readOnly: values.add_organization_user_readOnly,

            user: {
              ulid: values.add_organization_user_ulid,
              name: getUser.name,
              email: getUser.email,
              organizationIDs: getUser.organizationIDs,
            },
          };
          await setUsers((oldArray) => [...oldArray, newValues]);
          toast({ message: `Added User ${getUser.name}`, type: 'success' });
          setActiveEditingUser(initialFormValues);
          hideAddUserModal();
        }
      })
      .catch((err) => {
        if (err.networkError) {
          console.error(err.networkError.result);
        }
        toast({ type: 'error', message: err.message });

        setActiveEditingUser(initialFormValues);
        hideAddUserModal();
      });
  }

  /**
   * -------------------
   * CRUD - EDIT USER
   * -------------------
   */
  async function handleEditUser(values: FormValues) {
    const activeUser = users.find((x) => {
      return x.userUlid === values.add_organization_user_ulid;
    });

    const activeUserName = activeUser && activeUser.user ? activeUser.user.name : 'UNKNOWN';

    await editResponseUser({
      variables: {
        userUlid: values.add_organization_user_ulid,
        input: {
          readOnly: values.add_organization_user_readOnly,
          userRole: values.add_organization_user_userRole,
        },
      },
    })
      .then(async (response) => {
        if (response.data.editAdminResponseUser && response.data.editAdminResponseUser.errors) {
          console.info(JSON.stringify(response.data, null, 2));
          toast({ message: 'Something went wrong, please try again.', type: 'error' });
        } else {
          const newValues = {
            userUlid: values.add_organization_user_ulid,
            readOnly: values.add_organization_user_readOnly,
            userRole: values.add_organization_user_userRole,
          };

          await setUsers(
            users.map((x) => {
              return x.userUlid === values.add_organization_user_ulid ? { ...x, ...newValues } : x;
            })
          );
          toast({ type: 'success', message: `Updated "${activeUserName}"` });

          setActiveEditingUser(initialFormValues);
          hideAddUserModal();
        }
      })
      .catch((err) => {
        if (err.networkError) {
          console.error(err.networkError.result);
        }
        toast({ type: 'error', message: err.message });
        setActiveEditingUser(initialFormValues);
        hideAddUserModal();
      });
  }

  /**
   * -------------------
   * MODAL - ADD USER
   * -------------------
   */
  const [showAddUserModal, hideAddUserModal] = useModal(() => {
    const addNew = activeEditingUser === initialFormValues;

    return (
      <Formik
        initialValues={activeEditingUser}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          return addNew ? handleAddUser(values) : handleEditUser(values);
        }}
      >
        {(props: FormikProps<FormValues>) => {
          const { submitForm, isSubmitting } = props;
          return (
            <Modal
              hideModal={hideAddUserModal}
              title={addNew ? 'Add User' : 'Edit User'}
              icon={addNew ? 'plus' : 'edit'}
              overflowVisible
              compact
              footer
              cancelable
              submitDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              submittable={addNew ? 'Add' : 'Save'}
              onClose={() => {
                setActiveEditingUser(initialFormValues);
              }}
              handleSubmit={() => {
                return submitForm();
              }}
            >
              <Form>
                <RenderForm organizationSelection={organizationSelection} users={users} addNew={addNew} {...props} />
              </Form>
            </Modal>
          );
        }}
      </Formik>
    );
  }, [activeEditingUser]);

  /**
   * -------------------
   * RENDER
   * -------------------
   */
  return (
    <PageTemplate>
      <Row>
        <Column>
          <OrganizationAsyncSelect
            onChange={(selection: ValueType<OptionTypeBase, boolean>) => {
              setOrganizationSelection(selection || { value: '', label: '' });
            }}
            value={organizationSelection}
            isDisabled={userRoles.isOrganizationManager}
          />
        </Column>
      </Row>
      <Row>
        <Column flex="1" width="auto">
          <FormGroup hasError={false}>
            <InputGroup iconLeft="search">
              <Input border placeholder="Search Users" onChange={handleFilter} data-cy="search-users" />
            </InputGroup>
          </FormGroup>
        </Column>
        <Column flex="0 1 auto" width="auto">
          <Button icon="plus" onClick={showAddUserModal} data-cy="add-user">
            {'Add User'}
          </Button>
        </Column>
      </Row>
      <Row>
        <Column>
          <Table noHeader columns={columns} data={tableData} />
        </Column>
      </Row>
    </PageTemplate>
  );
};

/**
 * -------------------
 * WITH DATA FETCHER
 * -------------------
 *
 * The organizationSelection state value controls re-render,
 *   setOrganizationSelection is passed to the UserManagement component
 *   which fires onChange to re-fetch the query.
 *
 * fetchPolicy: 'no-cache' is set to force a fetch onChange, else the cache
 *   prevents new data from being loaded when a new organization is selected.
 *
 */
const UserManagementWithData = withDataFetcher(UserManagement);
const UserManagementWrapper = (): React.ReactElement => {
  const [{ userRoles, currentUser }] = useStateValue();
  /* organizationSelection state controls re-render & re-fetch*/
  const [organizationSelection, setOrganizationSelection] = React.useState<OptionTypeBase>({
    value: '',
    label: '',
  });
  React.useEffect(() => {
    if (userRoles.isOrganizationManager) {
      setOrganizationSelection({
        value: currentUser.responseOrganization?.ulid,
        label: currentUser.responseOrganization?.name,
      });
    }
  }, [userRoles, currentUser]);
  /* If organization has not been selected, request input change */
  if (!Boolean(organizationSelection.value)) {
    return (
      <>
        <PageTemplate>
          <OrganizationAsyncSelect
            onChange={(selection: ValueType<OptionTypeBase, boolean>) => {
              setOrganizationSelection(selection || { value: '', label: '' });
            }}
          />
          <ChooseOrganizationMessage>{'Please select an Organization...'}</ChooseOrganizationMessage>
        </PageTemplate>
      </>
    );
  } else {
    /* Else run query for responseOrganizationUsers and load table */
    return (
      <UserManagementWithData
        query={LIST_RESPONSE_ORGANIZATION_USERS}
        queryOptions={{
          variables: { responseOrganizationUlid: organizationSelection.value },
          fetchPolicy: 'cache-first',
        }}
        organizationSelection={organizationSelection}
        setOrganizationSelection={setOrganizationSelection}
        /* customLoadingMessage replicates page shape, reduces layout trashing */
        customLoadingMessage={
          <PageTemplate>
            <form>
              <FormGroup hasError={false}>
                <Label htmlFor="CustomLoadingMessage">{'Organization'}</Label>
                <Input border name="CustomLoadingMessage" disabled placeholder="Loading..." />
              </FormGroup>
            </form>
          </PageTemplate>
        }
      />
    );
  }
};

export default UserManagementWrapper;
