import React from 'react';
import ReactSelect, { OptionTypeBase, NamedProps } from 'react-select';
import styled from 'styled-components/macro';
import { selectStyles } from './Select.styled';

interface SelectProps extends NamedProps<OptionTypeBase, boolean> {
  name: string;
  border?: boolean;
  hasError?: boolean;
}

const Select = (props: SelectProps): React.ReactElement => {
  const { name, border, hasError, ...rest } = props;

  return (
    <ReactSelect
      name={name}
      border={border}
      hasError={hasError}
      classNamePrefix="Select"
      {...rest}
      // wrap props
    />
  );
};

const StyledSelect = styled(Select)`
  ${selectStyles}
`;

export default StyledSelect;
