// signatures.tsx UNKNOWN
import { ResponseSignature } from '@friendemic/erc-graph';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { RESPONSE_SIGNATURES_KEY } from '../queryKeys.utils';
import { SharedMutationResult, SignatureVariables } from '../types/async';
import { listSignatures, createSignature, deleteSignature, editSignature } from './signatures';

export function useListSignatures(placeUlid: string): UseQueryResult<ResponseSignature[]> {
  return useQuery({
    queryKey: [RESPONSE_SIGNATURES_KEY, placeUlid],
    queryFn: () => listSignatures(placeUlid),
  });
}

export function useCreateSignature(): SharedMutationResult<ResponseSignature, SignatureVariables> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createSignature,
    onSuccess: (_, { placeUlid }) => {
      queryClient.invalidateQueries([RESPONSE_SIGNATURES_KEY, placeUlid]);
    },
  });
}
export function useEditSignature(): SharedMutationResult<ResponseSignature, SignatureVariables> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editSignature,
    onSuccess: (_, { placeUlid }) => {
      queryClient.invalidateQueries([RESPONSE_SIGNATURES_KEY, placeUlid]);
    },
  });
}
export function useDeleteSignature(): SharedMutationResult<unknown, SignatureVariables> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteSignature,
    onSuccess: (_, { placeUlid }) => {
      queryClient.invalidateQueries([RESPONSE_SIGNATURES_KEY, placeUlid]);
    },
  });
}
