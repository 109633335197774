import React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components/macro';
import vars from 'src/styles/variables';

export interface PaginationProps extends ReactPaginateProps {
  outline?: boolean;
  debug?: boolean;
  isLoading?: boolean;
}

const PaginationWrapper = styled.div<{ outline?: boolean; isLoading?: boolean }>`
  transition: opacity 150ms ease-in-out 500ms;
  ${(props) => {
    return props.isLoading && 'opacity: 0;';
  }}
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      &:first-of-type {
        margin-right: 0.35rem;
      }
      &:last-of-type {
        margin-left: 0.35rem;
      }

      &:not(:first-of-type):not(:last-of-type) {
        margin: 0 0.35rem;
      }

      a {
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        width: 3rem;
        color: ${vars.$gray_9};
        border-radius: 10rem;
        ${(props) => {
          return props.outline
            ? css`
                background-color: transparent;
                border: ${vars.$border};
                &:hover,
                &:active {
                  border: 1px solid ${vars.$gray_c};
                  box-shadow: ${vars.$shallow_shadow};
                }
              `
            : css`
                background-color: white;
                box-shadow: ${vars.$shallow_shadow};
                border: 0;
                &:hover,
                &:active {
                  background-color: ${vars.$gray_fa};
                  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.125);
                }
              `;
        }}
        &:focus {
          outline: 0;
        }
      }
      &.active > a {
        background-color: ${vars.$greenlight};
        color: white;
        border: 0;
        /* height: 3.5rem;
        width: 3.5rem; */
        height: 3.25rem;
        width: 3.25rem;
        font-size: 125%;
      }
      &.disabled > a {
        color: ${vars.$gray_c};
        pointer-events: none;
      }
    }
  }
`;

const DebugWidth = styled.div`
  text-align: center;
  color: ${vars.$gray_9};
`;

/**
 * Pagination component built with [react-paginate](https://github.com/AdeleD/react-paginate)
 *
 * See below for available props, the useful ones have been documented in storybook.
 *
 * The component's width is variable depending on page #.
 * This has been mitigated by measuring the component's
 * _container_ width and adjusting the `pageRangeDisplayed` and `marginPagesDisplayed` internally.
 *
 * #### All props from react-paginate's [DefinitelyTyped](https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react-paginate/index.d.ts#L16)
 * ```typescript
 * export interface ReactPaginateProps {
 *   pageCount: number;
 *   pageRangeDisplayed: number;
 *   marginPagesDisplayed: number;
 *   previousLabel?: React.ReactNode;
 *   nextLabel?: React.ReactNode;
 *   breakLabel?: string | React.ReactNode;
 *   breakClassName?: string;
 *   breakLinkClassName?: string;
 *   onPageChange?(selectedItem: { selected: number }): void;
 *   initialPage?: number;
 *   forcePage?: number;
 *   disableInitialCallback?: boolean;
 *   containerClassName?: string;
 *   pageClassName?: string;
 *   pageLinkClassName?: string;
 *   activeClassName?: string;
 *   activeLinkClassName?: string;
 *   previousClassName?: string;
 *   nextClassName?: string;
 *   previousLinkClassName?: string;
 *   nextLinkClassName?: string;
 *   disabledClassName?: string;
 *   hrefBuilder?(pageIndex: number): void;
 *   extraAriaContext?: string;
 * }
 * ```
 *
 * Also see at react-paginate's [source](https://github.com/AdeleD/react-paginate/blob/master/react_components/PaginationBoxView.js#L8)
 *
 */
const Pagination = (props: PaginationProps): React.ReactElement => {
  const { outline, debug, pageCount, marginPagesDisplayed, pageRangeDisplayed, onPageChange, ...rest } = props;

  const [localMarginPagesDisplayed, setMarginPagesDisplayed] = React.useState(marginPagesDisplayed);
  const [localPageRangeDisplayed, setPageRangeDisplayed] = React.useState(pageRangeDisplayed);

  const [width, setWidth] = React.useState(0);
  const elementRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (elementRef.current !== null) {
      setWidth(elementRef.current.getBoundingClientRect().width);
    }

    switch (true) {
      case width < 500:
        setMarginPagesDisplayed(1);
        setPageRangeDisplayed(1);
        break;
      case width >= 500 && width < 600:
        setMarginPagesDisplayed(1);
        setPageRangeDisplayed(2);
        break;
      case width >= 600 && width < 800:
        setMarginPagesDisplayed(2);
        setPageRangeDisplayed(3);
        break;
      default:
        setMarginPagesDisplayed(2);
        setPageRangeDisplayed(4);
    }
  }, [width]);

  return (
    <PaginationWrapper ref={elementRef} outline={outline} isLoading={width === 0}>
      <ReactPaginate
        previousLabel={<FontAwesomeIcon icon={['far', 'angle-double-left']} data-cy="previous-page" />}
        nextLabel={<FontAwesomeIcon icon={['far', 'angle-double-right']} data-cy="next-page" />}
        breakLabel={<FontAwesomeIcon icon={['far', 'ellipsis-h']} />}
        pageCount={pageCount}
        marginPagesDisplayed={localMarginPagesDisplayed}
        pageRangeDisplayed={localPageRangeDisplayed}
        onPageChange={onPageChange}
        disabledClassName="disabled"
        activeClassName="active"
        {...rest}
      />
      {debug && <DebugWidth>{`Pagination width: ${width}px`}</DebugWidth>}
    </PaginationWrapper>
  );
};

Pagination.defaultProps = {
  marginPagesDisplayed: 2,
  pageRangeDisplayed: 1,
};

export default Pagination;
