import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import routes from './routes';
import FourOhFour from './views/FourOhFour';

const MainRouter = (): JSX.Element => {
  return (
    <Switch>
      {/* Set the default redirect from="/" to="some-route" */}
      <Redirect from="/" to="/response-console" exact />
      {/**
       * Remove double slashes in url, does not remove trailing slash. Modified to retain "?query=" strings
       *    via: https://github.com/ReactTraining/react-router/issues/4841#issuecomment-628091315
       *    Example:
       *      settings//some-setting/ --> settings/some-setting/
       *      settings//some-setting//asdf?q=1234 --> settings/some-setting/asdf?q=1234
       */}
      <Route
        exact
        strict
        path="(.*//+.*)"
        render={({ location }) => {
          return <Redirect to={`${location.pathname.replace(/\/\/+/g, '/')}${location.search}`} />;
        }}
      />
      {routes.map((route, i) => {
        const { path, exact, component } = route;
        return <Route key={i} path={path} exact={exact} component={component} />;
      })}
      <Route component={FourOhFour} />
    </Switch>
  );
};

export default MainRouter;
