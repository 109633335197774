import { CurrentUser } from 'src/context';

export const currentUserFixture: CurrentUser = {
  userUlid: 'CURRENT_USER_ULID',
  responseOrganizationUlid: 'CURRENT_USER_RESPONSE_ORGANIZATION_ULID',
  responseOrganization: {
    name: 'Response Organization Name',
    ulid: 'CURRENT_USER_RESPONSE_ORGANIZATION_ULID',
  },
  placeUlid: undefined,
  userRole: 2,
  readOnly: false,
  user: {
    ulid: 'CURRENT_USER_ULID',
    name: 'Response User',
    email: 'response.user@friendemic.com',
    organizationIDs: [99],
  },
};
