import { Flex } from 'reflexbox';
import styled from 'styled-components/macro';
import { ErrorBlockWrapper } from '../ErrorBlock';

interface ScrollWrapperProps {
  pt0?: boolean;
}

const ScrollWrapper = styled(Flex)<ScrollWrapperProps>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 0;
  padding: ${({ pt0 }) => {
    return pt0 ? '0 1rem 1rem' : '1rem';
  }};

  ${ErrorBlockWrapper} {
    padding: 0;
    margin-bottom: 1rem;
  }
`;

export default ScrollWrapper;
