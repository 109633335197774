import { ChannelTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/channel_type_friendly';
import { TypeFriendly as TicketStatusTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_status_type_friendly';
import { ReviewTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_type_friendly';
import * as FullStory from '@fullstory/browser';
import { ReviewResponseTicket } from 'src/async/types';
import { CurrentUser } from 'src/context';
import { buildSubmitButtonText } from 'src/views/ResponseConsole/utils';
import { friendlyUserRole } from './helpers';

/**
 * Matches catalyst-react flow type FullStoryUserVars
 */
export interface FullStoryUserVars extends FullStory.UserVars {
  displayName: string;
  email: string;
  ulid_str: string;
  id_int?: number;
  catalyst_userRole_str?: string;
  catalyst_placeNames_strs?: string[];
  catalyst_placeIds_strs?: string[];
  catalyst_organizationId_int?: number;
  catalyst_organizationName_str?: string;
  erc_userRole_str?: string;
  erc_userRole_int?: number;
  erc_responseApproverUlid_str?: string;
  erc_responseOrganizationUlid_str?: string;
  erc_responseOrganizationName_str?: string;
  erc_commit_sha_str?: string;
}

/**
 * Returns object with relevant user data to keep FullStory events bucketed to the correct user
 *
 * See: https://developer.fullstory.com/identify
 */
export function buildFullStoryUserVars(currentUser: CurrentUser): FullStoryUserVars {
  const { userRole, user, responseApproverUlid, responseOrganization, responseOrganizationUlid } = currentUser;

  return {
    displayName: user.name,
    email: user.email,
    ulid_str: user.ulid,
    erc_userRole_str: friendlyUserRole(userRole),
    erc_userRole_int: userRole,
    erc_responseApproverUlid_str: responseApproverUlid,
    erc_responseOrganizationUlid_str: responseOrganizationUlid,
    erc_responseOrganizationName_str: responseOrganization?.name,
    erc_commit_sha_str: process.env.REACT_APP_SENTRY_RELEASE,
  };
}

/**
 * Returns object with keys in the FullStory "Event Name" format with values relevant to ticket event analytics
 *
 * See: https://developer.fullstory.com/custom-events
 */
export const buildFullStoryTicketEvent = (ticket: ReviewResponseTicket): { [key: string]: any } => {
  const { channel, assigneeUserName, rating, placeName, responseOrganizationName, type, status, publishingDetails } =
    ticket;

  const friendlyChannelType = ChannelTypeFriendly.toFriendly(channel);
  const friendlyReviewType = ReviewTypeFriendly.toFriendly(type);
  const friendlyTicketStatus = TicketStatusTypeFriendly.toFriendly(status);

  return {
    assigneeUser_str: assigneeUserName,
    channelName_str: friendlyChannelType,
    reviewType_str: friendlyReviewType,
    ticketStatus_str: friendlyTicketStatus,
    responsePlace_name_str: placeName,
    responseOrganization_name_str: responseOrganizationName,
    reviewRating_int: rating,
    isOverLimit_bool: publishingDetails?.isOverLimit,
    canAutoPublish_bool: publishingDetails?.canAutoPublish,
    canPublish_bool: publishingDetails?.canPublish,
    submitButtonText_str: buildSubmitButtonText(publishingDetails, true),
  };
};
