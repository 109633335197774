import React from 'react';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { Row, Column, InputElement } from 'src/components';
import { FormikResetForm } from 'src/types';

export interface FormValues {
  response_delay_hours: number;
}

export const initialFormValues: FormValues = {
  response_delay_hours: 0,
};

export const validationSchema = Yup.object({
  response_delay_hours: Yup.number().positive().integer(),
});

export const RenderForm = ({
  handleUpdateSettings,
  ...props
}: FormikProps<FormValues> & {
  handleUpdateSettings: (values: FormValues, resetForm: FormikResetForm) => void;
}): React.ReactElement => {
  return (
    <Row>
      <Column width={[1, 1 / 2]} data-cy="responseSettingsDelayDropdown">
        <InputElement
          type="select"
          border
          name="response_delay_hours"
          label="Response Delay"
          isClearable={false}
          handleSelectOnChange={(args) => handleUpdateSettings({ response_delay_hours: args.value }, props.resetForm)}
          options={[
            { label: 'No Delay', value: 0 },
            { label: 'One Day', value: 24 },
            { label: 'Two Days', value: 48 },
            { label: 'Three Days', value: 72 },
          ]}
        />
      </Column>
    </Row>
  );
};
