import React from 'react';
import { ResponsePlaceSettings } from 'src/async/types';
import { WysiwygContentRenderer } from 'src/components';
import vars from 'src/styles/variables';
import { CardTemplate } from './CardTemplate';

export const RedAlertCard = (props: { settings: ResponsePlaceSettings }): JSX.Element => {
  const { red_alert } = props.settings;
  return (
    <CardTemplate
      config={{
        title: 'Red Alert',
        icon: 'exclamation-triangle',
        color: vars.$red,
      }}
    >
      <WysiwygContentRenderer content={red_alert.body} />
    </CardTemplate>
  );
};

export default RedAlertCard;
