import React from 'react';
import { Input, InputGroup } from 'src/components/Forms';
import { useDebounce } from 'src/hooks';

type GenericSearchProps = {
  datatype?: string;
  onDebounceChange?: (...args: any[]) => void;
  delay?: number;
} & React.HtmlHTMLAttributes<HTMLInputElement>;

const Search = React.forwardRef<HTMLInputElement, GenericSearchProps>(
  (
    { placeholder, onChange, datatype, onDebounceChange, delay = 500, ...rest }: GenericSearchProps,
    ref
  ): JSX.Element => {
    const searchDebounce = useDebounce((event: React.ChangeEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const { value } = event.target as HTMLInputElement;
      onDebounceChange && onDebounceChange(value);
    }, delay);

    return (
      <InputGroup iconLeft="search" data-search="search">
        <Input
          {...rest}
          onChange={onChange || searchDebounce}
          placeholder={placeholder ? placeholder : 'Search'}
          transparent
          data-searchtype={datatype}
          ref={ref}
          data-cy="search"
        />
      </InputGroup>
    );
  }
);
Search.displayName = 'Search';
export default Search;
