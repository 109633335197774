import React from 'react';
import { OptionTypeBase, NamedProps } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import styled from 'styled-components/macro';
import { selectStyles } from './Select.styled';

interface SelectProps extends AsyncProps<OptionTypeBase>, NamedProps<OptionTypeBase, boolean> {
  name: string;
  border?: boolean;
  hasError?: boolean;
  noResultsMessage?: string;
  moreSelectSpacing?: boolean;
}

const SelectAsync = (props: SelectProps): React.ReactElement => {
  const { name, border, hasError, noResultsMessage, moreSelectSpacing, loadOptions, ...rest } = props;
  return (
    <AsyncSelect
      // defaultOptions
      isClearable
      noOptionsMessage={() => noResultsMessage || 'Start typing to search'}
      loadOptions={loadOptions}
      name={name}
      border={border}
      hasError={hasError}
      moreSelectSpacing={moreSelectSpacing}
      classNamePrefix="Select"
      {...rest}
    />
  );
};

const StyledSelectAsync = styled(SelectAsync)`
  ${selectStyles}
`;

export default StyledSelectAsync;
