import { RefObject } from 'react';

export const getRefElement = <T>(element?: RefObject<Element> | T): Element | T | undefined | null => {
  if (element && 'current' in element) {
    return element.current;
  }

  return element;
};

// eslint-disable-next-line  @typescript-eslint/no-inferrable-types, no-unneeded-ternary
export const isSSR: boolean = !(typeof window !== 'undefined' && window.document?.createElement);

export const getWindowSize = (isServer = isSSR): Record<string, number> => ({
  innerHeight: !isServer ? window.innerHeight : 0,
  innerWidth: !isServer ? window.innerWidth : 0,
});
