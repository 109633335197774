import React from 'react';
import DatePicker from 'react-datepicker';
import { useFormikContext, useField } from 'formik';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';
import Input from './Input';

import 'react-datepicker/dist/react-datepicker.css';

export const StyledDatepickerContainer = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__day--selected {
    background-color: ${vars.$secondary};
  }

  .react-datepicker__input-container {
    > input {
      font-size: ${vars.$font_size_sm};
    }
  }
`;

interface DateInputProps {
  name: string;
  id: string;
  value?: string;
  rangeSelect?: boolean;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  placeholder?: string;
  handleChange?: (e: any) => void;
  minDate?: Date;
  maxDate?: Date;
}

/**
 * The date input is set up to operate in one of two ways:
 * 1. As a stand alone date picker that simply picks a single date
 * * * This is the default behavior of the picker
 * 2. As a date range selector
 * * * This requires 2 separate input forms
 * * * The formConfig object must contain a true prop of 'rangeSelect'
 * * * The the start date input formConfig must contain a true prop of 'selectsStart'
 * * * The the end date input formConfig must contain a true prop of 'selectsEnd'
 *
 * 3. Note from the future:
 * * * The dual input dateRange is unintuitive.
 * * * As an inline calendar it makes sense, but not as a dual input.
 * * * minDate & maxDate have been added to pre-validate and mimic a date range
 *
 * @example
 * InputEllement startDate:
 *   maxDate={endDate}
 *   handleChange={(val) => setStartDate(val)}
 * InputElement endDate:
 *   minDate={startDate}
 *   maxDate={new Date()}
 *   handleChange={(val) => setEndDate(val)}
 */
const DateInput = (props: DateInputProps): React.ReactElement => {
  const { placeholder, minDate, maxDate, handleChange, ...rest } = props;
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const { name, value } = field;

  return (
    <StyledDatepickerContainer>
      <DatePicker
        name={name}
        placeholderText={placeholder}
        // startDate={selectsStart && value}
        // endDate={selectsEnd && value}
        // selectsEnd={selectsStart}
        // selectsStart={selectsEnd}
        customInput={<Input {...rest} />}
        selected={(value && new Date(value)) || null}
        onChange={(val) => {
          handleChange && handleChange(val);
          setFieldValue(name, val);
        }}
        minDate={minDate}
        maxDate={maxDate}
      />
    </StyledDatepickerContainer>
  );
};

export default DateInput;
