import React from 'react';
import { gql } from '@apollo/client';
import { ResponsePlaceProfileChannel, ResponsePlace as ResponsePlaceGraph } from '@friendemic/erc-graph';
import channel_type_friendly from '@friendemic/friendemic-api-node/lib/friendemic/api/response/channel_type_friendly.js';
import { Formik, FormikProps } from 'formik';
import merge from 'lodash/merge';
import styled from 'styled-components/macro';
import { useEditResponsePlace } from 'src/async';
import { ResponsePlace } from 'src/async/types';
import { Row, Column, List, AccordionList } from 'src/components';
import { client } from 'src/graph';
import { FormikResetForm } from 'src/types';
import { toast, updateGraphMutation } from 'src/utils';
import { SettingsSubsection } from 'src/views/Settings/settings.styled';
import { LocationSettingsRouteParams } from 'src/views/Settings/SettingsTypes';
import {
  FormValues as FormValuesManagement,
  RenderForm as RenderFormManagement,
  validationSchema as validationSchemaManagement,
} from './ChannelManagementForm';
import {
  FormValues as FormValuesSettings,
  initialFormValues as initialFormValuesSettings,
  RenderForm as RenderFormSettings,
  validationSchema as validationSchemaSettings,
} from './ChannelSettingsForm';
import { useGraphMutations } from './useGraphMutations';

const NoResults = styled.div`
  padding: 1.7rem;
  width: 100;
  text-align: center;
`;

const ChannelManagement = (props: {
  params: LocationSettingsRouteParams;
  getResponsePlace?: ResponsePlaceGraph;
  responsePlaceData?: ResponsePlace;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement => {
  const { getResponsePlace, responsePlaceData, params, setSaving } = props;
  const { profiles } = getResponsePlace || {};

  const [formValuesSettings, setFormValuesSettings] = React.useState(initialFormValuesSettings);

  const { editResponseChannel, editResponseChannelLoading, deleteResponseChannelLoading } = useGraphMutations(
    props.params.location
  );

  const { mutateAsync: editResponsePlace, isLoading: isEditResponsePlaceLoading } = useEditResponsePlace();

  React.useEffect(() => {
    if (responsePlaceData?.settings?.channel_settings) {
      setFormValuesSettings({
        ...initialFormValuesSettings,
        limit_response: responsePlaceData.settings.channel_settings.limit_response,
      });
    }
  }, [responsePlaceData]);

  React.useEffect(() => {
    setSaving(isEditResponsePlaceLoading || editResponseChannelLoading || deleteResponseChannelLoading);
  }, [setSaving, isEditResponsePlaceLoading, editResponseChannelLoading, deleteResponseChannelLoading]);

  async function handleUpdateChannelSettings(values: FormValuesSettings, resetForm: FormikResetForm) {
    await editResponsePlace(
      {
        placeUlid: params.location,
        settings: merge(responsePlaceData?.settings, {
          channel_settings: {
            limit_response: values.limit_response,
          },
        }),
      },
      {
        onSuccess: () => {
          resetForm({ values });
        },
        onError: (err) => {
          console.error(err);
          toast({ type: 'error', message: err.response?.data?.message || err.message });
        },
      }
    );
  }

  // async function handleCreateChannel(
  //   values: FormValuesManagement,
  //   label: string,
  //   channel: ResponsePlaceProfileChannel,
  //   resetForm: FormikResetForm
  // ) {
  //   const response = await updateGraphMutation(
  //     {
  //       variables: {
  //         placeUlid: params.location,
  //         input: {
  //           harvestProfileUlid: channel.harvestProfileUlid,
  //           channel: channel.channel,
  //           active: values.active,
  //           feedbackType: values.feedbackType,
  //           reviewType: values.reviewType,
  //         },
  //       },
  //       mutationName: 'createResponseChannel',
  //     },
  //     createResponseChannel
  //   );
  //   if (response) {
  //     if (response.data.editResponseChannel && response.data.editResponseChannel.errors) {
  //       console.info(JSON.stringify(response.data, null, 2));
  //       toast({ type: 'error', message: 'Something went wrong, please try again.' });
  //     } else {
  //       console.info({ [label]: values, channel });
  //       resetForm({ values });
  //     }
  //   }
  // }

  async function handleUpdateChannel(
    values: FormValuesManagement,
    label: string,
    channel: ResponsePlaceProfileChannel,
    resetForm: FormikResetForm
  ) {
    const response = await updateGraphMutation(
      {
        variables: {
          placeUlid: params.location,
          ulid: channel.responseChannel?.ulid,
          input: {
            active: values.active,
            feedbackType: values.feedbackType,
            reviewType: values.reviewType,
          },
        },
        mutationName: 'editResponseChannel',
      },
      editResponseChannel
    );
    if (response) {
      if (response.data.editResponseChannel && response.data.editResponseChannel.errors) {
        console.info(JSON.stringify(response.data, null, 2));
        toast({ type: 'error', message: 'Something went wrong, please try again.' });
      } else {
        console.info({ [label]: values, channel });
        resetForm({ values });
      }
    }
  }

  const renderProfile = (profile: ResponsePlaceProfileChannel) => {
    const label = channel_type_friendly.ChannelTypeFriendly.toFriendly(profile.channel) || 'UNKNOWN';
    if (profile.responseChannel) {
      const { responseChannel: channel } = profile;
      return (
        <Formik
          initialValues={{
            active: channel.active,
            feedbackType: channel.feedbackType,
            reviewType: channel.reviewType,
          }}
          // enableReinitialize
          validateOnMount
          validationSchema={validationSchemaManagement}
          onSubmit={(values, { resetForm }) => {
            handleUpdateChannel(values, label, profile, resetForm);
          }}
        >
          {(props: FormikProps<FormValuesManagement>) => {
            return <RenderFormManagement placeUlid={params.location} channelData={profile} {...props} />;
          }}
        </Formik>
      );
    } else {
      return <></>;
      // <Formik
      //   key={JSON.stringify(profile)}
      //   initialValues={initialFormValuesManagement}
      //   enableReinitialize
      //   validateOnMount
      //   validationSchema={validationSchemaManagement}
      //   onSubmit={(values, { resetForm }) => {
      //     handleCreateChannel(values, label, profile, resetForm);
      //   }}
      // >
      //   {(props: FormikProps<FormValuesManagement>) => {
      //     return <RenderFormManagement placeUlid={params.location} channelData={profile} {...props} />;
      //   }}
      // </Formik>
    }
  };

  /**
   * * Watch cache and update placeIsActive state value is activation toggle has changed
   */
  React.useEffect(() => {
    const querySubscription = client
      .watchQuery({
        // Starting cache watcher
        query: gql`
          query ReadGetResponsePlace($placeUlid: ID!) {
            getResponsePlace(placeUlid: $placeUlid) {
              active
            }
          }
        `,
        variables: { placeUlid: params.location },
        fetchPolicy: 'cache-only',
      })
      .subscribe({
        // next: ({ data }) => {
        //   setPlaceIsActive(data.getResponsePlace.active);
        // },
        error: (e) => console.error(e),
      });
    return () => {
      // Cache watcher stopped on unmount
      return querySubscription.unsubscribe();
    };
  }, [params.location]);

  /**
   * * Used here to trigger a harvest for the entire place
   * !FLAG - Uncomment to re-envable Sync Reviews button
   */
  /* async function handleActivateChannelSyncButton() {
    await activateResponsePlace(
      {
        placeUlid: params.location,
        startDate: format(subDays(new Date(), 45), 'yyyy-MM-dd'),
      },
      {
        onSuccess: (response) => {
          toast({ type: 'success', message: 'Reviews are being synced in the background' });
        },
        onError: (err) => {
          console.error(err);
          toast({
            type: 'error',
            message: `Cannot sync reviews at this time: ${err.response?.data?.message || err.message}`,
          });
        },
      }
    );
  } */

  return (
    <SettingsSubsection>
      <Row>
        <Column>
          <List
            title="Channels Auth &amp; URLs"
            icon="money-check-edit"
            // !FLAG - Uncomment to re-envable Sync Reviews button
            /* action={{
              onClick: handleActivateChannelSyncButton,
              disabled: !placeIsActive,
              spin: isActivateLoading,
              icon: isActivateLoading ? 'spinner-third' : 'sync',
              content: 'Sync Reviews',
            }} */
          >
            <li>
              <Formik
                initialValues={formValuesSettings}
                validationSchema={validationSchemaSettings}
                enableReinitialize
                validateOnMount
                onSubmit={(values, { resetForm }) => {
                  handleUpdateChannelSettings(values, resetForm);
                }}
              >
                {(props) => {
                  return <RenderFormSettings {...props} />;
                }}
              </Formik>
              <AccordionList icon="money-check-edit">
                {profiles && profiles.length ? (
                  profiles.map((x) => {
                    return <React.Fragment key={x.harvestProfileUlid}>{renderProfile(x)}</React.Fragment>;
                  })
                ) : (
                  <NoResults>{'There are no records to display'}</NoResults>
                )}
              </AccordionList>
            </li>
          </List>
        </Column>
      </Row>
    </SettingsSubsection>
  );
};

export default ChannelManagement;
