import React from 'react';
import { Redirect } from 'react-router-dom';
import { LogoutAsync } from 'src/graph';

const Logout = (props: {}): JSX.Element => {
  /**
   ** If LogoutAsync is run while the route is `/logout`:
   **   - A user logging directly back in will be routed back to `/logout`
   **     which starts a loop and will not allow them back in unless they
   **     manually remove the query params from the URL.
   **   - <Redirect to="/" /> reroutes before useEffect runs LogoutAsync
   */
  React.useEffect(() => {
    LogoutAsync();
  }, []);

  return <Redirect to="/" />;
};

export default Logout;
