import { gql } from '@apollo/client';
import { REVIEW_RESPONSE_TICKET_FRAGMENT, REVIEW_RESPONSE_SUBMISSIONS_FRAGMENT } from './fragments';

export const LIST_RESPONSE_ORGANIZATIONS = gql`
  query listResponseOrganizations {
    listResponseOrganizations {
      name
      ulid
      responsePlaces {
        responseOrganizationUlid
        placeUlid
        active
      }
    }
  }
`;
export const LIST_RESPONSE_PLACES = gql`
  query listResponsePlaces($responseOrganizationUlid: ID!) {
    listResponsePlaces(responseOrganizationUlid: $responseOrganizationUlid) {
      active
      placeUlid
      placeName
      organizationName
      place {
        name
        ulid
        organization {
          name
        }
      }
    }
    getResponseOrganization(ulid: $responseOrganizationUlid) {
      name
    }
  }
`;

export const SUGGEST_RESPONSE_ORGANIZATION_PLACES = gql`
  query PlacesQueryFilter($responseOrganizationUlid: ID!, $query: String!) {
    suggestResponseOrganizationPlaces(responseOrganizationUlid: $responseOrganizationUlid, query: $query) {
      ulid
      name
      address
      organizationName
    }
  }
`;

export const GET_PLACE = gql`
  query getPlace($placeUlid: ID!) {
    getPlace(placeUlid: $placeUlid) {
      ulid
      name
    }
  }
`;

export const GET_REDALERT_SETTINGS = gql`
  query getRedAlertSettings($placeUlid: ID!) {
    getResponsePlace(placeUlid: $placeUlid) {
      placeUlid
      active
      settings {
        redAlert {
          show
          body
        }
      }
    }
  }
`;
export const GET_APPROVAL_SETTINGS = gql`
  query getApprovalSettings($placeUlid: ID!) {
    getResponsePlace(placeUlid: $placeUlid) {
      placeUlid
      active
      settings {
        approvalSettings {
          requireType
          approveAfter24
          approvalsNeededFor
        }
      }
    }
  }
`;
export const GET_RESPONSE_SETTINGS = gql`
  query getResponseSettings($placeUlid: ID!) {
    getResponsePlace(placeUlid: $placeUlid) {
      placeUlid
      active
      settings {
        responseSettings {
          responseDelayHours
        }
      }
    }
  }
`;
export const GET_RESPONSE_PLACE = gql`
  query getResponsePlace($placeUlid: ID!) {
    getResponsePlace(placeUlid: $placeUlid) {
      placeUlid
      active
      # placeUlid
      # placeName
      place {
        ulid
        name
      }
      responseApprovers {
        ulid
        placeUlid
        userUlid
        reviewType
        required
        user {
          name
          email
        }
      }
      responseSignatures {
        ulid
        placeUlid
        content
        signsReviewType
        includeLocationName
      }
      responseTemplates {
        ulid
        placeUlid
        title
        body
      }
      responseChannels {
        ulid
        placeUlid
        harvestProfileUlid
        channel
        active
        feedbackType
        reviewType
      }
      settings {
        startDate
        approvalSettings {
          requireType
          approveAfter24
          approvalsNeededFor
        }
        channelSettings {
          approvalsNeededFor
          responseLimit
        }
        responseSettings {
          responseDelayHours
        }
        redAlert {
          show
          body
        }
      }
    }
  }
`;

export const SUGGEST_RESPONSE_APPROVER_USERS = gql`
  query suggestResponseApproverUsers($placeUlid: ID!, $query: String!, $currentUserUlid: ID!) {
    suggestResponseApproverUsers(placeUlid: $placeUlid, query: $query, currentUserUlid: $currentUserUlid) {
      ulid
      name
      email
    }
  }
`;

export const LIST_RESPONSE_APPROVERS = gql`
  query listResponseApprovers($placeUlid: ID!) {
    listResponseApprovers(placeUlid: $placeUlid) {
      ulid
      placeUlid
      userUlid
      reviewType
      required
      user {
        name
        email
      }
    }
  }
`;

export const LIST_RESPONSE_SIGNATURES = gql`
  query listResponseSignatures($placeUlid: ID!) {
    listResponseSignatures(placeUlid: $placeUlid) {
      ulid
      placeUlid
      content
      signsReviewType
      includeLocationName
    }
  }
`;

export const LIST_RESPONSE_TEMPLATES = gql`
  query listResponseTemplates($placeUlid: ID!) {
    listResponseTemplates(placeUlid: $placeUlid) {
      ulid
      placeUlid
      title
      body
    }
  }
`;

export const LIST_RESPONSE_PLACE_PROFILE_CHANNELS = gql`
  query listResponseChannels($placeUlid: ID!) {
    getResponsePlace(placeUlid: $placeUlid) {
      placeUlid
      active
      settings {
        channelSettings {
          approvalsNeededFor
          responseLimit
        }
      }
      profiles {
        harvestProfileUlid
        channel
        responseChannel {
          ulid
          placeUlid
          harvestProfileUlid
          channel
          active
          feedbackType
          reviewType
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($userUlid: ID!) {
    getUser(userUlid: $userUlid) {
      ulid
      name
      email
      organizationIDs
    }
  }
`;

// FLAG - Hardcoded getPlace placeUlid
export const GET_RESPONSE_CONSOLE = gql`
  query ResponseConsoleQuery {
    getPlace(placeUlid: "01EE8Y7TBVXZBQ4TGKH4EFD7GH") {
      name
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    currentSessionUser {
      userUlid
      user {
        ulid
        name
        email
        organizationIDs
      }
      userRole
      responseOrganizationUlid
      responseOrganization {
        name
        ulid
      }
      responseApproverUlid
    }
  }
`;
export const LIST_ADMIN_RESPONSE_USERS = gql`
  query listAdminResponseUsers {
    listAdminResponseUsers {
      userUlid
      userRole
      readOnly
      user {
        name
        email
      }
    }
  }
`;

export const SUGGEST_ADMIN_RESPONSE_USERS = gql`
  query suggestResponseAdminUsers($query: String!, $currentUserUlid: ID!) {
    suggestResponseAdminUsers(query: $query, currentUserUlid: $currentUserUlid) {
      ulid
      name
      email
    }
  }
`;

// unused
export const GET_RESPONSE_ORGANIZATION = gql`
  query getResponseOrganization($ulid: ID!) {
    getResponseOrganization(ulid: $ulid) {
      name
      ulid
    }
  }
`;

export const LIST_RESPONSE_ORGANIZATION_USERS = gql`
  query listResponseOrganizationUsers($responseOrganizationUlid: ID!) {
    listResponseOrganizationUsers(responseOrganizationUlid: $responseOrganizationUlid) {
      # 01ERMWVVYXZEJXR0892C4VMNVY
      userUlid
      userRole
      readOnly
      user {
        ulid
        name
        email
      }
    }
  }
`;

export const SUGGEST_RESPONSE_ORGANIZATION_USERS = gql`
  query suggestResponseOrganizationUsers($responseOrganizationUlid: ID!, $query: String!, $currentUserUlid: ID!) {
    suggestResponseOrganizationUsers(
      responseOrganizationUlid: $responseOrganizationUlid
      query: $query
      currentUserUlid: $currentUserUlid
    ) {
      ulid
      name
      email
    }
  }
`;

export const SUGGEST_RESPONSE_ASSIGNEE_USERS = gql`
  query suggestResponseAssigneeUsers($responseOrganizationUlid: ID!, $query: String!) {
    suggestResponseAssigneeUsers(responseOrganizationUlid: $responseOrganizationUlid, query: $query) {
      ulid
      name
      email
    }
  }
`;

export const LIST_REVIEW_RESPONSE_TICKETS = gql`
  query listReviewResponseTickets(
    $responseOrganizationUlid: ID!
    $filters: ReviewResponseTicketFilters!
    $pagination: Pagination!
    $query: String
  ) {
    listReviewResponseTickets(
      responseOrganizationUlid: $responseOrganizationUlid
      pagination: $pagination
      filters: $filters
      query: $query
    ) {
      records {
        # placeName
        place {
          name
          organization {
            name
          }
        }
        assigneeUlid
        placeUlid
        status
        type
        submittedAt
        updatedAt
        createdAt
        reviewUlid
        responseOrganizationUlid
        channel
        assigneeUser {
          name
          ulid
        }
        review {
          ulid
          title
          rating
          externalId
          url
          submittedAt
          comment
          harvestProfile {
            channel
            ulid
          }
          profileUlid
          customer
          respondedAt
          submittedAt
          collectedAt
          updatedAt
        }
      }
      meta {
        total
        perPage
        lastPage
        currentPage
      }
    }
  }
`;
export const GET_REVIEW_RESPONSE_TICKET = gql`
  query getReviewResponseTicket($reviewUlid: ID!) {
    getReviewResponseTicket(reviewUlid: $reviewUlid) {
      ...ReviewResponseFragment
    }
  }
  ${REVIEW_RESPONSE_TICKET_FRAGMENT}
`;

export const GET_REVIEW_RESPONSE_TICKET_FOR_APPROVER = gql`
  query getReviewResponseTicketForApprover($reviewUlid: ID!) {
    getReviewResponseTicket(reviewUlid: $reviewUlid) {
      submittedAt
      placeUlid
      reviewUlid
      channel
      responseOrganizationUlid
      status
      type
      canAutoPublish
      responsePlace {
        place {
          name
          organization {
            name
          }
        }
      }
      review {
        ulid
        title
        rating
        externalId
        url
        submittedAt
        collectedAt
        comment
        harvestProfile {
          channel
          ulid
        }
        profileUlid
        customer
        respondedAt
        submittedAt
        collectedAt
      }
      ...ReviewResponseSubmissions
    }
  }
  ${REVIEW_RESPONSE_SUBMISSIONS_FRAGMENT}
`;

// unused
export const LIST_REVIEW_RESPONSE_NOTES = gql`
  query listReviewResponseNotes($reviewUlid: ID!) {
    listReviewResponseNotes(reviewUlid: $reviewUlid) {
      notableUlid
      notableType
      ulid
      userUlid
      content
      createdAt
      system
    }
  }
`;
export const LIST_RESPONSE_NOTIFICATION_RECIPIENTS = gql`
  query listResponseNotificationRecipients($placeUlid: ID!) {
    listResponseNotificationRecipients(placeUlid: $placeUlid) {
      ulid
      reviewType
      placeUlid
      userUlid
      user {
        ulid
        name
        email
      }
    }
  }
`;

// unused
export const GET_RESPONSE_NOTIFICATION_RECIPIENT = gql`
  query getResponseNotificationRecipient($placeUlid: ID!, $ulid: ID!) {
    getResponseNotificationRecipient(placeUlid: $placeUlid, ulid: $ulid) {
      reviewType
      placeUlid
      userUlid
      user {
        ulid
        name
        email
      }
    }
  }
`;

export const SUGGEST_RESPONSE_NOTIFICATION_RECIPIENTS = gql`
  query suggestResponseNotificationRecipientUsers($placeUlid: ID!, $query: String!, $currentUserUlid: ID!) {
    suggestResponseNotificationRecipientUsers(placeUlid: $placeUlid, query: $query, currentUserUlid: $currentUserUlid) {
      ulid
      name
      email
    }
  }
`;

export const LIST_RESPONSE_ASSIGNEES = gql`
  query listResponseAssignees($orgUlid: ID!) {
    listResponseAssignees(orgUlid: $orgUlid) {
      userUlid
      placeUlids
      places {
        name
        ulid
      }
      user {
        name
        ulid
        email
      }
    }
  }
`;

export const GENERATE_RESPONSE_MESSAGES = gql`
  query generateResponseMessage(
    $sentiment: String!
    $locationName: String!
    $customerName: String!
    $channelName: String!
  ) {
    generateResponseMessage(
      sentiment: $sentiment
      locationName: $locationName
      customerName: $customerName
      channelName: $channelName
    ) {
      content
    }
  }
`;
