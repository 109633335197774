import React from 'react';
import { useModal } from 'react-modal-hook';
import nl2br from 'react-nl2br';
import { ResponseTemplate as ResponseTemplateType } from '@friendemic/erc-graph';
import { Formik, FormikProps } from 'formik';
import styled from 'styled-components/macro';
import { useCreateTemplate, useDeleteTemplate, useEditTemplate, useListTemplates } from 'src/async';
import { Row, Column, Modal, AccordionList, Button, List } from 'src/components';
import vars from 'src/styles/variables';
import { toast } from 'src/utils';
import { templateVarsToRender } from 'src/utils/templateVars';
import { LoadingSubsectionPlaceholder, SettingsSubsection } from 'src/views/Settings/settings.styled';
import { LocationSettingsRouteParams } from 'src/views/Settings/SettingsTypes';
import { buildValidationSchema, RenderForm, FormValues } from './ResponseTemplateForm';

const VariablesModalRow = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 0.35rem 0 !important;
  font-size: ${vars.$font_size_sm};

  &:first-of-type {
    font-weight: bold;
    pointer-events: none;
    span {
      color: ${vars.$gray_9} !important;
    }
  }

  span:last-of-type {
    color: ${vars.$greenlight};
    text-align: right;
  }
`;

const EmptyAccordionListItem = styled.div`
  padding: 1.5rem 0;
  text-align: center;
`;

const emptyFormValues = {
  ulid: '',
  placeUlid: '',
  title: '',
  body: '',
};

type ResponseTemplateProps = {
  params: LocationSettingsRouteParams;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

const ResponseTemplate = (props: ResponseTemplateProps): React.ReactElement => {
  const { params, setSaving } = props;

  const { data: responseTemplatesData = [], isLoading } = useListTemplates(params.location);

  const [invalidVariables, setInvalidVariables] = React.useState<string[]>([]);
  const [activeEditingTemplate, setActiveEditingTemplate] = React.useState<ResponseTemplateType>(emptyFormValues);
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  const { mutateAsync: createTemplate, isLoading: isCreateTemplateLoading } = useCreateTemplate();
  const { mutateAsync: editTemplate, isLoading: isEditTemplateLoading } = useEditTemplate();
  const { mutateAsync: deleteTemplate, isLoading: isDeleteTemplateLoading } = useDeleteTemplate();

  React.useEffect(() => {
    setSaving(isCreateTemplateLoading || isEditTemplateLoading || isDeleteTemplateLoading);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isCreateTemplateLoading, isEditTemplateLoading, isDeleteTemplateLoading]);

  async function handleAddTemplate(values: FormValues) {
    const { title, body } = values;
    await createTemplate(
      {
        placeUlid: props.params.location,
        input: {
          title: title,
          body: body,
        },
      },
      {
        onSuccess: () => {
          hideTemplateModal();
        },
        onError: (err) => {
          console.error(err);
          toast({ type: 'error', message: 'Something went wrong, please try again.' });
        },
      }
    );
  }

  async function handleEditTemplate(values: FormValues, templateUlid: string) {
    const { title, body } = values;

    await editTemplate(
      {
        placeUlid: props.params.location,
        templateUlid: templateUlid,
        input: {
          title: title,
          body: body,
        },
      },
      {
        onSuccess: () => {
          hideTemplateModal();
        },
        onError: (err) => {
          console.error(err);
          toast({ type: 'error', message: err.response?.data?.message || err.message });
        },
      }
    );
  }

  async function handleDeleteTemplate(values: FormValues, templateUlid: string) {
    await deleteTemplate(
      {
        placeUlid: props.params.location,
        templateUlid: templateUlid,
      },
      {
        onSuccess: () => {
          toast({ type: 'error', message: `Deleted "${values.title}" template` });
          hideTemplateModal();
        },
        onError: (err) => {
          console.error(err);
          toast({ type: 'error', message: err.response?.data?.message || err.message });
        },
      }
    );
  }

  const [showTemplateModal, hideTemplateModal] = useModal(() => {
    const addNew = activeEditingTemplate === emptyFormValues;

    return (
      <Formik
        initialValues={{
          title: activeEditingTemplate.title,
          body: activeEditingTemplate.body,
        }}
        validationSchema={buildValidationSchema(invalidVariables, setInvalidVariables)}
        onSubmit={(values) => {
          addNew ? handleAddTemplate(values) : handleEditTemplate(values, activeEditingTemplate.ulid);
        }}
      >
        {(props: FormikProps<FormValues>) => {
          const { submitForm, values, isSubmitting } = props;

          return (
            <Modal
              hideModal={hideTemplateModal}
              title="Template Variables"
              icon="brackets-curly"
              compact
              footer
              cancelable="Close"
              submitDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              submittable={addNew ? 'Add' : 'Save'}
              FooterLeftComponent={() => {
                return !addNew ? (
                  <Button
                    danger
                    onClick={() => {
                      if (!confirmDelete) {
                        setConfirmDelete(true);
                      } else {
                        setConfirmDelete(false);
                        return handleDeleteTemplate(values, activeEditingTemplate.ulid);
                      }
                    }}
                    icon="trash-alt"
                  >
                    {confirmDelete ? 'Are you sure?' : 'Delete'}
                  </Button>
                ) : (
                  <span></span>
                );
              }}
              onClose={() => {
                setConfirmDelete(false);
                setActiveEditingTemplate(emptyFormValues);
                setInvalidVariables([]);
              }}
              handleSubmit={() => {
                submitForm();
              }}
            >
              <RenderForm />
              <List>
                <>
                  <VariablesModalRow>
                    <span>{'Description'}</span>
                    <span>{'Variables'}</span>
                  </VariablesModalRow>
                  {templateVarsToRender.map((variable) => {
                    return (
                      <VariablesModalRow
                        key={variable.label}
                        /**
                         ** May want this later -- it may end up being requested.
                         ** - Click row to append variable to END of input value.
                         ** - Would need extra magic to insert at cursor position.
                         *
                         * onClick={() => {
                         *   props.setFieldValue('body', (props.values.body += variable.value));
                         * }}
                         */
                      >
                        <span>{variable.label}</span>
                        <span>{variable.value}</span>
                      </VariablesModalRow>
                    );
                  })}
                </>
              </List>
            </Modal>
          );
        }}
      </Formik>
    );
  }, [responseTemplatesData, activeEditingTemplate, invalidVariables, confirmDelete]);

  if (isLoading) return <LoadingSubsectionPlaceholder />;

  return (
    <SettingsSubsection>
      <Row>
        <Column>
          <AccordionList
            title="Response Template"
            icon="reply-all"
            titlePadding="0 0 1rem"
            action={{
              onClick: async () => {
                await setActiveEditingTemplate(emptyFormValues);
                return showTemplateModal();
              },
              icon: 'plus',
              content: 'Add',
            }}
          >
            {responseTemplatesData.length ? (
              responseTemplatesData.map((template) => {
                return (
                  <AccordionList.Item title={template.title} id={template.ulid} key={template.ulid}>
                    <>
                      <p>{nl2br(template.body)}</p>
                      <Button
                        mb="4"
                        secondary
                        onClick={async () => {
                          await setActiveEditingTemplate(template);
                          return showTemplateModal();
                        }}
                        icon="edit"
                      >
                        {'Edit'}
                      </Button>
                    </>
                  </AccordionList.Item>
                );
              })
            ) : (
              <EmptyAccordionListItem>{'There are no Response Templates to display'}</EmptyAccordionListItem>
            )}
          </AccordionList>
        </Column>
      </Row>
    </SettingsSubsection>
  );
};

export default ResponseTemplate;
