import {
  EDIT_RESPONSE_PLACE_PROFILE_CHANNEL,
  LIST_RESPONSE_PLACE_PROFILE_CHANNELS,
  DELETE_RESPONSE_CHANNEL,
} from 'src/graph';
import { useGraphMutation } from 'src/hooks';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useGraphMutations(location: string) {
  const { mutationMethod: editResponseChannel, loading: editResponseChannelLoading } = useGraphMutation({
    mutation: EDIT_RESPONSE_PLACE_PROFILE_CHANNEL,
    options: {
      refetchQueries: [{ query: LIST_RESPONSE_PLACE_PROFILE_CHANNELS, variables: { placeUlid: location } }],
    },
    errorType: 'warn',
  });
  const { mutationMethod: deleteResponseChannel, loading: deleteResponseChannelLoading } = useGraphMutation({
    mutation: DELETE_RESPONSE_CHANNEL,
    options: {
      refetchQueries: [{ query: LIST_RESPONSE_PLACE_PROFILE_CHANNELS, variables: { placeUlid: location } }],
    },
    errorType: 'warn',
  });
  return {
    editResponseChannel,
    deleteResponseChannel,
    editResponseChannelLoading,
    deleteResponseChannelLoading,
  };
}
