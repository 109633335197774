import React from 'react';
import { useQuery } from '@apollo/client';
import { SessionUser } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/session_user_pb';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import queryString from 'query-string';
import { ErrorBlock, PageLoading, PageWrapper, ContentWrapper } from 'src/components';
import { CurrentUser, useStateValue } from 'src/context';
import * as types from 'src/context/actionTypes';
import { GET_CURRENT_USER } from 'src/graph';
import { buildFullStoryUserVars } from 'src/utils/fullStoryUtils';
import { friendlyUserRole } from './helpers';

const { SessionUserRoleType } = SessionUser;

export function ProtectedTemplate(props: { children: React.ReactElement }): React.ReactElement {
  const { children } = props;

  return (
    <PageWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </PageWrapper>
  );
}

/**
 * ApperoverRoute manages:
 * - Global state
 * - Sentry user & context
 * - FullStory user
 * - Final rendering of the route component
 *
 */
function approverRoute() {
  return <P extends Record<string, unknown>>(Component: React.FunctionComponent<P>): any => {
    /**
     * RenderApproverRoute sets state and does final rendering of the route's component after authorization
     *
     */
    const RenderApproverRoute = (props: any): React.ReactElement => {
      const [{ currentUser }, dispatch] = useStateValue();
      const { token, authError, ...rest } = props;
      const [authorized, setAuthorized] = React.useState(false);
      const { loading, error, data } = useQuery<{ currentSessionUser: CurrentUser }>(GET_CURRENT_USER, {
        skip: authError || !token,
      });

      /**
       * Set local state if <AuthenticateRouteWrapper> has recieved a valid token
       *
       */
      React.useEffect(() => {
        if (token) {
          setAuthorized(true);
        }
        if (authError || error) {
          setAuthorized(false);
        }
      }, [token, authError, error]);

      React.useEffect(() => {
        if (data && data.currentSessionUser && !currentUser) {
          const {
            currentSessionUser: { userRole, user, responseOrganization },
          } = data;

          /**
           * Set global state values
           *
           */
          responseOrganization &&
            dispatch({
              type: types.SET_ACTIVE_ORGANIZATION,
              organization: responseOrganization,
            });
          dispatch({
            type: types.SET_USER_ROLE,
            userRoles: {
              isAdmin: userRole === SessionUserRoleType.SYSTEMADMIN,
              isOrganizationManager: userRole === SessionUserRoleType.ORGANIZATIONMANAGER,
              isResponseUser: userRole === SessionUserRoleType.RESPONSEUSER,
              isApprover: userRole === SessionUserRoleType.APPROVER,
            },
          });
          dispatch({
            type: types.SET_USER,
            currentUser: data.currentSessionUser,
          });

          /**
           * Sentry.setUser
           * - Check if user object is available and set user data for sentry error reporting
           *
           */
          user &&
            Sentry.setUser({
              email: user.email,
              username: user.name,
              id: user.ulid,
            });

          /**
           * Sentry.setContext
           * - Check if user object is available and set userRole for sentry error reporting
           *
           */
          user && Sentry.setContext('User Role', { Value: userRole, Name: friendlyUserRole(userRole) });

          /**
           * FullStory.identify User
           * - Check if FullStory is initialized and user object is available
           *
           */
          user &&
            FullStory.isInitialized() &&
            FullStory.identify(user.ulid, buildFullStoryUserVars(data.currentSessionUser));
        }

        // No need to rerender when currentUser is set
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [data]);

      /**
       * If
       *  - User is authorized
       * Then
       * - Render the route's component
       * Else
       * - Return a not authorized message
       *
       */
      if (authorized && !loading && data) {
        return (
          <ProtectedTemplate>
            <Component {...rest} />
          </ProtectedTemplate>
        );
      } else if (authError || error) {
        return <ErrorBlock error={authError || error} />;
      }

      return <PageLoading />;
    };

    /**
     * Skip standard AuthWrapper for approvers.
     * - Tests to see if a valid token is present in the URL and moves on
     *
     */
    const AuthenticateRouteWrapper = (): React.ReactElement => {
      const parsed = queryString.parse(location.search);
      const { token } = parsed;

      let error: null | string = null;

      if (!token) {
        error = 'Authentication token required!';
      }

      return <RenderApproverRoute token={token} authError={error} />;
    };

    return AuthenticateRouteWrapper;
  };
}

export default approverRoute;
