import React, { SyntheticEvent, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import vars from 'src/styles/variables';
import { spacesToUnderscores, useUrl } from 'src/utils';
import Button from '../Button';

export const RemoveItemButton = styled(Button)`
  font-size: 100%;
  margin-right: -${vars.$spacing_small};
  margin-left: 0.25rem;
  width: 26px;
  height: 26px;
  background-color: ${vars.$greenlight};
  color: white;
  &:hover {
    color: ${vars.$secondary};
  }
`;

const OrganizationItemWrapper = styled.div<{ placeIsSelected?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${vars.$greenlight};
  min-height: 1.625rem;
  padding: 1rem 1rem 1rem 1.5rem;
  ${({ placeIsSelected }) =>
    placeIsSelected &&
    css`
      &:hover {
        ${RemoveItemButton} {
          background-color: white;
          color: ${vars.$greenlight} !important;
        }
      }
    `}
`;

const OrganizationItemLeft = styled.div``;
const OrganizationItemRight = styled.div`
  height: 26px;
`;

export const OrganizationItemComp = (props: Record<string, any>): JSX.Element | null => {
  const {
    data: { name, placeName, responsePlacesCount, placeUlid, responseOrganizationUlid },
    onClick,
  } = props;
  const { filtersOptions, queryOptions: qs, location, history, queryString } = useUrl();

  const [placeIsSelected, setPlaceIsSelected] = React.useState(false);

  const itemName = React.useMemo<string>(() => {
    return name || placeName || 'UNKNOWN';
  }, [name, placeName]);

  /**
   * If responseOrganizationsUlid, then we're working with a place
   */
  React.useEffect(() => {
    Boolean(responseOrganizationUlid) && filtersOptions.placeUlid?.find((ulid: any) => ulid === placeUlid)
      ? setPlaceIsSelected(true)
      : setPlaceIsSelected(false);
  }, [filtersOptions.placeUlid, placeUlid, responseOrganizationUlid]);

  const deselect = (item: Record<string, any>) => {
    const { placeUlid: queryPlacesId = [] } = filtersOptions;
    const { places: queryPlaces, ...rest } = qs;
    const index = (queryPlacesId as string[]).findIndex((id: string) => item.placeUlid === id);
    let newQs = {
      ...qs,
    };
    if (index > -1) {
      if (queryPlaces instanceof Array) {
        (queryPlaces as string[]).splice(index, 1);
        newQs.places = queryPlaces;
      } else {
        newQs = {
          ...rest,
        };
      }
      newQs.changed = 'true';
      history.push(
        `${location.pathname}${
          Object.keys(newQs).length ? `?${queryString.stringify(newQs, { arrayFormat: 'comma' })}` : ''
        }`
      );
    }
  };

  const select = (item: Record<string, any>) => {
    const { placeUlid = [] } = filtersOptions;
    const { places: queryPlaces } = qs;
    const isExist: string | undefined = (placeUlid as string[]).find((id: string) => item.placeUlid === id);
    if (!isExist) {
      const newQs = {
        ...qs,
      };

      if (queryPlaces instanceof Array) {
        (queryPlaces as string[]).push(`${spacesToUnderscores(item.placeName)}__${item.placeUlid}`);
        newQs.places = queryPlaces;
      } else {
        newQs.places = [queryPlaces as string, `${spacesToUnderscores(item.placeName)}__${item.placeUlid}`];
      }
      newQs.changed = 'true';
      history.push(
        `${location.pathname}${
          Object.keys(newQs).length ? `?${queryString.stringify(newQs, { arrayFormat: 'comma' })}` : ''
        }`
      );
    }
  };

  const clickHandler = useCallback(
    (e: SyntheticEvent, item: Record<string, any>) => {
      const { placeUlid: places = [] } = filtersOptions;
      const isChecked = places.find((ulid: any) => ulid === item.placeUlid) !== undefined;
      if (isChecked && Boolean(responseOrganizationUlid)) {
        deselect(item);
      } else if (!isChecked && Boolean(responseOrganizationUlid)) {
        select(item);
      }
      onClick && onClick(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search]
  );

  return (
    <>
      <OrganizationItemWrapper placeIsSelected={placeIsSelected} onClick={(e) => clickHandler(e, props.data)}>
        <OrganizationItemLeft>
          <span>{itemName}</span>
        </OrganizationItemLeft>
        <OrganizationItemRight>
          {responsePlacesCount && <div>{responsePlacesCount || 0}</div>}
          {placeIsSelected && <RemoveItemButton btnSm transparent secondary iconOnly icon="times" title="Close" />}
        </OrganizationItemRight>
      </OrganizationItemWrapper>
    </>
  );
};
