import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { initialState as importedState, appReducer, fixtureState, Actions, AppStateType } from './appReducer';

interface StateProviderArgs {
  reducer?: (state: Partial<AppStateType>, action: Partial<Actions>) => AppStateType;
  initialState?: any;
  children: React.ReactNode | React.ReactNode[];
  isStorybook?: boolean;
}

const StateContext = createContext({});

export function ERCStateProvider<T>({
  reducer = appReducer as (state: Partial<AppStateType>, actions: Partial<Actions>) => AppStateType,
  initialState = importedState,
  children,
  isStorybook,
}: StateProviderArgs): JSX.Element {
  const [state, dispatch]: [Partial<AppStateType>, Dispatch<any>] = useReducer(
    (state: T, action: T) => {
      return reducer(state, action);
    },
    isStorybook ? fixtureState : initialState
  );

  return <StateContext.Provider value={[state, dispatch]}>{children}</StateContext.Provider>;
}

export const useStateValue = (): [AppStateType, Dispatch<Actions>] => {
  return useContext(StateContext) as [AppStateType, Dispatch<Actions>];
};
