import React from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useCreateResponseOrganization } from 'src/async/responseOrganizations';
import { InputElement, Row, Column, Modal } from 'src/components';
import { toast } from 'src/utils';

export type FormValues = typeof initialValues;

const initialValues = {
  organizationName: '',
};

const AddOrganizationModal = (props: { hideModal: () => void }): React.ReactElement => {
  const { hideModal } = props;
  const { mutateAsync: createResponseOrganization } = useCreateResponseOrganization();

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          organizationName: Yup.string().required('Required'),
        })}
        onSubmit={async (values) => {
          await createResponseOrganization(
            {
              name: values.organizationName,
            },
            {
              onSuccess: () => {
                toast({ type: 'success', message: `Added Organization ${values.organizationName}` });
                hideModal();
              },
              onError: (err) => {
                toast({ type: 'error', message: err.response?.data?.message || err.message });
                hideModal();
              },
            }
          );
        }}
      >
        {(props: FormikProps<FormValues>) => {
          const { submitForm, isSubmitting } = props;
          return (
            <Modal
              title={'Add Organization'}
              icon={'plus'}
              footer
              compact
              submitDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              submittable="Add Organization"
              hideModal={hideModal}
              handleSubmit={() => {
                submitForm();
              }}
            >
              <Row>
                <Column>
                  <InputElement
                    border
                    label="Organization Name"
                    name="organizationName"
                    placeholder="Organization Name"
                  />
                </Column>
              </Row>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export default AddOrganizationModal;
