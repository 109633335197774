import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

/**
 * -------------------
 * RATING STARS
 * -------------------
 */
export const RatingStarsContainer = styled.div`
  width: 65px;
  height: 10px;
  display: flex;
  align-items: center;
`;

export const StarsContainer = styled.div`
  position: relative;
  font-size: 10px;
  line-height: 10px;
  height: 10px;
  width: 100%;
`;

export const Slider = styled.span`
  display: flex;
  justify-content: space-between;
  width: 65px;
  height: 10px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  width: ${(props: { percentage: string }) => {
    return props.percentage;
  }};
  height: 10px;
  overflow: hidden;
`;

interface InnerContainerProps {
  negative?: boolean;
  grey?: boolean;
  active?: boolean;
}

export const InnerContainer = styled.span<InnerContainerProps>`
  display: flex;
  justify-content: space-between;
  width: 65px;
  height: 10px;

  .icon {
    color: ${(props) => {
      return props.active ? 'white' : props.negative ? '#eb6565' : props.grey ? '#dddddd' : '#eedf35';
    }};
    margin: 0;
    width: initial;
  }

  svg {
    color: ${(props) => {
      return props.active ? 'white' : props.negative ? '#eb6565' : props.grey ? '#dddddd' : '#eedf35';
    }};
    margin: 0;
    width: initial;
  }
`;

const RatingStars = (props: { rating: number; active?: boolean }): ReactElement => {
  const { rating, active } = props;

  const percentage = (rating / 5) * 100;
  const negative = rating <= 3;

  return (
    <RatingStarsContainer>
      <StarsContainer>
        <Slider percentage={`${percentage}%`}>
          <InnerContainer negative={negative} active={active} data-cy="star-rating">
            <FontAwesomeIcon icon="star" />
            <FontAwesomeIcon icon="star" />
            <FontAwesomeIcon icon="star" />
            <FontAwesomeIcon icon="star" />
            <FontAwesomeIcon icon="star" />
          </InnerContainer>
        </Slider>
        <InnerContainer grey>
          <FontAwesomeIcon icon="star" />
          <FontAwesomeIcon icon="star" />
          <FontAwesomeIcon icon="star" />
          <FontAwesomeIcon icon="star" />
          <FontAwesomeIcon icon="star" />
        </InnerContainer>
      </StarsContainer>
    </RatingStarsContainer>
  );
};

export default RatingStars;
