import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import { Plugin } from 'tippy.js';

import 'tippy.js/dist/tippy.css';

export interface TooltipProps extends TippyProps {
  content: React.ReactNode;
  children: any;
}

/**
 * FullStory plugin for Tippy.js
 * - Attributes added by @fullstory/babel-plugin-annotate-react
 */
const plugins: Plugin[] = [
  {
    name: 'data-source-file',
    defaultValue: '',
    fn: () => ({}),
  },
  {
    name: 'data-component',
    defaultValue: '',
    fn: () => ({}),
  },
  {
    name: 'data-element',
    defaultValue: '',
    fn: () => ({}),
  },
];

/**
 * Uses Tippy.js, the official tooltip library of the _positioning_ library PopperJS
 * - From the [popper docs](https://popper.js.org/docs/v2/tippy/):
 * - > _Remember, Popper is not a tooltip library, it's the foundation to build one! If what you are looking for is a ready to use tooltip lib, take a look at Tippy.js.
 * - All of the [tippy props](https://atomiks.github.io/tippyjs/v6/all-props/) are available on this component, even though they're not visibile in the ArgsTable.
 */
const Tooltip: React.FC<TooltipProps> = (props: TooltipProps): React.ReactElement<TooltipProps> => {
  const { content, children, ...rest } = props;

  const options = {
    ...rest,
  };

  return (
    <Tippy
      plugins={plugins}
      content={content || 'This is a tooltip!'}
      placement={options.placement || 'right'}
      {...options}
    >
      <span>{children}</span>
    </Tippy>
  );
};

export default Tooltip;
