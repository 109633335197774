import React from 'react';
import { ResponsePlaceProfileChannel } from '@friendemic/erc-graph';
import channel_type_friendly from '@friendemic/friendemic-api-node/lib/friendemic/api/response/channel_type_friendly';
import Tippy from '@tippyjs/react';
import { FormikProps } from 'formik';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { Row, Column, InputElement, AutoSave, AccordionList, ChannelPill, Button, Pill } from 'src/components';
import vars from 'src/styles/variables';
import { InputElementFormConfig } from 'src/types';
import { selectOptions, toast, updateGraphMutation } from 'src/utils';
import { useGraphMutations } from './useGraphMutations';

export type FormValues = typeof initialFormValues;

export const initialFormValues = {
  active: false,
  feedbackType: 0,
  reviewType: 0,
};

export const validationSchema = Yup.object({
  feedbackType: Yup.number().required('Required'),
  reviewType: Yup.number().required('Required'),
});

export const formConfig: InputElementFormConfig = {
  active: {
    name: 'active',
    type: 'toggle',
    noValidation: true,
  },
  feedbackType: {
    name: 'feedbackType',
    label: 'Feedback Type',
    type: 'select',
    border: true,
    options: selectOptions.FeedbackType,
  },
  reviewType: {
    name: 'reviewType',
    label: 'Review Type',
    type: 'select',
    border: true,
    options: selectOptions.ReviewType,
  },
};
const StyledTippy = styled(Tippy)``;

const ActionsWrapper = styled.div`
  display: flex;
  > span {
    &:first-of-type {
      ${Button} {
        padding-left: 0;
      }
    }
    &:last-of-type {
      ${Button} {
        padding-right: 0;
      }
    }
    ${Button} {
      border: 0;
      transition: 150ms;
      color: ${vars.$gray_9};
      > svg {
        font-size: 100%;
      }
      &:hover {
        color: ${vars.$gray_7};
      }
      &:active {
        color: ${vars.$gray_b};
      }
    }
  }
`;

export const RenderForm = (
  props: FormikProps<FormValues> & { channelData: ResponsePlaceProfileChannel; placeUlid: string }
): React.ReactElement => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { deleteResponseChannel } = useGraphMutations(props.placeUlid);

  function handleClick(action: any) {
    if (action.action === 'delete') {
      handleClickDelete();
    } else {
      action.onClick();
    }
  }
  function handleClickDelete() {
    setIsDeleting(true);
  }

  async function handleClickConfirmDelete() {
    const response = await updateGraphMutation(
      {
        variables: {
          placeUlid: props.placeUlid,
          ulid: channelData.responseChannel?.ulid,
        },
        mutationName: 'deleteResponseChannel',
      },
      deleteResponseChannel
    );
    if (response) {
      if (response.data.deleteResponseChannel && response.data.deleteResponseChannel.errors) {
        console.info(JSON.stringify(response.data, null, 2));
        toast({ type: 'error', message: 'Something went wrong, please try again.' });
      } else {
        toast({ type: 'success', message: 'Response channel deleted successfully!' });
      }
    }
    setIsDeleting(false);
  }

  function handleClickDismiss() {
    setIsDeleting(false);
  }

  const actions = [
    {
      action: 'delete',
      icon: 'trash-alt',
      tooltip: 'Delete',
    },
  ];
  const { channelData } = props;
  const label = channel_type_friendly.ChannelTypeFriendly.toFriendly(channelData.channel) || 'UNKNOWN';

  const title = () => {
    return (
      <>
        <InputElement label={label} value={props.values.active} {...formConfig.active} />
      </>
    );
  };

  return (
    <AccordionList.Item
      preventOpen={!props.values.active}
      title={title()}
      id={channelData.harvestProfileUlid}
      key={channelData.harvestProfileUlid}
    >
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ChannelPill
            connected={true}
            channel={
              selectOptions.ChannelType.find((x) => {
                return x.label === label;
              }) || { label: 'UNKNOWN', value: 100 }
            }
          />
          <ActionsWrapper>
            {isDeleting ? (
              <Pill
                small
                dismissible
                icon="trash-alt"
                noSpacing
                danger
                onClick={handleClickConfirmDelete}
                onDismissClick={handleClickDismiss}
                data-cy="confirm-delete"
              />
            ) : (
              actions?.map((action, i) => {
                return (
                  <StyledTippy hideOnClick={false} key={i} content={action.tooltip} placement="top">
                    <span>
                      <Button
                        blank
                        icon={action.icon}
                        ml="1rem"
                        mr="0.3rem"
                        onClick={() => {
                          return handleClick(action);
                        }}
                        data-cy="delete"
                      />
                    </span>
                  </StyledTippy>
                );
              })
            )}
          </ActionsWrapper>
        </div>
        <Row mt={2}>
          <Column width={[1, 1 / 2]}>
            <InputElement {...formConfig.feedbackType} />
          </Column>
          <Column width={[1, 1 / 2]}>
            <InputElement {...formConfig.reviewType} />
          </Column>
          <AutoSave delay={300} />
        </Row>
      </>
    </AccordionList.Item>
  );
};
