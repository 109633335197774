import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Card, Column } from 'src/components';
import vars from 'src/styles/variables';

export const AssignedToOverlay = styled.div<{ isLoading: boolean; isAssigned: boolean }>`
  display: none; /* Display controlled by WriteResponseCardWrapper css */
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};

  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: ${vars.$border_radius};
  background-color: rgba(0, 0, 0, 0.055);
  cursor: pointer;

  transition: 150ms;
  padding: 1rem;
  font-size: 120%;

  z-index: 1;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    box-shadow: ${vars.$shadow_3};
  }
`;

export const WriteResponseCardWrapper = styled.div<{ hideOverlay: boolean }>`
  position: relative;
  ${({ hideOverlay }) =>
    /* if is NOT assigned, show overlay & reduce card opacity */
    !hideOverlay &&
    css`
      ${Card} {
        opacity: 0.65;
        *:not(button) {
          color: ${vars.$muted};
        }
      }
      ${AssignedToOverlay} {
        display: flex;
      }
    `};
`;

export const WriteResponseCardActionsWrapper = styled(Column)`
  @media (max-width: ${vars.$md}) {
    flex-direction: column;
    button:first-of-type {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }
`;
