import React from 'react';
import AnimateHeight from 'react-animate-height';
import _ from 'lodash';
import { InboxResponseTicket } from 'src/async/types';
import { Avatar, RatingStars } from 'src/components';
import { useStateValue } from 'src/context';
import * as types from 'src/context/actionTypes';
import { cache, GET_REVIEW_RESPONSE_TICKET } from 'src/graph';
import {
  getTicketStatusTypeFriendlyOverrides,
  getInitials,
  getChannelIcon,
  getFormatedDate,
  getHoursDiff,
  useUrl,
} from 'src/utils';
import {
  ReviewItemWrapper,
  ChannelIconWrapper,
  ReviewItemLeft,
  ReviewItemRight,
  TitleWrapper,
  Location,
  Customer,
  Overlay,
  DateWrapper,
  UpdatedFlag,
  StarsDateWrapper,
  ReviewItemTop,
  ReviewItemBottom,
  StatusCircle,
  StatusWrapper,
  StatusText,
} from './ReviewItem.styled';

export interface ReviewItemProps {
  ticket: InboxResponseTicket;
  locked?: boolean; // !FLAG is the locked state even in the spec anymore?
  active?: boolean;
  deleted?: boolean;
  updated?: boolean;
  show_bottom_bar?: boolean;
}

const ReviewItem = (props: ReviewItemProps): JSX.Element => {
  const { ticket, locked, active, show_bottom_bar = false } = props;
  const {
    reviewUlid,
    submittedAt,
    placeName = 'UNKNOWN',
    reviewCustomer,
    assigneeUserName,
    assigneeUlid,
    status,
    rating,
  } = ticket;
  const [{ activeTicket }, dispatch] = useStateValue();
  const isActive = active !== undefined ? active : activeTicket ? activeTicket.reviewUlid === reviewUlid : false;
  const [currentTicket, setCurrentTicket] = React.useState(ticket);
  const [updated, setUpdated] = React.useState(props.updated || false);
  const [deleted, setDeleted] = React.useState(props.deleted || false);
  const [remove, setRemove] = React.useState(false);
  const [height, setHeight] = React.useState<string | number>('auto');

  const hasFlag = updated || deleted;

  const hoursDiffBetweenThenAndNow = getHoursDiff(submittedAt as unknown as Date);
  const { queryOptions, queryString, history, location } = useUrl();
  const formattedTimeBetweenThenAndNow =
    hoursDiffBetweenThenAndNow >= 72
      ? getFormatedDate(submittedAt)
      : `${hoursDiffBetweenThenAndNow} ${hoursDiffBetweenThenAndNow <= 1 ? 'hour' : 'hours'}`;

  // * Update the UI when the ticket is Updated
  React.useEffect(() => {
    if (!_.isEqual(ticket, currentTicket) && ticket.title !== 'deleted') {
      setUpdated((prev) => !prev);
      const timer = setTimeout(() => {
        setUpdated((prev) => !prev);
        setCurrentTicket(ticket);
      }, 5000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  // * Update the UI if the ticket is Deleted
  React.useEffect(() => {
    // Check if the item's ticket object has been deleted by th deleted subscription
    if (ticket.title === 'deleted') {
      setDeleted(true);
      if (isActive) {
        // If the ticket is active, modify it's cache object to include the deleted flag
        cache.updateQuery(
          { query: GET_REVIEW_RESPONSE_TICKET, variables: { reviewUlid: activeTicket?.reviewUlid } },
          (data) => {
            // Update the cache object for the active ticket
            return _.merge({}, data, {
              getReviewResponseTicket: {
                review: {
                  title: 'deleted',
                },
              },
            });
          }
        );
      } else {
        const timer = setTimeout(() => {
          // Remove the ticket from the dom after setTimeout has finished
          setRemove(true);
          setHeight(0);
        }, 7500);
        return () => clearTimeout(timer);
      }
    }
  }, [ticket.title, activeTicket?.reviewUlid, isActive]);

  /**
   * Verify that one of the available values for channel is not null or undefined
   * Accounts for the value of channel being 0 (which is falsy)
   */
  const channel: number | 'UNKNOWN' = !_.isNil(ticket.channel) ? ticket.channel : 'UNKNOWN';

  return (
    <>
      {ticket ? (
        <AnimateHeight
          id={reviewUlid}
          duration={500}
          height={height} // set dynamically
        >
          <ReviewItemWrapper
            hasFlag={updated || deleted}
            deleted={deleted}
            updated={updated}
            active={isActive}
            remove={remove}
            locked={locked || deleted}
            data-cy="review-item"
            onClick={() => {
              queryOptions.reviewUlid = reviewUlid as string;
              dispatch({
                type: types.SET_ACTIVE_TICKET,
                ticket: ticket,
              });
              delete queryOptions.changed;
              history.push(`${location.pathname}?${queryString.stringify(queryOptions, { arrayFormat: 'comma' })}`);
            }}
          >
            {(locked || deleted) && <Overlay />}
            {hasFlag && (
              <UpdatedFlag hasFlag={hasFlag} updated={updated} deleted={deleted}>
                <span>{updated ? 'updated' : deleted ? 'deleted' : ''}</span>
              </UpdatedFlag>
            )}
            <ReviewItemTop>
              <ReviewItemLeft>
                <ChannelIconWrapper withAvatar={Boolean(assigneeUlid)} channel={channel}>
                  {getChannelIcon(channel, isActive, deleted)}
                  {assigneeUlid && <Avatar channelIconOverlay>{getInitials(assigneeUserName)}</Avatar>}
                </ChannelIconWrapper>
              </ReviewItemLeft>
              <ReviewItemRight>
                <TitleWrapper>
                  <Location>{placeName}</Location>
                </TitleWrapper>
                <Customer>{reviewCustomer}</Customer>
                <StarsDateWrapper>
                  <RatingStars active={isActive} rating={rating || 0} />
                  {!show_bottom_bar && <DateWrapper>{`${formattedTimeBetweenThenAndNow} ago`}</DateWrapper>}
                </StarsDateWrapper>
              </ReviewItemRight>
            </ReviewItemTop>
            {show_bottom_bar && (
              <ReviewItemBottom ticketStatusType={status}>
                <StatusWrapper>
                  <StatusCircle ticketStatusType={status} />
                  <StatusText>{getTicketStatusTypeFriendlyOverrides(status)}</StatusText>
                </StatusWrapper>
                <DateWrapper>{`${formattedTimeBetweenThenAndNow} ago`}</DateWrapper>
              </ReviewItemBottom>
            )}
          </ReviewItemWrapper>
        </AnimateHeight>
      ) : null}
    </>
  );
};

export default ReviewItem;
