import React from 'react';
import { PlaceSuggestion } from '@friendemic/erc-graph';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { suggestResponseOrganizationPlaces, useCreateResponsePlace } from 'src/async';
import {
  SelectCustomMenu,
  SelectCustomOption,
  SelectCustomItem,
  InputElement,
  Row,
  Column,
  Modal,
} from 'src/components';
import { toast } from 'src/utils';

export type FormValues = typeof initialValues;

const initialValues = {
  place_ulid: '',
};

const AddLocationModal = (props: { hideModal: () => void; responseOrganizationUlid: string }): React.ReactElement => {
  const { hideModal, responseOrganizationUlid } = props;
  const { mutateAsync: createResponsePlace } = useCreateResponsePlace();
  const [selectHasThreeLines, setSelectHasThreeLines] = React.useState(false);
  const [noResultsMessage, setNoResultsMessage] = React.useState('');

  function fetchPlaces(query: string) {
    if (!query) {
      setNoResultsMessage('');
      return [];
    }

    return suggestResponseOrganizationPlaces({ responseOrganizationUlid, query }).then((places) => {
      if (places.length === 0) {
        setNoResultsMessage(`No results found for "${query}"`);
        return [];
      }

      if (places) {
        setNoResultsMessage('');
        return places.map((item: PlaceSuggestion) => {
          const { name, address, organizationName, ulid: value } = item;
          const label = SelectCustomItem({
            primaryLabel: name,
            secondaryLabel: address,
            tertiaryLabel: organizationName,
          });
          return { label, value };
        });
      }
      return [];
    });
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          place_ulid: Yup.string().required('Required'),
        })}
        onSubmit={async (values) => {
          await createResponsePlace(
            {
              placeUlid: values.place_ulid,
              responseOrganizationUlid: props.responseOrganizationUlid,
            },
            {
              onSuccess: (res) => {
                const { data } = res;
                const { placeName } = data;
                toast({
                  type: 'success',
                  message: `Added Location ${placeName ? placeName : values.place_ulid}`,
                });
                hideModal();
              },
              onError: (err) => {
                toast({ type: 'error', message: err.response?.data?.message || err.message });
                hideModal();
              },
            }
          );
        }}
      >
        {(props: FormikProps<FormValues>) => {
          const { submitForm, isSubmitting } = props;
          return (
            <Modal
              title={'Add Location'}
              icon={'plus'}
              footer
              compact
              overflowVisible
              submittable="Add Location"
              hideModal={hideModal}
              submitDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              handleSubmit={() => {
                submitForm();
              }}
            >
              <Row>
                <Column>
                  <InputElement
                    type="select-async"
                    name="place_ulid"
                    border
                    label="Place Name"
                    placeholder="Select A Place..."
                    moreSelectSpacing={selectHasThreeLines}
                    loadOptions={fetchPlaces}
                    components={{ SelectCustomMenu, SelectCustomOption }}
                    noResultsMessage={noResultsMessage}
                    handleSelectOnChange={async (option: { [key: string]: string }) => {
                      if (option !== null) {
                        // add spacing to select with more than two label children
                        const castLabel = option.label as unknown as JSX.Element;
                        const labelChildren = castLabel.props.children.filter(Boolean);
                        setSelectHasThreeLines(labelChildren.length > 2);
                      } else {
                        setSelectHasThreeLines(false);
                      }
                    }}
                  />
                </Column>
                {/* <Column width={[1, 1 / 2]}>
                  <InputElement border type="dateInput" label="Start Date" name="startDate" placeholder="Start Date" />
                </Column> */}
              </Row>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export default AddLocationModal;
