import React from 'react';
import styled from 'styled-components/macro';
import { vars } from 'src/styles/variables';

export const FilterDropdownHelperText = styled.div`
  font-size: ${vars.$font_size_extra_sm};
  padding: 0.5rem 2rem 0.5rem 1rem;
  box-shadow: inset 0 -1px 0 #f0f0f0;
  background-color: ${vars.$white};
`;

export const FilterDropdownWrapper = styled.div<{ isOpen?: boolean }>`
  position: relative;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  pointer-events: ${(props) => (props.isOpen ? 'inherit' : 'none')};
  width: 100%;
  & > div:first-child {
    position: absolute;
    display: block;
    right: ${vars.$spacing_extra_small};
    left: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    margin-left: auto;
    height: 100%;
    z-index: 2;
  }
  .toggle_accordion {
    background: ${vars.$gray_f4};
    padding: ${vars.$spacing_small} 0px ${vars.$spacing_small} ${vars.$spacing};
    border-radius: ${vars.$border_radius};
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: ${vars.$font_size_sm};
    &[aria-expanded='false'] {
      background: ${vars.$gray_f4};
    }
    &:hover,
    &[aria-expanded='true'] {
      background: ${vars.$gray_e};
    }
    & > svg {
      font-size: ${vars.$font_size_extra_extra_sm};
    }
  }
  .toggle_accordion:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  & form {
    -webkit-box-shadow: ${vars.$shadow_light};
    box-shadow: ${vars.$shadow_light};
    background-color: ${vars.$white};
    border-radius: ${vars.$border_radius};
    max-height: 90vh;
    overflow: auto;
    & > div {
      border-bottom: none;
    }
  }
`;
export const FilterItem = styled.div<{ rating?: boolean }>`
  background-color: ${vars.$white};
  padding: ${vars.$spacing_small} ${vars.$spacing};
  margin: 0px 2px ${vars.$spacing_extra_small} 2px;
  display: flex;
  &,
  & * {
    cursor: pointer;
  }
  & > label {
    & > label {
      padding-left: 2rem;
      &::before {
        top: ${(props) => (props.rating ? '-14px' : '1px')};
      }
    }
  }

  &:hover {
    background-color: ${vars.$greenlight};
    border-radius: ${vars.$border_radius};
    -webkit-box-shadow: ${vars.$shadow_dark};
    box-shadow: ${vars.$shadow_dark};
    label {
      color: ${vars.$white};
    }
  }
  &:hover > label > label::before {
    background: ${vars.$green};
    border-color: ${vars.$green};
  }
  & > span {
    font-size: ${vars.$font_size_sm};
    svg {
      padding-right: 0.1rem;
    }
  }
  &:last-child {
    border-bottom-right-radius: ${vars.$border_radius};
    border-bottom-left-radius: ${vars.$border_radius};
  }
`;
