import React from 'react';
import { FormikProps } from 'formik';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { Row, Column, InputElement, Checkbox, AutoSave } from 'src/components';
import vars from 'src/styles/variables';
import { InputElementFormConfig } from 'src/types';
import { selectOptions } from 'src/utils';

export type FormValues = typeof initialFormValues;

export const initialFormValues = {
  require_type: 0,
  approve_after_24_hours: false,
  approvals_needed_for: 0,
  response_limit: 0,
  show: false,
  body: '',
};

export const validationSchema = Yup.object({
  approvals_needed_for: Yup.number().required('Required'),
  require_type: Yup.number().required('Required'),
});

export const formConfig: InputElementFormConfig = {
  require_type: {
    type: 'select',
    border: true,
    name: 'require_type',
    label: 'Approves Reviews',
    options: selectOptions.ApprovalRequiredType,
  },
  approve_after_24_hours: {
    type: 'checkbox',
    name: 'approve_after_24_hours',
    label: 'Automatic Approval',
    checkboxlabel: 'Auto-approve after 24 hours',
  },
  feedbackType: {
    name: 'approvals_needed_for',
    label: 'Approvals Needed For',
    type: 'select',
    border: true,
    options: selectOptions.FeedbackType,
  },
};

const CheckboxWithSpacing = styled.div`
  ${Checkbox} {
    @media (min-width: ${vars.$md}) {
      margin-top: 0.5rem;
    }
  }
`;

export const RenderForm = (props: FormikProps<FormValues>): React.ReactElement => {
  const [currentApprovalRequiredValue, setCurrentApprovalRequiredValue] = React.useState<any>();

  return (
    <Row>
      <Column width={[1, 1 / 2]} data-cy="approvalSettingsApproversDropdown">
        <InputElement
          value={currentApprovalRequiredValue}
          handleSelectOnChange={(option) => {
            setCurrentApprovalRequiredValue(option);
          }}
          {...formConfig.require_type}
        />
      </Column>
      <Column width={[1, 1 / 2]} data-cy="approvalSettingsApprovalsFor">
        <InputElement {...formConfig.feedbackType} />
      </Column>
      <Column width={[1]}>
        <CheckboxWithSpacing>
          <InputElement {...formConfig.approve_after_24_hours} />
        </CheckboxWithSpacing>
      </Column>
      <AutoSave />
    </Row>
  );
};
