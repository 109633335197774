import React from 'react';
import styled, { css } from 'styled-components/macro';
import vars from 'src/styles/variables';

export type AvatarProps = {
  channelIconOverlay?: boolean;
  shadowColor?: string;
  backgroundColor?: string;
  color?: string;
};

const Avatar = styled.div<AvatarProps>`
  /* approvalActionType ? darken(vars.$danger, 0.08) : shadowColor ? shadowColor : '#fff'}; */

  display: flex;
  width: 29px; /* use px for more reliable for circles */
  min-width: 29px;
  height: 29px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 0.7rem;
  text-transform: uppercase;

  box-shadow: inset 0px 0px 0px 1px ${({ shadowColor }) => (shadowColor ? shadowColor : '#fff')};
  color: ${({ color }) => (color ? color : 'inherit')};
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'inherit')};

  svg[data-icon] {
    width: 29px; /* use px for more reliable for icons */
    height: 12px;
  }

  ${({ channelIconOverlay }) => {
    return (
      channelIconOverlay &&
      css`
        align-self: center;
        position: absolute;
        box-shadow: 0 0 0 0.2rem #ffffff;
        background-color: ${vars.$greenlight};
        color: white;
        width: 1.5rem;
        min-width: 1.5rem;
        height: 1.5rem;
        font-size: 0.57rem;
        border-radius: 50%;
        right: -1.25rem;
        margin-top: 1px;
        z-index: 1;
      `
    );
  }};
`;

export default Avatar;
