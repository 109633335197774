import React, { ReactElement, useEffect, useState, useCallback, useRef } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ResponseOrganization } from '@friendemic/erc-graph';
import * as _ from 'lodash';
import { Search } from 'src/components';
import { useDebounce, useOnClickOutside } from 'src/hooks';
import vars from 'src/styles/variables';
import { DropDownWrapper, DropDownListWrapper, DropDownErrorWrapper } from './DropDown.styled';
import { DropDownHeader } from './DropDownHeader';
import { DropDownItem } from './DropdownItem';

type DropDownState = {
  search: string;
  isdropOpen: boolean;
  isClose?: boolean;
  dataToRender: [];
  selected: any;
  states: number;
  initial_State: number;
  primarySelected: any;
  secondarySelected: any;
  multiOptions: Record<string, any>;
};

const initialState: DropDownState = {
  search: '',
  isdropOpen: false,
  isClose: false,
  dataToRender: [],
  selected: null,
  states: 1,
  initial_State: 1,
  primarySelected: null,
  secondarySelected: null,
  multiOptions: {},
};
interface DropDownProps {
  onSearch?: (...args: any) => void;
  onReset?: (...args: any) => void;
  onSelect?: (...args: any) => void;
  placeholder?: string | null;
  primaryTitle?: string | null;
  secondaryTitle?: string | null;
  isSearch?: boolean;
  isMulti?: boolean;
  isOpen?: boolean;
  isCloseIcon?: boolean;
  headerbg?: string;
  component?: any;
  data?: any;
  leftIcon?: { icon: string | null | IconDefinition; showDefault: boolean };
  color?: string;
  maxHeight?: string;
  minHeight?: string;
  headerComp?: {
    component: string | React.ReactNode | JSX.Element;
    props?: Record<string, any>;
    text?: string;
    headerWidth?: number;
    [key: string]: any;
  };
  itemPadding?: string;
  selectedOptions?: { primaryOption: any; secondaryOption: any };
  dropdownPosition?: 'left' | 'right' | 'center';
  listItemHoverColor?: string;
  listItemActiveColor?: string;
  multiActiveOptions?: Record<string, any>;
  selected?: any;
  selectExternal?: (...args: any) => boolean;
}

const DropDown = (props: DropDownProps): ReactElement<{ organizations: ResponseOrganization[] }> => {
  const {
    onSearch,
    secondaryTitle,
    primaryTitle,
    placeholder,
    isSearch,
    isCloseIcon,
    headerbg,
    leftIcon,
    component,
    color,
    maxHeight,
    minHeight,
    onSelect,
    onReset,
    headerComp,
    selectedOptions,
    dropdownPosition,
    isMulti,
    listItemHoverColor,
    listItemActiveColor,
    multiActiveOptions,
    selected,
    selectExternal,
  } = props;
  const [state, setState] = useState(_.cloneDeep(initialState));
  const [searchValue, setSearchValue] = useState('');
  const [filteredDataToRender, setFilteredDataToRender] = useState([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const detectOutSideclickRef = useRef<HTMLInputElement>(null);
  useOnClickOutside(detectOutSideclickRef, () => setState({ ...state, isdropOpen: false }));
  // * Custom component to render
  let Comp: any = undefined;
  if (component) {
    Comp = component;
  }
  // * Custom Header component to render
  let HeaderComponent: any;
  if (headerComp) {
    HeaderComponent = headerComp.component;
  }
  // * Partially update the state
  const updateState = useCallback(
    (newState: Partial<DropDownState>) => {
      setState(() => ({ ...state, ...newState }));
    },
    [state]
  );
  // * Handles the initial options
  useEffect(() => {
    const states = 1;
    if (!isMulti && (primaryTitle || secondaryTitle)) {
      handleInitialPrimarySecondaryOptions();
    } else if (isMulti) {
      updateState({
        states,
        multiOptions: multiActiveOptions || {},
      });
      !searchValue && setFilteredDataToRender(props.data);
    } else {
      updateState({
        states,
        selected,
      });
      !searchValue && setFilteredDataToRender(props.data);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [props.data, props.isOpen, props.multiActiveOptions]);
  useEffect(() => {
    let states = 1;
    if (primaryTitle && secondaryTitle) {
      states = 2;
    }
    let primarySelected;
    let secondarySelected;
    if (selectedOptions?.primaryOption) {
      primarySelected = selectedOptions?.primaryOption;
    }
    if (selectedOptions?.secondaryOption) {
      secondarySelected = selectedOptions?.secondaryOption;
    }
    updateState({
      primarySelected,
      secondarySelected,
      states,
    });
    !searchValue && setFilteredDataToRender(props.data);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [props.selectedOptions]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = searchValue;
    }
  }, [searchValue, state.isdropOpen]);

  function handleInitialPrimarySecondaryOptions() {
    let states = 1;
    if (primaryTitle && secondaryTitle) {
      states = 2;
    }
    let primarySelected;
    let secondarySelected;
    if (primaryTitle) {
      primarySelected = { name: primaryTitle };
    }
    if (secondaryTitle) {
      secondarySelected = { name: secondaryTitle };
    }
    if (selectedOptions?.primaryOption) {
      primarySelected = selectedOptions?.primaryOption;
    }
    if (selectedOptions?.secondaryOption) {
      secondarySelected = selectedOptions?.secondaryOption;
    }
    !searchValue && setFilteredDataToRender(props.data);
    updateState({
      primarySelected,
      secondarySelected,
      isdropOpen: props.isOpen,
      states,
    });
  }
  const handleErrorClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.data?.length > 0) {
      setFilteredDataToRender(props.data);
      if (inputRef.current) {
        (inputRef.current as HTMLInputElement).value = '';
      }
    } else {
      onReset && onReset();
    }
  };
  function hanldeClickedPrimarySecondaryOptions(item: Record<string, any> | React.MouseEvent, e: React.MouseEvent) {
    const { states } = state;
    if (states > 1) {
      let { initial_State } = state;
      initial_State = initial_State > 1 ? 1 : 2;

      updateState({
        isdropOpen: false,
        primarySelected: state.initial_State > 1 ? state.primarySelected : e,
        secondarySelected: state.initial_State > 1 ? item : '',
        initial_State,
      });
    } else {
      updateState({
        isdropOpen: false,
        secondarySelected: secondaryTitle ? item : undefined,
        primarySelected: primaryTitle ? item : undefined,
      });
    }
  }
  function handleClickMultiOptions(item: Record<string, any> | React.MouseEvent, e: React.MouseEvent) {
    const { multiOptions } = state;
    e.stopPropagation();
    e.preventDefault();
    const { id } = item as Record<string, any>;
    const tempOptions = { ...multiOptions };
    if (tempOptions && tempOptions[id]) {
      delete tempOptions[id];
    } else {
      tempOptions[id] = item;
    }
    updateState({
      multiOptions: tempOptions,
      isdropOpen: true,
    });
  }
  const handleClick = (item: Record<string, any> | React.MouseEvent, e: React.MouseEvent) => {
    if (selectExternal && selectExternal(item)) {
      return updateState({
        isdropOpen: true,
        selected: null,
      });
    }
    if (!isMulti) {
      if (!primaryTitle && !secondaryTitle) {
        updateState({
          isdropOpen: false,
          selected: item,
        });
      } else {
        hanldeClickedPrimarySecondaryOptions(item, e);
      }
    } else {
      handleClickMultiOptions(item, e);
    }

    onSelect && onSelect(item, e);
  };
  const searchDebounce = useDebounce((e: React.ChangeEvent) => {
    e.persist();
    e.stopPropagation();
    e.preventDefault();
    const { value } = e.target as HTMLInputElement;
    const { data } = props;
    let values = props.data;
    setSearchValue(value);
    if (value != '') {
      values = data.filter((item: { name?: string; placeName?: string }) =>
        item.placeName
          ? item.placeName?.toLowerCase().includes(value.toLowerCase())
          : item.name?.toLowerCase().includes(value.toLowerCase())
      );
    }
    setFilteredDataToRender(values);
    onSearch && onSearch(e);
  }, 1);

  return (
    <DropDownWrapper
      ref={detectOutSideclickRef}
      key={'orgId'}
      backgroundColor={headerComp == undefined ? headerbg || vars.$greendark : 'transparent'}
      color={color ? color : vars.$white}
      showheader={headerComp == undefined}
    >
      {HeaderComponent ? (
        <HeaderComponent
          {...headerComp?.props}
          onClick={() => {
            updateState({ isdropOpen: !state.isdropOpen });
            !searchValue && setFilteredDataToRender(props.data);
            headerComp?.pros?.onClick && headerComp?.props?.onClick(); // !FLAG - this is looking for headerComp.pros instead of headerComp.props <-- don't want to break anything so leaving it for now
          }}
        >
          {headerComp?.props?.text}
        </HeaderComponent>
      ) : (
        <DropDownHeader
          isOpen={state.isdropOpen}
          data={props.data}
          updateState={updateState}
          leftIcon={leftIcon}
          onReset={onReset}
          isCloseIcon={isCloseIcon}
          primaryTitle={state.primarySelected?.name || state.primarySelected?.placeName || primaryTitle}
          secondaryTitle={state.secondarySelected?.name || state.secondarySelected?.placeName || secondaryTitle}
        />
      )}
      {state.isdropOpen && (
        <DropDownListWrapper
          maxheight={maxHeight || 'calc(100vh - 13rem)'}
          {...(minHeight ? { minHeight: minHeight } : {})}
          isCustomHeader={HeaderComponent != undefined}
          headerWidth={headerComp?.headerWidth || 15}
          dropdownPosition={dropdownPosition}
        >
          {isSearch && (
            <Search
              datatype={placeholder || 'search'}
              placeholder={`Search ${placeholder ? placeholder : ''}`}
              ref={inputRef}
              onChange={searchDebounce}
            />
          )}

          {filteredDataToRender?.length > 0 &&
            filteredDataToRender.map((d: any, i: number) => {
              let isSelected = false;
              if (!isMulti) {
                if (!primaryTitle && !secondaryTitle) {
                  isSelected = state.selected?.name === d.name || state.selected?.placeName === d.placeName;
                } else {
                  isSelected =
                    state.secondarySelected?.name === d.name ||
                    state.primarySelected?.name === d.name ||
                    state.secondarySelected?.placeName === d.placeName ||
                    state.primarySelected?.placeName === d.placeName;
                }
              }
              if (d.selected) {
                isSelected = true;
              } else {
                isSelected = state.multiOptions && state.multiOptions[d.id] != undefined;
              }
              return (
                <DropDownItem
                  keyProp={String(i)}
                  key={i}
                  onClick={(e) => handleClick(d, e)}
                  selected={isSelected}
                  disable={d.disabled}
                  itemPadding={props.itemPadding}
                  hoverColor={listItemHoverColor}
                  activeColor={listItemActiveColor}
                >
                  {Comp && <Comp data={d} selected={isSelected} />}
                </DropDownItem>
              );
            })}
          {filteredDataToRender?.length < 1 && (
            <DropDownErrorWrapper onClick={handleErrorClick}>
              {'No Data Available...'}
              {(props.data?.length > 0 ||
                state.primarySelected ||
                (state.secondarySelected && props.data?.length < 1)) && <div>{'click to reset'}</div>}
            </DropDownErrorWrapper>
          )}
        </DropDownListWrapper>
      )}
    </DropDownWrapper>
  );
};

export { DropDown };
