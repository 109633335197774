import React from 'react';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';

const DeletedOverlayWrapper = styled.div`
  width: 100%;
  box-shadow: 3px -3px 6px rgba(0, 0, 0, 0.16);
  z-index: 1;
  padding: 1rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: ${vars.$red};
  max-height: 4rem;
`;

const OverLimitMessage = styled.div`
  font-weight: bold;
  font-size: 135%;
  text-align: center;
  color: ${vars.$white};
`;

export const DeletedOverlay = (props: {}): JSX.Element => {
  return (
    <DeletedOverlayWrapper>
      <OverLimitMessage>
        <div>{'This review has been deleted'}</div>
      </OverLimitMessage>
    </DeletedOverlayWrapper>
  );
};

export default DeletedOverlay;
