import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { ResponseSignature } from '@friendemic/erc-graph';
import review_type_friendly from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_type_friendly.js';
import { ActionsCell } from 'src/components';
import { DataRow } from '../../../AdminManagement/table';
import { FormValues } from './SignatureManagementForm';

interface SignatureCell {
  signature: ResponseSignature;
}

const SignatureCell = (props: SignatureCell | Record<string, any>): React.ReactElement => {
  const { signature } = props.signature;
  const { content } = signature;
  return (
    <div>
      <pre>
        <span dangerouslySetInnerHTML={{ __html: content }} />
      </pre>
    </div>
  );
};

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Signature',
    selector: (row: Record<string, any>): any => row.first,
    sortable: true,
    cell: SignatureCell,
    //! FLAG - investigate what's going on with the sortfunction typesript definition
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sortFunction: (a: Record<string, SignatureCell>, b: Record<string, any>): number => {
      const a_content = a.signature.signature.content;
      const b_content = b.signature.signature.content;
      return a_content.localeCompare(b_content);
    },
  },
  {
    name: 'Signs',
    selector: (row: Record<string, any>): any => row.signs,
    sortable: true,
    grow: 0,
  },
  {
    name: 'Actions',
    width: '6rem',
    sortable: false,
    grow: 0,
    center: true,
    cell: ActionsCell,
  },
];

export function buildData(
  signatureData: ResponseSignature[],
  setActiveEditingSignature: React.Dispatch<React.SetStateAction<FormValues>>,
  showAddSignatureModal: () => void,
  handleDeleteSignature: (signatureUlid: string, signsReviewTypeFriendly: string) => Promise<void>
): any[] {
  return signatureData.map((signature: ResponseSignature) => {
    const signsReviewTypeFriendly = review_type_friendly.ReviewTypeFriendly.toFriendly(signature.signsReviewType);
    return {
      signature: { signature },
      signs: signsReviewTypeFriendly,
      actions: [
        {
          action: 'delete',
          icon: 'trash-alt',
          tooltip: 'Delete',
          onClick: () => handleDeleteSignature(signature.ulid, signsReviewTypeFriendly),
        },
        {
          action: 'edit',
          icon: 'edit',
          tooltip: 'Edit',
          onClick: () => {
            setActiveEditingSignature({
              ulid: signature.ulid,
              placeUlid: signature.ulid,
              content: signature.content,
              signsReviewType: signature.signsReviewType,
            });
            showAddSignatureModal();
          },
        },
      ],
    };
  });
}
