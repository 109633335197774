import React from 'react';
import { useModal } from 'react-modal-hook';
import { ResponseApprover } from '@friendemic/erc-graph';
import { Formik, Form, FormikProps } from 'formik';
import { Row, Column, List, Modal, Table } from 'src/components';
import { toast, updateGraphMutation, fetchUserByUlid } from 'src/utils';
import { SettingsSubsection } from 'src/views/Settings/settings.styled';
import { LocationSettingsRouteParams } from 'src/views/Settings/SettingsTypes';
import { RenderForm, initialFormValues, FormValues, validationSchema } from './ApproverManagementForm';
import { buildData, columns } from './table';
import { useGraphMutations } from './useGraphMutations';

const ApproverManagement = (props: {
  params: LocationSettingsRouteParams;
  listResponseApprovers?: ResponseApprover[];
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement => {
  const { params, listResponseApprovers, setSaving } = props;

  // Data object to use in table
  const [tableData, setTableData] = React.useState<any>();

  // Approvers array to send in final request
  const [approvers, setApprovers] = React.useState<ResponseApprover[]>(listResponseApprovers || []);

  // The approver being actively edited, set when user clicks "Edit" button
  const [activeEditingApprover, setActiveEditingApprover] = React.useState<FormValues>(initialFormValues);

  const { createResponseApprover, createResponseApproverLoading, editResponseApprover, editResponseApproverLoading } =
    useGraphMutations(props.params.location);

  React.useEffect(() => {
    setTableData(buildData(approvers, setApprovers, setActiveEditingApprover, params, showAddApproverModal));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [approvers, params]);

  React.useEffect(() => {
    setSaving(createResponseApproverLoading || editResponseApproverLoading);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [createResponseApproverLoading, editResponseApproverLoading]);

  async function handleAddApprover(values: FormValues) {
    const {
      data: { getUser },
    } = await fetchUserByUlid(values.add_approver_ulid);
    const response = await updateGraphMutation(
      {
        variables: {
          placeUlid: params.location,
          input: {
            userUlid: values.add_approver_ulid,
            reviewType: values.add_approver_review_type,
            required: values.add_approver_required,
          },
        },
        mutationName: 'createResponseApprover',
      },
      createResponseApprover
    );
    if (response) {
      if (response.data.createResponseApprover && response.data.createResponseApprover.errors) {
        console.info(JSON.stringify(response.data, null, 2));
        toast({ type: 'error', message: 'Something went wrong, please try again.' });
      } else {
        const newValues = {
          ulid: response.data.createResponseApprover.record?.ulid
            ? response.data.createResponseApprover.record?.ulid
            : getUser.ulid,
          placeUlid: params.location,
          userUlid: getUser.ulid,
          user: {
            ulid: getUser.ulid,
            name: getUser.name,
            email: getUser.email,
            organizationIDs: getUser.organizationIDs,
          },
          reviewType: values.add_approver_review_type,
          required: values.add_approver_required,
        };
        await setApprovers((oldArray) => [...oldArray, newValues]);
        setActiveEditingApprover(initialFormValues);
        hideAddApproverModal();
      }
    } else {
      setActiveEditingApprover(initialFormValues);
      hideAddApproverModal();
    }
  }

  async function handleEditApprover(values: FormValues) {
    // const activeApprover = approvers.find((x) => {
    //   return x.ulid === values.add_approver_ulid;
    // });

    // const activeApproverName = activeApprover && activeApprover.user ? activeApprover.user.name : 'UNKNOWN';
    const response = await updateGraphMutation(
      {
        variables: {
          placeUlid: params.location,
          ulid: values.add_approver_ulid,
          input: {
            reviewType: values.add_approver_review_type,
            required: values.add_approver_required,
          },
        },
        mutationName: 'editResponseApprover',
      },
      editResponseApprover
    );
    if (response) {
      if (response.data.editResponseApprover && response.data.editResponseApprover.errors) {
        console.info(JSON.stringify(response.data, null, 2));
        toast({ type: 'error', message: 'Something went wrong, please try again.' });
      } else {
        const newValues = {
          reviewType: values.add_approver_review_type,
          required: values.add_approver_required,
        };

        await setApprovers(
          approvers.map((x) => {
            return x.ulid === values.add_approver_ulid ? { ...x, ...newValues } : x;
          })
        );
        setActiveEditingApprover(initialFormValues);
        hideAddApproverModal();
      }
    } else {
      setActiveEditingApprover(initialFormValues);
      hideAddApproverModal();
    }
  }

  const [showAddApproverModal, hideAddApproverModal] = useModal(() => {
    const addNew = activeEditingApprover === initialFormValues;

    return (
      <Formik
        initialValues={activeEditingApprover}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          return addNew ? handleAddApprover(values) : handleEditApprover(values);
        }}
      >
        {(props: FormikProps<FormValues>) => {
          const { submitForm, isSubmitting } = props;
          return (
            <Modal
              hideModal={hideAddApproverModal}
              title={addNew ? 'Add Approver' : 'Edit Approver'}
              icon={addNew ? 'plus' : 'edit'}
              overflowVisible
              compact
              footer
              cancelable
              submittable={addNew ? 'Add' : 'Save'}
              submitDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              onClose={() => {
                setActiveEditingApprover(initialFormValues);
              }}
              handleSubmit={() => {
                return submitForm();
              }}
            >
              <Form>
                <RenderForm params={params} approvers={approvers} addNew={addNew} {...props} />
              </Form>
            </Modal>
          );
        }}
      </Formik>
    );
  }, [activeEditingApprover]);

  return (
    <SettingsSubsection>
      <Row>
        <Column>
          <List
            title="Approver Management"
            icon="money-check-edit"
            action={{
              onClick: showAddApproverModal,
              icon: 'plus',
              content: 'Add',
            }}
          >
            <li>
              <Table noHeader columns={columns} data={tableData} />
            </li>
          </List>
        </Column>
      </Row>
    </SettingsSubsection>
  );
};

export default ApproverManagement;
