import { ResponseTemplate } from '@friendemic/erc-graph';
import { AxiosResponse } from 'axios';
import axios from 'src/axios';
import { TemplateVariables } from '../types/async';

export async function listTemplates(placeUlid: string): Promise<ResponseTemplate[]> {
  const { data } = await axios.get(`${process.env.REACT_APP_GO_API}/response-places/${placeUlid}/response-templates`);
  return data;
}

export function createTemplate(variables: TemplateVariables): Promise<AxiosResponse<ResponseTemplate>> {
  return axios.post(`${process.env.REACT_APP_GO_API}/response-places/${variables.placeUlid}/response-templates`, {
    ...variables.input,
  });
}

export function editTemplate(variables: TemplateVariables): Promise<AxiosResponse<ResponseTemplate>> {
  return axios.put(
    `${process.env.REACT_APP_GO_API}/response-places/${variables.placeUlid}/response-templates/${variables.templateUlid}`,
    {
      ...variables.input,
    }
  );
}

export function deleteTemplate(variables: TemplateVariables): Promise<AxiosResponse<unknown>> {
  return axios.delete(
    `${process.env.REACT_APP_GO_API}/response-places/${variables.placeUlid}/response-templates/${variables.templateUlid}`
  );
}
