import React from 'react';
import { IconName, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Placement } from '@popperjs/core';
import { darken, lighten } from 'color2k';
import styled, { css } from 'styled-components/macro';
import { space, SpaceProps } from 'styled-system';
import vars from 'src/styles/variables';
import ConditionalWrapper from './ConditionalWrapper';
import Tooltip from './Tooltip';

const paddingY = 0.625;
const paddingX = 0.75;
const fontSize = 0.875;
const iconMargin = 0.5;

export interface ButtonProps extends SpaceProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string | string[] | IconDefinition;
  spin?: boolean;
  iconRight?: string;
  content?: string;
  btnSm?: boolean;
  btnLg?: boolean;
  block?: boolean;
  outline?: boolean;
  secondary?: boolean;
  danger?: boolean;
  transparent?: boolean;
  blank?: boolean;
  className?: string;
  disabled?: boolean;
  iconOnly?: boolean;
  children?: any;
  tooltip?: React.ReactNode;
  placement?: Placement;
}

const RESET = css`
  /* MAIN RESET STYLES */
  color: ${vars.$gray_6};
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  line-height: 1rem;

  /* MAIN RESET PSUEDO-SELECTORS */
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: 0;
    z-index: 1;
  }
  &:disabled,
  &:disabled:hover {
    opacity: 0.5;
    box-shadow: none;
    cursor: not-allowed !important;
  }
  &:not(:disabled):not(.pill-static) {
    cursor: pointer;
  }
`;

const BLOCK = css`
  ${(props: Pick<ButtonProps, 'block'>) => {
    return props.block
      ? css`
          display: block;
          width: 100%;
          box-shadow: ${vars.$shallow_shadow};
        `
      : css`
          display: inline-block;
        `;
  }}
`;

const BUTTONSIZE = css`
  ${(props: Pick<ButtonProps, 'btnSm' | 'btnLg' | 'blank' | 'iconOnly' | 'iconRight'>) => {
    const { btnSm, btnLg, blank, iconOnly, iconRight } = props;

    const newPaddingY = btnSm ? paddingY * 0.65 : btnLg ? paddingY * 1.25 : paddingY;
    const newPaddingX = btnSm ? paddingX * 0.65 : btnLg ? paddingX * 1.25 : paddingX;
    const newFontSize = btnSm ? fontSize * 0.85 : btnLg ? fontSize * 1.25 : fontSize;
    const newSvgMargin = btnSm ? iconMargin * 0.65 : btnLg ? iconMargin * 1.25 : iconMargin;
    const newWidthHeight = btnSm ? '2rem' : btnLg ? '2.75rem' : '2.375rem';
    const newLineHeight = btnSm ? 'inherit' : btnLg ? '1.1rem' : '1rem';

    return blank
      ? ''
      : css`
          ${iconOnly && `width: ${newWidthHeight}; height: ${newWidthHeight};`}
          padding: ${iconOnly ? 0 : `${newPaddingY}rem ${newPaddingX}rem`};
          font-size: ${newFontSize}rem;
          border-radius: 10rem;
          line-height: ${newLineHeight};
          svg {
            margin: ${iconOnly ? 0 : iconRight ? `0 0 0 ${newSvgMargin}rem` : `0 ${newSvgMargin}rem 0 0`};
            /* margin-right: ${iconOnly || iconRight ? 0 : '0.5rem'};
            margin-left: ${iconOnly ? 0 : iconRight ? '0.5rem' : 0}; */
            font-size: 85%;
            vertical-align: -1px;
          }
        `;
  }}
`;

/**
 * buildColorVariant( '#ca4ca4' );
 *
 */
const buildColorVariant = ($background: string, outline = false, transparent = false) => {
  const isSecondary = $background === vars.$gray_e;
  const isSecondaryOutline = Boolean(isSecondary && outline);
  const _secondaryOutlineColor = vars.$gray_a;

  const _hoverBackground = transparent
    ? $background
    : isSecondary
    ? lighten($background, 0.015)
    : lighten($background, 0.1);
  const _activeBackground = isSecondary ? darken($background, 0.01) : darken($background, 0.08);

  const _hoverSecondaryOutline = lighten(_secondaryOutlineColor, 0.1);
  const _activeSecondaryOutline = darken(_secondaryOutlineColor, 0.08);

  return css`
    transition: color 150ms ease-in-out, background-color 150ms ease-in-out, border-color 150ms ease-in-out;
    box-shadow: ${outline || transparent ? 0 : vars.$shallow_shadow};
    background-color: ${outline || transparent ? 'transparent' : $background};

    /**
     * Easier to understand as if/else-ifs, the
     * other ternaries in this file are already pushing the
     * grokability of this funciton.
     */
    border-color: ${() => {
      if (transparent) {
        return 'transparent';
      } else if (isSecondaryOutline) {
        return _secondaryOutlineColor;
      } else {
        return $background;
      }
    }};
    color: ${() => {
      if (isSecondary) {
        return _secondaryOutlineColor;
      } else if (outline || transparent) {
        return $background;
      } else {
        return 'white';
      }
    }};

    /**
     * The "Secondary" button is so light that it requires it's own
     * color functions in order to not end up totally white on hover.
     */
    &:not(:disabled):not(.pill-static) {
      &:hover {
        background-color: ${outline ? 'white' : _hoverBackground};
        color: ${isSecondary ? _hoverSecondaryOutline : outline ? _hoverBackground : 'white'};
        border-color: ${isSecondaryOutline ? _hoverSecondaryOutline : _hoverBackground};
      }
      &:active {
        background-color: ${outline ? 'white' : _activeBackground};
        color: ${isSecondary ? _activeSecondaryOutline : outline ? _activeBackground : 'white'};
        border-color: ${isSecondaryOutline ? _activeSecondaryOutline : _activeBackground};
      }
    }
  `;
};

const COLORVARIANT = css`
  ${(props: ButtonProps) => {
    const { blank, color, outline, danger, secondary, transparent } = props;
    if (!blank) {
      if (color) {
        return buildColorVariant(color, outline, transparent);
      } else if (danger) {
        return buildColorVariant(vars.$danger, outline, transparent);
      } else if (secondary) {
        return buildColorVariant(vars.$gray_e, outline, transparent);
      } else {
        return buildColorVariant(vars.$primary, outline, transparent);
      }
    }
  }}
`;

const StyledButton = styled.button<ButtonProps>`
  ${RESET}
  ${BLOCK}
  ${BUTTONSIZE}
  ${COLORVARIANT}
`;

const ButtonComponent: React.FC<ButtonProps> = (props: ButtonProps): React.ReactElement<ButtonProps> => {
  const { icon, children, content, tooltip, iconRight, type, spin, placement, ...rest } = props;

  return (
    <ConditionalWrapper
      condition={Boolean(tooltip)}
      wrapper={(children) => (
        <Tooltip placement={placement || 'right'} content={tooltip || ''}>
          {children}
        </Tooltip>
      )}
    >
      <StyledButton
        {...rest}
        type={type ? type : 'button'}
        iconRight={iconRight}
        iconOnly={Boolean(icon && !children && !content)}
      >
        {icon && <FontAwesomeIcon spin={spin} fixedWidth size="sm" icon={icon as IconName} />}
        {content && content}
        {children}
        {iconRight && <FontAwesomeIcon spin={spin} fixedWidth size="sm" icon={iconRight as IconName} />}
      </StyledButton>
    </ConditionalWrapper>
  );
};

/**
 * ### The button has a handful of states, the important ones being: <br />
 *   • `btnSm` (decreased font-size & padding) <br />
 *   • `btnLg` (increased font-size & padding) <br />
 *   • `secondary` (light) <br />
 *   • `danger` (red) <br />
 *   • `outline` <br />
 *   • `transparent` <br />
 *   • `blank` <br />
 *   • `disabled`
 *
 * ### An icon can be added using the `icon` prop. <br />
 * If the button has an icon but no text, it will automatically be sized correctly.
 *
 *   `<Button icon="any-fa-icon" />` <br />
 *   or <br />
 *   `<Button icon={props.icon as IconName} />`
 *
 * ### The `block` prop is used to extend the button to 100% width w/ display block. <br />
 * `<Button block>{'Button Text'}</Button>`
 *
 * ### The `tooltip` prop will accept only a string. Use Tooltip component for more options.
 * `<Button tooltip="Tooltip text">{'Button w/ Tooltip'}</Button>`
 *
 */
export const Button = styled(ButtonComponent)`
  ${space}
`;

Button.displayName = 'Button';

export default Button;
