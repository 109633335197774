import { Box } from 'reflexbox/styled-components';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';

interface ColumnProps {
  pa0?: boolean;
  auto?: boolean;
}

// See docs on ReflexBox Width - Styled-System
// eslint-disable-next-line space-in-parens
export const Column = styled(Box).attrs((props) => {
  return {
    width: props.width ? props.width : 1,
  };
})`
  padding-left: ${vars.$spacing_small};
  padding-right: ${vars.$spacing_small};

  ${(props: ColumnProps) => {
    return props.pa0 && 'padding: 0;';
  }}

  ${(props: ColumnProps) => {
    return (
      props.auto &&
      `
        flex: 1 1 auto;
        width: auto;
        min-width: 0;
        min-height: 0;
      `
    );
  }}
`;
Column.displayName = 'Column';
export default Column;
