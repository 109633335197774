import React from 'react';
import { darken } from 'color2k';
import { Flex } from 'reflexbox/styled-components';
import styled, { css } from 'styled-components/macro';
import { Button, Row, Column } from 'src/components';
import { DropDownWrapper } from 'src/components/DropDown/DropDown.styled';
import vars from 'src/styles/variables';

export const ResponseConsoleSection = styled(Flex)<{ pb0?: boolean }>`
  overflow-y: scroll;
  flex-direction: column;
  padding: 2rem 1rem;
  ${({ pb0 }) => pb0 && 'padding-bottom: 0;'};
  scroll-behavior: smooth;
  position: relative;

  &:first-of-type {
    /* border: 1px solid red; */
    box-shadow: ${vars.$shadow_2};
    padding: 0;
  }
`;

export const ResponseConsoleSectionTicket = styled(Flex)`
  flex: 1 1 auto;
  flex-direction: column;
`;

export const ResponseConsoleSectionTicketWrapper = styled(Flex)`
  overflow-y: auto;
  padding: 2rem 1rem 0;
  scroll-behavior: smooth;
  position: relative;
  flex: 1 1 auto;
  flex-direction: column;
`;

export const ResponseConsoleHeaderWrapper = styled.div`
  position: sticky;
  top: -31px;
  z-index: ${vars.$dropdown};
  background-color: ${vars.$gray_fa};
  padding: 2.1rem 1rem;
  padding-bottom: 0;
`;
export const ResponseConsoleListWrapper = styled.div`
  padding: 0.5rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const SectionHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: ${vars.$spacing};

  ${DropDownWrapper} {
    margin-bottom: 0;
  }
`;

export const ResponseConsoleSectionTitle = styled.div`
  letter-spacing: -0.5px;
  color: #999;
  font-size: 1.25rem;
`;

export const ResponsesWrapper = styled.div<{ showOverLimit: boolean; showDeletedOverlay: boolean }>`
  position: relative;
  ${(props) => {
    return (
      (props.showOverLimit || props.showDeletedOverlay) &&
      css`
        * {
          pointer-events: none;
        }
        &::after {
          background-color: rgba(0, 0, 0, 0.14);
          content: '';
          position: absolute;
          top: -2rem;
          right: -1rem;
          bottom: 0rem;
          left: -1rem;
          z-index: 10;
          min-height: ${props.showDeletedOverlay ? 'calc(100vh - 4rem)' : 'calc(100vh - 6.75rem)'};
        }
      `
    );
  }}
`;

export const CloseTicketButton = styled(Button).attrs({
  btnSm: true,
  transparent: true,
  danger: true,
  content: 'close',
  title: 'Close',
})`
  position: absolute;
  top: 0;
  right: 1rem;
  font-size: 80%;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ddd;
  color: #b3b3b3;
  border-top: 0;
  border-radius: 0;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0 0.5rem 2px !important;
  height: auto;
  width: auto;
  transition: 150ms;
  &:hover {
    background-color: ${vars.$redlight};
    color: white;
  }
`;

export const AssignedToFlag = styled.div<{ isAssigned: boolean }>`
  position: absolute;
  top: -2rem;
  left: 0rem;
  font-size: 80%;
  text-align: center;
  border-top: 0;
  border-radius: 0;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0 0.5rem 2px;
  height: auto;
  width: auto;

  ${({ isAssigned }) => {
    return isAssigned
      ? css`
          background-color: ${vars.$greenlight};
          color: white;
        `
      : css`
          background-color: ${vars.$gray_e};
          border: ${vars.$border};
          color: ${vars.$gray_b};
        `;
  }}
`;

type PlaceholderBlockProps = {
  title: string;
  content?: any | any[];
  border?: boolean | string;
  mb?: boolean | string;
  pa?: boolean | string;
  pre?: boolean;
  children?: string | JSX.Element | JSX.Element[];
};

const PlaceholderWrapper = styled.div<Pick<PlaceholderBlockProps, 'border' | 'mb' | 'pa' | 'pre'>>`
  max-height: 20rem;

  pre {
    margin: 0;
    overflow: visible;
  }

  div[data-title]:first-of-type {
    color: ${vars.$gray_c};
    font-size: 100%;
  }
  div[data-content]:last-of-type {
    font-size: 80%;
    margin-bottom: 1rem;
  }
  ${({ pre }) =>
    pre &&
    css`
      /* box-shadow: ${vars.$shadow_2}; */
      overflow: auto;
      border: 1px solid ${vars.$gray_1};
      background-color: ${vars.$gray_3};
      border-radius: ${vars.$border_radius};
      color: ${vars.$gray_a};

      div[data-title]:first-of-type {
        color: #cea848;
        font-size: 100%;
      }
    `}

  ${({ border }) =>
    border
      ? css`
          border: 1px solid ${typeof border === 'string' ? border : 'cornflowerblue'};
        `
      : ''}

  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${typeof mb === 'string' ? mb : '1rem'};
    `}
  ${({ pa }) =>
    pa &&
    css`
      padding: ${typeof pa === 'string' ? pa : '1rem'};
    `}
`;

export const PlaceholderBlock = (props: PlaceholderBlockProps): JSX.Element => {
  return (
    <PlaceholderWrapper mb={props.mb} pa={props.pa} pre={props.pre} border={props.border}>
      {props.title && <div data-title>{props.title}</div>}
      {Boolean(props.content && typeof props.content === 'string') ? (
        <div data-content>{props.content}</div>
      ) : (
        props.content && <pre>{JSON.stringify(props.content, null, 2)}</pre>
      )}
      {props.children && <div data-content>{props.children}</div>}
    </PlaceholderWrapper>
  );
};

export const ApproverTicketMeta = styled.div`
  color: ${vars.$muted};
  padding-bottom: 1rem;
  text-align: center;
`;

export const InboxSectionCountWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const InboxTicketsCountWrapper = styled.span`
  color: ${vars.$gray_a};
  font-size: ${vars.$font_size_sm};
  margin-left: 0.3rem;
`;

export const NoMoreDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 0;
  font-size: ${vars.$font_size_extra_sm};
  color: ${vars.$white};
  background-color: ${vars.$greenlight};
  svg {
    font-size: ${vars.$font_size};
    margin-right: 1rem;
    transition: all 0.3s ease;
    cursor: pointer;
  }
`;

export const NoMoreDataTextWrapper = styled.div`
  text-align: center;
  flex: 1;
  margin-left: 1.875rem;
`;
export const TicketStatusDropdownWrapper = styled.div`
  margin-right: 0.5rem;
`;

export const dropdownButtonMixin = css`
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  font-size: 0.85rem;
  border: 0;
  transition: 150ms;
  color: ${vars.$gray_9};
  background-color: ${vars.$gray_e};
  > svg {
    /* font-size: 100%; */
    margin-right: 0.25rem;
  }
  border-radius: ${vars.$border_radius};
  box-shadow: ${vars.$shallow_shadow};
  padding-right: 0.5rem;
  &:hover {
    color: white;
    background-color: ${vars.$greenlight};
  }
  &:active {
    background-color: ${darken(vars.$greenlight, 0.08)};
  }
`;

export const PublishButtonColumn = styled(Column)``;

export const CompletionButtonsWrapper = styled(Row)`
  ${Column} {
    min-width: 0;
    min-height: 0;
  }
  @media (max-width: ${vars.$md}) {
    ${PublishButtonColumn} {
      ${Button} {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: ${vars.$md}) {
    ${PublishButtonColumn} {
      width: auto;
      flex: 1 1 auto;
    }
  }
  ${Button} {
    width: 100%;
  }
`;

export const ResponseInboxFilterView = styled.div`
  display: flex;
`;
export const FilterBarButton = styled(Button)`
  margin-right: ${vars.$spacing_small};
`;

export const InvisibleInboxPaginationTrigger = styled.div`
  height: 1px;
  width: 1px;
  position: relative;
  bottom: 200px;
`;
