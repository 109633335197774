import React from 'react';
import { RouteComponentProps } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { SessionUser } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/session_user_pb';
import { UserRoles } from './context';
import approverRoute from './utils/approverRoute';
import protectedRoute, { permissionLimitedRoute } from './utils/protectedRoute';
import { ResponseConsole, Dashboard, Reports, Settings, ResponseFeedback, Logout } from './views';
import { ApproveTicket } from './views/ResponseFeedback/components';
import {
  LocationSettings,
  MyProfile,
  AdminManagement,
  UserManagement,
  TicketManagement,
} from './views/Settings/components';

const { SessionUserRoleType } = SessionUser;

interface Route {
  path: string;
  exact?: boolean;
  component: React.FC | React.FC<any & RouteComponentProps>;
  onClick?: (args: any) => void;
  meta: {
    sidebar?: boolean;
    label?: string;
    icon?: IconName;
    sidebarSection?: 'top' | 'bottom';
    disabled?: boolean;
    disabledFor?: number; // ResponseUserRoleType
    routes?: Route[];
  };
}

const TempComponent = (props: {}): React.ReactElement => {
  const { path } = useRouteMatch();
  const tempLabel = path.split('/').pop();
  return <p>{tempLabel}</p>;
};

/**
 ** ROUTES
 **  - Flip disabled boolean to add/remove from sidebar
 **  - Sidebar value just indicates if it is supposed to be in the sidebar
 */
export const routes: Route[] = [
  {
    path: '/dashboard',
    exact: true,
    component: protectedRoute()(Dashboard),
    meta: {
      disabled: true,
      sidebar: true,
      label: 'Dashboard',
      icon: 'digital-tachograph',
      sidebarSection: 'top',
    },
  },
  {
    path: '/response-console',
    component: protectedRoute()(ResponseConsole),
    meta: {
      disabled: false,
      sidebar: true,
      label: 'Response Console',
      icon: 'edit',
      sidebarSection: 'top',
    },
  },
  {
    path: '/reports',
    exact: true,
    component: protectedRoute()(Reports),
    meta: {
      disabled: false,
      disabledFor: SessionUserRoleType.RESPONSEUSER,
      sidebar: true,
      label: 'Reports',
      icon: 'analytics',
      sidebarSection: 'top',
    },
  },
  {
    path: '/settings',
    component: protectedRoute()(Settings),
    meta: {
      disabled: false,
      sidebar: true,
      label: 'Settings',
      icon: 'cog',
      sidebarSection: 'bottom',
    },
  },
  {
    path: '/response-feedback',
    component: approverRoute()(ResponseFeedback),
    meta: {
      disabled: false,
      sidebar: false,
      label: 'Response Feedback',
    },
  },
  {
    path: '/logout',
    exact: true,
    component: Logout,
    meta: {
      disabled: false,
      sidebar: true,
      label: 'Logout',
      icon: 'sign-out',
      sidebarSection: 'bottom',
    },
  },
];

export const settingsRoutes = (userRoles: UserRoles): Route[] => [
  {
    path: '/settings/my-profile',
    component: MyProfile,
    meta: {
      disabled: false,
      label: 'My Profile',
      icon: 'user-circle',
    },
  },
  {
    path: '/settings/organization-management',
    component: TempComponent,
    meta: {
      disabled: true,
      label: 'Organization Management',
      icon: 'sitemap',
    },
  },
  {
    path: '/settings/location-settings',
    component: permissionLimitedRoute(userRoles.isResponseUser)(LocationSettings),
    meta: {
      disabled: userRoles.isResponseUser,
      label: 'Location Settings',
      icon: 'map-marker-edit',
    },
  },
  {
    path: '/settings/user-management',
    component: permissionLimitedRoute(userRoles.isResponseUser)(UserManagement),
    meta: {
      disabled: userRoles.isResponseUser,
      label: 'User Management',
      icon: 'users-cog',
    },
  },
  {
    path: '/settings/admin-management',
    component: permissionLimitedRoute(!userRoles.isAdmin)(AdminManagement),
    meta: {
      disabled: !userRoles.isAdmin,
      label: 'Admin Management',
      icon: 'user-crown',
    },
  },
  {
    path: '/settings/ticket-management',
    component: permissionLimitedRoute(userRoles.isResponseUser)(TicketManagement),
    meta: {
      disabled: userRoles.isResponseUser,
      label: 'Ticket Management',
      icon: 'ticket-alt',
    },
  },
  {
    path: '/settings/bulk-import',
    component: TempComponent,
    meta: {
      disabled: true,
      label: 'Bulk Import',
      icon: 'file-import',
    },
  },
];

export const responseFeedbackRoutes: Route[] = [
  {
    path: '/response-feedback/tickets/:reviewUlid',
    component: ApproveTicket,
    meta: {
      disabled: false,
    },
  },
];

export default routes;
