import React from 'react';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';

const CardAlert = styled.div`
  padding: 1rem 1.5rem;
  font-size: ${vars.$font_size_sm};
  border: ${vars.$border};
  border-bottom: none;
`;

export default CardAlert;
