import React, { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';
import { Route, Redirect, Switch } from 'react-router-dom';
import { StatusFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_filters_status_friendly';
import {
  ReviewResponseTicketFilters as FilterStatusType,
  ReviewResponseTicket as TicketStatusType,
} from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_pb';
import { SessionUser } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/session_user_pb';
import { useGetReviewResponseTicket } from 'src/async';
import { NoDataMessage } from 'src/components';
import { FilterTypePartial, useStateValue } from 'src/context';
import { cache } from 'src/graph';
import { selectOptions, spacesToUnderscores, useUrl } from 'src/utils';
import { ResponseConsoleSection } from './ResponseConsole.styled';
import ResponseInbox from './ResponseInbox';
import ResponseTicket from './ResponseTicket';

const { TicketFiltersStatusTypes } = selectOptions;
const { Type: TicketStatus } = TicketStatusType.Status;

export const ResponseConsole = (props: RouteComponentProps<{ organization?: string }>): ReactElement => {
  const [{ currentUser, userRoles }] = useStateValue();
  const [defaultFilters, setDefaultFilters] = React.useState<FilterTypePartial>({
    status: [TicketStatus.UNKNOWN, TicketStatus.REVIEW_UPDATED],
  });
  const { queryOptions, filtersOptions, history, location, queryString } = useUrl();

  const { data: ticketData, isInitialLoading: isTicketDataInitialLoading } = useGetReviewResponseTicket(
    queryOptions?.reviewUlid as string
  );

  const {
    match: {
      params: { organization },
    },
  } = props;
  React.useEffect(() => {
    cache.modify({
      id: 'ROOT_QUERY',
      fields: {
        listReviewResponseTickets() {
          return { meta: {}, records: [] };
        },
      },
    });

    const statuses = [
      TicketFiltersStatusTypes.find(({ label }) => label === StatusFriendly.toFriendly(FilterStatusType.Status.NEW)),
      TicketFiltersStatusTypes.find(
        ({ label }) => label === StatusFriendly.toFriendly(FilterStatusType.Status.UPDATED)
      ),
    ] as typeof TicketFiltersStatusTypes;

    const newQs: Record<string, unknown> = {
      status: statuses.map((status) => spacesToUnderscores(status.label)),
    };
    if (currentUser && userRoles.isResponseUser) {
      newQs.assigneeUlid = ['UNASSIGNED', currentUser.userUlid];
    }
    if (organization && !Object.keys(queryOptions).length) {
      history.push(`${location.pathname}?${queryString.stringify(newQs, { arrayFormat: 'comma' })}`);
    } else {
      delete filtersOptions.reviewUlid;
      delete filtersOptions.changed;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { state, code, ...rest } = filtersOptions;
      if (!Object.keys(rest)?.length) {
        history.push(`${location.pathname}?${queryString.stringify(newQs, { arrayFormat: 'comma' })}`);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { reviewUlid, changed, ...restQ } = rest;
        setDefaultFilters(restQ);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  return (
    <>
      {queryOptions && (
        <>
          <ResponseInbox defaultFilters={defaultFilters} />
          {isTicketDataInitialLoading ? (
            <ResponseConsoleSection flex="1 1 auto">
              <NoDataMessage>{'Loading...'}</NoDataMessage>
            </ResponseConsoleSection>
          ) : (
            <ResponseTicket ticket={ticketData} />
          )}
        </>
      )}
    </>
  );
};

const ResponseConsoleRouter = (props: {}): React.ReactElement => {
  const [{ currentUser }] = useStateValue();

  if (!currentUser) {
    return <></>;
  }

  return (
    <>
      <Route
        exact
        path="/response-console"
        render={(routeProps) => {
          if (currentUser.userRole !== SessionUser.SessionUserRoleType.SYSTEMADMIN) {
            return <Redirect to={`/response-console/${currentUser.responseOrganizationUlid}`} />;
          }

          return <ResponseConsole {...routeProps} />;
        }}
      />
      <Switch>
        <Route path={'/response-console/:organization'} component={ResponseConsole} />
      </Switch>
    </>
  );
};

export default ResponseConsoleRouter;
