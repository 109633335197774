import { MouseEvent, ReactNode } from 'react';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';
import CardBody from './CardBody';

interface CardHeadingProps {
  'aria-controls'?: string;
  headingColor?: string;
  children: ReactNode | ReactNode[];
  onClick?: (e?: MouseEvent<Element, MouseEvent>) => void;
  white?: boolean;
}

const CardHeading = styled.div<CardHeadingProps>`
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${vars.$spacing_medium_large};
  background-color: ${(props) => {
    return props.headingColor ? props.headingColor : vars.$gray_f4;
  }};
  color: ${(props) => {
    return props.white ? 'white' : 'inherit';
  }};
  transition: 250ms border-radius;
  border-top-left-radius: ${vars.$border_radius};
  border-top-right-radius: ${vars.$border_radius};
  &[aria-expanded='false'] {
    border-bottom-left-radius: ${vars.$border_radius};
    border-bottom-right-radius: ${vars.$border_radius};
  }

  border: 1px solid transparent;
  border-color: ${(props) => {
    return props.headingColor ? props.headingColor : vars.$border_color;
  }};
  border-bottom: 0;

  /* If next sibling is CardBody & headingColor exists, remove top border from card */
  /* Does not work for Accordion cards */
  + ${CardBody} {
    ${(props) => {
      return props.headingColor && 'border-top: 0;';
    }}
  }

  cursor: ${(props) => {
    return props.onClick !== undefined ? 'pointer' : 'default';
  }};
`;

CardHeading.displayName = 'Card.Heading';

export default CardHeading;
