import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';
import loginBackground from 'src/assets/images/login_background.svg';
import vars from 'src/styles/variables';

const PageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
  flex-wrap: nowrap;
  background: url(${loginBackground}) left bottom no-repeat fixed;
  background-size: 100%;
  align-items: center;
  justify-content: center;
`;

const black = '27, 28, 29';

/* prettier-ignore */
const ErrorCard = styled.div`
  text-align: center;
  align-self: center;
  padding: 2.5rem 3.75rem;
  width: 65vw;
  margin: 0 auto;
  background-color: white;
  border-radius: ${vars.$border_radius};
  box-shadow:
    0 3px 2px rgba(${black}, 0.024),
    0 7px 5px rgba(${black}, 0.038),
    0 12px 10px rgba(${black}, 0.05),
    0 22px 18px rgba(${black}, 0.062),
    0 42px 32px rgba(${black}, 0.076),
    0 80px 60px rgba(${black}, 0.12);
`;

const ErrorText = styled.h1`
  font-size: 25vw;
  color: #5bbe90;
  font-weight: 600;
`;

const PageNotFound = (props: {}): ReactElement => (
  <PageWrapper>
    <ErrorCard>
      <ErrorText>{'404'}</ErrorText>
    </ErrorCard>
  </PageWrapper>
);

export default PageNotFound;
