import React from 'react';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { InputElement, Column, Row } from 'src/components';
import { InputElementFormConfig } from 'src/types';

type FormValues = typeof initialFormValues;

export const initialFormValues = {
  firstName: '',
  lastName: '',
  email: '',
};

export const validationSchema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
});

export const formConfig: InputElementFormConfig = {
  firstName: {
    label: 'First Name',
    placeholder: 'First Name',
    name: 'firstName',
    autoComplete: 'given-name',
  },
  lastName: {
    label: 'Last Name',
    placeholder: 'Last Name',
    name: 'lastName',
    autoComplete: 'family-name',
  },
  email: {
    label: 'Email',
    placeholder: 'Email',
    name: 'email',
    autoComplete: 'email',
  },
};

export const RenderForm = (props: FormikProps<FormValues>): React.ReactElement => {
  return (
    <>
      <Row>
        <Column width={[1 / 2]}>
          <InputElement border disabled {...formConfig.firstName} />
        </Column>
        <Column width={[1 / 2]}>
          <InputElement border disabled {...formConfig.lastName} />
        </Column>
        <Column width={[1]}>
          <InputElement border disabled {...formConfig.email} />
        </Column>
      </Row>
    </>
  );
};
