import React from 'react';
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select';
import { ResponseOrganization } from '@friendemic/erc-graph';
import { FormGroup, SelectAsync, Label } from 'src/components';
import { LIST_RESPONSE_ORGANIZATIONS } from 'src/graph';
import { getRecords } from 'src/hooks';

const OrganizationAsyncSelect = (props: {
  onChange: ((value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => void) | undefined;
  value?: OptionTypeBase;
  isDisabled?: boolean;
}): JSX.Element => {
  async function fetchOrganizations() {
    const res = await getRecords({
      queryString: LIST_RESPONSE_ORGANIZATIONS,
    });

    const { data }: { data: { listResponseOrganizations: ResponseOrganization[] } } = res;

    if (data) {
      return data.listResponseOrganizations.map((a: ResponseOrganization) => ({
        label: a.name,
        value: a.ulid,
      }));
    }

    return [];
  }
  return (
    <form>
      <FormGroup hasError={false}>
        <Label htmlFor="organizationSelectAsync">{'Organization'}</Label>
        <SelectAsync
          name="organizationSelectAsync"
          hasError={false}
          isDisabled={props.isDisabled}
          border
          defaultOptions
          placeholder="Select An Organization..."
          id="organizationSelectAsync"
          loadOptions={fetchOrganizations}
          filterOption={(candidate, input) => {
            return candidate.data.__isNew__ || candidate.label.toLowerCase().includes(input);
          }}
          value={props.value}
          onChange={props.onChange}
          data-cy="organization-select"
        />
      </FormGroup>
    </form>
  );
};

export default OrganizationAsyncSelect;
