import React from 'react';
import toaster from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconTheme, Renderable, Toast, ValueOrFunction } from 'react-hot-toast/dist';
import styled from 'styled-components/macro';
import { vars } from 'src/styles/variables';
import Button from '../components/Button';

const ToastWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CrossButton = styled(Button)`
  color: inherit;
  margin-left: ${vars.$spacing_medium_large};
  font-size: 1.15rem;
  padding: 0;
`;

type Variant = 'dark' | 'light';
type ToastProps = {
  type?: 'error' | 'warn' | 'info' | 'success' | 'loading' | 'default';
  variant?: Variant;
  options?: Partial<Exclude<Toast, 'message'>>;
  message: ValueOrFunction<Renderable, Toast> | any;
  removeConfig?: {
    duration?: number;
    removeType: 'remove' | 'removeAll' | 'dismiss' | 'dismissAll';
  };
  promiseToResolve?: {
    loadingMessage?: string;

    promise: Promise<any>;
  };
  callback?: (...args: any) => void;
} & Partial<Omit<typeof toaster, 'dismiss' | 'remove' | 'loading'>>;
const SHARED_TOAST_STYLES = { maxHeight: '50vh', maxWidth: '500px', overflow: 'auto' };
const TOASTS_COLORS = {
  error: {
    dark: { color: vars.$white, backgroundColor: vars.$danger, ...SHARED_TOAST_STYLES },
    light: { color: vars.$white, backgroundColor: vars.$redlight, ...SHARED_TOAST_STYLES },
  },
  warn: {
    dark: { color: vars.$white, backgroundColor: vars.$yellowdark, ...SHARED_TOAST_STYLES },
    light: { color: vars.$gray_8, backgroundColor: vars.$yellowlight, ...SHARED_TOAST_STYLES },
  },
  success: {
    dark: { color: vars.$white, backgroundColor: vars.$greenlight, ...SHARED_TOAST_STYLES },
    light: { color: vars.$white, backgroundColor: vars.$greenlighter, ...SHARED_TOAST_STYLES },
  },
  info: {
    dark: { color: vars.$white, backgroundColor: vars.$info, ...SHARED_TOAST_STYLES },
    light: { color: vars.$gray_fa, backgroundColor: vars.$infolight, ...SHARED_TOAST_STYLES },
  },
  loading: {
    dark: { color: vars.$white, backgroundColor: vars.$greendark, ...SHARED_TOAST_STYLES },
    light: { color: vars.$white, backgroundColor: vars.$greenlight, ...SHARED_TOAST_STYLES },
  },
  default: {
    dark: { color: vars.$black, backgroundColor: vars.$white, ...SHARED_TOAST_STYLES },
    light: { color: vars.$black, backgroundColor: vars.$white, ...SHARED_TOAST_STYLES },
  },
};

export const toast = (props: ToastProps): void => {
  const {
    type = 'success',
    variant = 'dark',
    options,
    promise,
    promiseToResolve,
    removeConfig,
    message,
    callback,
  } = props;
  let messageToRender: any;
  if (typeof message === 'function') {
    messageToRender = message();
  }
  if (typeof message === 'object') {
    messageToRender = JSON.stringify(message, null, 2);
  }
  const styles = options?.style ?? { ...TOASTS_COLORS[type][variant] };
  const customWrapper = (t: any) => (
    <ToastWrapper>
      {message}
      <CrossButton iconOnly blank icon="times" btnSm onClick={() => toaster.dismiss(t.id)} />
    </ToastWrapper>
  );
  function customToaster(style: Record<string, any>, iconTheme: IconTheme | undefined) {
    toaster(() => messageToRender as any, {
      style,
      iconTheme,
    });
  }
  function error() {
    const style = { ...styles };
    const iconTheme = {
      primary: vars.$gray_fa,
      secondary: vars.$danger,
    };
    if (messageToRender) {
      customToaster(style, iconTheme);
    } else {
      toaster.error(customWrapper, {
        ...options,
        style,
        iconTheme,
      });
    }
    callback && callback(toast, options?.id);
  }
  function info() {
    const iconTheme = {
      primary: vars.$gray_fa,
      secondary: vars.$blue,
    };
    const style = { ...styles };
    if (messageToRender) {
      customToaster(style, iconTheme);
    } else {
      toaster.success(customWrapper, {
        ...options,
        style,
        iconTheme,
      });
    }
    callback && callback(toast, options?.id);
  }
  function success() {
    const iconTheme = {
      primary: vars.$gray_fa,
      secondary: vars.$greenlight,
    };
    const style = { ...styles };
    if (messageToRender) {
      customToaster(style, iconTheme);
    } else {
      toaster.success(customWrapper, {
        ...options,
        style,
        iconTheme,
      });
    }
    callback && callback(toast, options?.id);
  }
  function loadingToast() {
    toaster.success(message, {
      ...options,
      style: { ...styles },
    });
    callback && callback(toast, options?.id);
  }
  function warn() {
    const iconTheme = {
      primary: vars.$gray_fa,
      secondary: vars.$yellow,
    };
    const style = { ...styles };
    if (messageToRender) {
      customToaster(style, iconTheme);
    } else {
      toaster.error(customWrapper, {
        ...options,
        style,
        iconTheme,
        icon: <FontAwesomeIcon icon="exclamation-triangle" />,
      });
    }
    callback && callback(toast, options?.id);
  }
  function defaultToaster() {
    if (messageToRender) {
      customToaster({ ...styles }, options?.iconTheme);
    } else {
      toaster.error(customWrapper, {
        ...options,
        style: { ...styles },
      });
    }
    callback && callback(toast, options?.id);
  }
  switch (type) {
    case 'error':
      error();
      break;
    case 'info':
      info();
      break;
    case 'warn':
      warn();
      break;
    case 'success':
      success();
      break;
    case 'loading':
      loadingToast();
      break;
    default:
      defaultToaster();
      break;
  }
  if (promise && promiseToResolve) {
    toaster.promise(promiseToResolve.promise, {
      loading: promiseToResolve.loadingMessage ? promiseToResolve.loadingMessage : 'Loading...',
      success: 'Operation successful',
      error: 'Error occured',
    });
  }
  if (removeConfig?.removeType === 'dismiss' && options?.id) {
    setTimeout(() => toaster.dismiss(options?.id), removeConfig.duration || 2000);
  }
  if (removeConfig?.removeType === 'dismissAll') {
    setTimeout(() => toaster.dismiss(), removeConfig.duration || 2000);
  }
  if (removeConfig?.removeType === 'remove' && options?.id) {
    setTimeout(() => toaster.remove(options?.id), removeConfig.duration || 1000);
  }
  if (removeConfig?.removeType === 'removeAll') {
    setTimeout(() => toaster.remove(), removeConfig.duration || 1000);
  }
};
