import styled, { css } from 'styled-components/macro';
import vars from 'src/styles/variables';
import Column from './Column';

interface ContainerFluidProps {
  fullHeight?: boolean;
  overflowAuto?: boolean;
  pa0?: boolean;
}

export const ContainerFluid = styled.div<ContainerFluidProps>`
  width: 100%;
  padding-left: ${vars.$spacing_small};
  padding-right: ${vars.$spacing_small};

  &:after,
  &:before {
    display: table;
    content: ' ';
  }

  overflow: ${(props) => {
    return props.overflowAuto && 'auto';
  }};

  ${(props) => {
    return (
      props.fullHeight &&
      css`
        height: 100vh;
        max-height: 100vh;
        flex: 0 0 auto;
        display: flex;
        flex-flow: row;
        padding: ${vars.$spacing};

        ${Column} {
          overflow-y: auto;
        }
      `
    );
  }}

  padding: ${(props) => {
    return props.pa0 && '0';
  }};
`;
ContainerFluid.displayName = 'ContainerFluid';
export default ContainerFluid;
