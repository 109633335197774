import React from 'react';
import { ScrollWrapper, SubNavbar } from 'src/components';
import { SettingsSubsection } from '../../settings.styled';

const PageTemplate = (props: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  return (
    <>
      <SubNavbar icon="money-check-edit" title="User Management" />
      <ScrollWrapper>
        <SettingsSubsection>{props.children}</SettingsSubsection>
      </ScrollWrapper>
    </>
  );
};

export default PageTemplate;
