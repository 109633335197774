import styled, { css, FlattenSimpleInterpolation } from 'styled-components/macro';
import { useStateValue } from 'src/context';

type DebugPixelPlacement = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
type DebugPixelProps = {
  log?: Record<string, any>;
  placement?: DebugPixelPlacement;
  debug?: boolean;
};

function getPlacement(placement: DebugPixelPlacement = 'top-right'): FlattenSimpleInterpolation {
  switch (placement) {
    case 'top-left':
      return css`
        top: 0;
        left: 0;
      `;
    case 'top-right':
      return css`
        top: 0;
        right: 0;
      `;
    case 'bottom-left':
      return css`
        bottom: 0;
        left: 0;
      `;
    case 'bottom-right':
      return css`
        bottom: 0;
        left: 0;
      `;
    default:
      return css``;
  }
}

const DebugPixel = styled.div.attrs((props: DebugPixelProps) => {
  const [globalState] = useStateValue();

  return {
    onClick: () => console.info({ globalState, ...props.log }),
  };
})<DebugPixelProps>`
  padding: 3px;
  position: absolute;
  z-index: 1000;
  ${({ debug }) => debug && 'border: 1px solid red;'}
  ${({ placement }) => getPlacement(placement)}
`;

export default DebugPixel;
