import React from 'react';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { InputElement } from 'src/components';
import { InputElementFormConfig } from 'src/types';
import { SubmitCommentButton, CommentBox } from './ResponseNotes.styled';

export type FormValues = typeof initialFormValues;

export const initialFormValues = {
  noteContent: '',
};

export const validationSchema = Yup.object({
  noteContent: Yup.string().required('Required'),
});

export const formConfig: InputElementFormConfig = {
  noteContent: {
    // type: 'textarea',
    placeholder: 'Add a note...',
    name: 'noteContent',
    border: true,
  },
};

export const RenderForm = (props: FormikProps<FormValues>): React.ReactElement => {
  return (
    <CommentBox>
      <InputElement {...formConfig.noteContent} />
      <SubmitCommentButton type="submit" />
    </CommentBox>
  );
};
