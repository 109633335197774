import { lighten } from 'color2k';
import styled, { css } from 'styled-components/macro';
import vars from 'src/styles/variables';

export const StaticInput = styled.div`
  div {
    border: ${vars.$border};
    border-radius: ${vars.$border_radius};
    padding: ${vars.$spacing_extra_small} ${vars.$spacing_small};
    background-color: ${vars.$graylight};
    margin-bottom: 1.25rem;
    cursor: not-allowed;
    background-color: ${vars.$gray_fa};
    opacity: 1;
    font-size: 73%;
    line-height: normal;
  }
`;

export const selectStyles = css<{ hasError?: boolean; border?: boolean; moreSelectSpacing?: boolean }>`
  .Select_ {
    &_single-value,
    &_placeholder {
      font-size: ${vars.$font_size_sm};
    }
    &_single-value {
      color: ${(props) => {
        return props.hasError ? vars.$danger : vars.$gray_6;
      }};
    }
    &_placeholder {
      color: ${(props) => {
        return props.hasError ? vars.$danger : vars.$gray_9;
      }};
    }
    &_control {
      border-color: ${vars.$white};
      box-shadow: ${vars.$shallow_shadow};
      border-radius: ${vars.$border_radius};
      cursor: pointer;
      ${(props) => {
        return (
          props.border &&
          css`
            border-color: ${props.hasError ? vars.$danger : vars.$border_color};
            box-shadow: none;
          `
        );
      }}
      &:hover {
        border-color: ${(props) => {
          return props.hasError ? vars.$danger : props.border ? vars.$gray_b : 'white';
        }};
      }

      &--menu-is-open {
        &:hover {
          border-color: ${(props) => {
            return props.hasError ? vars.$danger : lighten(vars.$primary, 0.25);
          }};
        }
        border-color: ${(props) => {
          return props.hasError ? vars.$danger : lighten(vars.$primary, 0.25);
        }};
      }
    }
    &_indicator-separator {
      background-color: ${(props) => {
        return props.hasError ? vars.$danger : vars.$border_color;
      }};
    }
    &_dropdown-indicator {
      svg {
        color: ${(props) => {
          return props.hasError ? vars.$danger : vars.$border_color;
        }};
      }
    }
    &_clear-indicator {
      color: ${(props) => {
        return props.hasError ? vars.$danger : vars.$border_color;
      }};

      &:hover {
        color: ${(props) => {
          return props.hasError ? vars.$danger : vars.$gray_b;
        }};
      }
    }
    &_menu-list {
      max-height: 20rem;
    }
    &_value-container {
      padding: ${(props) => {
        return props.moreSelectSpacing ? '0.875rem 0.5rem' : '0.125rem 0.5rem';
      }};
    }
    &_option {
      &:hover {
        background-color: ${lighten(vars.$greenlight, 0.4)};
      }
      &--is-focused {
        background-color: ${lighten(vars.$greenlight, 0.4)};
        &:hover {
          background-color: ${lighten(vars.$greenlight, 0.4)};
        }
      }
      &--is-selected {
        background-color: ${vars.$greenlight};
        color: white;
        &:hover {
          background-color: ${vars.$greenlight};
        }
      }
    }
  }
`;
