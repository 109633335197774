import React from 'react';
import { useModal } from 'react-modal-hook';
import { useRouteMatch, useParams, Switch, Route, Redirect } from 'react-router-dom';
import { ResponseOrganization } from '@friendemic/erc-graph';
import _ from 'lodash';
import styled from 'styled-components/macro';
import { useDeleteResponsePlace, useListResponsePlaces } from 'src/async';
import { useGetResponseOrganization, useListResponseOrganizations } from 'src/async/responseOrganizations';
import { ResponsePlace } from 'src/async/types';
import {
  Row,
  Column,
  FormGroup,
  InputGroup,
  Input,
  Button,
  ErrorBlock,
  ScrollWrapper,
  SubNavbar,
  DropDown,
} from 'src/components';
import { useStateValue } from 'src/context';
import vars from 'src/styles/variables';
import { toast } from 'src/utils';
import {
  SettingsInternalNav,
  SettingsListItem,
  LocationSettingsListItem,
  IsActiveFlag,
  DeleteLocationItemWrapper,
} from 'src/views/Settings/settings.styled';
import { LocationSettingsRouteParams } from 'src/views/Settings/SettingsTypes';
import { AddLocationModal, AddOrganizationModal } from './components';
import LocationSettingsPage from './LocationSettingsPage';

const ItemWrapper = styled.div<{ selected: boolean }>`
  font-size: ${vars.$font_size_extra_sm};
  color: ${vars.$font_color};
`;

const DropdownItem = (props: Record<string, any>) => {
  const {
    data: { name, selected },
  } = props;
  return <ItemWrapper selected={selected}>{name}</ItemWrapper>;
};

const filterItems = [
  { name: 'All', selected: true },
  { name: 'Active', selected: false },
  { name: 'Inactive', selected: false },
];

const LocationSettingsChooseOrganization = (props: {}): React.ReactElement => {
  const {
    data: responseOrganizationsData,
    isLoading: isResponseOrganizationsLoading,
    isError: isResponseOrganizationsError,
    error: responseOrganizationsError,
  } = useListResponseOrganizations();

  const [filteredOrganizations, setFilteredOrganizations] = React.useState<ResponseOrganization[]>([]);
  const [showAddOrganizationModal, hideAddOrganizationModal] = useModal(() => (
    <AddOrganizationModal hideModal={hideAddOrganizationModal} />
  ));

  React.useEffect(() => {
    responseOrganizationsData && setFilteredOrganizations(_.sortBy(responseOrganizationsData, 'name'));
  }, [responseOrganizationsData]);

  if (isResponseOrganizationsLoading)
    return (
      <SettingsInternalNav>
        <SubNavbar icon="spinner-third" spin title="Loading..." />
      </SettingsInternalNav>
    );
  if (!responseOrganizationsData && isResponseOrganizationsError)
    return <ErrorBlock error={responseOrganizationsError} />;

  function handleFilter(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.currentTarget;
    const filtered =
      responseOrganizationsData &&
      responseOrganizationsData.filter((orgMatch: ResponseOrganization) => {
        return (
          (orgMatch.ulid && orgMatch.ulid.toLowerCase().includes(value.toLowerCase())) ||
          (orgMatch.name && orgMatch.name.toLowerCase().includes(value.toLowerCase()))
        );
      });

    setFilteredOrganizations(_.sortBy(filtered, 'name'));
  }

  return (
    <SettingsInternalNav>
      <SubNavbar icon="sitemap" title="Organizations" />
      <ScrollWrapper>
        <Row>
          <Column flex="1" width="auto">
            <FormGroup hasError={false}>
              <InputGroup iconLeft="search">
                <Input placeholder="Search Organizations" onChange={handleFilter} />
              </InputGroup>
            </FormGroup>
          </Column>
          <Column flex="0 1 auto" width="auto">
            <Button icon="plus" onClick={showAddOrganizationModal}>
              {'Add Organization'}
            </Button>
          </Column>
        </Row>
        {filteredOrganizations
          .sort(function (a, b) {
            const textA = a.name.toUpperCase();
            const textB = b.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .map((org: ResponseOrganization) => {
            const { ulid, name } = org;
            return (
              <SettingsListItem to={`/settings/location-settings/${ulid}`} key={ulid}>
                {name}
              </SettingsListItem>
            );
          })}
      </ScrollWrapper>
    </SettingsInternalNav>
  );
};

const LocationSettingsChooseLocation = (props: {}): React.ReactElement => {
  const [{ userRoles }] = useStateValue();
  const { organization } = useParams<LocationSettingsRouteParams>();

  const {
    data: responsePlacesData,
    isLoading: isResponsePlacesLoading,
    isError: isResponsePlacesError,
    error: responsePlacesError,
  } = useListResponsePlaces(organization);

  const {
    data: responseOrganizationData,
    isLoading: isResponseOrganizationLoading,
    isError: isResponseOrganizationError,
    error: responseOrganizationError,
  } = useGetResponseOrganization(organization);

  const { mutateAsync: deletePlace } = useDeleteResponsePlace();

  const [filteredPlaces, setFilteredPlaces] = React.useState<ResponsePlace[]>([]);
  const [dropDownItems, setDropDownItems] = React.useState<Record<string, any>[]>(filterItems);
  const [showAddLocationModal, hideAddLocationModal] = useModal(() => (
    <AddLocationModal responseOrganizationUlid={organization} hideModal={hideAddLocationModal} />
  ));

  React.useEffect(() => {
    responsePlacesData &&
      responseOrganizationData &&
      setFilteredPlaces(_.sortBy(responsePlacesData, (o) => o?.placeName));
  }, [responsePlacesData, responseOrganizationData]);

  if (isResponsePlacesLoading || isResponseOrganizationLoading)
    return (
      <SettingsInternalNav>
        <SubNavbar icon="spinner-third" spin title="Loading..." />
      </SettingsInternalNav>
    );
  if ((!responseOrganizationData || !responsePlacesData) && (isResponsePlacesError || isResponseOrganizationError))
    return <ErrorBlock error={{ responsePlacesError, responseOrganizationError }} />;

  function handleFilter(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.currentTarget;
    const filtered =
      responsePlacesData &&
      responsePlacesData.filter((placeMatch: ResponsePlace) => {
        return (
          (placeMatch.placeUlid && placeMatch.placeUlid.toLowerCase().includes(value.toLowerCase())) ||
          (placeMatch.placeName && placeMatch.placeName.toLowerCase().includes(value.toLowerCase())) ||
          (placeMatch.organizationName && placeMatch.organizationName.toLowerCase().includes(value.toLowerCase()))
        );
      });

    setFilteredPlaces(_.sortBy(filtered, (o) => o?.placeName));
  }
  async function deletePlaceHandler(e: React.MouseEvent, placeUlid: string, placeName?: string | null) {
    e.preventDefault();
    e.stopPropagation();
    await deletePlace(
      {
        placeUlid: placeUlid,
      },
      {
        onSuccess: async () => {
          toast({ type: 'error', message: `Place ${placeName} deleted` });
        },
        onError: (err) => {
          console.error(err);
          toast({ type: 'error', message: err.response?.data?.message || err.message });
        },
      }
    );
  }

  function updateSelectedFilter(item: Record<string, any>) {
    let places: ResponsePlace[] = [];
    const filters: Record<string, any>[] = [];

    dropDownItems.forEach((dropDownItem) => {
      if (dropDownItem.name === item.name) {
        filters.push({ name: dropDownItem.name, selected: true });
      } else {
        filters.push({ name: dropDownItem.name, selected: false });
      }
    });

    if (item.name === 'Active')
      places = responsePlacesData ? responsePlacesData.filter((item) => Boolean(item.active)) : [];
    else if (item.name === 'Inactive')
      places = responsePlacesData ? responsePlacesData.filter((item) => Boolean(!item.active)) : [];
    else places = responsePlacesData ? responsePlacesData : [];

    setDropDownItems(filters);
    setFilteredPlaces(_.sortBy(places, (o) => o?.placeName));
  }

  return (
    <SettingsInternalNav>
      <SubNavbar
        icon="map-marker-alt"
        title={`Locations for ${responseOrganizationData?.name || ''}`}
        // hide back button if user is an org manager
        // show if user is admin
        backButton={userRoles.isAdmin ? '/settings/location-settings' : undefined}
      />
      <ScrollWrapper>
        <Row>
          <Column flex="1" width="auto">
            <FormGroup hasError={false}>
              <InputGroup iconLeft="search">
                <Input placeholder="Search Locations" onChange={handleFilter} />
              </InputGroup>
            </FormGroup>
          </Column>
          <Column flex="0 1 auto" width="auto">
            <DropDown
              dropdownPosition="left"
              headerComp={{
                component: Button,
                props: {
                  icon: 'filter',
                },
              }}
              onSelect={(e) => {
                updateSelectedFilter(e);
              }}
              data={dropDownItems}
              component={DropdownItem}
              itemPadding={'10px'}
            />
          </Column>
          <Column flex="0 1 auto" width="auto">
            <Button icon="plus" onClick={showAddLocationModal}>
              {'Add Location'}
            </Button>
          </Column>
        </Row>
        {filteredPlaces.map((placeMatch: ResponsePlace) => {
          const { placeUlid, placeName, organizationName, active } = placeMatch;

          if (!placeUlid) {
            return null;
          }

          const isActive = Boolean(active);

          return placeMatch ? (
            <LocationSettingsListItem
              placeIsActive={active}
              to={`/settings/location-settings/${organization}/${placeUlid}`}
              key={placeUlid}
            >
              <span>{placeName}</span>
              <span>{organizationName}</span>
              <IsActiveFlag isActive={isActive}>{isActive ? 'Active' : 'Inactive'}</IsActiveFlag>
              <DeleteLocationItemWrapper onClick={(e) => deletePlaceHandler(e, placeUlid, placeName)}>
                {'Delete'}
              </DeleteLocationItemWrapper>
            </LocationSettingsListItem>
          ) : null;
        })}
      </ScrollWrapper>
    </SettingsInternalNav>
  );
};

const InternalRouter = (props: {}): React.ReactElement => {
  const { path } = useRouteMatch();
  const params = useParams<LocationSettingsRouteParams>();
  const [{ currentUser }] = useStateValue();

  /**
   * Check if currentUser has an associated organization.
   *   If so, set the organization param and skip to choose location.
   *   `exact` and `from` props prevent an infinite loop.
   */
  return (
    <Switch>
      {Boolean(!params.organization && currentUser && currentUser.responseOrganizationUlid) && (
        <Redirect
          exact
          from="/settings/location-settings/"
          to={`/settings/location-settings/${currentUser.responseOrganizationUlid}`}
        />
      )}
      <Route path={`${path}/:organization?/:location?`}>
        <Navigation />
      </Route>
    </Switch>
  );
};

function Navigation() {
  const params = useParams<LocationSettingsRouteParams>();

  if (params.location) {
    return <SettingsInternalNav>{<LocationSettingsPage params={params} />}</SettingsInternalNav>;
  } else if (params.organization) {
    return <LocationSettingsChooseLocation />;
  } else {
    return <LocationSettingsChooseOrganization />;
  }
}

export default InternalRouter;
