import _ from 'lodash';
import { ReviewResponseTicket } from 'src/async/types';
import {
  assigneeResponseUser,
  reviewResponseTicket,
  reviewResponseNotes,
  reviewResponseTags,
  reviewResponseApprovals,
  reviewResponseSubmissions,
} from './reusable.fixture';

// ------------------------------------------------
// LISTREVIEWRESPONSETICKET STATES - REPRESENT A TIMELINE OF STATUS EVENTS
// ------------------------------------------------
export const unknown: ReviewResponseTicket = _.merge({}, reviewResponseTicket, {
  type: 1,
  status: 0, // UNKNOWN (default starting status)
  reviewUlid: 'REVIEW_ULID_1',
  review: {
    ulid: 'REVIEW_ULID_1',
  },
});

export const flagged_for_manager: ReviewResponseTicket = _.merge({}, reviewResponseTicket, {
  type: 2,
  status: 0, // UNKNOWN (no change)
  reviewResponseNotes: [reviewResponseNotes[0]],
  reviewResponseTags: reviewResponseTags,
  reviewUlid: 'REVIEW_ULID_2',
  review: {
    ulid: 'REVIEW_ULID_2',
  },
});

const fixture_base_responded: ReviewResponseTicket = {
  ...reviewResponseTicket,
  reviewResponseNotes: reviewResponseNotes,
};
const fixture_base_assigned: ReviewResponseTicket = {
  ...fixture_base_responded,
  assigneeUser: assigneeResponseUser,
};

export const manager_responded: ReviewResponseTicket = _.merge({}, fixture_base_responded, {
  status: 0, // UNKNOWN (no change)
  reviewUlid: 'REVIEW_ULID_3',
  review: {
    ulid: 'REVIEW_ULID_3',
  },
});

export const response_submitted: ReviewResponseTicket = _.merge({}, fixture_base_assigned, {
  status: 10, // RESPONSE_SUBMITTED
  reviewResponseSubmissions: [reviewResponseSubmissions[0]],
  reviewUlid: 'REVIEW_ULID_4',
  review: {
    ulid: 'REVIEW_ULID_4',
  },
});

export const edits_requested_denied: ReviewResponseTicket = _.merge({}, fixture_base_assigned, {
  type: 3,
  status: 11, // EDITS_REQUESTED
  reviewResponseSubmissions: [
    _.merge({}, reviewResponseSubmissions[0], {
      reviewResponseApprovals: [reviewResponseApprovals[0]],
    }),
  ],
  reviewUlid: 'REVIEW_ULID_5',
  review: {
    ulid: 'REVIEW_ULID_5',
  },
});

export const response_re_submitted: ReviewResponseTicket = _.merge({}, fixture_base_assigned, {
  status: 10, // RESPONSE_SUBMITTED
  reviewResponseSubmissions: [
    _.merge({}, reviewResponseSubmissions[0], {
      reviewResponseApprovals: [reviewResponseApprovals[0]],
    }),
    reviewResponseSubmissions[1],
  ],
  reviewUlid: 'REVIEW_ULID_6',
  review: {
    ulid: 'REVIEW_ULID_6',
  },
});

export const response_approved: ReviewResponseTicket = _.merge({}, fixture_base_assigned, {
  status: 12, // APPROVED_PENDING_PUBLISH
  reviewResponseSubmissions: [
    _.merge({}, reviewResponseSubmissions[0], {
      reviewResponseApprovals: [reviewResponseApprovals[0]],
    }),
    _.merge({}, reviewResponseSubmissions[1], {
      reviewResponseApprovals: [reviewResponseApprovals[1], reviewResponseApprovals[2]],
    }),
  ],
  reviewUlid: 'REVIEW_ULID_7',
  review: {
    ulid: 'REVIEW_ULID_7',
  },
});

export const response_published: ReviewResponseTicket = _.merge({}, response_approved, {
  status: 30, // PUBLISHED
  reviewUlid: 'REVIEW_ULID_8',
  review: {
    ulid: 'REVIEW_ULID_8',
  },
});

export const response_completed: ReviewResponseTicket = _.merge({}, response_approved, {
  status: 31, // COMPLETED
  reviewUlid: 'REVIEW_ULID_9',
  review: {
    ulid: 'REVIEW_ULID_9',
  },
});

export const active_ticket_fixture: ReviewResponseTicket = _.merge({}, response_approved, {
  status: 0, // COMPLETED
  reviewUlid: 'REVIEW_ULID_10',
  review: {
    ulid: 'REVIEW_ULID_10',
  },
});
