import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { ImageProps } from 'src/types';

const Carfax = ({ width = '100%', active, deleted }: ImageProps): ReactElement => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 64 64"
    width={width}
    height={width}
    xmlSpace="preserve"
    enableBackground="new 0 0 64 64"
  >
    <path
      fill={deleted && active ? '#eb2424' : active ? '#5dc094' : '#ffffff'}
      d="M59,48C54.87,56.11,48,64,33.24,64,14.8,64,5,50.35,5,32,5,12.06,16.66,0,33.33,0,48.58,0,54.6,6.56,58.86,18.26l-15.6,6.12C41,18.62,38.91,15,33.24,15,26.06,15,23,22.16,23,32c0,9.49,3,17,10.46,17,5.41,0,7.71-2.93,11.08-8.78Z"
    />
  </svg>
);

Carfax.propTypes = {
  width: PropTypes.string,
  active: PropTypes.bool,
};

export default Carfax;
