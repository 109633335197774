import React, { ReactElement } from 'react';
import { Formik } from 'formik';
import merge from 'lodash/merge';
import { useEditResponsePlace } from 'src/async';
import { ResponsePlace } from 'src/async/types';
import { Row, Column, List } from 'src/components';
import { FormikResetForm } from 'src/types';
import { toast } from 'src/utils';
import { LoadingSubsectionPlaceholder, SettingsSubsection } from 'src/views/Settings/settings.styled';
import { LocationSettingsRouteParams } from 'src/views/Settings/SettingsTypes';
import { FormValues, initialFormValues, RenderForm, validationSchema } from './ResponseSettingsForm';

const ResponseSettings = (props: {
  params: LocationSettingsRouteParams;
  responsePlaceData?: ResponsePlace;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  isResponsePlaceDataLoading: boolean;
}): ReactElement => {
  const { params, responsePlaceData, setSaving, isResponsePlaceDataLoading } = props;
  const [formValues, setFormValues] = React.useState(initialFormValues);

  const { mutateAsync: editResponsePlace, isLoading: isEditResponsePlaceLoading } = useEditResponsePlace();

  React.useEffect(() => {
    if (responsePlaceData?.settings?.response_settings) {
      setFormValues({
        response_delay_hours: responsePlaceData.settings.response_settings.response_delay_hours || 0,
      });
    }
  }, [responsePlaceData]);

  React.useEffect(() => {
    setSaving(isEditResponsePlaceLoading);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isEditResponsePlaceLoading]);

  async function handleUpdateSettings(values: FormValues, resetForm: FormikResetForm) {
    await editResponsePlace(
      {
        placeUlid: params.location,
        settings: merge(responsePlaceData?.settings, {
          response_settings: {
            response_delay_hours: values.response_delay_hours,
          },
        }),
      },
      {
        onSuccess: () => {
          resetForm({ values });
        },
        onError: (err) => {
          console.error(err);
          toast({ type: 'error', message: err.response?.data?.message || err.message });
        },
      }
    );
  }

  if (isResponsePlaceDataLoading) return <LoadingSubsectionPlaceholder />;

  return (
    <SettingsSubsection>
      <Row>
        <Column>
          <List title="Response Settings" icon="money-check-edit">
            <li>
              <Formik
                initialValues={formValues}
                enableReinitialize
                validateOnMount
                validationSchema={validationSchema}
                validateOnBlur
                validateOnChange
                onSubmit={(values, { resetForm }) => {
                  handleUpdateSettings(values, resetForm);
                }}
              >
                {(props) => <RenderForm handleUpdateSettings={handleUpdateSettings} {...props} />}
              </Formik>
            </li>
          </List>
        </Column>
      </Row>
    </SettingsSubsection>
  );
};

export default ResponseSettings;
