import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ErrorBlock } from 'src/components';
import { responseFeedbackRoutes } from 'src/routes';

const ResponseFeedbackRouter = (props: {}): React.ReactElement => {
  return (
    <>
      <Route exact path="/response-feedback">
        <ErrorBlock error="Invalid route" />
      </Route>
      <Switch>
        {responseFeedbackRoutes.map((route, i) => {
          const { path, component } = route;
          return <Route key={i} path={path} component={component} />;
        })}
      </Switch>
    </>
  );
};

export default ResponseFeedbackRouter;
