import { CREATE_RESPONSE_USER, LIST_RESPONSE_ORGANIZATION_USERS, EDIT_RESPONSE_USER } from 'src/graph';
import { useGraphMutation } from 'src/hooks';

//eslint-disable-next-line
export function useGraphMutations(value?: string) {
  const { mutationMethod: createResponseUser, error: createError } = useGraphMutation({
    mutation: CREATE_RESPONSE_USER,
    options: {
      refetchQueries: [
        {
          query: LIST_RESPONSE_ORGANIZATION_USERS,
          variables: { responseOrganizationUlid: value },
        },
      ],
    },
  });

  const { mutationMethod: editResponseUser, error: editError } = useGraphMutation({
    mutation: EDIT_RESPONSE_USER,
    options: {
      refetchQueries: [
        {
          query: LIST_RESPONSE_ORGANIZATION_USERS,
          variables: { responseOrganizationUlid: value },
        },
      ],
    },
  });
  return {
    createResponseUser,
    createError,
    editResponseUser,
    editError,
  };
}
