import React from 'react';
import { useModal } from 'react-modal-hook';
import { ReviewResponseTicket } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_pb';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components/macro';
import { REVIEW_RESPONSE_TICKET_KEY } from 'src/async/queryKeys.utils';
import { Button } from 'src/components';
import { CLOSE_REVIEW_RESPONSE_TICKET, GET_REVIEW_RESPONSE_TICKET } from 'src/graph';
import { useGraphMutation } from 'src/hooks';
import vars from 'src/styles/variables';
import { toast, updateGraphMutation } from 'src/utils';
import NoResponseNeededModal, { FormValues } from './NoResponseNeededModal';

const NoResponseNeededButtonWrapper = styled(Button)`
  background-color: ${vars.$gray_f4};
  color: ${vars.$gray_7};
  border-color: ${vars.$gray_f4};
`;
const NoResponseNeededButton = (props: { reviewUlid: string; disabled: boolean }): JSX.Element => {
  const { reviewUlid, disabled } = props;

  const queryClient = useQueryClient();

  // ! closeReviewResponseTicket mutation
  const { mutationMethod: closeReviewResponseTicket } = useGraphMutation({
    mutation: CLOSE_REVIEW_RESPONSE_TICKET,
    options: {
      refetchQueries: [
        {
          query: GET_REVIEW_RESPONSE_TICKET,
          variables: {
            reviewUlid: reviewUlid,
          },
        },
      ],
      onCompleted: () => {
        queryClient.invalidateQueries([REVIEW_RESPONSE_TICKET_KEY, reviewUlid]);
      },
    },
    errorType: 'error',
  });

  async function handleNoResponseNeeded(values: FormValues) {
    const response = await updateGraphMutation(
      {
        variables: {
          reviewUlid: reviewUlid,
          input: {
            note: values.noteContent,
            reason: ReviewResponseTicket.CloseTicketReason.NO_RESPONSE,
          },
        },
        mutationName: 'closeReviewResponseTicket',
        onCompleted: () => {
          queryClient.invalidateQueries([REVIEW_RESPONSE_TICKET_KEY, reviewUlid]);
        },
      },
      closeReviewResponseTicket
    );
    if (response) {
      toast({
        type: 'success',
        message: 'No Response Needed',
      });
      hideModal();
    } else {
      hideModal();
    }
  }

  const [showModal, hideModal] = useModal(() => {
    return (
      <NoResponseNeededModal
        hideModal={hideModal}
        handleNoResponseNeeded={(values) => handleNoResponseNeeded(values)}
      />
    );
  });
  return (
    <NoResponseNeededButtonWrapper
      danger
      btnSm
      content="No Response Needed"
      onClick={showModal}
      mr={2}
      disabled={disabled}
    />
  );
};

export default NoResponseNeededButton;
