import React from 'react';
import { ResponseNotificationRecipient, User } from '@friendemic/erc-graph';
import { FormikProps } from 'formik';
import { Option } from 'react-select/src/filters';
import * as Yup from 'yup';
import { Row, Column, InputElement, Label, StaticInput, SelectCustomItem } from 'src/components';
import { useStateValue } from 'src/context';
import { SUGGEST_RESPONSE_NOTIFICATION_RECIPIENTS } from 'src/graph';
import { getRecords } from 'src/hooks';
import { selectOptions } from 'src/utils';
import { LocationSettingsRouteParams } from 'src/views/Settings/SettingsTypes';

export const initialFormValues = {
  add_recipient_ulid: '',
  add_recipient_review_type: '',
  // add_recipient_required: false,
};

export const validationSchema = Yup.object({
  add_recipient_ulid: Yup.string().required('Required'),
  add_recipient_review_type: Yup.string().required('Required'),
});

export type FormValues = typeof initialFormValues;

export const RenderForm = (
  props: FormikProps<FormValues> & {
    params: LocationSettingsRouteParams;
    recipients: ResponseNotificationRecipient[];
    addNew: boolean;
  }
): React.ReactElement => {
  // current values of selects
  // - used in clearing values when a new recipient is added to the table
  const [currentRecipientValue, setCurrentRecipientValue] = React.useState<any>();
  const [currentReviewTypeValue, setCurrentReviewTypeValue] = React.useState<any>();
  const [{ currentUser }] = useStateValue();

  const activeRecipient = props.recipients.find((x) => {
    return x.ulid === props.values.add_recipient_ulid;
  });

  const ActiveRecipientName: JSX.Element | string =
    activeRecipient && activeRecipient.user
      ? SelectCustomItem({
          primaryLabel: activeRecipient?.user?.name,
          secondaryLabel: activeRecipient?.user?.email,
        })
      : 'UNKNOWN';
  return (
    <Row>
      <Column width={[1, 1 / 2]}>
        {!props.addNew ? (
          <>
            <StaticInput>
              <Label>{'Recipient'}</Label>
              {ActiveRecipientName}
            </StaticInput>
            <InputElement name="add_recipient_ulid" disabled hidden />
          </>
        ) : (
          <InputElement
            type="select-async"
            loadOptions={(input: string) => {
              return fetchUsers(input, props.params.location, currentUser.userUlid);
            }}
            // VERY IMPORTANT PROPERTY
            // - Forces a rerender of options when a new recipient gets added
            // - Required for custom filter to work in real-time
            key={JSON.stringify(props.recipients)}
            filterOption={(option: Option) => {
              // Check to see if recipient has already been adeed
              // - if found, return false to exclude current iterate from select options
              // - ie: filterOption means "if false, filter the current option in loop"
              const added_ulids = props.recipients.map(({ userUlid }) => userUlid);
              return !(added_ulids.indexOf(option.value) > -1);
            }}
            border
            placeholder="Select Recipient..."
            name="add_recipient_ulid"
            label="Recipient"
            value={currentRecipientValue}
            handleSelectOnChange={(option) => {
              setCurrentRecipientValue(option);
            }}
          />
        )}
      </Column>
      <Column width={[1, 1 / 2]}>
        <InputElement
          type="select"
          options={selectOptions.ReviewType}
          border
          name="add_recipient_review_type"
          label="Notify for"
          value={currentReviewTypeValue}
          handleSelectOnChange={(option) => {
            setCurrentReviewTypeValue(option);
          }}
        />
      </Column>
      {/* <Column>
        <InputElement
          type="checkbox"
          name="add_recipient_required"
          label="Required Recipient"
          checkboxlabel="Recipient response is required"
        />
      </Column> */}
    </Row>
  );
};

/**
 * Get Users for autocomplete
 */
async function fetchUsers(input: string, responsePlaceUlid: string, currentUserUlid: string) {
  const res = await getRecords({
    queryString: SUGGEST_RESPONSE_NOTIFICATION_RECIPIENTS,
    options: { variables: { placeUlid: responsePlaceUlid, query: input, currentUserUlid: currentUserUlid } },
  });

  const { data }: { data: { suggestResponseNotificationRecipientUsers: User[] } } = res;

  if (data && data.suggestResponseNotificationRecipientUsers) {
    const { suggestResponseNotificationRecipientUsers }: { suggestResponseNotificationRecipientUsers: User[] } = data;
    return suggestResponseNotificationRecipientUsers.map((item: User) => {
      const { name, email, ulid: value } = item;
      const label = SelectCustomItem({ primaryLabel: name, secondaryLabel: email });
      return { label, value };
    });
  }
  return [];
}
