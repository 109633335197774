import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { ResponseApprover } from '@friendemic/erc-graph';
import review_type_friendly from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_type_friendly.js';
import { ActionsCell } from 'src/components';
import { client, DELETE_RESPONSE_APPROVER } from 'src/graph';
import { toast } from 'src/utils';
import { LocationSettingsRouteParams } from 'src/views/Settings/SettingsTypes';
import { DataRow } from '../../../AdminManagement/table';
import { FormValues } from './ApproverManagementForm';

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Approver',
    selector: (row: Record<string, any>): any => row.approver,
    sortable: true,
  },
  {
    name: 'Approves',
    selector: (row: Record<string, any>): any => row.approves,
    sortable: true,
    grow: 0,
  },
  {
    name: 'Required Approver',
    selector: (row: Record<string, any>): any => row.required,
    sortable: true,
    grow: 0,
  },
  {
    name: 'Actions',
    width: '6rem',
    sortable: false,
    center: true,
    grow: 0,
    cell: ActionsCell,
  },
];

export function buildData(
  approvers: ResponseApprover[],
  setApprovers: React.Dispatch<React.SetStateAction<ResponseApprover[]>>,
  setActiveEditingApprover: React.Dispatch<React.SetStateAction<FormValues>>,
  params: LocationSettingsRouteParams,
  showAddApproverModal: () => void
): any[] {
  return approvers
    ? approvers.map((approver: ResponseApprover, i: number) => {
        const currentApproverName = approver.user ? approver.user.name : 'UNKNOWN';

        return {
          approver: currentApproverName,
          approves: review_type_friendly.ReviewTypeFriendly.toFriendly(approver.reviewType),
          required: approver.required ? 'Yes' : 'No',
          actions: [
            {
              action: 'delete',
              icon: 'trash-alt',
              tooltip: 'Delete',
              onClick: async () => {
                const res = await client.mutate({
                  mutation: DELETE_RESPONSE_APPROVER,
                  variables: { placeUlid: params.location, ulid: approver.ulid },
                });

                if (res.data.deleteResponseApprover && res.data.deleteResponseApprover.errors) {
                  console.info(res.data.deleteResponseApprover.errors);
                  toast({ type: 'error', message: 'Something went wrong, please try again.' });
                } else {
                  const modifiedApprovers = [...approvers];
                  modifiedApprovers.splice(i, 1);
                  setApprovers(modifiedApprovers);
                  toast({ type: 'error', message: `Removed ${currentApproverName} as Approver` });
                }
              },
            },
            {
              action: 'edit',
              icon: 'edit',
              tooltip: 'Edit',
              onClick: () => {
                setActiveEditingApprover({
                  add_approver_ulid: approver.ulid,
                  add_approver_review_type: approver.reviewType,
                  add_approver_required: approver.required,
                });
                showAddApproverModal();
              },
            },
          ],
        };
      })
    : [];
}
