import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button';
import {
  CloseButton,
  ModalCardWrapper,
  ModalCard,
  ModalHeading,
  ModalFooter,
  FooterRight,
  ModalCardBody,
  TitleBlock,
  NoContentSpacer,
  ModalAlert,
} from './Modal.styled';

interface ModalProps {
  title: string;
  children: React.ReactElement | React.ReactElement[];
  hideModal: () => void;
  handleSubmit?: (args?: any) => void;
  onClose?: () => void;
  FooterLeftComponent?: React.ElementType;
  footer?: true;
  icon?: string;
  compact?: boolean;
  noPadding?: boolean;
  overflowVisible?: boolean;
  confirmable?: boolean;
  cancelable?: boolean | string;
  submittable?: boolean | string;
  alert?: boolean | string | JSX.Element;
  submitDisabled?: boolean;
  isSubmitting?: boolean;
}

const Modal: React.FC<ModalProps> = (props: ModalProps): React.ReactElement<ModalProps> => {
  const {
    title,
    children,
    hideModal,
    handleSubmit,
    onClose,
    FooterLeftComponent,
    footer,
    icon,
    compact,
    noPadding,
    overflowVisible,
    confirmable,
    cancelable,
    submittable,
    alert,
    submitDisabled,
    isSubmitting,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(true);

  // fix where react-modal-hook is unmounting immedately
  function handleHideModal() {
    if (onClose) {
      onClose();
    }
    setIsModalOpen(false);
    return setTimeout(() => {
      hideModal();
    }, 300);
  }

  return (
    <ReactModal
      appElement={document.getElementById('root') as HTMLElement}
      ariaHideApp={process.env.NODE_ENV !== 'test'}
      isOpen={isModalOpen}
      className="ReactModal"
      overlayClassName="ReactModalOverlay"
      onRequestClose={handleHideModal}
      closeTimeoutMS={300}
    >
      <ModalCardWrapper>
        <ModalCard compact={compact}>
          <ModalHeading>
            <TitleBlock>
              {icon && <FontAwesomeIcon icon={icon as IconName} />}
              <span>{title}</span>
            </TitleBlock>
            <CloseButton btnSm transparent secondary onClick={handleHideModal} icon="times" title="Close" />
          </ModalHeading>
          {alert && <ModalAlert>{alert}</ModalAlert>}
          <ModalCardBody noPadding={noPadding} overflowVisible={overflowVisible}>
            {children}
          </ModalCardBody>
          {confirmable && (
            <ModalFooter>
              <NoContentSpacer />
              <FooterRight>
                <Button secondary onClick={handleHideModal}>
                  {typeof cancelable === 'string' ? cancelable : 'Cancel'}
                </Button>
                <Button danger onClick={handleSubmit} disabled={submitDisabled}>
                  {typeof submittable === 'string' ? submittable : "I'm sure"}
                </Button>
              </FooterRight>
            </ModalFooter>
          )}
          {Boolean(footer && !confirmable) && (
            <ModalFooter>
              {FooterLeftComponent ? <FooterLeftComponent /> : <NoContentSpacer />}
              <FooterRight>
                {cancelable && (
                  <Button secondary onClick={handleHideModal}>
                    {typeof cancelable === 'string' ? cancelable : 'Cancel'}
                  </Button>
                )}
                {submittable && (
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={submitDisabled}
                    icon={isSubmitting ? 'spinner-third' : undefined}
                    spin={isSubmitting}
                  >
                    {typeof submittable === 'string' ? submittable : 'Save'}
                  </Button>
                )}
              </FooterRight>
            </ModalFooter>
          )}
        </ModalCard>
      </ModalCardWrapper>
    </ReactModal>
  );
};

export default Modal;
