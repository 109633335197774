import { CREATE_REVIEW_RESPONSE_NOTE, GET_REVIEW_RESPONSE_TICKET, DELETE_REVIEW_RESPONSE_NOTE } from 'src/graph';
import { useGraphMutation } from 'src/hooks';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useGraphMutations(reviewUlid?: string) {
  const { mutationMethod: deleteNote } = useGraphMutation({
    mutation: DELETE_REVIEW_RESPONSE_NOTE,
    options: {
      refetchQueries: [
        {
          query: GET_REVIEW_RESPONSE_TICKET,
          variables: {
            reviewUlid,
          },
        },
      ],
      fetchPolicy: 'no-cache',
    },

    errorType: 'error',
  });

  const { mutationMethod: createNote } = useGraphMutation({
    mutation: CREATE_REVIEW_RESPONSE_NOTE,
    options: {
      refetchQueries: [
        {
          query: GET_REVIEW_RESPONSE_TICKET,
          variables: {
            reviewUlid,
          },
        },
      ],
      fetchPolicy: 'no-cache',
    },
    errorType: 'error',
  });
  return {
    deleteNote,
    createNote,
  };
}
