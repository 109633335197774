import React from 'react';
import styled from 'styled-components/macro';
import { FormGroup, Label, ErrorSpan } from 'src/components';
import vars from 'src/styles/variables';

const RadioWrapper = styled.div<{ stacked?: boolean }>`
  display: ${(props) => {
    return props.stacked ? 'block' : 'inline-block';
  }};

  &:not(:first-of-type) {
    margin-left: ${(props) => {
      return props.stacked ? 0 : '1.5rem';
    }};
  }
`;

const StyledRadio = styled.input<{ hasError?: boolean }>`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  &:checked + label,
  &:not(:checked) + label {
    /* width: 100%; */
    font-size: ${vars.$font_size_sm};
    position: relative;
    padding-left: 1.5rem;
    cursor: pointer;
    line-height: 1.25rem;
    display: inline-block;
    color: ${({ hasError }) => {
      return hasError ? vars.$danger : vars.$gray_7;
    }};
  }
  &:checked + label:before,
  &:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background: ${({ hasError }) => {
      return hasError ? vars.$redlight : vars.$graylight;
    }};
  }
  &:checked + label:before {
    background: ${({ hasError }) => {
      return hasError ? vars.$redlight : vars.$greenlight;
    }};
  }
  &:checked + label:after,
  &:not(:checked) + label:after {
    content: '';
    width: 6px;
    height: 6px;
    background: white;
    position: absolute;
    top: 7px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

const yesNoOptions = [
  {
    value: false,
    label: 'No',
  },
  {
    value: true,
    label: 'Yes',
  },
];

interface RadioProps {
  name?: string;
  touched?: any;
  errors?: any;
  values?: any;
  setFieldValue?: any;
  label?: string;
  options?: { label: string; value: string | boolean | number }[];
  width?: string[] | number;
  stacked?: boolean;
}

const Radio = (props: RadioProps): React.ReactElement => {
  const { name, touched, errors, values, setFieldValue, options, label } = props;

  const whichOptions = options ? options : yesNoOptions;

  const hasError = Boolean(name && touched[name] && errors[name]);

  return (
    <FormGroup hasError={hasError}>
      <Label htmlFor={name}>{label}</Label>
      <div>
        {whichOptions.map((x: any, i: number) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <RadioWrapper stacked={props.stacked} key={i}>
              <StyledRadio
                hasError={hasError}
                type="radio"
                name={name}
                id={`${name}${x.label}`}
                value={x.value}
                checked={Boolean(name && values[name] === x.value)}
                data-testid={`error-input-radio-${name}${x.label}`}
                onChange={() => {
                  return setFieldValue(name, x.value);
                }}
              />
              <label htmlFor={`${name}${x.label}`}>{x.label}</label>
            </RadioWrapper>
          );
        })}
      </div>
      <ErrorSpan data-testid="errors-input-radio">{name ? errors[name] : ''}</ErrorSpan>
    </FormGroup>
  );
};

export default Radio;
