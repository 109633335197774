import React, { ComponentType, ReactNode } from 'react';
import {
  DocumentNode,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
  QueryOptions,
  ApolloQueryResult,
  useMutation,
  MutationHookOptions,
  ApolloError,
  MutationFunctionOptions,
} from '@apollo/client';
import * as Sentry from '@sentry/react';
import { CaptureContext } from '@sentry/types';
import { useQuery as useReactQuery, QueryKey, QueryFunction } from '@tanstack/react-query';
import { ExecutableDefinitionNode } from 'graphql';
import { ErrorBlock } from 'src/components';
import { client } from 'src/graph';
import { toast } from 'src/utils';

type graphQueryType = 'query' | 'queryOptions';
type variablesType = QueryHookOptions<any, any> | undefined;
type QueryType = Partial<QueryOptions>;
interface IQueryProps {
  queryString: DocumentNode;
  options?: QueryType;
}
interface IGraphQueryProps {
  query: DocumentNode | TypedDocumentNode<any, any>;
  queryOptions?: variablesType;
  isCustomError?: boolean;
  errorMessage?: string | ReactNode;
  customLoadingMessage?: string | ReactNode;
  loadingOnNetWorkStatusChange?: boolean;
}

interface AxiosQueryProps {
  query: [QueryKey, QueryFunction];
  customLoadingMessage?: string | ReactNode;
}

export function withAxiosDataFetcher<P extends Record<string, unknown> & AxiosQueryProps>(
  WrappedComponent: ComponentType<P>
) {
  return function InnerFunc(props: P): JSX.Element | null {
    const {
      query: [queryKey, queryFn],
      customLoadingMessage,
    } = props;
    const queryResult = useReactQuery({ queryKey: queryKey, queryFn: queryFn });
    const { isLoading = true, isError, data, error } = queryResult;
    if (!data && isError) return <ErrorBlock error={error} component={WrappedComponent} />;

    const isComponentReady = !isLoading && data;
    if (isComponentReady) {
      return <WrappedComponent queryResult={queryResult} {...props} loading={isLoading} />;
    }
    return customLoadingMessage ? <>{customLoadingMessage}</> : <p>{'Loading....'}</p>;
  };
}

export function withDataFetcher<P extends Record<string, unknown> & IGraphQueryProps>(
  WrappedComponent: ComponentType<Exclude<P, IGraphQueryProps>>
): ComponentType<P> {
  return function InnerFunc(props: P): JSX.Element | null {
    const { isCustomError = false, errorMessage = '', customLoadingMessage, loadingOnNetWorkStatusChange } = props;
    const { data, loading, error, ...rest } = useGraphQuery({ query: props.query, queryOptions: props.queryOptions });

    if (!data && error && !isCustomError) return <ErrorBlock error={error} component={WrappedComponent} />;
    if (!data && error && isCustomError) {
      return errorMessage ? <span>{errorMessage}</span> : null;
    }

    const isComponentReady = ((!loading || !loadingOnNetWorkStatusChange) && data) || (!data && !error && !loading);
    if (isComponentReady) {
      return <WrappedComponent {...data} {...props} {...rest} loading={loading} />;
    }
    return customLoadingMessage ? <>{customLoadingMessage}</> : <p>{'Loading....'}</p>;
  };
}

export const useGraphQuery = (props: Pick<IGraphQueryProps, graphQueryType>): QueryResult<any, any> => {
  const { query, queryOptions } = props;
  const { loading, error, data, ...rest } = useQuery(query, {
    ...queryOptions,
  });

  if (error) {
    const sentryContext = buildSentryContext(query, queryOptions);
    Sentry.captureException(error, sentryContext);
    // console.info('useGraphQuery sentry error context', { sentryContext });
  }

  return { loading, error, data, ...rest };
};

export const getRecords = async <T extends unknown>(props: IQueryProps): Promise<ApolloQueryResult<T | any>> => {
  const { queryString, options } = props;
  const { loading, error, data, ...rest } = await client.query({
    query: queryString,
    ...options,
  });
  // const dataType = Object.keys(res.data)[0];
  // res.data = res.data[dataType];

  if (error) {
    const sentryContext = buildSentryContext(queryString, options);
    Sentry.captureException(error, sentryContext);
    // console.info('getRecords sentry error context', { sentryContext });
  }

  return { loading, error, data, ...rest };
};

interface IGraphMutaionProps {
  mutation: DocumentNode | TypedDocumentNode<any, Record<string, any>>;
  options?: MutationHookOptions<any, Record<string, any>> | undefined;
  errorType?: 'success' | 'info' | 'error' | 'warn';
  errorToast?: boolean;
}
export function useGraphMutation(props: IGraphMutaionProps): {
  data: any;
  error: ApolloError | undefined;
  loading: boolean;
  mutationMethod: (options?: MutationFunctionOptions<any, Record<string, any>, any, any> | undefined) => Promise<any>;
} {
  const { mutation, options, errorType = 'error', errorToast = true } = props;
  const [mutationMethod, { error, loading, data, ...rest }] = useMutation(mutation, options);

  if (errorToast && error) {
    toast({ type: errorType, message: error.message });
  }

  if (error) {
    /* Remove refetchQueries from options, it's a huge object and it's not needed */
    const sanitizedOptions = options?.refetchQueries ? deleteKeyFromObject(options, 'refetchQueries') : options;

    const sentryContext = buildSentryContext(mutation, sanitizedOptions);
    Sentry.captureException(error, sentryContext);
    // console.info('getRecords sentry error context', { sentryContext });
  }

  return { data, error, loading, mutationMethod, ...rest };
}

function deleteKeyFromObject(obj: Record<string, any>, key: string): { [k: string]: unknown } {
  return Object.fromEntries(Object.entries(obj).filter((el) => el[0] !== key));
}

function buildSentryContext(query: DocumentNode, queryOptions: variablesType): CaptureContext {
  const definition = query.definitions[0] as ExecutableDefinitionNode;

  const sentryCaptureContext: CaptureContext = {
    contexts: {
      GraphQL: {
        name: definition.name?.value || 'unknown',
        queryOptions: JSON.stringify(queryOptions),
      },
    },
  };

  return sentryCaptureContext;
}
