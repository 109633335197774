import React from 'react';
import AnimateHeight from 'react-animate-height';
import { ApolloError, useSubscription } from '@apollo/client';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components/macro';
import { LIST_REVIEW_RESPONSE_TICKETS_KEY } from 'src/async/queryKeys.utils';
import { Button } from 'src/components';
import { client, LIST_REVIEW_RESPONSE_TICKETS, REVIEW_RESPONSE_TICKET_CREATE_SUBSCRIPTION } from 'src/graph';
import { toast } from 'src/utils';

export const NewTicketsAvailableNotificationWrapper = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 1;
  box-shadow: 3px -3px 6px rgba(0, 0, 0, 0.16);
`;

export const NewTicketsAvailableNotification = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0.25rem 1rem;
`;

export const NewTicketsAvailableText = styled.div`
  font-size: 0.875rem;
`;

export const RefetchListReviewResponseTicketsButton = styled(Button)`
  font-size: 0.875rem;
`;

function getNewTicketsCounterText(newTicketsCounter: number) {
  const isPlural = newTicketsCounter > 1;
  if (newTicketsCounter === 0) {
    return "You're up to date";
  } else {
    return (
      <>
        <span>{newTicketsCounter}</span>
        {` new review${isPlural ? 's' : ''}`}
      </>
    );
  }
}

const NewTicketsSubscriptionCounter = ({ organization }: { organization?: string }): JSX.Element | null => {
  const [newTicketsCounter, setNewTicketsCounter] = React.useState(0);
  const [height, setHeight] = React.useState<number | string>(0);
  const [loading, setLoading] = React.useState(false);

  const queryClient = useQueryClient();

  const { data, error } = useSubscription(REVIEW_RESPONSE_TICKET_CREATE_SUBSCRIPTION, {
    variables: { responseOrganizationUlid: organization },
  });

  /**
   * # REFETCH query LIST_REVIEW_RESPONSE_TICKET on demand
   * * updates cached variables for listReviewResponseTickets query
   */
  function refetchListReviewResponseTickets() {
    queryClient.invalidateQueries([LIST_REVIEW_RESPONSE_TICKETS_KEY]);
    setLoading(true);
    client
      .refetchQueries({
        include: [LIST_REVIEW_RESPONSE_TICKETS],
      })
      .catch((err: ApolloError) => {
        setHeight(0); // * Hide if error
        toast({ type: 'error', message: err.message });
      })
      .finally(() => {
        // * Finally runs in every condition
        setNewTicketsCounter(0);
        setLoading(false);
      });
  }

  /**
   * * Set visibility (height) of NewTicketsAvailableNotification depending on newTicketsCounter
   * * If newTicketsCounter is 0, hide after 5 seconds
   */
  React.useEffect(() => {
    if (newTicketsCounter > 0) {
      setHeight('auto');
    } else if (newTicketsCounter === 0) {
      const timer = setTimeout(() => {
        setHeight(0);
      }, 5000);
      return () => clearTimeout(timer); // * Cleanup timeout when component unmounts
    }
  }, [newTicketsCounter]);

  /**
   * * Increment counter if new ticket message is recieved
   */
  React.useEffect(() => {
    if (data?.reviewResponseTicketCreated) {
      //! In order to use checkIfTicketMatchesFilters, reviewResponseTags response will have to be added to the subscription in erc-graph
      setNewTicketsCounter((prev) => prev + 1);
    }
  }, [data]);

  if (error) return null; // * Just hide the banner if there's an error

  return (
    <NewTicketsAvailableNotificationWrapper>
      <AnimateHeight id={'NewTicketsCounter'} duration={500} height={height}>
        <NewTicketsAvailableNotification>
          <NewTicketsAvailableText>{getNewTicketsCounterText(newTicketsCounter)}</NewTicketsAvailableText>
          <RefetchListReviewResponseTicketsButton
            btnSm
            transparent
            spin={loading}
            disabled={loading || newTicketsCounter === 0}
            icon="sync"
            content="Sync"
            onClick={refetchListReviewResponseTickets}
          />
        </NewTicketsAvailableNotification>
      </AnimateHeight>
    </NewTicketsAvailableNotificationWrapper>
  );
};

export default NewTicketsSubscriptionCounter;
