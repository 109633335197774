import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';
import { getChannelIcon, getChannelColor } from 'src/utils';
import Button, { ButtonProps } from './Button';
import ConditionalWrapper from './ConditionalWrapper';

// FLAG - Cleanup / make static

interface ChannelPillProps extends ButtonProps {
  className?: 'pill';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  connected?: boolean;
  channel: { label: string; value: number };
}

const PillWrapper = styled.div`
  display: inline-block;
  position: relative;
  margin-left: 2.5rem;
  width: 100%;
`;

const StaticPill = styled(Button).attrs((props: ChannelPillProps) => {
  return {
    className: props.connected ? 'pill' : undefined,
  };
})<ChannelPillProps>`
  user-select: text;
  transform-style: preserve-3d;
  white-space: nowrap;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  span {
    margin-left: 30px;
  }
  pointer-events: none;
`;

const ConnectedBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -2.5rem;
  width: 4rem;
  line-height: 1;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${vars.$greenlight};
  color: ${vars.$white};
  transform: translateZ(-1px);
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 10rem;
  transition: 150ms;
  box-shadow: ${vars.$shallow_shadow};
`;

// import { darken } from 'color2k';
// const RemoveConnection = styled(Button)`
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   right: 2rem;
//   transition: color 150ms;
//   font-size: 125%;
//   &:hover {
//     color: ${vars.$danger};
//   }
//   &:active {
//     color: ${darken(vars.$danger, 0.08)};
//   }
// `;

const ChannelIcon = styled.div`
  background-color: rgba(0, 0, 0, 0.0875);
  border: none;
  border-radius: 10rem;
  display: flex;
  padding: 6px 0;
  margin-right: 0.5rem;

  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  left: 0;

  svg {
    margin: 0;
  }
`;

const ChannelPill = (props: ChannelPillProps): React.ReactElement => {
  const { connected, onClick, channel, ...rest } = props;

  return (
    <ConditionalWrapper condition={Boolean(connected)} wrapper={(children) => <PillWrapper>{children}</PillWrapper>}>
      <>
        {connected && (
          <ConnectedBackground>
            <FontAwesomeIcon icon="check" />
          </ConnectedBackground>
        )}
        <StaticPill
          color={getChannelColor(channel.label)}
          channel={channel}
          connected={connected}
          onClick={!connected ? onClick : undefined}
          {...rest}
        >
          <ChannelIcon>{getChannelIcon(channel.label)}</ChannelIcon>
          <span>{connected ? 'Connected' : `Connect ${channel.label}`}</span>
        </StaticPill>
        {/* {connected && <RemoveConnection secondary blank icon="trash-alt" onClick={onClick} />} */}
      </>
    </ConditionalWrapper>
  );
};

export default ChannelPill;
