import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';

const SavedNotificationWrapper = styled.div`
  display: flex;
  font-size: ${vars.$font_size_extra_sm};
`;

/**
 * * Use PX for w/h, more predictable for svg
 */
const SavingSaved = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 12px;
    height: 12px;
  }
`;

const Saving = styled(SavingSaved)`
  svg {
    color: cornflowerblue;
  }
`;

const Saved = styled(SavingSaved)`
  svg {
    color: ${vars.$greenlight};
  }
`;

const SavedMessage = styled.div<{ color?: string }>`
  margin-left: 0.2rem;
  ${({ color }) => color && `color: ${color};`}
`;

const SavedNotification = (props: { saving: boolean }): JSX.Element => {
  const { saving } = props;
  const [savedOnce, setSavedOnce] = React.useState(false);

  React.useEffect(() => {
    if (!savedOnce && saving) {
      setSavedOnce(true);
    }
    // Reset message after timeout
    // else {
    //   setTimeout(() => {
    //     setSavedOnce(false);
    //   }, 4000);
    // }
  }, [saving, savedOnce]);

  return (
    <SavedNotificationWrapper>
      {!savedOnce ? (
        <SavedMessage>{'Changes saved automatically'}</SavedMessage>
      ) : saving ? (
        <Saving>
          <FontAwesomeIcon spin fixedWidth icon="spinner-third" />
          <SavedMessage>{'Saving'}</SavedMessage>
        </Saving>
      ) : (
        <Saved>
          <FontAwesomeIcon fixedWidth icon="check-circle" />
          <SavedMessage>{'Saved'}</SavedMessage>
        </Saved>
      )}
    </SavedNotificationWrapper>
  );
};

export default SavedNotification;
