import React, { MouseEventHandler, ReactNode } from 'react';
import vars from 'src/styles/variables';
import { DropDownItemWrapper } from './DropDown.styled';

type DropDownItemProps = {
  children: ReactNode;
  title?: string;
  count?: number;
  keyProp: string;
  onClick?: MouseEventHandler<HTMLLIElement>;
  disable?: boolean;
  bgColor?: string;
  selected?: boolean;
  itemPadding?: string;
  hoverColor?: string;
  activeColor?: string;
};

export const DropDownItem = ({
  keyProp,
  onClick,
  children,
  disable,
  bgColor,
  selected,
  itemPadding,
  hoverColor,
  activeColor,
}: DropDownItemProps): JSX.Element => {
  return (
    <DropDownItemWrapper
      itemPadding={itemPadding || ''}
      onClick={onClick}
      key={keyProp}
      disable={disable || false}
      bgcolor={bgColor || vars.$white}
      className={selected ? 'selected' : ''}
      hoverColor={hoverColor}
      activeColor={activeColor}
    >
      {children}
    </DropDownItemWrapper>
  );
};
