import styled, { css } from 'styled-components/macro';
import { Row } from 'src/components';
import vars from 'src/styles/variables';

export const MetaWrapper = styled(Row)`
  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
`;

export const MetaTitle = styled.div<{ bold?: boolean; transform?: 'capitalize' | 'lowercase' | 'none' }>`
  color: ${vars.$gray_b};
  font-size: 0.85rem;
  ${(props) => css`
    text-transform: ${props.transform ? props.transform : 'uppercase'};
    color: ${props.bold ? vars.$gray_7 : vars.$gray_b};
    font-weight: ${props.bold ? 'bold' : 'normal'};
  `};
`;

export const MetaText = styled.div<{ isEmpty?: boolean }>`
  font-size: 115%;
  ${(props) => {
    if (props.isEmpty) {
      return css`
        color: ${vars.$gray_b};
        font-style: italic;
      `;
    }
  }}
`;
