import React from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darken } from 'color2k';
import styled, { css } from 'styled-components/macro';
import vars from 'src/styles/variables';
import Button, { ButtonProps } from './Button';
import ConditionalWrapper from './ConditionalWrapper';

export interface PillProps extends ButtonProps {
  className?: 'pill-static' | '';
  small?: boolean;
  danger?: boolean;
  icon?: IconName;
  onDismissClick?: (e: any) => any;
  dismissible?: true;
  noSpacing?: boolean;
}

export const PillWrapper = styled.div<Pick<PillProps, 'noSpacing'>>`
  display: inline-block;
  position: relative;
  margin-right: ${(props) => {
    return props.noSpacing ? 0 : '1rem';
  }};
`;

const StaticPill = styled(Button).attrs((props) => {
  return {
    className: props.onClick ? '' : 'pill-static',
  };
})<PillProps>`
  user-select: text;
  transform-style: preserve-3d;
  white-space: nowrap;
  cursor: ${(props) => (props.onClick ? 'inherit' : 'default !important')};
  height: ${(props) => {
    return Boolean(props.small && props.icon) && 'auto';
  }};
  margin-right: ${(props) => {
    return props.dismissible && !props.small ? '1.25rem' : '1rem';
  }};

  ${(props: Pick<PillProps, 'small'>) => {
    return (
      props.small &&
      css`
        padding: 0.25rem 0.5rem;
        font-size: ${vars.$font_size_sm};
        border-radius: 0.25rem;
      `
    );
  }}
`;

const DismissibleBackground = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1rem;
  width: 3.5rem;
  line-height: 1;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${vars.$gray_e};
  color: ${vars.$gray_9};
  transform: translateZ(-1px);
  border-top-right-radius: 10rem;
  border-bottom-right-radius: 10rem;
  transition: 150ms;
  box-shadow: ${vars.$shallow_shadow};

  ${(props: PillProps) => {
    return css`
      &:hover {
        background-color: ${props.danger ? darken(vars.$gray_e, 0.05) : vars.$danger};
        color: white;
      }
      &:active {
        background-color: ${props.danger ? darken(vars.$gray_e, 0.1) : darken(vars.$danger, 0.08)};
      }
    `;
  }}

  ${(props: PillProps) => {
    return (
      props.small &&
      css`
        padding: 0.25rem 0.5rem;
        font-size: 0.73rem;
        border-radius: 0.35rem;
        right: -0.5rem;
      `
    );
  }}
`;

/**
 * ### Pill is an enxtension of Button
 * So all of the `ButtonProps` are available, though not all are useful.
 *
 * A `<Pill>{'...'}</Pill>` looks just like a button but w/o interactivity.
 *
 * ### The `dismissible` prop adds the `X`.
 *
 * `<Pill dismissible onDismissClick={handleClick}>{'...'}</Pill>`
 *
 * An `onDismissClick` is required to control what happens `onClick` of the dismiss button.
 *
 * Addding an onClick={handleClick} prop enables hover / click events on Pill body.
 *
 */
const Pill = (props: PillProps): React.ReactElement => {
  const { dismissible, onDismissClick, small, danger, noSpacing, ...rest } = props;

  return (
    <ConditionalWrapper
      condition={Boolean(dismissible)}
      wrapper={(children) => <PillWrapper noSpacing={noSpacing}>{children}</PillWrapper>}
    >
      <>
        {dismissible && (
          <DismissibleBackground small={small} onClick={onDismissClick} danger={danger}>
            <FontAwesomeIcon icon="times" />
          </DismissibleBackground>
        )}
        <StaticPill small={small} dismissible={dismissible} danger={danger} {...rest}>
          {props.children}
        </StaticPill>
      </>
    </ConditionalWrapper>
  );
};

export default styled(Pill)``;
