import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { ResponseUser } from '@friendemic/erc-graph';
import { ResponseUserRoleTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/response_user_response_user_role_type_friendly';
import { ActionsCell } from 'src/components';
import { client, DELETE_RESPONSE_USER } from 'src/graph';
import { toast } from 'src/utils';
import { DataRow } from '../AdminManagement/table';
import { FormValues } from './UserManagementForm';

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'User',
    selector: (row: Record<string, any>): any => row.user,
    sortable: true,
  },
  {
    name: 'Role',
    selector: (row: Record<string, any>): any => row.userRole,
    sortable: true,
  },
  {
    name: 'Read\u00a0Only',
    selector: (row: Record<string, any>): any => row.readOnly,
    sortable: true,
    grow: 0,
  },
  {
    name: 'Actions',
    width: '6rem',
    sortable: false,
    center: true,
    grow: 0,
    cell: ActionsCell,
  },
];

export function buildData(
  users: ResponseUser[],
  setUsers: React.Dispatch<React.SetStateAction<ResponseUser[]>>,
  setActiveEditingUser: React.Dispatch<React.SetStateAction<FormValues>>,
  showAddUserModal: () => void
): any[] {
  return users
    ? users.map((user: ResponseUser, i: number) => {
        const currentUserName = user.user ? user.user.name : 'UNKNOWN';

        return {
          user: currentUserName,
          readOnly: user.readOnly ? 'Yes' : 'No',
          userRole: ResponseUserRoleTypeFriendly.toFriendly(user.userRole),
          actions: [
            {
              action: 'delete',
              icon: 'trash-alt',
              tooltip: 'Delete',
              onClick: async () => {
                const res = await client.mutate({
                  mutation: DELETE_RESPONSE_USER,
                  variables: { userUlid: user.userUlid },
                });

                if (res.data.deleteResponseUser && res.data.deleteResponseUser.errors) {
                  console.info(res.data.deleteResponseUser.errors);
                  toast({ type: 'error', message: 'Something went wrong, please try again.' });
                } else {
                  const modifiedUsers = [...users];
                  modifiedUsers.splice(i, 1);
                  setUsers(modifiedUsers);
                  toast({
                    message: `Removed User ${currentUserName}`,
                    type: 'error',
                  });
                }
              },
            },
            {
              action: 'edit',
              icon: 'edit',
              tooltip: 'Edit',
              onClick: () => {
                setActiveEditingUser({
                  add_organization_user_ulid: user.userUlid,
                  add_organization_user_userRole: user.userRole,
                  add_organization_user_readOnly: user.readOnly,
                  // FLAG - disabled until data is available
                  // carfax: 3,
                  // cargurus: 3,
                  // carscom: 3,
                  // dealerrater: 3,
                  // facebook: 3,
                  // google: 3,
                  // yelp: 3,
                });
                showAddUserModal();
              },
            },
          ],
        };
      })
    : [];
}
