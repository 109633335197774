import React from 'react';
import { ResponseUser, User } from '@friendemic/erc-graph';
import { FormikProps } from 'formik';
import { Option } from 'react-select/src/filters';
import * as Yup from 'yup';
import { Row, Column, InputElement, Label, StaticInput, SelectCustomItem } from 'src/components';
import { useStateValue } from 'src/context';
import { SUGGEST_ADMIN_RESPONSE_USERS } from 'src/graph';
import { getRecords } from 'src/hooks';

export const initialFormValues = {
  add_admin_ulid: '',
  add_admin_readOnly: false,
};

export const validationSchema = Yup.object({
  add_admin_ulid: Yup.string().required('Required'),
});

export type FormValues = typeof initialFormValues;

export const RenderForm = (
  props: FormikProps<FormValues> & {
    admins: ResponseUser[];
    addNew: boolean;
  }
): React.ReactElement => {
  // current values of selects
  // - used in clearing values when a new admin is added to the table
  const [currentAdminValue, setCurrentAdminValue] = React.useState<any>();
  const [{ currentUser }] = useStateValue();

  const activeAdmin = props.admins.find((x) => {
    return x.userUlid === props.values.add_admin_ulid;
  });

  const ActiveAdminName: JSX.Element | string =
    activeAdmin && activeAdmin.user
      ? SelectCustomItem({
          primaryLabel: activeAdmin?.user?.name,
          secondaryLabel: activeAdmin?.user?.email,
        })
      : 'UNKNOWN';
  return (
    <Row>
      <Column>
        {!props.addNew ? (
          <>
            <StaticInput>
              <Label>{'Admin'}</Label>
              {ActiveAdminName}
            </StaticInput>
            <InputElement name="add_admin_ulid" disabled hidden />
          </>
        ) : (
          <InputElement
            type="select-async"
            loadOptions={(input: string) => {
              return fetchUsers(input, currentUser.userUlid);
            }}
            // VERY IMPORTANT PROPERTY
            // - Forces a rerender of options when a new admin gets added
            // - Required for custom filter to work in real-time
            key={JSON.stringify(props.admins)}
            filterOption={(option: Option) => {
              // Check to see if admin has already been adeed
              // - if found, return false to exclude current iterate from select options
              // - ie: filterOption means "if false, filter the current option in loop"
              const added_ulids = props.admins.map(({ userUlid }) => userUlid);
              return !(added_ulids.indexOf(option.value) > -1);
            }}
            border
            placeholder="Select Admin..."
            name="add_admin_ulid"
            label="Admin"
            value={currentAdminValue}
            data-cy="select-admin"
            handleSelectOnChange={(option) => {
              setCurrentAdminValue(option);
            }}
          />
        )}
      </Column>
      <Column>
        <InputElement type="checkbox" name="add_admin_readOnly" checkboxlabel="Read only" />
      </Column>
    </Row>
  );
};

/**
 * Get Users for autocomplete
 */
async function fetchUsers(input: string, currentUserUlid: string) {
  const res = await getRecords({
    queryString: SUGGEST_ADMIN_RESPONSE_USERS,
    options: { variables: { query: input, currentUserUlid: currentUserUlid } },
  });

  const { data }: { data: { suggestResponseAdminUsers: User[] } } = res;

  if (data && data.suggestResponseAdminUsers) {
    const { suggestResponseAdminUsers }: { suggestResponseAdminUsers: User[] } = data;
    return suggestResponseAdminUsers.map((item: User) => {
      const { name, email, ulid: value } = item;
      const label = SelectCustomItem({ primaryLabel: name, secondaryLabel: email });
      return { label, value };
    });
  }
  return [];
}
