import styled from 'styled-components/macro';
import vars from 'src/styles/variables';

export const Hr = styled.hr<{ width?: string }>`
  margin: 0;
  border: 0;
  height: 0;
  width: ${({ width }) => width || '80%'};
  align-self: center;
  border-top: none;
  border-bottom: 1px solid ${vars.$border_color};
`;

export default Hr;
