import { ApolloClient, ApolloLink, createHttpLink, NormalizedCacheObject, split, ServerError } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { TokenRefreshLink } from '@friendemic/sso-consumer/dist/TokenRefreshLink';
import * as Sentry from '@sentry/react';
import { createClient } from 'graphql-ws';
import queryString from 'query-string';
import { authConsumer } from 'src/utils/auth-consumer';
import { cache } from './cache';

/**
 * Configured via `src/setupProxy.js` and the REACT_APP_GRAPHQL_REMOTE_SERVER environment variable
 */
const httpLink = createHttpLink({
  uri: '/graph-api/',
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GRAPHQL_WEBSOCKET_URI as string, // use wss for a secure endpoint
    lazy: true, // wait to connect until required to (and authenticated)
    retryAttempts: 5,
    connectionParams: async () => {
      // check for token in url first
      const parsed = queryString.parse(location.search);
      const { token: queryToken } = parsed;

      if (queryToken) {
        return {
          authToken: queryToken,
        };
      }

      const tokens = await authConsumer.getTokens();

      return {
        authToken: tokens && tokens.accessToken,
      };
    },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

const refreshTokenLink = new TokenRefreshLink(authConsumer, () => {
  LogoutAsync();
});

const authLink = setContext(async (_, { headers }) => {
  // check for token in url first
  const parsed = queryString.parse(location.search);
  const { token: queryToken } = parsed;
  if (queryToken) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${queryToken}`,
      },
    };
  }

  // get the authentication token from local storage if it exists
  const tokens = await authConsumer.getTokens();
  // return the headers to the context so httpLink can read them
  if (!tokens) {
    return { headers };
  }

  return {
    headers: {
      ...headers,
      authorization: tokens.accessToken ? `Bearer ${tokens.accessToken}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
    );
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`, networkError);
    const err = networkError as ServerError;
    if (err.statusCode === 401) {
      LogoutAsync();
    }
    Sentry.captureException(err);
  }
});

export const LogoutAsync = async (): Promise<void> => {
  await authConsumer.endSession();
  await Sentry.configureScope((scope) => scope.clear());
  const redirectUrl = await authConsumer.getLoginUri(authConsumer.getCurrentUri());
  window.location.replace(redirectUrl);
};

export const client = new ApolloClient<NormalizedCacheObject>({
  link: ApolloLink.from([errorLink, refreshTokenLink, authLink, splitLink]), //authLink must follow refreshTokenLink
  cache: cache,
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
    watchQuery: {
      errorPolicy: 'all',
    },
  },
});
