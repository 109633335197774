import styled from 'styled-components/macro';
import vars from 'src/styles/variables';

const NoDataMessage = styled.div`
  color: ${vars.$gray_9};
  text-align: center;
  position: relative;
  padding: 10% 1rem;
  width: 100%;
  svg {
    margin-right: 0.5rem;
  }
`;

export default NoDataMessage;
