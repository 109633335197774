import { filterStatusToTicketStatusMap } from '@friendemic/erc-graph/build/constants';
import { ReviewResponseTicket as ReviewResponseTicket_pb } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_pb';
import { Tag } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/tags_pb';
import { PublishingDetails, ReviewResponseTicket } from 'src/async/types';
import { EMPTY_FILTERS, FilterType } from 'src/context';
import { matchSubmittedAtEpochToEnum } from 'src/utils/helpers';

/**
 * Unused function
 */
// export function getFiltersKeysData(filters: FilterType): {
//   channels: number[];
//   rating: number[];
//   tags: number[];
//   status: number[];
//   submittedAt: number[];
//   assigneeUlid: any[];
//   placeUlid: string[];
//   isFiltersExists: boolean;
// } {
//   const { placeUlid, status, rating, assigneeUlid, channels, tags, submittedAt } = filters;
//   const isFiltersExists = Object.keys(filters).filter((k) => filters[k as keyof FilterType].length > 0).length > 0;
//   return {
//     channels,
//     rating,
//     tags,
//     status,
//     submittedAt,
//     assigneeUlid,
//     placeUlid,
//     isFiltersExists,
//   };
// }

/**
 * Accepts the current filters object, and the ticket to compare against
 * - Returns true/false if the ticket would be shown given the current filters
 *
 */
export function checkIfTicketMatchesFilters(filters: FilterType, ticket: ReviewResponseTicket): boolean | undefined {
  if (!ticket) return false;

  const normalizedFilters = { ...EMPTY_FILTERS, ...filters };
  const {
    placeUlid: filters_placeUlid,
    status: filters_status,
    rating: filters_rating,
    assigneeUlid: filters_assigneeUlid,
    channels: filters_channels,
    tags: filters_tags,
    submittedAt: filters_submittedAt,
  } = normalizedFilters;

  const { reviewResponseTags, rating, status, assigneeUlid, createdAt, channel, placeUlid } = ticket;

  type CastStatusMap = Record<number, never[] | ReviewResponseTicket_pb.Status.Type[]>;
  const statusMap: CastStatusMap = filterStatusToTicketStatusMap;
  const getSubmittedAtEnum = matchSubmittedAtEpochToEnum(createdAt);

  let checkIfTagsMatch = filters_tags.length
    ? reviewResponseTags?.length === 0 && filters_tags.includes(Tag.UNKNOWN)
    : true;
  if (!checkIfTagsMatch && filters_tags.length) {
    checkIfTagsMatch = Boolean(reviewResponseTags?.some((e) => filters_tags.includes(e.value)));
  }

  return (
    (filters_rating.length ? filters_rating.includes(rating as 1 | 2 | 3 | 4 | 5) : true) &&
    checkIfTagsMatch &&
    (filters_status.length ? filters_status.find((x) => statusMap[x].includes(status as never)) != undefined : true) &&
    (filters_assigneeUlid.length ? filters_assigneeUlid.includes(assigneeUlid || '') : true) &&
    (filters_submittedAt.length ? filters_submittedAt?.includes(getSubmittedAtEnum) : true) &&
    (filters_channels.length ? filters_channels.includes(channel) : true) &&
    (filters_placeUlid.length ? filters_placeUlid.includes(placeUlid) : true)
  );
}

export function buildSubmitButtonText(
  publishingDetails: PublishingDetails | null | undefined,
  previewModal?: boolean
): string {
  const { canAutoPublish, canPublish } = publishingDetails as PublishingDetails;

  let publishMessage = canAutoPublish ? 'Publish' : 'Mark as Published';

  if (!previewModal) {
    publishMessage = 'Continue to Publish';
  }

  if (canPublish) {
    return publishMessage;
  } else {
    return 'Submit for Approval';
  }
}
