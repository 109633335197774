import { lighten } from 'color2k';
import { Flex } from 'reflexbox';
import styled, { css } from 'styled-components/macro';
import { BackgroundColorProps } from 'styled-system';
import { InputGroup } from 'src/components';
import vars from 'src/styles/variables';
import { RemoveItemButton } from '../OrganizationSelector/Organization.styled';

export const DropDownWrapper = styled(Flex).attrs({
  mb: vars.$spacing_medium,
})<{ backgroundColor?: BackgroundColorProps; showheader: boolean }>`
  cursor: ${({ showheader }) => (showheader ? 'pointer' : 'initial')};
  transition: 150ms;
  position: relative;
  flex-direction: row;
  padding: ${({ showheader }) => (showheader ? vars.$spacing : '0px')};
  color: ${vars.$white};
  align-items: center;
  svg[data-lefticon='iconleft'] {
    background-color: ${vars.$greenlight};
    border-radius: ${vars.$border_radius_circle};
    width: 2rem;
    height: 2rem;
    text-align: center;
    padding: ${vars.$spacing_small};
    align-self: center;
  }
  ${InputGroup} {
    padding-left: ${vars.$spacing};
    justify-content: space-between;
    flex: 1 1 auto;
    svg {
      cursor: pointer;
      padding: 0px;
      height: 0.8rem;
    }
  }

  border-radius: ${vars.$border_radius};

  div[data-header='dropdown_head'] {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  &:hover {
    background-color: ${({ backgroundColor }) => lighten(String(backgroundColor), 0.05)};
  }
`;

/****************** Drop Down Header Wrapper *********** */
export const DropdownHeaderWrapper = styled.div<{ isopen: boolean }>`
  width: 100%;
  display: flex;
  svg[data-icon='chevron-down'] {
    transition: transform 0.3s ease;
    transform: ${(props) => (props.isopen ? 'rotate(-180deg)' : 'rotate(0)')};
    margin-top: ${vars.$spacing_extra_small};
  }
  svg[data-icon='xmark'] {
    margin-right: -${vars.$spacing_extra_small};
    padding: 0.375rem;
    &:hover {
      color: ${vars.$secondary};
    }
  }
`;
/****************** Drop Down Head *********** */
export const DropDownTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

/****************** Drop Down Titles *********** */
export const DropDownPrimaryTitle = styled.div`
  font-size: ${vars.$font_size_sm};
`;

export const DropDownSecondaryTitle = styled.div<{ primaryTitle: undefined | null | string }>`
  font-size: ${vars.$font_size_extra_sm};
  opacity: ${({ primaryTitle }) => (primaryTitle ? 0.5 : 1)};
`;

/****************** Drop Down List Wrapper *********** */
type DropDownListWrapperProps = {
  maxheight: string;
  minHeight?: string;
  isCustomHeader: boolean;
  headerWidth: number;
  dropdownPosition?: string;
};

export const DropDownListWrapper = styled.ul<DropDownListWrapperProps>`
  background-color: ${vars.$white};
  color: ${vars.$font_color};

  position: absolute;
  display: block;
  top: 100%;
  ${({ dropdownPosition }) => (dropdownPosition == 'right' ? '' : 'right: 0')};
  ${({ dropdownPosition }) => (dropdownPosition == 'left' ? '' : 'left: 0')};
  ${({ dropdownPosition }) => (dropdownPosition == 'center' ? 'transform: translateX(-50%)' : '')};
  width: ${({ isCustomHeader, headerWidth }) => (isCustomHeader ? `${headerWidth}rem ` : '100%')};
  padding: 0;
  list-style: none;
  z-index: ${vars.$dropdown};
  padding: ${vars.$spacing_small};
  height: auto;
  max-height: ${({ maxheight }) => maxheight};
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `}
  margin-top: ${vars.$spacing_extra_extra_small};
  overflow-y: auto;
  box-shadow: ${vars.$shallow_shadow};
  border: ${vars.$border};
  border-radius: ${vars.$border_radius};
  border: ${vars.$border};
  div[data-search='search'] {
    padding: 0px;
  }
`;

/****************** Error Wrapper *********** */
export const DropDownErrorWrapper = styled.div`
  padding: ${vars.$spacing} 0 ${vars.$spacing} 0;
  text-align: center;
  div {
    font-size: ${vars.$font_size_extra_sm};
    color: ${vars.$gray_9};
    &:hover {
      text-decoration: underline;
    }
  }
`;

/****************** Drop Down Item *********** */
type DropDownItemWrapperProps = {
  disable: boolean;
  bgcolor: string;
  itemPadding: string;
  hoverColor: undefined | string;
  activeColor: undefined | string;
};

export const DropDownItemWrapper = styled.li<DropDownItemWrapperProps>`
  pointer-events: ${(props) => (props.disable ? 'none' : 'initial')};
  background: ${({ bgcolor }) => bgcolor};

  display: flex;
  margin: ${vars.$spacing_small} 0px;
  padding: ${({ itemPadding }) =>
    itemPadding ? itemPadding : `${vars.$spacing}  ${vars.$spacing} ${vars.$spacing} ${vars.$spacing_medium_large}`};
  transition: all 0.3s ease;
  &:not(:last-child) {
    border-bottom: 1px solid ${vars.$gray_f4};
  }
  border-radius: ${vars.$border_radius};
  opacity: 1;
  justify-content: space-between;
  &:hover,
  &.selected {
    color: ${vars.$white};
    cursor: pointer;
    div {
      color: ${vars.$white};
    }
    input ~ label {
      color: ${vars.$white};
    }
  }
  &:hover {
    background-color: ${({ hoverColor }) => (hoverColor ? hoverColor : vars.$greenlight)};
    ${RemoveItemButton} {
      color: ${vars.$white};
    }
  }
  &.selected {
    background-color: ${({ activeColor }) => (activeColor ? activeColor : vars.$greenlight)};
  }
`;
