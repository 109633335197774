import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import queryString from 'query-string';
import { ReviewResponseNote } from 'src/async/types';
import { Avatar } from 'src/components';
import { useStateValue } from 'src/context/appContext';
import { useInterval } from 'src/hooks';
import vars from 'src/styles/variables';
import { getInitials, getFormatedDate } from 'src/utils';
import {
  NoteSectionWrapper,
  NoteSectionInner,
  NoteSection,
  NoteSectionLeft,
  NoteSectionRight,
  NoteAttribution,
  NoteFromName,
  NoteFromDate,
  NoteContent,
  NoteDeleteBtn,
  DeleteTextWrapper,
} from './ResponseNotes.styled';
import { initialFormValues, validationSchema, RenderForm, FormValues } from './ResponseNotesForm';
import { useGraphMutations } from './useGraphMutations';

/**
 * URL Validation
 * See https://regex101.com/r/dWMMoh/1
 */
const HAS_URL_REGEX =
  /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/g;

const ResponseNotes = (props: { notes: ReviewResponseNote[] }): JSX.Element => {
  const [{ currentUser, userRoles }] = useStateValue();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setReRender] = React.useState(false);
  const scroll = document.getElementById('response-scroll');
  useEffect(() => {
    setTimeout(() => {
      scroll?.scrollTo({ top: scroll?.scrollHeight, behavior: 'smooth' });
    }, 20);
  }, [props.notes, scroll]);

  const location = useLocation();
  const qs = queryString.parse(location.search);
  const [mutableNotes, setMutableNotes] = React.useState<ReviewResponseNote[]>(props.notes);
  useInterval(() => setReRender((s) => !s), 1000 * 60); // * one minute delay
  // ! deleteNote mutation
  const { deleteNote, createNote } = useGraphMutations(qs.reviewUlid as string);

  React.useEffect(() => {
    /**
     * * Return cleanup to reset props to their original state
     * *   Prevents comment state from persisting when switching to a different ticket
     *
     */
    return setMutableNotes(props.notes);
  }, [props.notes]);
  async function handleDelete(noteUlid: string) {
    await deleteNote({
      variables: {
        input: {
          reviewUlid: qs.reviewUlid,
          noteUlid,
        },
      },
    });
  }
  async function handleSubmit(values: FormValues) {
    const { noteContent } = values;
    await createNote({
      variables: {
        input: {
          content: noteContent,
          notableUlid: qs.reviewUlid,
        },
      },
    });
    scroll?.scrollTo({ top: scroll?.scrollHeight, behavior: 'smooth' });
  }

  // ![FE] Visually differentiate between user notes & system notes - https://friendemic.atlassian.net/browse/ERC-628
  // ![BE] System flag for paper trail notes - https://friendemic.atlassian.net/browse/ERC-632

  return (
    <NoteSectionWrapper>
      <NoteSectionInner id="response-scroll">
        {mutableNotes.map((note) => {
          const { userName, deletedAt, createdAt, ulid, content, system } = note as ReviewResponseNote;
          const noteUserName = userName || 'System';
          const formattedCreatedAt = getFormatedDate(createdAt);
          const urlMatches = content.match(HAS_URL_REGEX);
          const parsedContent = urlMatches
            ? urlMatches.reduce<{ array: React.ReactNode[]; rest: string }>(
                (acc, match) => {
                  const [before, ...after] = acc.rest.split(match);
                  const href = match.startsWith('http') ? match : `https://${match}`;
                  return {
                    array: [
                      ...acc.array,
                      before,
                      <a key={match} href={href} target="_blank" rel="noreferrer">
                        {match}
                      </a>,
                    ],
                    rest: after.join(),
                  };
                },
                { array: [], rest: content }
              )
            : content;

          return (
            <NoteSection key={ulid}>
              <NoteSectionLeft>
                <Avatar
                  shadowColor={system ? vars.$border_color : vars.$greenlight}
                  backgroundColor={system ? undefined : vars.$greenlight}
                  color={system ? undefined : 'white'}
                >
                  {system || noteUserName === 'System' ? (
                    <FontAwesomeIcon fixedWidth icon={['fal', 'chart-network']} />
                  ) : (
                    getInitials(noteUserName)
                  )}
                </Avatar>
              </NoteSectionLeft>
              <NoteSectionRight>
                <NoteAttribution>
                  <NoteFromName>{noteUserName}</NoteFromName>
                  <NoteFromDate>{/second/gm.test(formattedCreatedAt) ? 'Now' : formattedCreatedAt}</NoteFromDate>
                  {!userRoles.isApprover && !deletedAt && !system ? (
                    <NoteDeleteBtn onClick={() => handleDelete(ulid)}>{'Delete'}</NoteDeleteBtn>
                  ) : (
                    !system && <DeleteTextWrapper>{'Deleted'}</DeleteTextWrapper>
                  )}
                </NoteAttribution>
                <NoteContent>
                  {deletedAt
                    ? 'This note has been deleted'
                    : typeof parsedContent === 'object'
                    ? [...parsedContent.array, parsedContent.rest]
                    : parsedContent}
                </NoteContent>
              </NoteSectionRight>
            </NoteSection>
          );
        })}
      </NoteSectionInner>
      {!userRoles.isApprover && (
        <NoteSection>
          <NoteSectionLeft></NoteSectionLeft>
          <NoteSectionRight>
            <Formik
              initialValues={initialFormValues}
              validationSchema={validationSchema}
              validateOnBlur={false}
              onSubmit={(values, { resetForm }) => {
                /**
                 * * Use Date().toISOString() as an in-state unique id.
                 * * Real ULIDs are fetched and populated on re-fetch
                 */
                handleSubmit(values);
                const date = new Date().toISOString();
                setMutableNotes((prevNotes) => [
                  ...prevNotes,
                  {
                    content: values.noteContent,
                    createdAt: Date.parse(date),
                    system: false,
                    ulid: date,
                    userName: currentUser.user.name,
                    userUlid: currentUser.userUlid,
                    userRole: currentUser.userRole,
                  },
                ]);
                resetForm({});
              }}
            >
              {(props) => {
                return (
                  <Form>
                    <RenderForm {...props} />
                  </Form>
                );
              }}
            </Formik>
          </NoteSectionRight>
        </NoteSection>
      )}
    </NoteSectionWrapper>
  );
};

export default ResponseNotes;
