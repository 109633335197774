import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { ImageProps } from 'src/types';

const CarGurus = ({ width = '100%', active, deleted }: ImageProps): ReactElement => (
  <svg
    x="0"
    y="0"
    version="1"
    width={width}
    height={width}
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    enableBackground="new 0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={deleted && active ? '#eb2424' : active ? '#5dc094' : '#ffffff'}
      d="M62.5 22h-7.48l-2.08-5.2C50.8 11.45 45.7 8 39.94 8H24.06c-5.76 0-10.86 3.45-13 8.8L8.98 22H1.5c-.98 0-1.69.92-1.46 1.86l.75 3c.17.67.77 1.14 1.46 1.14h2.51C3.08 29.47 2 31.6 2 34v6c0 2.01.77 3.83 2 5.24V52c0 2.21 1.79 4 4 4h4c2.21 0 4-1.79 4-4v-4h32v4c0 2.21 1.79 4 4 4h4c2.21 0 4-1.79 4-4v-6.76c1.23-1.41 2-3.23 2-5.24v-6c0-2.4-1.08-4.53-2.76-6h2.51c.69 0 1.29-.47 1.46-1.14l.75-3c.23-.94-.49-1.86-1.46-1.86zm-44.01-2.23A5.997 5.997 0 0124.06 16h15.88c2.45 0 4.66 1.49 5.57 3.77L48 26H16l2.49-6.23zM12 39.98c-2.4 0-4-1.6-4-3.99S9.6 32 12 32s6 3.59 6 5.98-3.6 2-6 2zm40 0c-2.4 0-6 .4-6-1.99S49.6 32 52 32s4 1.6 4 3.99-1.6 3.99-4 3.99z"
    />
  </svg>
);

CarGurus.propTypes = {
  width: PropTypes.string,
  active: PropTypes.bool,
};

export default CarGurus;
