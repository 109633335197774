import { lighten } from 'color2k';
import styled, { css } from 'styled-components/macro';
import vars from 'src/styles/variables';

const inputHeight = 2.375;
const paddingY = 0.6;
const paddingX = 0.75;
const fontSize = 0.875;

const inputSize = (/* height, padding_y, padding_x, font_size, border_radius */) => {
  return css`
    height: ${inputHeight}rem;
    padding: ${paddingY}rem ${paddingX}rem;
    font-size: ${fontSize}rem;
    line-height: inherit;
    border-radius: ${vars.$border_radius};
  `;
};

interface InputProps {
  hasError?: boolean;
  border?: boolean;
  transparent?: boolean;
}

export const Input = styled.input<InputProps>`
  display: block;
  width: 100%;
  font-weight: normal;
  /* background-color: white; */
  color: ${vars.$gray_6};
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: ${vars.$border};
  border-color: ${vars.$white};
  box-shadow: ${vars.$shallow_shadow};
  ${inputSize()}

  /* Fix for iOS force zooming inputs */
  @media (max-width: ${vars.$md}) {
    /* font-size: 16px; */
  }

  ${(props) => {
    return props.hasError && `border-color: ${vars.$danger};`;
  }}

  /* Unstyle the caret on <select>'s in IE10+. */
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &::placeholder {
    color: ${vars.$gray_9}; /* #999999 */
    opacity: 1; /* Override Firefox's unusual default opacity; */
  }

  &:focus {
    /* color: ${vars.$gray_6}; */
    background-color: white;
    border-color: ${lighten(vars.$primary, 0.25)};
    outline: 0;
  }

  /* CHECKBOX */
  &[type='checkbox'] {
    &:focus {
      box-shadow: none;
    }
  }

  ${(props) => {
    return (
      props.border &&
      css`
        border-color: ${vars.$gray_d};
        box-shadow: none;
      `
    );
  }}

  ${(props) => {
    return (
      props.transparent &&
      css`
        border-color: transparent;
        box-shadow: none;
        background-color: transparent;
        border-top: none;
        border-left: none;
        border-bottom: ${vars.$border};
        border-right: none;
        border-radius: 0;

        &:focus,
        &:hover {
          border-color: transparent;
          background-color: transparent;
          border-bottom: ${vars.$border};
        }
      `
    );
  }}

  &:disabled,
  &[readonly] {
    cursor: not-allowed;
    background-color: ${vars.$gray_fa};
    /* iOS fix for unreadable disabled content; */
    opacity: 1;
  }
`;

Input.displayName = 'Input';

export default Input;
