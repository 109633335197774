import React from 'react';
import { ScrollWrapper, SubNavbar } from 'src/components';
import { SettingsSubsection } from '../../settings.styled';
import PersonalInfo from './PersonalInfo';

const MyProfile = (props: {}): React.ReactElement => {
  return (
    <>
      <SubNavbar icon="user-plus" title="Personal Info" />
      <ScrollWrapper>
        <SettingsSubsection>
          <PersonalInfo />
        </SettingsSubsection>
      </ScrollWrapper>
    </>
  );
};

export default MyProfile;
