import React from 'react';
import { OptionTypeBase } from 'react-select';
import { ResponseUser, User } from '@friendemic/erc-graph';
import { FormikProps } from 'formik';
import { Option } from 'react-select/src/filters';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { Row, Column, InputElement, Label, StaticInput, SelectCustomItem, Pill } from 'src/components';
import { SUGGEST_RESPONSE_ASSIGNEE_USERS } from 'src/graph';
import { getRecords } from 'src/hooks';
import { InputElementFormConfig } from 'src/types';

export type FormValues = typeof initialFormValues;

export const initialFormValues = {
  add_organization_user_ulid: '',
  // FLAG - disabled until data is available
  // carfax: 3,
  // cargurus: 3,
  // carscom: 3,
  // dealerrater: 3,
  // facebook: 3,
  // google: 3,
  // yelp: 3,
};

export const IndividualPillWrapper = styled.div`
  margin-bottom: 0.75rem;
`;
export const LocationSelected = styled(Column)`
  display: flex;
  flex-wrap: wrap;
`;
export const validationSchema = Yup.object({
  add_organization_user_ulid: Yup.string().required('Required'),
});

const formConfig: InputElementFormConfig = {
  user: {
    type: 'select-async',
    border: true,
    placeholder: 'Select User...',
    name: 'add_organization_user_ulid',
    label: 'User',
  },
  location: {
    type: 'select',
    placeholder: 'Select location...',
    border: true,
    name: 'add_organization_user_places',
    label: 'Location',
  },
};
export const RenderForm = (
  props: FormikProps<FormValues> & {
    users: ResponseUser[];
    addNew: boolean;
    organizationSelection: OptionTypeBase;
    setAssignee: (assignee: string) => void;
    locationSelection: OptionTypeBase;
    setLocationSelection: (location: OptionTypeBase) => void;
    setPlacesTags: (tags: any[]) => void;
    placesTags: any[];
    places: any;
    activeUser: any;
  }
): React.ReactElement => {
  const [currentUserValue, setCurrentUserValue] = React.useState<any>();
  const ActiveUserName: JSX.Element | string = SelectCustomItem({
    primaryLabel: props.activeUser?.name,
    secondaryLabel: props.activeUser?.email,
  });

  return (
    <>
      <Row>
        <Column width={[1, 1 / 2]}>
          {!props.addNew ? (
            <>
              <StaticInput>
                <Label>{'User'}</Label>
                {ActiveUserName}
              </StaticInput>
              <InputElement name="add_organization_user_ulid" disabled hidden />
            </>
          ) : (
            <InputElement
              loadOptions={(input: string) => {
                return fetchUsers(props.organizationSelection.value, input);
              }}
              filterOption={(option: Option) => {
                const added_ulids = props.users.map(({ userUlid }) => userUlid);
                return !(added_ulids.indexOf(option.value) > -1);
              }}
              // disabled={props.placesTags.length === 0}
              key={JSON.stringify(props.users)}
              value={currentUserValue}
              handleSelectOnChange={(option) => {
                if (option) {
                  setCurrentUserValue(option);
                  props.setAssignee(option);
                }
              }}
              {...formConfig.user}
            />
          )}
        </Column>
        <Column width={[1, 1 / 2]}>
          <InputElement
            value={props.places && props.places.length > 0 ? props.places[0].name : ''}
            options={props.places}
            handleSelectOnChange={(e: any) => {
              if (e) {
                const tag = { name: e.label, ulid: e.value };
                const found = props.placesTags.find((x) => x.name === tag.name);
                if (!found) {
                  props.setPlacesTags([...props.placesTags, tag]);
                }
              }
            }}
            // handleSelectOnChange={(e: any) => {
            //   props.setLocationSelection(e);
            // }}
            // value={props.locationSelection}
            // options={props.places}
            {...formConfig.location}
          />
        </Column>
        <LocationSelected width={[1]}>
          {props.placesTags?.map((placeTag, index) => {
            return (
              <IndividualPillWrapper key={index}>
                <Pill
                  small
                  dismissible={true}
                  onDismissClick={() => {
                    const findIndex = props.placesTags.findIndex((x) => x.name === placeTag.name);
                    if (findIndex > -1) {
                      const newPlacesTags = [...props.placesTags];
                      newPlacesTags.splice(findIndex, 1);
                      props.setPlacesTags(newPlacesTags);
                    }
                  }}
                >
                  {placeTag.name}
                </Pill>
              </IndividualPillWrapper>
            );
          })}
        </LocationSelected>
      </Row>
      {/* // FLAG - disabled until data is available */}
      {/* <ChannelPermissionsForm /> */}
    </>
  );
};

/**
 * Get Users for autocomplete
 */
async function fetchUsers(responseOrganizationUlid: string, input: string) {
  const res = await getRecords({
    queryString: SUGGEST_RESPONSE_ASSIGNEE_USERS,
    options: {
      variables: { responseOrganizationUlid: responseOrganizationUlid, query: input },
    },
  });

  const { data }: { data: { suggestResponseAssigneeUsers: User[] } } = res;

  if (data && data.suggestResponseAssigneeUsers) {
    const { suggestResponseAssigneeUsers }: { suggestResponseAssigneeUsers: User[] } = data;
    return suggestResponseAssigneeUsers.map((item: User) => {
      const { name, email, ulid: value } = item;
      const label = SelectCustomItem({ primaryLabel: name, secondaryLabel: email });
      return { label, value };
    });
  }
  return [];
}
