import { gql } from '@apollo/client';

//! ------------------------
//! Response Organization
//! ------------------------
export const CREATE_RESPONSE_ORGANIZATION = gql`
  mutation createResponseOrganization($input: CreateResponseOrganizationInput!) {
    createResponseOrganization(input: $input) {
      status
    }
  }
`;
export const EDIT_RESPONSE_ORGANIZATION = gql`
  mutation editResponseOrganization($ulid: ID!, $input: EditResponseOrganizationInput!) {
    editResponseOrganization(ulid: $ulid, input: $input) {
      recordUlid
      status
    }
  }
`;
export const DELETE_RESPONSE_ORGANIZATION = gql`
  mutation deleteResponseOrganization($ulid: ID!) {
    deleteResponseOrganization(ulid: $ulid) {
      status
    }
  }
`;

//! ------------------------
//! Response Place
//! ------------------------
export const CREATE_RESPONSE_PLACE = gql`
  mutation createResponsePlace($placeUlid: String!, $responseOrganizationUlid: String!) {
    createResponsePlace(input: { placeUlid: $placeUlid, responseOrganizationUlid: $responseOrganizationUlid }) {
      errors
    }
  }
`;
export const EDIT_RESPONSE_PLACE = gql`
  mutation editResponsePlace($placeUlid: ID!, $input: EditResponsePlaceInput!) {
    editResponsePlace(placeUlid: $placeUlid, input: $input) {
      errors
    }
  }
`;
export const DELETE_RESPONSE_PLACE = gql`
  mutation deleteResponsePlace($placeUlid: ID!) {
    deleteResponsePlace(placeUlid: $placeUlid) {
      status
      errors
    }
  }
`;
export const ACTIVATE_RESPONSE_PLACE = gql`
  mutation activateResponsePlace($placeUlid: ID!, $input: ActivateResponsePlaceInput!) {
    activateResponsePlace(placeUlid: $placeUlid, input: $input) {
      errors
    }
  }
`;
export const DEACTIVATE_RESPONSE_PLACE = gql`
  mutation deactivateResponsePlace($placeUlid: ID!) {
    deactivateResponsePlace(placeUlid: $placeUlid) {
      errors
    }
  }
`;

//! ------------------------
//! Response Approver
//! ------------------------
export const CREATE_RESPONSE_APPROVER = gql`
  mutation createResponseApprover($placeUlid: ID!, $input: CreateResponseApproverInput!) {
    createResponseApprover(placeUlid: $placeUlid, input: $input) {
      record {
        ulid
      }
      errors
    }
  }
`;
export const EDIT_RESPONSE_APPROVER = gql`
  mutation editResponseApprover($placeUlid: ID!, $ulid: ID!, $input: EditResponseApproverInput!) {
    editResponseApprover(placeUlid: $placeUlid, ulid: $ulid, input: $input) {
      errors
    }
  }
`;
export const DELETE_RESPONSE_APPROVER = gql`
  mutation deleteResponseApprover($placeUlid: ID!, $ulid: ID!) {
    deleteResponseApprover(placeUlid: $placeUlid, ulid: $ulid) {
      errors
    }
  }
`;

//! ------------------------
//! Response Signature
//! ------------------------
export const CREATE_RESPONSE_SIGNATURE = gql`
  mutation createResponseSignature($placeUlid: ID!, $input: CreateResponseSignatureInput!) {
    createResponseSignature(placeUlid: $placeUlid, input: $input) {
      recordUlid
      errors
    }
  }
`;
export const EDIT_RESPONSE_SIGNATURE = gql`
  mutation editResponseSignature($placeUlid: ID!, $ulid: ID!, $input: EditResponseSignatureInput!) {
    editResponseSignature(placeUlid: $placeUlid, ulid: $ulid, input: $input) {
      recordUlid
      errors
    }
  }
`;
export const DELETE_RESPONSE_SIGNATURE = gql`
  mutation deleteResponseSignature($placeUlid: ID!, $ulid: ID!) {
    deleteResponseSignature(placeUlid: $placeUlid, ulid: $ulid) {
      errors
    }
  }
`;

//! ------------------------
//! Response Template
//! ------------------------
export const CREATE_RESPONSE_TEMPLATE = gql`
  mutation createResponseTemplate($placeUlid: ID!, $input: CreateResponseTemplateInput!) {
    createResponseTemplate(placeUlid: $placeUlid, input: $input) {
      recordUlid
      errors
    }
  }
`;
export const EDIT_RESPONSE_TEMPLATE = gql`
  mutation editResponseTemplate($placeUlid: ID!, $ulid: ID!, $input: EditResponseTemplateInput!) {
    editResponseTemplate(placeUlid: $placeUlid, ulid: $ulid, input: $input) {
      errors
    }
  }
`;
export const DELETE_RESPONSE_TEMPLATE = gql`
  mutation deleteResponseTemplate($placeUlid: ID!, $ulid: ID!) {
    deleteResponseTemplate(placeUlid: $placeUlid, ulid: $ulid) {
      errors
    }
  }
`;

//! ------------------------
//! Response Channel
//! ------------------------
export const EDIT_RESPONSE_CHANNEL = gql`
  mutation editResponseChannel($placeUlid: ID!, $ulid: ID!, $input: EditResponseChannelInput!) {
    editResponseChannel(placeUlid: $placeUlid, ulid: $ulid, input: $input) {
      errors
    }
  }
`;
export const DELETE_RESPONSE_CHANNEL = gql`
  mutation deleteResponseChannel($placeUlid: ID!, $ulid: ID!) {
    deleteResponseChannel(placeUlid: $placeUlid, ulid: $ulid) {
      status
      statusCode
      errors
    }
  }
`;

//! ------------------------
//! Response Place Profile Channel
//! ------------------------
export const CREATE_RESPONSE_PLACE_PROFILE_CHANNEL = gql`
  mutation createResponsePlaceProfileChannel($placeUlid: ID!, $input: CreateResponsePlaceProfileChannelInput!) {
    createResponsePlaceProfileChannel(placeUlid: $placeUlid, input: $input) {
      errors
    }
  }
`;

export const EDIT_RESPONSE_PLACE_PROFILE_CHANNEL = gql`
  mutation editResponsePlaceProfileChannel($placeUlid: ID!, $ulid: ID!, $input: EditResponsePlaceProfileChannelInput!) {
    editResponsePlaceProfileChannel(placeUlid: $placeUlid, ulid: $ulid, input: $input) {
      errors
    }
  }
`;

//! ------------------------
//! Admin Management
//! ------------------------
export const CREATE_ADMIN_RESPONSE_USER = gql`
  mutation createAdminResponseUser($input: CreateAdminResponseUserInput!) {
    createAdminResponseUser(input: $input) {
      errors
      record {
        userRole
      }
    }
  }
`;
export const EDIT_ADMIN_RESPONSE_USER = gql`
  mutation editAdminResponseUser($userUlid: ID!, $input: EditAdminResponseUserInput!) {
    editAdminResponseUser(userUlid: $userUlid, input: $input) {
      errors
    }
  }
`;
export const DELETE_ADMIN_RESPONSE_USER = gql`
  mutation deleteAdminResponseUser($userUlid: ID!) {
    deleteAdminResponseUser(userUlid: $userUlid) {
      errors
    }
  }
`;

//! ------------------------
//! User Management
//! ------------------------
export const CREATE_RESPONSE_USER = gql`
  mutation createResponseUser($input: CreateResponseUserInput!) {
    createResponseUser(input: $input) {
      errors
      record {
        userRole
      }
    }
  }
`;
export const EDIT_RESPONSE_USER = gql`
  mutation editResponseUser($userUlid: ID!, $input: EditResponseUserInput!) {
    editResponseUser(userUlid: $userUlid, input: $input) {
      errors
    }
  }
`;
export const DELETE_RESPONSE_USER = gql`
  mutation deleteResponseUser($userUlid: ID!) {
    deleteResponseUser(userUlid: $userUlid) {
      errors
    }
  }
`;

//! ------------------------
//! REPORTS
//! ------------------------
export const GENERATE_USER_REPORT = gql`
  mutation generateUserReport($input: GenerateUserReportInput!) {
    generateUserReport(input: $input) {
      errors
      statusCode
      record {
        url
      }
    }
  }
`;

//! ------------------------
//! Response Console
//! ------------------------
export const CREATE_REVIEW_RESPONSE_SUBMISSION = gql`
  mutation createReviewResponseSubmission($input: CreateReviewResponseSubmissionInput!) {
    createReviewResponseSubmission(input: $input) {
      errors
      recordUlid
    }
  }
`;
export const CREATE_REVIEW_RESPONSE_APPROVAL = gql`
  mutation createReviewResponseApproval($input: CreateReviewResponseApprovalInput!) {
    createReviewResponseApproval(input: $input) {
      errors
    }
  }
`;
export const CREATE_REVIEW_RESPONSE_NOTE = gql`
  mutation createReviewResponseNote($input: CreateReviewResponseNoteInput!) {
    createReviewResponseNote(input: $input) {
      errors
    }
  }
`;
/* Not available on graph-api yet */
export const DELETE_REVIEW_RESPONSE_NOTE = gql`
  mutation deleteReviewResponseNote($input: DeleteReviewResponseNoteInput!) {
    deleteReviewResponseNote(input: $input) {
      errors
    }
  }
`;
export const CREATE_REVIEW_RESPONSE_TAG = gql`
  mutation createReviewResponseTag($input: CreateReviewResponseTagInput!) {
    createReviewResponseTag(input: $input) {
      errors
    }
  }
`;
export const DELETE_REVIEW_RESPONSE_TAG = gql`
  mutation deleteReviewResponseTag($input: DeleteReviewResponseTagInput!) {
    deleteReviewResponseTag(input: $input) {
      errors
    }
  }
`;
export const EDIT_REVIEW_RESPONSE_TICKET = gql`
  mutation editReviewResponseTicket($reviewUlid: ID!, $input: EditReviewResponseTicketInput!) {
    editReviewResponseTicket(reviewUlid: $reviewUlid, input: $input) {
      errors
      record {
        type
      }
    }
  }
`;
export const COMPLETE_REVIEW_RESPONSE_SUBMISSION = gql`
  mutation completeReviewResponseSubmission($reviewResponseSubmissionUlid: ID!) {
    completeReviewResponseSubmission(reviewResponseSubmissionUlid: $reviewResponseSubmissionUlid) {
      errors
    }
  }
`;
export const ASSIGN_REVIEW_RESPONSE_TICKET = gql`
  mutation assignReviewResponseTicket($assigneeUlid: ID!, $reviewUlid: ID!) {
    assignReviewResponseTicket(assigneeUlid: $assigneeUlid, reviewUlid: $reviewUlid) {
      errors
      record {
        assigneeUser {
          name
          ulid
        }
      }
    }
  }
`;
export const CLOSE_REVIEW_RESPONSE_TICKET = gql`
  mutation closeReviewResponseTicket($reviewUlid: ID!, $input: CloseReviewResponseTicketInput!) {
    closeReviewResponseTicket(reviewUlid: $reviewUlid, input: $input) {
      errors
    }
  }
`;
export const CREATE_RESPONSE_NOTIFICATION_RECIPIENT = gql`
  mutation createResponseNotificationRecipient($placeUlid: ID!, $input: CreateResponseNotificationRecipientInput!) {
    createResponseNotificationRecipient(placeUlid: $placeUlid, input: $input) {
      recordUlid
      record {
        ulid
        placeUlid
        reviewType
      }
      status
      statusCode
      errors
    }
  }
`;
export const EDIT_RESPONSE_NOTIFICATION_RECIPIENT = gql`
  mutation editResponseNotificationRecipient(
    $placeUlid: ID!
    $ulid: ID!
    $input: EditResponseNotificationRecipientInput!
  ) {
    editResponseNotificationRecipient(placeUlid: $placeUlid, ulid: $ulid, input: $input) {
      recordUlid
      record {
        ulid
        placeUlid
        userUlid
        reviewType
      }

      status
      statusCode
      errors
    }
  }
`;

export const DELETE_RESPONSE_NOTIFICATION_RECIPIENT = gql`
  mutation deleteResponseNotificationRecipient($placeUlid: ID!, $ulid: ID!) {
    deleteResponseNotificationRecipient(placeUlid: $placeUlid, ulid: $ulid) {
      status
      statusCode
      errors
    }
  }
`;

export const CREATE_RESPONSE_ASSIGNEE = gql`
  mutation createResponseAssignee($input: UpsertResponseAssigneeInput!) {
    createResponseAssignee(input: $input) {
      recordUlid
      statusCode
      errors
    }
  }
`;

export const EDIT_RESPONSE_ASSIGNEE = gql`
  mutation editResponseAssignee($input: UpsertResponseAssigneeInput!) {
    editResponseAssignee(input: $input) {
      recordUlid
      statusCode
      errors
    }
  }
`;

export const DELETE_RESPONSE_ASSIGNEE = gql`
  mutation deleteResponseAssignee($orgUlid: ID!, $userUlid: ID!) {
    deleteResponseAssignee(orgUlid: $orgUlid, userUlid: $userUlid) {
      statusCode
      errors
    }
  }
`;
