import styled from 'styled-components/macro';
import Input from './Input';

interface InputProps {
  hasError?: boolean;
  border?: boolean;
}

// eslint-disable-next-line space-in-parens
export const TextArea = styled(Input).attrs({
  as: 'textarea',
})<InputProps>`
  min-height: 10rem;
  resize: none;
`;
TextArea.displayName = 'TextArea';

export default TextArea;
