import React from 'react';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';
import MainLogoText from '../../assets/images/MainLogoText';

const LoadingPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  z-index: 1;

  svg:last-of-type {
    width: 75%;
    animation: breathe 2s infinite alternate;
    @media (min-width: ${vars.$md}) {
      width: 50%;
    }
  }

  @keyframes breathe {
    0% {
      opacity: 0.8;
    }
    50% {
      transform: scale(1.01);
    }
    100% {
      opacity: 0.95;
    }
  }
`;

const PageLoading = (props: {}): JSX.Element => {
  return (
    <LoadingPageWrapper>
      <MainLogoText />
    </LoadingPageWrapper>
  );
};

export default PageLoading;
