import { LIST_ADMIN_RESPONSE_USERS, EDIT_ADMIN_RESPONSE_USER, CREATE_ADMIN_RESPONSE_USER } from 'src/graph';
import { useGraphMutation } from 'src/hooks';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useGraphMutations() {
  const { mutationMethod: createAdminResponseUser, error: createError } = useGraphMutation({
    mutation: CREATE_ADMIN_RESPONSE_USER,
    options: {
      refetchQueries: [{ query: LIST_ADMIN_RESPONSE_USERS }],
    },
  });

  const { mutationMethod: editAdminResponseUser, error: editError } = useGraphMutation({
    mutation: EDIT_ADMIN_RESPONSE_USER,
    options: {
      refetchQueries: [{ query: LIST_ADMIN_RESPONSE_USERS }],
    },
  });
  return {
    createAdminResponseUser,
    createError,
    editAdminResponseUser,
    editError,
  };
}
