import {
  ReviewResponseTicket,
  ReviewResponseNote,
  ReviewResponseTag,
  ReviewResponseSubmission,
  ReviewResponseApproval,
  ResponseApprover,
  ResponseSignature,
  PublishingDetails,
  AssigneeUser,
  ResponsePlace,
} from 'src/async/types';

// ------------------------------------------------
// REUSABLE FIXTURE BITS
// ------------------------------------------------

export const responseApprovers: ResponseApprover[] = [
  {
    ulid: '01GG0W3JTY9D5KNTERBXQ0EPCT',
    placeUlid: '01EE8Y83EBBMX5NT0679DMBS0S',
    userUlid: '01ENNNV11DHKA3QZDG4ZV21NY3',
    required: true,
    reviewType: 1,
    name: 'Kevin Bost',
    email: 'kevin@friendemic.com',
  },
  {
    ulid: '01GWSM42R0ERZJNXNN97ZGRZE3',
    placeUlid: '01EE8Y83EBBMX5NT0679DMBS0S',
    userUlid: '01ENNNV11DHKA3QZDG4ZV21NY9',
    required: false,
    reviewType: 1,
    name: 'A Different Name',
    email: 'adifferentemail@friendemic.com',
  },
];

export const responseSignatures: ResponseSignature[] = [
  {
    ulid: '01F1ERP8DDFV8RHBQRHGTCTKCE',
    content: 'General/All Signature',
    includeLocationName: false,
    signsReviewType: 1,
    placeUlid: '01ENTPBFGDCVRGR2BSD6CR50J7',
  },
  {
    ulid: '01F1RBPQSXJYYX7AF59C692CH7',
    content: 'Sales Signature',
    includeLocationName: false,
    signsReviewType: 2,
    placeUlid: '01ENTPBFGDCVRGR2BSD6CR50J7',
  },
  {
    ulid: '01F1RBPQSXJYYX7AF59C692CH7',
    content: 'Service Signature',
    includeLocationName: false,
    signsReviewType: 3,
    placeUlid: '01ENTPBFGDCVRGR2BSD6CR50J7',
  },
];

export const responsePlaces: ResponsePlace[] = [
  {
    placeUlid: '01EE8Y83EBBMX5NT0679DMBS0S',
    responseOrganizationUlid: '01F2STNHZZ7HNAK0TH5EPFANHM',
    active: true,
    placeName: 'Porsche of Greenville',
    organizationName: 'Porsche',
    settings: {
      approval_settings: {
        require_type: 1,
        approve_after_24_hours: false,
        approvals_needed_for: 0,
      },
      channel_settings: {
        approvals_needed_for: 0,
        limit_response: 0,
      },
      red_alert: {
        show: false,
        body: '',
      },
      response_settings: {
        response_delay_hours: 48,
      },
      start_date: '2021-08-04',
    },
  },
  {
    placeUlid: '01EE8Y7VTEWRXRP8M638FY2Y1P',
    responseOrganizationUlid: '01F2STNHZZ7HNAK0TH5EPFANHM',
    active: true,
    placeName: 'Volkswagen North Scottsdale',
    organizationName: 'Penske Automotive Group',
    settings: {
      approval_settings: {
        require_type: 2,
        approve_after_24_hours: false,
        approvals_needed_for: 0,
      },
      channel_settings: {
        approvals_needed_for: 0,
        limit_response: 0,
      },
      red_alert: {
        show: false,
        body: '',
      },
      response_settings: {
        response_delay_hours: 0,
      },
      start_date: '2017-01-01',
    },
  },
  {
    placeUlid: '01EE8Y7TFZDMH7ZKF9N2X19C96',
    responseOrganizationUlid: '01F2STNHZZ7HNAK0TH5EPFANHM',
    active: true,
    placeName: 'Mazda of Escondido',
    organizationName: 'Penske Automotive Group',
    settings: {
      approval_settings: {
        require_type: 2,
        approve_after_24_hours: false,
        approvals_needed_for: 2,
      },
      channel_settings: {
        approvals_needed_for: 2,
        limit_response: 0,
      },
      red_alert: {
        show: false,
        body: null,
      },
      response_settings: {
        response_delay_hours: 0,
      },
      start_date: '2017-05-06',
    },
  },
];

export const assigneeResponseUser: AssigneeUser = {
  name: 'Kevin Bost',
  ulid: '01ENNNV11DHKA3QZDG4ZV21NY3', // Kevin Bost
  email: 'kevin@friendemic.com',
  organizationIDs: [2121, 2121],
};

export const publishingDetails: PublishingDetails = {
  canAutoPublish: true,
  isApprovalRequired: false,
  shouldApproveAfter24: false,
  isApproved: false,
  isPublished: false,
  canPublish: false,
  isOverLimit: false,
};

export const reviewResponseTicket: ReviewResponseTicket = {
  reviewUlid: '01ENB8T0T2RQXN0VEDHMEGNQ76',
  responseOrganizationUlid: '01F2STNHZZ7HNAK0TH5EPFANHM',
  responseOrganizationName: '',
  placeUlid: '01EE8Y7VTEWRXRP8M638FY2Y1P',
  assigneeUlid: null,
  responseUlid: null,
  rating: 5,
  channel: 4,
  type: 1,
  status: 2,
  submittedAt: 1679071427000,
  delayedTill: null,
  reviewCustomer: 'James Huber',
  placeName: 'Volkswagen North Scottsdale',
  assigneeUserName: null,
  createdAt: 1677265181000,
  respondedAt: null,
  collectedAt: null,
  url: null,
  comment: 'James Huber',
  review: {
    ulid: '01ENB8T0T2RQXN0VEDHMEGNQ76',
    rating: 5,
    externalId: 'TODO',
    url: null,
    submittedAt: 1679071427000,
    comment: 'James Huber',
    harvestProfileChannel: 'facebook',
    harvestProfileUlid: '01EA2D8XQZM4T1E2SEW829KRJR',
    customer: 'James Huber',
    respondedAt: null,
    collectedAt: null,
  },
  assigneeUser: null,
  responsePlace: {
    placeUlid: '01EE8Y7VTEWRXRP8M638FY2Y1P',
    responseOrganizationUlid: '01F2STNHZZ7HNAK0TH5EPFANHM',
    active: true,
    placeName: 'Volkswagen North Scottsdale',
    organizationName: 'Penske Automotive Group',
    settings: {
      approval_settings: {
        require_type: 2,
        approve_after_24_hours: false,
        approvals_needed_for: 0,
      },
      channel_settings: {
        approvals_needed_for: 0,
        limit_response: 0,
      },
      red_alert: {
        show: false,
        body: '',
      },
      response_settings: {
        response_delay_hours: 0,
      },
      start_date: '2017-01-01',
    },
  },
  responseApprovers: [
    {
      ulid: '01G19GWMWC1YFCJVZH4RW6EXNZ',
      placeUlid: '01EE8Y7VTEWRXRP8M638FY2Y1P',
      userUlid: '01FMX7YJBP4N3Y38DTTZC769BQ',
      required: true,
      reviewType: 2,
      name: null,
      email: null,
    },
    {
      ulid: '01G19GWVM9RCDT91T5WHXQAZQ8',
      placeUlid: '01EE8Y7VTEWRXRP8M638FY2Y1P',
      userUlid: '01FMX81TXZS1TXYA4ZW615BYGP',
      required: false,
      reviewType: 3,
      name: null,
      email: null,
    },
    {
      ulid: '01G19GX6CTNK0AQ8FW8GHC5B2N',
      placeUlid: '01EE8Y7VTEWRXRP8M638FY2Y1P',
      userUlid: '01FVWKA4TBE1FHHDQHXNYF34T2',
      required: false,
      reviewType: 1,
      name: null,
      email: null,
    },
  ],
  responseSignatures: responseSignatures,
  responseTemplates: [
    {
      ulid: '01F1DZ08AK9ZQFSRKTXTJJQZKM',
      body: 'r: {r}\nreviewer: {reviewer}\n\nc: {c}\ncustomer: {customer}',
      title: 'Var Test',
      placeUlid: '01ENTPBFGDCVRGR2BSD6CR50J7',
    },
    {
      ulid: '01F1R8B1QM0488R7GZ0CSMV7VQ',
      body: 'Hello!\n\n\n{loc}\nTest\nBye {r}!',
      title: 'Var Test 2',
      placeUlid: '01ENTPBFGDCVRGR2BSD6CR50J7',
    },
  ],
  reviewResponseTags: [],
  reviewResponseNotes: [],
  reviewResponseSubmissions: [],
  publishingDetails: {
    canAutoPublish: true,
    canPublish: false,
    isApprovalRequired: true,
    isApproved: false,
    isPublished: false,
    shouldApproveAfter24: false,
    isOverLimit: false,
  },
};

export const responseUsers: AssigneeUser[] = [
  {
    ulid: '01ENNNGTRGHT732WR2CTB4H3DM',
    name: 'Josh Norton',
    email: 'email@email.email',
    organizationIDs: [2121, 2121],
  },
  {
    ulid: '01ENNNV11DHKA3QZDG4ZV21NY3',
    name: 'Kevin Bost',
    email: 'kevin@friendemic.com',
    organizationIDs: [2121, 2121],
  },
];

export const reviewResponseNotes: ReviewResponseNote[] = [
  {
    ulid: '01GWQR5SBY43F382SM4ZAPFN3Q',
    system: true,
    content: 'Ticket type changed to Sales',
    userUlid: '01ENNNV11DHKA3QZDG4ZV21NY3',
    userName: 'Kevin Bost',
    userRole: 0,
    createdAt: 1680129320000,
    deletedAt: null,
  },
  {
    ulid: '01GWSP5S10SR1Z2V4BPFN5TYHS',
    system: true,
    content: 'Ticket Assignee changed',
    userUlid: '01ENNNV11DHKA3QZDG4ZV21NY3',
    userName: 'Kevin Bost',
    userRole: 0,
    createdAt: 1680194331000,
    deletedAt: null,
  },
  {
    ulid: '01GWST4MH7HMYQB4B4Y4S40176',
    system: true,
    content: "Added Tag 'Awaiting Client Input'",
    userUlid: '01ENNNV11DHKA3QZDG4ZV21NY3',
    userName: 'Kevin Bost',
    userRole: 0,
    createdAt: 1680198488000,
    deletedAt: null,
  },
];

export const reviewResponseTags: ReviewResponseTag[] = [
  {
    ulid: '01FA3BPG0H2S13ST1BM0WP6JAE',
    value: 30,
    createdAt: 1625758068000,
    reviewUlid: '01ENB6PC79MF43J8MQQSEFGJGY',
  },
];

export const reviewResponseApprovals: ReviewResponseApproval[] = [
  {
    action: 3, // Denied
    comment: "No... you weren't supposed to actually say that. Make it more like a warm handshake.",
    createdAt: 1666482416000,
    responseApproverEmail: responseApprovers[0].email,
    responseApproverName: responseApprovers[0].name,
    responseApproverUlid: responseApprovers[0].ulid,
    reviewResponseSubmissionUlid: 'RESPONSE_SUBMISSION_ULID_1',
    ulid: 'APPROVAL_ULID_1',
  },
  {
    action: 2, // Approved
    comment: 'Whatever just send it.',
    createdAt: 1666482417000,
    responseApproverEmail: responseApprovers[0].email,
    responseApproverName: responseApprovers[0].name,
    responseApproverUlid: responseApprovers[0].ulid,
    reviewResponseSubmissionUlid: 'RESPONSE_SUBMISSION_ULID_2',
    ulid: 'APPROVAL_ULID_2',
  },
  {
    action: 2, // Approved
    comment: null,
    createdAt: 1666482418000,
    responseApproverUlid: responseApprovers[1].ulid,
    responseApproverName: responseApprovers[1].name,
    responseApproverEmail: responseApprovers[1].email,
    reviewResponseSubmissionUlid: 'REVIEWSUBMISSION_ULID_3',
    ulid: 'APPROVAL_ULID_3',
  },
];

export const reviewResponseSubmissions: ReviewResponseSubmission[] = [
  {
    action: 2,
    approvals: [],
    comment: 'asdf',
    responseUserEmail: 'kevin@friendemic.com',
    responseUserName: 'Kevin Bost',
    submittedAt: 1666482416000,
    ulid: 'RESPONSE_SUBMISSION_1',
  },
  {
    action: 1, // Pending
    approvals: [],
    comment: 'I am the manager.',
    responseUserEmail: 'kevin@friendemic.com',
    responseUserName: 'Kevin Bost',
    submittedAt: 1616963556000,
    ulid: 'RESPONSE_SUBMISSION_2',
  },
  {
    action: 1, // Pending
    approvals: [],
    comment: "Good morning! I'm sorry you had a wuff time wittle guy. Here's a voucher $3 off your next car lmao.",
    responseUserEmail: 'kevin@friendemic.com',
    responseUserName: 'Kevin Bost',
    submittedAt: 1616964556000,
    ulid: 'RESPONSE_SUBMISSION_2',
  },
];
