import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScrollWrapper, SubNavbar } from 'src/components';
import { useStateValue } from 'src/context';
import { settingsRoutes } from 'src/routes';
import { SettingsSectionLeft, SettingsSectionRight, SettingsListItem } from './settings.styled';

const Settings = (props: {}): React.ReactElement => {
  const [{ userRoles }] = useStateValue();
  return (
    <>
      <Route exact path="/settings">
        <Redirect to="/settings/my-profile" />
      </Route>
      <SettingsSectionLeft>
        <SubNavbar icon="cog" title="Settings" />
        <ScrollWrapper>
          {settingsRoutes(userRoles)?.map((route) => {
            const {
              path,
              meta: { label, icon, disabled },
            } = route;

            return !disabled ? (
              <SettingsListItem to={path} key={label}>
                <FontAwesomeIcon fixedWidth icon={icon as IconName} />
                {label}
              </SettingsListItem>
            ) : null;
          })}
        </ScrollWrapper>
      </SettingsSectionLeft>
      <SettingsSectionRight>
        <Switch>
          {settingsRoutes(userRoles)?.map((route, i) => {
            const { path, component } = route;
            return <Route key={i} path={path} component={component} />;
          })}
        </Switch>
      </SettingsSectionRight>
    </>
  );
};

export default Settings;
