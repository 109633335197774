import React from 'react';
import nl2br from 'react-nl2br';
import { ActionFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_submission_action_friendly';
import { ReviewResponseSubmission as Submission } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_submission_pb';
import { ReviewResponseSubmission, ReviewResponseTicket } from 'src/async/types';
import { Column } from 'src/components';
import CardTemplate from '../CardTemplate';
import { MetaWrapper, MetaTitle, MetaText } from '../ReviewResponse.styled';
import ResponseApprovals from './ResponseApprovals';

const ResponseSubmissions = (props: {
  submissions: ReviewResponseSubmission[];
  ticket: ReviewResponseTicket;
}): JSX.Element => {
  const { submissions } = props;

  return (
    <>
      {submissions.map((submission) => {
        // * Check if currentUser's ulid exists in the array of current submission's approvals: Boolean

        const draftOrPendingStatus =
          submission.action === Submission.Action.PENDING || submission.action === Submission.Action.DRAFT;

        return (
          <div key={submission.ulid}>
            <CardTemplate
              config={{
                title: 'Response Submission',
                icon: 'reply-all',
                date: submission.submittedAt,
                dateTitle: 'Submitted at',
                headingRight:
                  !submission.submittedAt && draftOrPendingStatus ? (
                    <span style={{ color: '#bbb' }}>{ActionFriendly.toFriendly(submission.action)}</span>
                  ) : undefined,
              }}
            >
              <MetaWrapper>
                <Column>
                  <MetaTitle>{'From'}</MetaTitle>
                  <MetaText>{submission?.responseUserName || 'External User'}</MetaText>
                </Column>
                {/* <Column>
                  <MetaTitle>{'Action'}</MetaTitle>
                  <MetaText>{ActionFriendly.toFriendly(submission.action)}</MetaText>
                </Column> */}
              </MetaWrapper>
              <MetaWrapper>
                <Column>
                  <MetaTitle>{'Response'}</MetaTitle>
                  <MetaText>{nl2br(submission.comment)}</MetaText>
                </Column>
              </MetaWrapper>
            </CardTemplate>
            <ResponseApprovals approvals={submission.approvals || []} submissions={submissions} />
          </div>
        );
      })}
    </>
  );
};

export default ResponseSubmissions;
