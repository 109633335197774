import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { InputActionMeta } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Placement } from '@popperjs/core';
import { useField } from 'formik';
import { Option } from 'react-select/src/filters';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';
import Checkbox from './Checkbox';
import DateInput from './DateInput';
import ErrorSpan from './ErrorSpan';
import FormGroup from './FormGroup';
import Input from './Input';
import InputGroup from './InputGroup';
import Label from './Label';
import Radio from './Radio';
import { Select, SelectAsync } from './Select';
import TextArea from './TextArea';
import Toggle from './Toggle';
import Wysiwyg from './Wysiwyg';

const ErrorSpanWithLink = styled.div<{ hasError?: boolean }>`
  display: flex;
  justify-content: space-between;
  span {
    flex: auto;
    width: inherit;
  }
  a {
    font-size: 0.625rem;
    margin-top: 0.15rem;
    text-align: right;
    color: ${(props) => (props.hasError ? vars.$danger : vars.$gray_d)};
    font-weight: bold;
    flex-shrink: 0;
  }
`;
ErrorSpanWithLink.displayName = 'ErrorSpan';

/* prettier-ignore */
type InputTypes = 'button' | 'checkbox' | 'color' | 'date' | 'datetime' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week';

export interface InputElementProps {
  name: string;
  // multi
  label?: string;
  id?: string;
  type?: 'select-async' | 'select' | 'toggle' | 'textarea' | 'wysiwyg' | 'dateInput' | InputTypes;
  disabled?: boolean;
  placeholder?: string;
  border?: boolean;
  transparent?: boolean;
  iconLeft?: string;
  iconRight?: string;
  icon?: string;
  autoComplete?: string;
  hidden?: boolean;
  helperText?: string;
  // input
  LinkComponent?: React.ComponentType;
  // radio
  stacked?: boolean;
  // checkbox
  checkboxlabel?: string;
  checkboxhref?: string;
  checkboxlabelbefore?: string;
  checkboxlabellinktext?: string;
  checkboxlabelafter?: string;
  // toggle
  noValidation?: boolean;
  tooltip?: string;
  tooltipPlacement?: Placement;
  // dateInput
  selectsEnd?: boolean;
  rangeSelect?: boolean;
  selectsStart?: boolean;
  minDate?: Date;
  maxDate?: Date;
  // react-select & react-select/async
  value?: any;
  isMulti?: boolean;
  options?: { label: string; value: string | boolean | number }[];
  loadOptions?: any;
  filterOption?: ((option: Option, rawInput: string) => boolean) | null | undefined;
  handleSelectOnChange?: (args: any) => void;
  handleSelectOnBlur?: () => void;
  onInputChange?: ((newValue: string, actionMeta: InputActionMeta) => void) | undefined;
  menuPosition?: 'absolute' | 'fixed';
  components?: Record<string, ReactNode>;
  handleChange?: (e: any) => void;
  defaultOptions?: boolean;
  moreSelectSpacing?: boolean;
  isClearable?: boolean;
  noResultsMessage?: string;
  // number
  min?: number;
  max?: number;
  step?: number;
}

/**
 * Reusable Formik enabled group of label/input/error
 * useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
 *
 */
const InputElement = (props: InputElementProps): React.ReactElement => {
  const {
    label,
    id,
    name,
    hidden,
    LinkComponent,
    options,
    loadOptions,
    type,
    checkboxhref = '#',
    checkboxlabel,
    checkboxlabelbefore,
    checkboxlabellinktext,
    checkboxlabelafter,
    transparent,
    noValidation,
    value,
    handleSelectOnChange,
    handleSelectOnBlur,
    handleChange,
    defaultOptions,
    moreSelectSpacing,
    isClearable = true,
    noResultsMessage,
    disabled,
    helperText,
  } = props;
  const [field, meta, helpers] = useField(props);
  const { touched, error } = meta;
  let elem;

  switch (type) {
    case 'select':
      elem = (
        <FormGroup hasError={Boolean(touched && error)} hidden={hidden}>
          <Label htmlFor={id || name}>{label}</Label>
          <Select
            {...props}
            isClearable={isClearable}
            isDisabled={disabled}
            id={id || name}
            options={options}
            hasError={Boolean(touched && error)}
            value={value ? value : options ? options.find((option) => option.value === field.value) : ''}
            onChange={(option: any): void => {
              if (handleSelectOnChange) {
                handleSelectOnChange(option);
              }
              option && helpers.setError('');
              return helpers.setValue(option ? option.value : '');
            }}
            onInputChange={(newValue) => {
              newValue && helpers.setError('');
            }}
            onBlur={(e) => {
              if (handleSelectOnBlur) {
                handleSelectOnBlur();
              }
              field.onBlur(e);
            }}
            data-testid="input-select"
          />
          <ErrorSpan data-testid="errors-input-select">{error}</ErrorSpan>
        </FormGroup>
      );
      break;
    case 'select-async':
      elem = (
        <FormGroup hasError={Boolean(touched && error)} hidden={hidden}>
          <Label htmlFor={id || name}>{label}</Label>
          <SelectAsync
            {...props}
            isClearable
            moreSelectSpacing={moreSelectSpacing}
            loadOptions={loadOptions}
            isDisabled={disabled}
            id={id || name}
            hasError={Boolean(touched && error)}
            value={value && value}
            defaultOptions={defaultOptions}
            noResultsMessage={noResultsMessage}
            onChange={(option: any): void => {
              if (handleSelectOnChange) {
                handleSelectOnChange(option);
              }
              option && helpers.setError('');
              return helpers.setValue(option ? option.value : '');
            }}
            onInputChange={(newValue) => {
              newValue && helpers.setError('');
            }}
            onBlur={field.onBlur}
            data-testid="input-select-async"
          />
          <ErrorSpan data-testid="errors-input-select">{error}</ErrorSpan>
        </FormGroup>
      );
      break;
    case 'checkbox':
      elem = (
        <FormGroup hasError={Boolean(touched && error)} hidden={hidden}>
          {label && <Label htmlFor={id || name}>{label}</Label>}
          <Checkbox hasError={Boolean(touched && error)}>
            <input {...field} {...props} id={id || name} type="checkbox" data-testid="input-checkbox" />
            <FontAwesomeIcon icon={['fas', 'check']} title="check" />
            {props.checkboxhref ? (
              <label htmlFor={id || name}>
                {checkboxlabelbefore}
                <Link to={checkboxhref} target="_blank">
                  {checkboxlabellinktext}
                </Link>
                {checkboxlabelafter}
              </label>
            ) : (
              <label htmlFor={id || name}>{checkboxlabel}</label>
            )}
          </Checkbox>
          <ErrorSpan data-testid="errors-input-checkbox">{error}</ErrorSpan>
        </FormGroup>
      );
      break;
    case 'toggle':
      elem = (
        <FormGroup hasError={Boolean(touched && error)} noValidation={noValidation} hidden={hidden}>
          <Toggle
            {...field}
            {...props}
            disabled={disabled}
            id={id || name}
            hasError={Boolean(touched && error)}
            labelRight={<>{label && label}</>}
            testId="input-toggle"
          />
          {!noValidation && <ErrorSpan data-testid="errors-input-toggle">{error}</ErrorSpan>}
        </FormGroup>
      );
      break;
    case 'radio':
      elem = <Radio {...props} />;
      break;
    case 'textarea':
      elem = (
        <FormGroup hasError={Boolean(touched && error)} hidden={hidden}>
          {label && <Label htmlFor={id || name}>{label}</Label>}
          <TextArea
            {...field}
            {...props}
            id={id || name}
            type={type}
            data-testid="input-textarea"
            {...(handleChange ? { onChange: handleChange } : {})}
          />
          {LinkComponent ? (
            <ErrorSpanWithLink hasError={Boolean(touched && error)}>
              <ErrorSpan data-testid="errors-input-textarea">{error}</ErrorSpan>
              <LinkComponent />
            </ErrorSpanWithLink>
          ) : (
            <ErrorSpan data-testid="errors-input-textarea">{error}</ErrorSpan>
          )}
        </FormGroup>
      );
      break;
    case 'dateInput':
      elem = (
        <FormGroup hasError={Boolean(touched && error)} hidden={hidden}>
          {label && <Label htmlFor={id || name}>{label}</Label>}
          <DateInput {...field} {...props} id={id || name} {...(handleChange ? { onChange: handleChange } : {})} />
          {helperText && !error ? <ErrorSpan hasHelperText>{helperText}</ErrorSpan> : <ErrorSpan>{error}</ErrorSpan>}
        </FormGroup>
      );
      break;
    case 'wysiwyg':
      elem = (
        <FormGroup hasError={Boolean(touched && error)} hidden={hidden}>
          {label && <Label htmlFor={id || name}>{label}</Label>}
          {/* <Wysiwyg value={field.value} onChange={field.onChange(field.name)} id={id || name} {...props} /> */}
          <Wysiwyg {...props} value={field.value} onChange={field.onChange(field.name)} id={id || name} />
          <ErrorSpan data-testid="errors-input-wysiwyg">{error}</ErrorSpan>
        </FormGroup>
      );
      break;

    default:
      const { iconLeft, iconRight, ...rest } = props;
      elem = (
        <FormGroup hasError={Boolean(touched && error)} hidden={hidden}>
          {label && <Label htmlFor={id || name}>{label}</Label>}
          {Boolean(iconLeft || iconRight) ? (
            <InputGroup iconLeft={iconLeft} iconRight={iconRight} transparent={transparent}>
              <Input
                {...field}
                {...rest}
                id={id || name}
                transparent={transparent}
                type={type}
                data-testid="input-text-input-group"
                {...(handleChange ? { onChange: handleChange } : {})}
              />
            </InputGroup>
          ) : (
            <Input
              {...field}
              {...rest}
              id={id || name}
              transparent={transparent}
              type={type}
              data-testid="input-text"
              {...(handleChange ? { onChange: handleChange } : {})}
            />
          )}
          {LinkComponent ? (
            <ErrorSpanWithLink hasError={Boolean(touched && error)}>
              <ErrorSpan data-testid="errors-input-text">{error}</ErrorSpan>
              <LinkComponent />
            </ErrorSpanWithLink>
          ) : (
            <ErrorSpan data-testid="errors-input-text">{error}</ErrorSpan>
          )}
        </FormGroup>
      );
      break;
  }

  return elem;
};

export default InputElement;
