import React from 'react';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { Row, Column, InputElement, AutoSave } from 'src/components';
import { useDebounceInput } from 'src/hooks';
import { InputElementFormConfig } from 'src/types';

export type FormValues = typeof initialFormValues;

export const initialFormValues = {
  require_type: 0,
  approve_after_24_hours: false,
  limit_response: 0,
  show: false,
  body: '',
};

export const validationSchema = Yup.object({
  limit_response: Yup.number().moreThan(-1, 'Negative numbers are not allowed').required('Required'),
});

export const formConfig: InputElementFormConfig = {
  limit_response: {
    name: 'limit_response',
    label: 'Limit Responses',
    type: 'number',
    border: true,
    min: 0,
  },
};

export const RenderForm = (props: FormikProps<FormValues>): React.ReactElement => {
  const { values } = props;
  const [state, setValue] = React.useState({ name: formConfig.limit_response.name, value: values.limit_response });

  React.useEffect(() => {
    setValue((s) => ({ ...s, value: values.limit_response }));
  }, [values]);

  const handleChangeText = useDebounceInput((...args: any) => {
    const [e] = args;
    setValue({ ...state, name: e.target.name, value: e.target.value });
  }, 500);

  return (
    <Row>
      <Column width={[1]}>
        <InputElement {...formConfig.limit_response} handleChange={handleChangeText} value={state.value} />
      </Column>
      <AutoSave />
    </Row>
  );
};
