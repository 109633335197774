import styled from 'styled-components/macro';
import { FormGroup } from 'src/components';

export const CardTemplateWrapper = styled.div`
  margin-bottom: 1rem;
  .ql-editor {
    padding: 0;
  }
`;

export const HeadingLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const HeadingRight = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;

  ${FormGroup} {
    flex: 1;
    /* margin-left: 2rem; */
    max-width: 20rem;
  }
`;

export const DateWrapper = styled.div`
  line-height: 0.9rem;
  text-align: center;
  margin-left: 1rem;
  div:first-of-type {
    font-size: 70%;
  }
  div:last-of-type {
    font-size: 80%;
    font-weight: bold;
  }
`;

export const HeadingIcon = styled.div`
  /* px more reliable for small square circle w/ fa icon */

  border-radius: 50px;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;

  align-items: center;
  justify-content: center;
  svg,
  svg[data-icon] {
    width: 0.8rem;
    height: 0.8em;
  }
  background-color: rgba(0, 0, 0, 0.0875);
  margin-right: 0.5rem;
  display: flex;
  span {
    line-height: 1rem;
  }
`;
