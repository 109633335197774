import React from 'react';
import { ScrollWrapper, SubNavbar, ContentWrapper, NoDataMessage } from 'src/components';
import { useStateValue } from '../../context/appContext';
import UserReports from './UserReports';

const Reports = (props: {}): React.ReactElement => {
  const [{ userRoles }] = useStateValue();

  if (userRoles.isResponseUser) {
    return <NoDataMessage>{'You do not have permission to view this page.'}</NoDataMessage>;
  } else {
    return (
      <ContentWrapper flexDirection="column">
        <SubNavbar icon="analytics" title="Reports" />
        <ScrollWrapper>
          <UserReports />
        </ScrollWrapper>
      </ContentWrapper>
    );
  }
};

export default Reports;
