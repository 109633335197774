import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Flex } from 'reflexbox/styled-components';
import styled from 'styled-components/macro';
import KenectLogo from 'src/assets/images/KenectLogo';
import { useGetReviewResponseTicket } from 'src/async';
import { NoDataMessage, ScrollWrapper } from 'src/components';
import vars from 'src/styles/variables';
import { ResponseConsoleSection, ApproverTicketMeta } from '../../../ResponseConsole/ResponseConsole.styled';
import ResponseTicket from '../../../ResponseConsole/ResponseTicket';

const ApproveTicketWrapper = styled(Flex)`
  flex-direction: column;
  flex: 1;
  z-index: 1;
  ${ResponseConsoleSection} {
    @media (min-width: ${vars.$sm}) {
      padding: 2rem 3rem;
    }
    ${ApproverTicketMeta} {
      margin-top: -1rem;
    }
  }
`;

const KenectHeader = styled.div`
  background-color: ${vars.$gray_f4};
  border-bottom: ${vars.$border};
  padding: 1rem 2rem;
  svg {
    margin-left: 0.25rem; // aligns logo with the left edge of the body content
    height: 1.625rem;
    width: auto;
  }
`;

const ApproveTicket = (props: RouteComponentProps<{ reviewUlid?: string }>): React.ReactElement => {
  const {
    match: {
      params: { reviewUlid },
    },
  } = props;

  const { data: ticketData, isLoading: isTicketDataLoading } = useGetReviewResponseTicket(reviewUlid as string);

  return (
    <ApproveTicketWrapper>
      <KenectHeader>
        <KenectLogo />
      </KenectHeader>
      <ScrollWrapper pt0>
        {isTicketDataLoading ? (
          <ResponseConsoleSection flex="1 1 auto">
            <NoDataMessage>{'Loading...'}</NoDataMessage>
          </ResponseConsoleSection>
        ) : (
          <ResponseTicket ticket={ticketData} />
        )}
      </ScrollWrapper>
    </ApproveTicketWrapper>
  );
};

export default ApproveTicket;
