import React from 'react';
import { useModal } from 'react-modal-hook';
import styled from 'styled-components/macro';
import { ReviewResponseTicket } from 'src/async/types';
import { Button } from 'src/components';
import ResponsePreviewModal from './ResponsePreviewModal';

const PublishButtonWrapper = styled(Button).attrs({
  btnLg: true,
})`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const PublishButton = (props: {
  handlePublish: () => Promise<void>;
  disabled: boolean;
  isSubmitting: boolean;
  ticket: ReviewResponseTicket;
}): JSX.Element => {
  const { disabled, ticket, handlePublish, isSubmitting } = props;
  const { reviewResponseSubmissions } = ticket;
  const submissions = reviewResponseSubmissions || [];
  const lastSubmission = submissions[submissions.length - 1] || null;

  const [showModal, hideModal] = useModal(() => {
    return (
      <ResponsePreviewModal
        commentBody={lastSubmission ? lastSubmission.comment : 'UNKNOWN'}
        handleSubmit={handlePublish}
        hideModal={hideModal}
        ticket={ticket}
        submitDisabled={disabled}
        isSubmitting={isSubmitting}
      />
    );
  });

  return <PublishButtonWrapper disabled={disabled} content="Publish" onClick={showModal} />;
};

export default PublishButton;
