import React from 'react';
import { useModal } from 'react-modal-hook';
import { ResponseUser } from '@friendemic/erc-graph';
import { Formik, Form, FormikProps } from 'formik';
import {
  FormGroup,
  Input,
  InputGroup,
  Row,
  Column,
  Button,
  Table,
  Modal,
  ScrollWrapper,
  SubNavbar,
} from 'src/components';
import { LIST_ADMIN_RESPONSE_USERS } from 'src/graph';
import { withDataFetcher } from 'src/hooks/withDataFetcher';
import { fetchUserByUlid, toast } from 'src/utils';
import { SettingsSubsection } from '../../settings.styled';
import { RenderForm, FormValues, initialFormValues, validationSchema } from './AdminManagementForm';
import { buildData, columns } from './table';
import { useGraphMutations } from './useGraphMutations';

const AdminManagement = (props: { listAdminResponseUsers?: ResponseUser[] }): React.ReactElement => {
  const { listAdminResponseUsers } = props;

  // Data object to use in table
  const [tableData, setTableData] = React.useState<any>();

  // Admin array to send in final request
  const [admins, setAdmins] = React.useState<ResponseUser[]>(listAdminResponseUsers || []);

  // The admin being actively edited, set when user clicks "Edit" button
  const [activeEditingAdmin, setActiveEditingAdmin] = React.useState<FormValues>(initialFormValues);

  const { createAdminResponseUser, createError, editAdminResponseUser, editError } = useGraphMutations();

  if (createError) {
    toast({ type: 'warn', message: createError });
  }

  if (editError) {
    toast({ type: 'warn', message: editError });
  }

  React.useEffect(() => {
    setTableData(buildData(admins, setAdmins, setActiveEditingAdmin, showAddAdminModal));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [admins]);

  function handleFilter(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.currentTarget;
    if (!listAdminResponseUsers) {
      return setAdmins([]);
    }
    const filtered = listAdminResponseUsers.filter(
      (userMatch: ResponseUser) =>
        (userMatch && userMatch.userUlid.toLowerCase().includes(value.toLowerCase())) ||
        (userMatch && userMatch.user && userMatch.user.email.toLowerCase().includes(value.toLowerCase())) ||
        (userMatch && userMatch.user && userMatch.user.name.toLowerCase().includes(value.toLowerCase()))
    );
    setAdmins(filtered);
  }

  async function handleAddAdmin(values: FormValues) {
    const {
      data: { getUser },
    } = await fetchUserByUlid(values.add_admin_ulid);

    await createAdminResponseUser({
      variables: {
        input: {
          userUlid: values.add_admin_ulid,
          readOnly: values.add_admin_readOnly,
        },
      },
    })
      .then(async (response: Record<string, any>) => {
        if (response.data.createAdminResponseUser && response.data.createAdminResponseUser.errors) {
          console.info(JSON.stringify(response.data, null, 2));
          toast({ type: 'error', message: 'Something went wrong, please try again.' });
        } else {
          const newValues = {
            userRole: response.data.createAdminResponseUser.record.userRole,
            userUlid: values.add_admin_ulid,
            readOnly: values.add_admin_readOnly,
            user: {
              ulid: values.add_admin_ulid,
              name: getUser.name,
              email: getUser.email,
              organizationIDs: getUser.organizationIDs,
            },
          };
          await setAdmins((oldArray) => [...oldArray, newValues]);
          toast({ type: 'success', message: `Added ${getUser.name} as Admin` });
          setActiveEditingAdmin(initialFormValues);
          hideAddAdminModal();
        }
      })
      .catch((err: Record<string, any>) => {
        if (err.networkError) {
          console.error(err.networkError.result);
        }
        toast({ type: 'error', message: err.message });
        setActiveEditingAdmin(initialFormValues);
        hideAddAdminModal();
      });
  }

  async function handleEditAdmin(values: FormValues) {
    const activeAdmin = admins.find((x) => {
      return x.userUlid === values.add_admin_ulid;
    });

    const activeAdminName = activeAdmin && activeAdmin.user ? activeAdmin.user.name : 'UNKNOWN';

    await editAdminResponseUser({
      variables: {
        userUlid: values.add_admin_ulid,
        input: {
          readOnly: values.add_admin_readOnly,
        },
      },
    })
      .then(async (response: Record<string, any>) => {
        if (response.data.editAdminResponseUser && response.data.editAdminResponseUser.errors) {
          console.info(JSON.stringify(response.data, null, 2));
          toast({ type: 'error', message: 'Something went wrong, please try again.' });
        } else {
          const newValues = {
            userUlid: values.add_admin_ulid,
            readOnly: values.add_admin_readOnly,
          };

          await setAdmins(
            admins.map((x) => {
              return x.userUlid === values.add_admin_ulid ? { ...x, ...newValues } : x;
            })
          );
          toast({ type: 'success', message: `Updated "${activeAdminName}"` });
          setActiveEditingAdmin(initialFormValues);
          hideAddAdminModal();
        }
      })
      .catch((err: Record<string, any>) => {
        if (err.networkError) {
          console.error(err.networkError.result);
        }
        toast({ type: 'error', message: err.message });
        setActiveEditingAdmin(initialFormValues);
        hideAddAdminModal();
      });
  }

  const [showAddAdminModal, hideAddAdminModal] = useModal(() => {
    const addNew = activeEditingAdmin === initialFormValues;

    return (
      <Formik
        initialValues={activeEditingAdmin}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          return addNew ? handleAddAdmin(values) : handleEditAdmin(values);
        }}
      >
        {(props: FormikProps<FormValues>) => {
          const { submitForm, isSubmitting } = props;
          return (
            <Modal
              hideModal={hideAddAdminModal}
              title={addNew ? 'Add Admin' : 'Edit Admin'}
              icon={addNew ? 'plus' : 'edit'}
              overflowVisible
              compact
              footer
              cancelable
              submittable={addNew ? 'Add' : 'Save'}
              submitDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              onClose={() => {
                setActiveEditingAdmin(initialFormValues);
              }}
              handleSubmit={() => {
                return submitForm();
              }}
            >
              <Form>
                <RenderForm admins={admins} addNew={addNew} {...props} />
              </Form>
            </Modal>
          );
        }}
      </Formik>
    );
  }, [activeEditingAdmin]);

  return (
    <>
      <SubNavbar icon="money-check-edit" title="Admin Management" />
      <ScrollWrapper>
        <SettingsSubsection>
          <Row>
            <Column flex="1" width="auto">
              <FormGroup hasError={false}>
                <InputGroup iconLeft="search">
                  <Input border placeholder="Search Admins" onChange={handleFilter} data-cy="search-admin" />
                </InputGroup>
              </FormGroup>
            </Column>
            <Column flex="0 1 auto" width="auto">
              <Button icon="plus" onClick={showAddAdminModal} data-cy="add-admin">
                {'Add Admin'}
              </Button>
            </Column>
          </Row>
          <Row>
            <Column>
              <Table
                noHeader
                columns={columns}
                data={tableData?.sort(function (a: any, b: any) {
                  const textA = a.admin.toUpperCase();
                  const textB = b.admin.toUpperCase();
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
                })}
              />
            </Column>
          </Row>
        </SettingsSubsection>
      </ScrollWrapper>
    </>
  );
};

const AdminManagementWithData = withDataFetcher(AdminManagement);
const AdminManagementWrapper = (props: {}): JSX.Element => (
  <AdminManagementWithData
    customLoadingMessage={<SubNavbar icon="spinner-third" spin title="Loading..." />}
    query={LIST_ADMIN_RESPONSE_USERS}
  />
);

export default AdminManagementWrapper;
