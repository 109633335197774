import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReviewResponseTicket as ReviewResponseTicket_pb } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_pb';
import * as FullStory from '@fullstory/browser';
import queryString from 'query-string';
import { ReviewResponseTicket } from 'src/async/types';
import { NoDataMessage, DebugPixel } from 'src/components';
import { useStateValue } from 'src/context';
import * as types from 'src/context/actionTypes';
import { COMPLETE_REVIEW_RESPONSE_SUBMISSION } from 'src/graph';
import { useGraphMutation } from 'src/hooks';
import { buildFullStoryTicketEvent } from 'src/utils/fullStoryUtils';
import {
  ReviewCard,
  ResponseNotes,
  ResponseSubmissions,
  WriteResponseCard,
  RedAlertCard,
  PublishButton,
  OverLimit,
} from '../components';
import DeletedOverlay from '../components/DeletedOverlay';
import {
  ResponseConsoleSection,
  ResponseConsoleSectionTicket,
  ResponseConsoleSectionTicketWrapper,
  ResponsesWrapper,
  CompletionButtonsWrapper,
  PublishButtonColumn,
} from '../ResponseConsole.styled';
import ResponseTicketTitleBar from './ResponseTicketTitleBar';

// !FLAG - Disabled subscriptions from apollo graphql
//? import useResponseTicketSubs from '../Subscriptions/useResponseTicketSubs';

// interface ResponseTicketProps extends QueryResult<any, any> {
//   getReviewResponseTicket: ReviewResponseTicket;
// }

type ResponseTicketProps = {
  ticket?: ReviewResponseTicket;
};

const ResponseTicket = (props: ResponseTicketProps): JSX.Element => {
  const { ticket } = props;
  const [published, setPublished] = React.useState(false);
  const [{ userRoles }, dispatch] = useStateValue();
  const history = useHistory();
  const qs = queryString.parse(location.search);
  //? useResponseTicketSubs(getReviewResponseTicket, subscribeToMore);

  const [showOverLimit, setShowOverLimit] = React.useState(false);
  const [showDeletedOverlay, setShowDeletedOverlay] = React.useState(false);

  // * Clears activeReview from globalState when the close button is clicked
  function clearActiveReview() {
    dispatch({
      type: types.SET_ACTIVE_TICKET,
      ticket: undefined,
    });
    qs.reviewUlid && delete qs.reviewUlid;
    const currentQs = qs && Object.keys(qs) ? `?${queryString.stringify(qs)}` : '';
    history.push(`${location.pathname}${currentQs}`);
  }

  // * Set the rendering of WriteResponseCard based on TicketStatusType
  const [showWriteResponseCard, setShowWriteResponseCard] = React.useState(false);
  React.useEffect(() => {
    if (ticket) {
      const { status, publishingDetails, review, rating } = ticket;

      if (review?.title === 'deleted') {
        setShowDeletedOverlay(true);
      }

      // * OverLimit effect
      const { PUBLISHED, COMPLETED, COMPLETED_EXTERNAL } = ReviewResponseTicket_pb.Status.Type;
      const dontShowForStatus = [PUBLISHED, COMPLETED, COMPLETED_EXTERNAL].indexOf(status) === -1;
      setShowOverLimit((publishingDetails?.isOverLimit && rating >= 4 && dontShowForStatus) || false);

      // * WriteResponseCard effect
      if (status !== 0 && status !== 11) {
        setShowWriteResponseCard(false);
      } else {
        setShowWriteResponseCard(true);
      }
    }
  }, [ticket]);

  const { mutationMethod: completeReviewResponseSubmission, loading: completeReviewResponseSubmissionLoading } =
    useGraphMutation({
      mutation: COMPLETE_REVIEW_RESPONSE_SUBMISSION,
      options: {},
      errorType: 'error',
    });

  if (!ticket) {
    return (
      <ResponseConsoleSection flex="1 1 auto">
        <NoDataMessage>{'No Review Selected...'}</NoDataMessage>
      </ResponseConsoleSection>
    );
  }

  async function publish() {
    if (!ticket) return;
    const { reviewResponseSubmissions } = ticket;
    const submissions = reviewResponseSubmissions || [];
    const lastSubmission = submissions[submissions.length - 1] || null;
    if (lastSubmission) {
      await completeReviewResponseSubmission({
        variables: {
          reviewResponseSubmissionUlid: lastSubmission.ulid,
        },
      });
      setPublished(true);

      // FS CUSTOM EVENT
      FullStory.isInitialized() &&
        FullStory.event('ERC - API Event - Response Published', buildFullStoryTicketEvent(ticket));
    }
  }

  const {
    reviewUlid,
    reviewResponseNotes,
    reviewResponseSubmissions,
    status,
    responsePlace,
    type,
    placeName,
    assigneeUserName,
    publishingDetails,
  } = ticket;

  return (
    <ResponseConsoleSectionTicket>
      <ResponseConsoleSectionTicketWrapper>
        <DebugPixel log={{ ticket }} />
        <ResponsesWrapper showOverLimit={showOverLimit} showDeletedOverlay={showDeletedOverlay}>
          {!userRoles.isApprover && (
            <ResponseTicketTitleBar
              ticketReviewType={type}
              clearActiveReview={clearActiveReview}
              status={status}
              reviewUlid={reviewUlid}
              assigneeUserName={assigneeUserName}
            />
          )}
          {Boolean(responsePlace.settings.red_alert?.body?.length && responsePlace.settings.red_alert.show) &&
            responsePlace.settings.red_alert && <RedAlertCard settings={responsePlace.settings} />}
          <ReviewCard ticket={ticket} />
          <ResponseNotes notes={reviewResponseNotes || []} />
          {reviewResponseSubmissions && <ResponseSubmissions submissions={reviewResponseSubmissions} ticket={ticket} />}
          {Boolean(
            (showWriteResponseCard || status === ReviewResponseTicket_pb.Status.Type.REVIEW_UPDATED) &&
              !userRoles.isApprover
          ) && <WriteResponseCard ticket={ticket} />}
          {!userRoles.isApprover &&
            status === ReviewResponseTicket_pb.Status.Type.APPROVED_PENDING_PUBLISH &&
            publishingDetails?.canPublish && (
              <CompletionButtonsWrapper>
                <PublishButtonColumn>
                  <PublishButton
                    disabled={published}
                    isSubmitting={completeReviewResponseSubmissionLoading}
                    handlePublish={() => publish()}
                    ticket={ticket}
                  />
                </PublishButtonColumn>
              </CompletionButtonsWrapper>
            )}
        </ResponsesWrapper>
      </ResponseConsoleSectionTicketWrapper>
      {showOverLimit && (
        <OverLimit placeName={placeName || 'UNKNOWN'} setShowOverLimit={setShowOverLimit} reviewUlid={reviewUlid} />
      )}
      {showDeletedOverlay && <DeletedOverlay />}
    </ResponseConsoleSectionTicket>
  );
};

export default ResponseTicket;
