import React from 'react';
import { useModal } from 'react-modal-hook';
import { ReviewResponseTicket as ReviewResponseTicket_pb } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/review_response_ticket_pb';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { ReviewResponseTicket } from 'src/async/types';
import { InputElement, Column, Row, Button } from 'src/components';
import { useStateValue } from 'src/context';
import { InputElementFormConfig } from 'src/types';
import NoResponseNeededButton from '../NoResponseNeededButton';
import ResponsePreviewModal from '../ResponsePreviewModal';
import { WriteResponseCardActionsWrapper } from './WriteResponseCard.styled';

export type FormValues = typeof initialFormValues;

export const initialFormValues = {
  comment: '',
  attach_signature: false,
};

export const validationSchema = Yup.object({
  comment: Yup.string().required('Required'),
});

export const formConfig: InputElementFormConfig = {
  comment: {
    placeholder: 'Write response...',
    type: 'textarea',
    name: 'comment',
    border: true,
  },
  attach_signature: {
    noValidation: true,
    type: 'toggle',
    name: 'attach_signature',
    icon: 'info-circle',
  },
};

export const RenderForm = (
  props: FormikProps<FormValues> & {
    parsedCommentBody: string;
    handleTextAreaOnChange: (e: React.ChangeEvent<any>) => void;
    disableSignatureToggle: boolean;
    disableSignatureToggleTooltip: string;
    signatureToggleLabel: string;
    submitButtonText: string;
    ticket: ReviewResponseTicket;
    suggestedComment?: string;
  }
): React.ReactElement => {
  const {
    // passed props
    parsedCommentBody,
    handleTextAreaOnChange,
    disableSignatureToggle,
    handleChange,
    disableSignatureToggleTooltip,
    signatureToggleLabel,
    submitButtonText,
    ticket,
    // formik props
    values,
    submitForm,
    setFieldValue,
    isSubmitting,
    resetForm,
  } = props;
  const [{ userRoles }] = useStateValue();
  React.useEffect(() => {
    // Uncheck attach_signature if toggle is disabled
    disableSignatureToggle && setFieldValue('attach_signature', false);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [disableSignatureToggle]);

  // Reset form as the first action, and if ticket changes
  React.useEffect(() => {
    resetForm({
      values: {
        ...initialFormValues,
        attach_signature: disableSignatureToggle ? false : values.attach_signature,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  const [showModal, hideModal] = useModal(() => {
    return (
      <ResponsePreviewModal
        commentBody={parsedCommentBody}
        handleSubmit={submitForm}
        hideModal={hideModal}
        ticket={ticket}
        submitDisabled={isSubmitting}
        isSubmitting={isSubmitting}
      />
    );
  }, [values, parsedCommentBody, submitButtonText, ticket, isSubmitting]);
  function disableCloseTicketButton(ticketStatus: number) {
    const { PUBLISHED, COMPLETED, COMPLETED_EXTERNAL } = ReviewResponseTicket_pb.Status.Type;
    const disabledFor = [PUBLISHED, COMPLETED, COMPLETED_EXTERNAL];
    return disabledFor.indexOf(ticketStatus) > -1;
  }

  React.useEffect(() => {
    if (props.suggestedComment) {
      setFieldValue('comment', props.suggestedComment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.suggestedComment]);

  React.useEffect(() => {
    if (!isSubmitting) {
      hideModal();
    }
  }, [isSubmitting, hideModal]);

  return (
    <Row>
      <Column width={[1]}>
        <InputElement
          {...formConfig.comment}
          handleChange={(e) => {
            handleChange(e);
            handleTextAreaOnChange(e);
          }}
        />
      </Column>
      <Column>
        <Row marginY={-2}>
          <Column auto marginY={[2]} display="flex" alignItems="center">
            <InputElement
              {...formConfig.attach_signature}
              label={signatureToggleLabel}
              disabled={disableSignatureToggle}
              tooltip={(disableSignatureToggle && disableSignatureToggleTooltip) || ''}
              tooltipPlacement="bottom"
            />
          </Column>
          <WriteResponseCardActionsWrapper auto marginY={[2]} display="flex" justifyContent="flex-end">
            {/* <Button btnSm disabled={!values.comment} content="Preview" outline secondary mr={4} onClick={showModal} /> */}
            {!userRoles.isApprover && (
              <NoResponseNeededButton
                reviewUlid={ticket.reviewUlid}
                disabled={disableCloseTicketButton(ticket.status)}
              />
            )}
            <Button btnSm disabled={!values.comment} content={submitButtonText} onClick={showModal} />
          </WriteResponseCardActionsWrapper>
        </Row>
      </Column>
    </Row>
  );
};
