import { CREATE_RESPONSE_APPROVER, EDIT_RESPONSE_APPROVER, LIST_RESPONSE_APPROVERS } from 'src/graph';
import { useGraphMutation } from 'src/hooks';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useGraphMutations(location: string) {
  const { mutationMethod: createResponseApprover, loading: createResponseApproverLoading } = useGraphMutation({
    mutation: CREATE_RESPONSE_APPROVER,
    options: {
      refetchQueries: [{ query: LIST_RESPONSE_APPROVERS, variables: { placeUlid: location } }],
    },
    errorType: 'warn',
  });

  const { mutationMethod: editResponseApprover, loading: editResponseApproverLoading } = useGraphMutation({
    mutation: EDIT_RESPONSE_APPROVER,
    options: {
      refetchQueries: [{ query: LIST_RESPONSE_APPROVERS, variables: { placeUlid: location } }],
    },
    errorType: 'warn',
  });
  return {
    createResponseApprover,
    createResponseApproverLoading,
    editResponseApprover,
    editResponseApproverLoading,
  };
}
