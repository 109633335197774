import React from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components/macro';
import vars from 'src/styles/variables';
import ButtonLink from './ButtonLink';

const ListWrapper = styled.div<{ title?: string }>`
  color: ${vars.$gray_9};
  > ul {
    list-style: none;
    margin: 0;
    padding-left: 0;

    > li {
      border-bottom: 1px solid ${vars.$gray_e};
      padding: 1rem 0;

      ${(props) => {
        return (
          props.title &&
          css`
            &:first-of-type {
              padding-top: 0;
            }
          `
        );
      }}

      &:last-of-type {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
`;
const ListTitle = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    margin-right: 0.5rem;
  }
`;

const ListSubTitle = styled.div`
  padding-top: 0.25rem;
  margin-bottom: -0.75rem;
  font-size: 0.625rem;
`;

const List = (props: {
  title?: string;
  subtitle?: string;
  icon?: string;
  spin?: boolean;
  children: React.ReactElement<HTMLLIElement> | React.ReactElement<HTMLLIElement>[];
  action?: {
    onClick: (e: any) => void;
    content?: string;
    icon?: IconName;
    iconRight?: IconName;
    color?: string;
    disabled?: boolean;
    spin?: boolean;
  };
}): React.ReactElement => {
  return (
    <ListWrapper title={props.title}>
      <ul>
        {props.title && (
          <ListTitle>
            <span>
              {props.icon && <FontAwesomeIcon size="sm" icon={props.icon as IconName} spin={props.spin} />}
              {props.title}
              {props.subtitle && <ListSubTitle>{props.subtitle}</ListSubTitle>}
            </span>
            {props.action && (
              <ButtonLink
                disabled={props.action.disabled}
                color={props.action.color}
                icon={props.action.icon}
                iconRight={props.action.iconRight}
                content={props.action.content}
                onClick={props.action.onClick}
                spin={props.action.spin}
              />
            )}
          </ListTitle>
        )}
        {props.children}
      </ul>
    </ListWrapper>
  );
};

export default List;
