import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import Tippy from '@tippyjs/react';
import { useStateValue } from 'src/context';
import * as types from 'src/context/actionTypes';
import routes from 'src/routes';
import { getInitials } from 'src/utils';
import Avatar from '../Avatar';
import DebugPixel from '../DebugPixel';
import Tooltip from '../Tooltip';
import {
  LogoWrapper,
  LogoSm,
  LogoLg,
  SidebarSection,
  SidebarMenu,
  SidebarMenuItem,
  AvatarMenuItem,
  MenuIcon,
  MenuItemTitle,
  SidebarWrapper,
  CollapseSidebarButton,
  SidebarIcon,
  SideBarItem,
  VersionNumber,
} from './Sidebar.styled';

const commit_sha = process.env.REACT_APP_SENTRY_RELEASE;
const commit_sha_short = commit_sha?.slice(0, 7);

const Sidebar = (props: {}): React.ReactElement => {
  /* Inline media-query - no onResize listeners */
  const [{ currentUser, sideBarCollapsed }, dispatch] = useStateValue();
  const currentUserName = currentUser?.user?.name;
  const currentUserRole = currentUser?.userRole;

  const [commitShaCopied, setCommitShaCopied] = React.useState(false);

  /* Top & Bottom section as `[top, bottom]` */
  const navLinkBank = [
    routes.filter((route) => {
      return route.meta.sidebar && route.meta.sidebarSection === 'top';
    }),
    routes.filter((route) => {
      return route.meta.sidebar && route.meta.sidebarSection === 'bottom';
    }),
  ];

  React.useEffect(() => {
    if (commitShaCopied) {
      const timer = setTimeout(() => {
        setCommitShaCopied(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [commitShaCopied]);

  React.useEffect(() => {
    const sideBarSavedValue = localStorage.getItem('@sidebar_collapsed');
    if (sideBarSavedValue && sideBarSavedValue === 'true') {
      dispatch({
        type: types.SET_SIDEBAR_COLLAPSED,
        sideBarCollapsed: true,
      });
    } else {
      dispatch({
        type: types.SET_SIDEBAR_COLLAPSED,
        sideBarCollapsed: false,
      });
    }
  }, [dispatch]);

  function updateSideBar() {
    if (sideBarCollapsed) localStorage.setItem('@sidebar_collapsed', 'false');
    else localStorage.setItem('@sidebar_collapsed', 'true');
    dispatch({
      type: types.SET_SIDEBAR_COLLAPSED,
      sideBarCollapsed: !sideBarCollapsed,
    });
  }

  return (
    <SidebarWrapper collapsed={sideBarCollapsed} data-cy="sidebar">
      <DebugPixel placement="top-left" />
      {navLinkBank.map((section, i) => {
        /* Loop through top & bottom sections */
        return (
          <SidebarSection key={i}>
            {i === 0 && (
              <LogoWrapper>
                <LogoSm />
                <LogoLg />
              </LogoWrapper>
            )}
            <SidebarMenu>
              {section.map((route) => {
                /* Loop through routes & build nav links */
                const {
                  path,
                  onClick,
                  meta: { label, icon, disabled, disabledFor },
                } = route;

                const shouldDisableForUser = currentUserRole === disabledFor;

                return !disabled && !shouldDisableForUser ? (
                  <SideBarItem key={path}>
                    {label === 'Settings' && (
                      <Tooltip content="Show Sidebar" disabled={!sideBarCollapsed}>
                        <CollapseSidebarButton blank onClick={updateSideBar}>
                          <SidebarIcon collapsed={`${sideBarCollapsed}`} icon={['far', 'sidebar']} />
                          {/* <MenuItemTitle>{'Hide Sidebar'}</MenuItemTitle> */}
                        </CollapseSidebarButton>
                      </Tooltip>
                    )}
                    <Tippy key={path} content={label} placement="right" disabled={!sideBarCollapsed}>
                      <SidebarMenuItem
                        data-cy={`sidebar-menu-item-${label}`}
                        to={path}
                        onClick={onClick ? onClick : () => null}
                        data-popper-reference-hidden
                      >
                        <MenuIcon icon={icon as IconName} />
                        <MenuItemTitle>{label}</MenuItemTitle>
                      </SidebarMenuItem>
                    </Tippy>
                  </SideBarItem>
                ) : null;
              })}
              {i === 1 && (
                <>
                  <AvatarMenuItem to="/noop">
                    <Avatar shadowColor={'rgba(255,255,255,0.5)'}>{getInitials(currentUserName)}</Avatar>
                    <MenuItemTitle>{currentUserName}</MenuItemTitle>
                  </AvatarMenuItem>
                  {commit_sha && (
                    <CopyToClipboard text={commit_sha} onCopy={() => setCommitShaCopied(true)}>
                      <VersionNumber>{`Version: ${commitShaCopied ? 'Copied!' : commit_sha_short}`}</VersionNumber>
                    </CopyToClipboard>
                  )}
                </>
              )}
            </SidebarMenu>
          </SidebarSection>
        );
      })}
    </SidebarWrapper>
  );
};

export default Sidebar;
