import React from 'react';
import { useQuery } from '@apollo/client';
import { SessionUser } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/session_user_pb';
import { AuthWrapper } from '@friendemic/sso-consumer/dist';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import axios from 'src/axios';
import { Sidebar, ErrorBlock, PageLoading, PageWrapper, ContentWrapper, NoDataMessage } from 'src/components';
import { CurrentUser, useStateValue } from 'src/context';
import * as types from 'src/context/actionTypes';
import { GET_CURRENT_USER, LogoutAsync } from 'src/graph';
import { buildFullStoryUserVars } from 'src/utils/fullStoryUtils';
import { authConsumer } from './auth-consumer';
import { friendlyUserRole } from './helpers';
import { toast } from './toast';

const { SessionUserRoleType } = SessionUser;

export function ProtectedTemplate(props: { children: React.ReactElement }): React.ReactElement {
  const { children } = props;

  return (
    <PageWrapper>
      <Sidebar />
      <ContentWrapper>{children}</ContentWrapper>
    </PageWrapper>
  );
}

/**
 * ProtectedRoute manages:
 * - Global state
 * - Sentry user & context
 * - FullStory user
 * - Final rendering of the route component
 *
 */
function protectedRoute(/* routeConfig */) {
  return <P extends Record<string, unknown>>(Component: React.FunctionComponent<P>): any => {
    /**
     * RenderProtectedRoute sets state and does final rendering of the route's component after authorization
     *
     */
    const RenderProtectedRoute = (props: any): React.ReactElement => {
      const [{ currentUser }, dispatch] = useStateValue();
      const { tokens, authError, ...rest } = props;
      const [authorized, setAuthorized] = React.useState(false);
      const { loading, error, data } = useQuery<{ currentSessionUser: CurrentUser }>(GET_CURRENT_USER, {
        skip: authError || !(tokens && tokens.accessToken),
      });

      /**
       * Set local state if <AuthWrapper> has returned valid tokens
       *
       */
      React.useEffect(() => {
        if (tokens && tokens.accessToken) {
          axios.defaults.headers.common.Authorization = `Bearer ${tokens.accessToken}`;
          setAuthorized(true);
        }
        if (authError || error) {
          setAuthorized(false);
        }
      }, [tokens, authError, error]);

      React.useEffect(() => {
        if (data && data.currentSessionUser && !currentUser) {
          const {
            currentSessionUser: { userRole, user, responseOrganization },
          } = data;

          /**
           * Set global state values
           *
           */
          responseOrganization &&
            dispatch({
              type: types.SET_ACTIVE_ORGANIZATION,
              organization: responseOrganization,
            });
          dispatch({
            type: types.SET_USER_ROLE,
            userRoles: {
              isAdmin: userRole === SessionUserRoleType.SYSTEMADMIN,
              isOrganizationManager: userRole === SessionUserRoleType.ORGANIZATIONMANAGER,
              isResponseUser: userRole === SessionUserRoleType.RESPONSEUSER,
              isApprover: userRole === SessionUserRoleType.APPROVER,
            },
          });
          dispatch({
            type: types.SET_USER,
            currentUser: data.currentSessionUser,
          });

          /**
           * Sentry.setUser
           * - Check if user object is available and set user data for sentry error reporting
           *
           */
          user &&
            Sentry.setUser({
              email: user.email,
              username: user.name,
              id: user.ulid,
            });

          /**
           * Sentry.setContext
           * - Check if user object is available and set userRole for sentry error reporting
           *
           */
          user && Sentry.setContext('User Role', { Value: userRole, Name: friendlyUserRole(userRole) });

          /**
           * FullStory.identify User
           * - Check if FullStory is initialized and user object is available
           *
           */
          user &&
            FullStory.isInitialized() &&
            FullStory.identify(user.ulid, buildFullStoryUserVars(data.currentSessionUser));
        }
        // No need to rerender when currentUser is set
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [data]);

      /**
       * If
       *  - User is authorized
       * Then
       * - Render the route's component
       * Else
       * - Logout
       *
       */
      if (authorized && !loading && data) {
        return (
          <ProtectedTemplate>
            <Component {...rest} />
          </ProtectedTemplate>
        );
      } else if (authError || error) {
        if (authError && authError.response) {
          const { status } = authError.response;
          // if 4** response, force new login
          if (status === 401) {
            LogoutAsync();
          } else if (status === 403) {
            toast({
              type: 'error',
              message: 'Forbiden',
              options: { duration: Infinity },
            });
          }
          Sentry.captureException(authError);
        }
        // return <p>{'here, yes'}</p>;
        return <ErrorBlock error={authError || error} />;
      }

      return <PageLoading />;
    };

    /**
     * AuthWrapper from sso-consumer manages tokens and auth using sso
     * - when completed, it passes tokens and authError to the RenderProtectedRoute component above
     */
    const AuthenticateRouteWrapper = (): React.ReactElement => {
      return (
        <AuthWrapper consumer={authConsumer}>
          {(tokens, authError) => <RenderProtectedRoute tokens={tokens} authError={authError} />}
        </AuthWrapper>
      );
    };

    return AuthenticateRouteWrapper;
  };
}

/**
 * For use on routes that need to be both hidden from navigation and
 *   prevented from being routed to if param `forbidden === true`.
 *
 * Used alone in `settingsRoutes` because Settings is already a protectedRoute.
 *
 * const [{ userRoles }] = useStateValue()
 *   userRoles is passed as an argument to settingsRoutes(userRoles)
 *   which is initialized in context as a permissionless object with every role as false
 *
 * @example
 * "routes.tsx"
 * component: permissionLimitedRoute(!userRoles.isAdmin)(Dashboard)),
 */
export function permissionLimitedRoute(forbidden: boolean) {
  return <P extends Record<string, unknown>>(Component: React.FunctionComponent<P>): any => {
    const RenderPermissionLimitedRoute = (props: any): React.ReactElement => {
      if (!forbidden) {
        return <Component {...props} />;
      } else {
        return <NoDataMessage>{'You do not have permission to view this page.'}</NoDataMessage>;
      }
    };

    return RenderPermissionLimitedRoute;
  };
}

export default protectedRoute;
