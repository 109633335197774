import React from 'react';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { InputElement } from 'src/components';
import { InputElementFormConfig } from 'src/types';
import { CommentBox } from '../ResponseNotes/ResponseNotes.styled';

export type FormValues = typeof initialFormValues;

export const initialFormValues = {
  approvalComment: '',
};

export const validationSchema = Yup.object({
  approvalComment: Yup.string().required('Required'),
});

export const formConfig: InputElementFormConfig = {
  approvalComment: {
    label: 'Leave feedback',
    placeholder: 'Required',
    name: 'approvalComment',
    border: true,
  },
};

export const RenderForm = (props: FormikProps<FormValues>): React.ReactElement => {
  return (
    <>
      <CommentBox>
        <InputElement {...formConfig.approvalComment} />
      </CommentBox>
    </>
  );
};
