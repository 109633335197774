import React from 'react';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { Row, Column, InputElement, Button } from 'src/components';
import { InputElementFormConfig } from 'src/types';

export type FormValues = typeof initialFormValues;

export const initialFormValues = {
  show: false,
  body: '',
};

export const validationSchema = Yup.object({
  body: Yup.string(),
});

export const formConfig: InputElementFormConfig = {
  body: {
    type: 'wysiwyg',
    border: true,
    name: 'body',
    placeholder: 'Alert to display at the top of Response Console',
  },
};
export const RenderForm = (props: FormikProps<FormValues>): React.ReactElement => {
  const { values, submitForm, isSubmitting, dirty } = props;
  const [state, setValue] = React.useState({ name: formConfig.body.name, value: values.body });

  React.useEffect(() => {
    setValue((s) => ({ ...s, value: values.body }));
  }, [values]);

  const handleSubmit = async () => {
    await submitForm();
  };

  return (
    <Row>
      <Column>
        <InputElement {...formConfig.body} value={state.value} />
      </Column>
      <Column display="flex" justifyContent="flex-end">
        <Button
          btnSm
          onClick={handleSubmit}
          disabled={isSubmitting || !dirty}
          icon={isSubmitting ? 'spinner-third' : undefined}
          spin={isSubmitting}
        >
          {'Update Red Alert'}
        </Button>
      </Column>
    </Row>
  );
};
