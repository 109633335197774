import React from 'react';
import { OptionTypeBase } from 'react-select';
import { ResponseUser } from '@friendemic/erc-graph';
import { FormikProps } from 'formik';
import { Option } from 'react-select/src/filters';
import * as Yup from 'yup';
import { suggestResponseOrganizationUsers } from 'src/async';
import { UserSuggestion } from 'src/async/types';
import { Row, Column, InputElement, Label, StaticInput, SelectCustomItem } from 'src/components';
import { InputElementFormConfig } from 'src/types';
import { selectOptions } from 'src/utils';
// FLAG - disabled until data is available
// import ChannelPermissionsForm from './ChannelPermissionsForm';

export type FormValues = typeof initialFormValues;

export const initialFormValues = {
  add_organization_user_ulid: '',
  add_organization_user_userRole: 2,
  add_organization_user_readOnly: false,
  // FLAG - disabled until data is available
  // carfax: 3,
  // cargurus: 3,
  // carscom: 3,
  // dealerrater: 3,
  // facebook: 3,
  // google: 3,
  // yelp: 3,
};

export const validationSchema = Yup.object({
  add_organization_user_ulid: Yup.string().required('Required'),
  add_organization_user_userRole: Yup.string().required('Required'),
});

const formConfig: InputElementFormConfig = {
  user: {
    type: 'select-async',
    border: true,
    placeholder: 'Select User...',
    name: 'add_organization_user_ulid',
    label: 'User',
  },
  userRole: {
    type: 'select',
    border: true,
    name: 'add_organization_user_userRole',
    label: 'User Role',
    options: selectOptions.ResponseUserRoleType.filter((el) => el.value !== 0), // remove admin as option
  },
  readOnly: {
    type: 'checkbox',
    name: 'add_organization_user_readOnly',
    checkboxlabel: 'Read only',
  },
};

export const RenderForm = (
  props: FormikProps<FormValues> & {
    users: ResponseUser[];
    addNew: boolean;
    organizationSelection: OptionTypeBase;
  }
): React.ReactElement => {
  // current values of selects
  // - used in clearing values when a new user is added to the table
  const [currentUserValue, setCurrentUserValue] = React.useState<any>();
  const [currentUserRole, setCurrentUserRole] = React.useState<any>();
  const [noResultsMessage, setNoResultsMessage] = React.useState('');

  const activeUser = props.users.find((x) => {
    return x.userUlid === props.values.add_organization_user_ulid;
  });

  const ActiveUserName: JSX.Element | string =
    activeUser && activeUser.user
      ? SelectCustomItem({
          primaryLabel: activeUser?.user?.name,
          secondaryLabel: activeUser?.user?.email,
        })
      : 'UNKNOWN';
  return (
    <>
      <Row>
        <Column width={[1, 1 / 2]}>
          {!props.addNew ? (
            <>
              <StaticInput>
                <Label>{'User'}</Label>
                {ActiveUserName}
              </StaticInput>
              <InputElement name="add_organization_user_ulid" disabled hidden />
            </>
          ) : (
            <InputElement
              loadOptions={(query: string) => {
                return fetchUsers(props.organizationSelection.value, query, setNoResultsMessage);
              }}
              noResultsMessage={noResultsMessage}
              filterOption={(option: Option) => {
                const added_ulids = props.users.map(({ userUlid }) => userUlid);
                return !(added_ulids.indexOf(option.value) > -1);
              }}
              key={JSON.stringify(props.users)}
              value={currentUserValue}
              handleSelectOnChange={(option) => {
                setCurrentUserValue(option);
              }}
              {...formConfig.user}
            />
          )}
        </Column>
        <Column width={[1, 1 / 2]}>
          <InputElement
            value={currentUserRole}
            handleSelectOnChange={(option) => {
              setCurrentUserRole(option);
            }}
            {...formConfig.userRole}
          />
        </Column>
        <Column>
          <InputElement {...formConfig.readOnly} />
        </Column>
      </Row>
      {/* // FLAG - disabled until data is available */}
      {/* <ChannelPermissionsForm /> */}
    </>
  );
};

/**
 * Get Users for autocomplete
 */
async function fetchUsers(
  responseOrganizationUlid: string,
  query: string,
  setNoResultsMessage: React.Dispatch<React.SetStateAction<string>>
) {
  return suggestResponseOrganizationUsers({
    responseOrganizationUlid,
    query: encodeURIComponent(query),
  }).then((users) => {
    if (users.length === 0) {
      setNoResultsMessage(`No results found for "${query}"`);
      return [];
    }

    if (users) {
      setNoResultsMessage('');
      return users.map((item: UserSuggestion) => {
        const { name, email, ulid: value } = item;
        const label = SelectCustomItem({ primaryLabel: name, secondaryLabel: email });
        return { label, value };
      });
    }
    return [];
  });
}
