import _ from 'lodash';
import { ReviewResponseTicket } from 'src/async/types';
import {
  unknown,
  flagged_for_manager,
  manager_responded,
  response_submitted,
  edits_requested_denied,
  response_re_submitted,
  response_approved,
  response_published,
  response_completed,
} from './buildReviewResponseTicket.fixture';

// ------------------------------------------------
// For use in building final fixtures
// ------------------------------------------------
type ApprovalKeys = keyof typeof approvalRequiredTypes_fixture;
type ListTicketsKeys = typeof listTicketsKeysArray[number];
type ApprovalObjectType = { [key in ApprovalKeys as string]: ReviewResponseTicket[] };
export type ListTicketsObjectType = { [key in ListTicketsKeys]: ReviewResponseTicket[] };

const listTicketsKeysArray = [
  'noApprovalRequired',
  'anySingleApproverRequired',
  'selectedApproversRequired',
  'noApprovalRequired_canAutoPublish_true',
  'anySingleApproverRequired_canAutoPublish_true',
  'selectedApproversRequired_canAutoPublish_true',
  'noApprovalRequired_canAutoPublish_false',
  'anySingleApproverRequired_canAutoPublish_false',
  'selectedApproversRequired_canAutoPublish_false',
];

/**
 * ! IMPORTANT
 * ! This is the shape of each fixture object
 */
export const listReviewResponseTicketsFixtures_base: ReviewResponseTicket[] = [
  unknown,
  flagged_for_manager,
  manager_responded,
  response_submitted,
  edits_requested_denied,
  response_re_submitted,
  response_approved,
  response_published,
  response_completed,
];

/**
 * Builds a version of listReviewResponseTicketsFixtures_base where approvalRequiredType is modified.
 */
function buildRequireType(require_type: number): ReviewResponseTicket[] {
  return listReviewResponseTicketsFixtures_base.map((ticket) => {
    return _.merge({}, ticket, {
      publishingDetails: { isApprovalRequired: require_type === 1 || require_type === 2 },
      responsePlace: { settings: { approval_settings: { require_type: require_type } } },
    });
  });
}

/**
 * Builds a version of listReviewResponseTicketsFixtures_base where approveAfter24 is modified.
 */
function buildApproveAfter24(approve_after_24_hours: boolean): ReviewResponseTicket[] {
  return listReviewResponseTicketsFixtures_base.map((ticket) => {
    return _.merge({}, ticket, {
      responsePlace: { settings: { approval_settings: { approve_after_24_hours: approve_after_24_hours } } },
    });
  });
}

/**
 * tickets with all of the approvalRequiredTypes and approveAfter24
 */
export const approvalRequiredTypes_fixture = {
  noApprovalRequired: buildRequireType(0),
  anySingleApproverRequired: buildRequireType(1),
  selectedApproversRequired: buildRequireType(2),
  notificationsOnly: buildRequireType(3),
  noApprovalRequired_approveAfter24: buildApproveAfter24(false),
  anySingleApproverRequired_approveAfter24: buildApproveAfter24(true),
};

/**
 * Builds two versions of approvalRequiredTypes_fixture
 * 1. canAutoPublish is true
 * 2. canAutoPublish is false
 */
export function buildCanAutoPublish(canAutoPublish: boolean): ApprovalObjectType {
  const cast_fixture = approvalRequiredTypes_fixture as ApprovalObjectType;
  const newObj: ApprovalObjectType = {};
  for (const key in approvalRequiredTypes_fixture) {
    newObj[`${key}_canAutoPublish_${canAutoPublish}`] = cast_fixture[key].map((x: any, i: number) => {
      return {
        ...cast_fixture[key][i],
        canAutoPublish: canAutoPublish,
        publishingDetails: { ...x.publishingDetails, canAutoPublish: canAutoPublish },
      };
    });
  }
  return newObj;
}
