import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import nl2br from 'react-nl2br';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChannelTypeFriendly } from '@friendemic/friendemic-api-node/lib/friendemic/api/response/channel_type_friendly';
import styled from 'styled-components/macro';
import { ReviewResponseTicket } from 'src/async/types';
import { Button, Modal, ButtonLink, Tooltip } from 'src/components';
import vars from 'src/styles/variables';
import { buildExternalLinkUrl } from 'src/utils';
import { buildSubmitButtonText } from '../utils';

const ModalAlertAutoPublish = styled.div`
  display: flex;
  div:first-of-type {
    display: flex;
    align-items: center;
    padding-right: 1.55rem;
    font-size: 3.5rem;
    color: ${vars.$red};
  }
  h3 {
    margin: 0;
    text-transform: uppercase;
  }
  span {
    margin-left: 0.25rem;
  }
  ${Button} {
    font-size: 16px; /* px is more predictable for small icons */
    vertical-align: baseline;
  }
`;

export const ResponsePreviewModal = (props: {
  commentBody: string;
  handleSubmit: (() => Promise<void>) & (() => Promise<any>);
  hideModal: () => void;
  ticket: ReviewResponseTicket;
  submitDisabled: boolean;
  isSubmitting: boolean;
}): JSX.Element => {
  const [copied, setCopied] = React.useState(false);
  const { hideModal, handleSubmit, commentBody, ticket, submitDisabled, isSubmitting } = props;

  const channelName = ChannelTypeFriendly.toFriendly(ticket.channel);
  const reviewUrl = ticket.review?.url || undefined;

  return (
    <Modal
      title="Preview Response"
      icon="eye"
      cancelable
      compact
      footer
      hideModal={hideModal}
      submittable={buildSubmitButtonText(ticket.publishingDetails, true)}
      handleSubmit={handleSubmit}
      submitDisabled={submitDisabled}
      isSubmitting={isSubmitting}
      alert={
        Boolean(!ticket.publishingDetails?.canAutoPublish && ticket.publishingDetails?.canPublish) && (
          <ModalAlertAutoPublish>
            <div>
              <FontAwesomeIcon icon="exclamation-triangle" />
            </div>
            <div>
              <h3>{'Unable to automatically publish.'}</h3>
              {`Please visit the review on ${channelName} and submit your response directly.`}
              <Tooltip
                onHidden={() =>
                  setTimeout(() => {
                    setCopied(false);
                  }, 2000)
                }
                hideOnClick={false}
                content={copied ? 'Copied!' : 'Copy response to clipboard'}
              >
                <CopyToClipboard text={commentBody} onCopy={() => setCopied(true)}>
                  <ButtonLink
                    ml={1}
                    color={copied ? vars.$greenlight : undefined}
                    icon={copied ? 'check-circle' : ['far', 'copy']}
                  />
                </CopyToClipboard>
              </Tooltip>
              {reviewUrl && (
                <Tooltip content={`Navigate to review on ${channelName}`}>
                  <a target="_BLANK" href={buildExternalLinkUrl(channelName, reviewUrl)} rel="noreferrer">
                    <ButtonLink icon={['far', 'external-link-square-alt']} />
                  </a>
                </Tooltip>
              )}
            </div>
          </ModalAlertAutoPublish>
        )
      }
      onClose={() => {
        setCopied(false);
      }}
    >
      <div>{nl2br(commentBody)}</div>
    </Modal>
  );
};

export default ResponsePreviewModal;
