import { Flex } from 'reflexbox/styled-components';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';

interface RowProps {
  ma0?: boolean;
}

// eslint-disable-next-line space-in-parens
export const Row = styled(Flex)`
  flex-wrap: wrap;
  margin-left: -${vars.$spacing_small};
  margin-right: -${vars.$spacing_small};

  ${(props: RowProps) => {
    return props.ma0 && 'margin: 0;';
  }}
`;
Row.displayName = 'Row';
export default Row;
