import styled from 'styled-components/macro';
import vars from 'src/styles/variables';

// eslint-disable-next-line space-in-parens
export const ErrorSpan = styled.span<{ hasError?: boolean; hasHelperText?: boolean }>`
  display: block;
  font-size: 0.625rem;
  width: 100%;
  min-height: 1.36rem;

  visibility: ${(props) => {
    return props.hasError || props.hasHelperText ? 'visible' : 'hidden';
  }};
  color: ${(props) => {
    return props.hasHelperText ? vars.$gray_b : vars.$danger;
  }};
  text-align: ${(props) => {
    return props.hasHelperText ? 'right' : 'left';
  }};
`;
ErrorSpan.displayName = 'ErrorSpan';

export default ErrorSpan;
