import styled from 'styled-components/macro';
import vars from 'src/styles/variables';
import ErrorSpan from './ErrorSpan';

const Checkbox = styled.label<{ hasError?: boolean }>`
  position: relative;
  display: block;
  margin: 0;

  + ${ErrorSpan} {
    margin-top: -0.26rem;
    margin-bottom: 0.25rem;
  }

  > input {
    position: absolute;
    opacity: 0;
    left: -9999px;
  }
  > input ~ svg {
    cursor: pointer;
    position: absolute;
    top: 6px;
    left: 0px;
    height: 0.7rem;
    width: 1rem !important;
    color: ${({ hasError }) => {
      return hasError ? vars.$danger : 'white';
    }};
    transition: transform 200ms, opacity 200ms;
    z-index: 1;
  }
  > input ~ label {
    position: relative;
    font-size: 0.85rem;
    padding-left: 1.5rem;
    cursor: pointer;
    color: ${({ hasError }) => {
      return hasError ? vars.$danger : vars.$gray_7;
    }};
    a {
      font-weight: bold;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 1px;
      width: 1rem;
      height: 1rem;
      background: ${({ hasError }) => {
        return hasError ? vars.$redlight : vars.$graylight;
      }};
      border: 1px solid
        ${({ hasError }) => {
          return hasError ? vars.$redlight : vars.$graylight;
        }};
      border-radius: 0.2rem;
    }
  }
  > input:checked ~ label {
    &:before {
      border-color: ${({ hasError }) => {
        return hasError ? vars.$redlight : vars.$primary;
      }};
      background-color: ${({ hasError }) => {
        return hasError ? vars.$redlight : vars.$primary;
      }};
    }
  }
  > input:not(:checked) ~ svg {
    opacity: 0;
    transform: scale(0);
  }
  > input:checked ~ svg {
    opacity: 1;
    transform: scale(1);
    cursor: pointer;
  }
  > input:disabled:not(:checked) ~ label {
  }
  > input:disabled:not(:checked) ~ label,
  > input:disabled:checked ~ svg,
  > input:disabled:checked ~ label {
    opacity: 0.4;
  }
`;

export default Checkbox;
