import React from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components/macro';
import vars from 'src/styles/variables';

interface InputGroupProps {
  iconLeft?: string;
  iconRight?: string;
  transparent?: boolean;
  hasError?: boolean;
  iconProps?: { id: string; onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void; tabIndex: -1 };
  children: React.ReactElement;
}

const IconGroupWrapper = styled.div<InputGroupProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  input ~ svg {
    color: ${vars.$gray_9};
    position: absolute;
    font-size: 90%;
  }

  input {
    ${(props) => {
      if (!props.iconLeft && props.transparent) {
        return css`
          padding-left: 0;
        `;
      } else if (props.iconLeft && !props.transparent) {
        return css`
          padding-left: 2.25rem;
        `;
      } else if (props.iconLeft && props.transparent) {
        return css`
          padding-left: 1.5rem;
        `;
      }
    }}

    ${(props) => {
      if (!props.iconRight && props.transparent) {
        return css`
          padding-right: 0;
        `;
      } else if (props.iconRight && !props.transparent) {
        return css`
          padding-right: 2.25rem;
        `;
      } else if (props.iconRight && props.transparent) {
        return css`
          padding-right: 1.5rem;
        `;
      }
    }}
  }
`;

// workaround for console errors, filter unrecognized props
const Icon = (props: any) => {
  // eslint-disable-next-line
  const { iconLeft, iconRight, transparent, ...rest } = props;
  return <FontAwesomeIcon {...rest} />;
};

const StyledIcon = styled(Icon)`
  ${(props: any) => {
    if (props.iconLeft && !props.transparent) {
      return css`
        left: 0.75rem;
      `;
    } else if (props.iconLeft && props.transparent) {
      return css`
        left: 0;
      `;
    } else if (props.iconRight && !props.transparent) {
      return css`
        right: 0.75rem;
      `;
    } else if (props.iconRight && props.transparent) {
      return css`
        right: 0;
      `;
    }
  }}

  ${(props: any) => {
    return (
      props.icon === 'chevron-down' &&
      css`
        cursor: pointer;
        padding: 0.75rem 0.85rem;
        width: 2.75rem !important;
        height: 100%;
        color: ${vars.$gray_d} !important;
        right: 0;

        &:focus {
          outline: 0;
        }
      `
    );
  }}
`;

const Divider = styled.div`
  width: 2.75rem;
  /* height: 100%; */
  position: absolute;
  border-left: 1px solid ${vars.$gray_d};
  right: 0;
  top: 0.65rem;
  bottom: 0.65rem;
`;

const InputGroup = (props: InputGroupProps): React.ReactElement => {
  return (
    <IconGroupWrapper {...props} data-testid="input-group">
      <>
        {props.children}
        {props.iconLeft && (
          <StyledIcon
            fixedWidth
            transparent={props.transparent}
            iconLeft
            icon={props.iconLeft as IconName}
            data-testid="input-group-icon-left"
            {...props.iconProps}
          />
        )}
        {props.iconRight && (
          <>
            {props.iconRight === 'chevron-down' && <Divider />}
            <StyledIcon
              fixedWidth
              transparent={props.transparent}
              iconRight
              icon={props.iconRight as IconName}
              data-testid="input-group-icon-right"
              {...props.iconProps}
            />
          </>
        )}
      </>
    </IconGroupWrapper>
  );
};

export default styled(InputGroup)``;
