import { GET_REVIEW_RESPONSE_TICKET, CREATE_REVIEW_RESPONSE_TAG, DELETE_REVIEW_RESPONSE_TAG } from 'src/graph';
import { useGraphMutation } from 'src/hooks';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useGraphMutations(reviewUlid?: string) {
  // ! createResponseTag mutation
  const { mutationMethod: createResponseTag } = useGraphMutation({
    mutation: CREATE_REVIEW_RESPONSE_TAG,
    options: {
      refetchQueries: [
        {
          query: GET_REVIEW_RESPONSE_TICKET,
          variables: {
            reviewUlid: reviewUlid,
          },
        },
      ],
    },
    errorType: 'error',
  });
  // ! deleteTag mutation
  const { mutationMethod: deleteResponseTag } = useGraphMutation({
    mutation: DELETE_REVIEW_RESPONSE_TAG,
    options: {
      refetchQueries: [
        {
          query: GET_REVIEW_RESPONSE_TICKET,
          variables: {
            reviewUlid: reviewUlid,
          },
        },
      ],
    },
    errorType: 'error',
  });
  return {
    createResponseTag,
    deleteResponseTag,
  };
}
