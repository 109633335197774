import React, { ReactElement } from 'react';
import { Toaster } from 'react-hot-toast';
import { ModalProvider } from 'react-modal-hook';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from 'styled-components/macro';
import { Reboot } from 'styled-reboot';
import { ERCStateProvider } from './context';
import { client } from './graph';
import MainRouter from './MainRouter';
import GlobalStyle from './styles/GlobalStyles.styled';
import { theme, rebootTheme } from './styles/variables';
import { fontAwesomeConfig } from './utils/fontawesomeconfig';

import './styles/font-styles.css';

interface AppProps {
  isStorybook?: boolean;
  children?: ReactElement<unknown>;
}

const queryClient = new QueryClient();

const App = (props: AppProps): ReactElement => {
  const { isStorybook, children } = props;

  // Initialize fontAwesome icons
  fontAwesomeConfig.init();

  return (
    <>
      <Reboot theme={rebootTheme} />
      <GlobalStyle />
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <ERCStateProvider isStorybook={isStorybook}>
                <ModalProvider>{isStorybook ? children : <MainRouter />}</ModalProvider>
              </ERCStateProvider>
            </QueryClientProvider>
          </ApolloProvider>
        </ThemeProvider>
        <Toaster position="bottom-center" />
      </BrowserRouter>
    </>
  );
};

export default App;
