import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { ImageProps } from 'src/types';

const Yelp = ({ width = '100%', active, deleted }: ImageProps): ReactElement => (
  <svg
    x="0"
    y="0"
    version="1"
    width={width}
    height={width}
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    enableBackground="new 0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={deleted && active ? '#eb2424' : active ? '#5dc094' : '#ffffff'}
      d="M13.39 30.04l12.45 6.08c2.4 1.17 2.02 4.69-.56 5.34L11.84 44.8a2.853 2.853 0 01-3.53-2.45 24.7 24.7 0 011.12-10.66c.5-1.49 2.12-2.29 3.61-1.79.13.04.24.09.35.14zm5.5 29.9c2.98 2.04 6.37 3.41 9.93 4.01 1.55.27 3.02-.77 3.29-2.32.02-.13.04-.26.04-.39l.49-13.85c.09-2.66-3.19-3.99-4.98-2.01l-9.28 10.3a2.858 2.858 0 00.21 4.03c.1.09.2.16.3.23zM37.06 46.2l7.35 11.75a2.865 2.865 0 003.95.9c.1-.07.2-.14.3-.22 2.79-2.3 5.03-5.19 6.59-8.45.67-1.44.05-3.15-1.39-3.82-.1-.05-.2-.09-.31-.12l-13.18-4.28c-2.53-.81-4.72 1.98-3.31 4.24zM55.6 29.68a24.671 24.671 0 00-6.3-8.66 2.852 2.852 0 00-4.03.27c-.08.09-.15.19-.22.28L37.3 33.05c-1.49 2.21.59 5.08 3.15 4.34l13.33-3.81a2.889 2.889 0 001.95-3.57c-.04-.11-.08-.23-.13-.33zM15.79 3.77a2.86 2.86 0 00-1.34 3.81c.03.06.06.13.1.19l13.01 22.55c1.46 2.52 5.33 1.49 5.33-1.42V2.86A2.836 2.836 0 0030.08 0c-.08 0-.16 0-.24.01-4.88.39-9.63 1.66-14.05 3.76z"
    />
  </svg>
);

Yelp.propTypes = {
  width: PropTypes.string,
  active: PropTypes.bool,
};

export default Yelp;
