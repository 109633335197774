import React from 'react';
import { ApolloError } from '@apollo/client';
import { GenerateUserReportOutput } from '@friendemic/erc-graph';
import { format } from 'date-fns';
import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import { List, Row, Column, Button } from 'src/components';
import { GENERATE_USER_REPORT } from 'src/graph';
import { useGraphMutation } from 'src/hooks';
import { toast } from 'src/utils';
import { SettingsSubsection } from '../Settings/settings.styled';
import { RenderForm, FormValues, initialFormValues, validationSchema } from './UserReportsForm';

const UserReports = (props: {}): React.ReactElement => {
  const [downloadCSVUrl, setDownloadCSVUrl] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);
  const [prevSubmittedValues, setPrevSubmittedValues] = React.useState<FormValues>();

  const { mutationMethod: generateUserReport } = useGraphMutation({
    mutation: GENERATE_USER_REPORT,
    errorToast: false,
  });

  /**
   * This component is able to intercept formik values via context from outside of <Formik>((props) => {})
   *   - Resets downloadCSVUrl
   *   - Disables download button if form values have changed since last submission
   */
  const FormikListener = () => {
    const { values } = useFormikContext<FormValues>();
    React.useEffect(() => {
      if (prevSubmittedValues !== values) {
        setDownloadCSVUrl('');
      }
    }, [values]);
    return null;
  };

  async function handleGenerateUserReport(values: FormValues) {
    setLoading(true);
    setPrevSubmittedValues(values); // for testing if form has changed since last submit

    await generateUserReport({
      variables: {
        input: {
          responseOrganizationUlid: values.responseOrganizationUlid,
          startDate: format(values.startDate, 'yyyy-MM-dd'),
          endDate: format(values.endDate, 'yyyy-MM-dd'),
        },
      },
    })
      .then((response: { data: { generateUserReport: GenerateUserReportOutput } }) => {
        setLoading(false);
        if (response && response.data.generateUserReport) {
          const { record, errors, statusCode } = response.data.generateUserReport;
          const toastMessage = `Something went wrong, please try again. (${statusCode || 'UNKNOWN'})`;
          if (errors) {
            console.info(JSON.stringify(response.data, null, 2));
            toast({ type: 'error', message: toastMessage });
          } else {
            if (!record?.url || !record?.url.length) {
              toast({ type: 'error', message: toastMessage });
            }
            return setDownloadCSVUrl(record?.url || '');
          }
        }
      })
      .catch((err: ApolloError) => {
        setLoading(false);
        console.error(err);
        return toast({ type: 'error', message: 'No user data found for selection.' });
      });
  }

  return (
    <SettingsSubsection>
      <Row>
        <Column>
          <List title="Download User Report" icon="user-chart">
            <li>
              <Formik
                initialValues={initialFormValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleGenerateUserReport(values)}
              >
                {(props: FormikProps<FormValues>) => {
                  return (
                    <Form>
                      <FormikListener />
                      <RenderForm {...props} />
                      <Row>
                        <Column width={[1]} mt={4} textAlign="right">
                          <Button
                            type="submit"
                            mr={3}
                            disabled={loading}
                            icon={loading ? 'spinner-third' : 'user-chart'}
                            spin={loading}
                          >
                            {'Generate'}
                          </Button>
                          <a href={downloadCSVUrl} download>
                            <Button
                              disabled={!downloadCSVUrl.length}
                              spin={loading}
                              icon={loading ? 'spinner-third' : 'file-csv'}
                            >
                              {'Download Report'}
                            </Button>
                          </a>
                        </Column>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </li>
          </List>
        </Column>
      </Row>
    </SettingsSubsection>
  );
};

export default UserReports;
