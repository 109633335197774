import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import Tippy from '@tippyjs/react';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';
import { DataRow } from 'src/views/Settings/components/AdminManagement/table';
import Button from '../Button';
import Pill from '../Pill';

const StyledTippy = styled(Tippy)``;

const ActionsWrapper = styled.div<{ isDeleting?: boolean }>`
  display: flex;
  ${({ isDeleting }) => !isDeleting && 'padding: 0.2rem 0;'}
  > span {
    &:first-of-type {
      ${Button} {
        padding-left: 0;
      }
    }
    &:last-of-type {
      ${Button} {
        padding-right: 0;
      }
    }
    ${Button} {
      border: 0;
      transition: 150ms;
      color: ${vars.$gray_9};
      > svg {
        font-size: 100%;
      }
      &:hover {
        color: ${vars.$gray_7};
      }
      &:active {
        color: ${vars.$gray_b};
      }
    }
  }
`;

export interface Action {
  action: string;
  icon: IconName;
  onClick: (e?: React.MouseEvent<Element, MouseEvent>) => any;
  tooltip: string;
}

const ActionsCell = (row: DataRow, rowIndex: number, column: TableColumn<DataRow>, id: React.Key): React.ReactNode => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [currentAction, setCurrentAction] = React.useState<Action>();

  function handleClick(action: any) {
    if (action.action === 'delete') {
      handleClickDelete(action);
    } else {
      action.onClick();
    }
  }

  function handleClickDelete(action: any) {
    setIsDeleting(true);
    setCurrentAction(action);
  }

  function handleClickConfirmDelete(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    currentAction && currentAction.onClick(e);
    setIsDeleting(false);
  }

  function handleClickDismiss() {
    setIsDeleting(false);
  }

  return (
    <ActionsWrapper isDeleting={isDeleting}>
      {isDeleting ? (
        <Pill
          small
          dismissible
          icon="trash-alt"
          noSpacing
          danger
          onClick={handleClickConfirmDelete}
          onDismissClick={handleClickDismiss}
          data-cy="confirm-delete"
        />
      ) : (
        row &&
        row.actions &&
        row?.actions?.map((action, i) => {
          const key = `${rowIndex}|${id}|${i}`;

          return (
            <StyledTippy hideOnClick={false} key={key} content={action.tooltip} placement="top">
              <span>
                <Button
                  blank
                  icon={action.icon}
                  onClick={() => {
                    return handleClick(action);
                  }}
                  data-cy="delete"
                />
              </span>
            </StyledTippy>
          );
        })
      )}
    </ActionsWrapper>
  );
};

export default ActionsCell;
