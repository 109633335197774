import React from 'react';
import { useModal } from 'react-modal-hook';
import { ResponseNotificationRecipient } from '@friendemic/erc-graph';
import { Formik, Form, FormikProps } from 'formik';
import { Row, Column, List, Modal, Table } from 'src/components';
import { toast, updateGraphMutation, fetchUserByUlid } from 'src/utils';
import { SettingsSubsection } from 'src/views/Settings/settings.styled';
import { LocationSettingsRouteParams } from 'src/views/Settings/SettingsTypes';
import { RenderForm, initialFormValues, FormValues, validationSchema } from './NotificationManagementForm';
import { buildData, columns } from './table';
import { useGraphMutations } from './useGraphMutations';

const NotificationManagement = (props: {
  params: LocationSettingsRouteParams;
  listResponseNotificationRecipients?: ResponseNotificationRecipient[];
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement => {
  const { params, listResponseNotificationRecipients, setSaving } = props;

  // Data object to use in table
  const [tableData, setTableData] = React.useState<any>();

  // Recipients array to send in final request
  const [recipients, setRecipients] = React.useState<ResponseNotificationRecipient[]>(
    listResponseNotificationRecipients || []
  );

  // The recipient being actively edited, set when user clicks "Edit" button
  const [activeEditingNotificationRecipient, setActiveEditingNotificationRecipient] =
    React.useState<FormValues>(initialFormValues);

  const {
    createResponseNotificationRecipient,
    createResponseNotificationRecipientLoading,
    editResponseNotificationRecipient,
    editResponseNotificationRecipientLoading,
  } = useGraphMutations(props.params.location);

  React.useEffect(() => {
    setTableData(
      buildData(recipients, setRecipients, setActiveEditingNotificationRecipient, params, showAddRecipientModal)
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [recipients, params]);

  React.useEffect(() => {
    setSaving(createResponseNotificationRecipientLoading || editResponseNotificationRecipientLoading);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [createResponseNotificationRecipientLoading, editResponseNotificationRecipientLoading]);

  async function handleAddRecipient(values: FormValues) {
    const {
      data: { getUser },
    } = await fetchUserByUlid(values.add_recipient_ulid);
    const response = await updateGraphMutation(
      {
        variables: {
          placeUlid: params.location,
          input: {
            userUlid: values.add_recipient_ulid,
            reviewType: values.add_recipient_review_type,
            // required: values.add_recipient_required,
          },
        },
        mutationName: 'createResponseNotificationRecipient',
      },
      createResponseNotificationRecipient
    );
    if (response) {
      if (
        response.data.createResponseNotificationRecipient &&
        response.data.createResponseNotificationRecipient.errors
      ) {
        console.info(JSON.stringify(response.data, null, 2));
        toast({ type: 'error', message: 'Something went wrong, please try again. ' });
      } else {
        const newValues = {
          ulid: response.data.createResponseNotificationRecipient.record?.ulid
            ? response.data.createResponseNotificationRecipient.record?.ulid
            : getUser.ulid,
          placeUlid: params.location,
          userUlid: getUser.ulid,
          user: {
            ulid: getUser.ulid,
            name: getUser.name,
            email: getUser.email,
            organizationIDs: getUser.organizationIDs,
          },
          reviewType: values.add_recipient_review_type,
          // required: values.add_recipient_required,
        };
        await setRecipients((oldArray) => [...oldArray, newValues]);
        setActiveEditingNotificationRecipient(initialFormValues);
        hideAddRecipientModal();
      }
    } else {
      setActiveEditingNotificationRecipient(initialFormValues);
      hideAddRecipientModal();
    }
  }

  async function handleEditRecipient(values: FormValues) {
    // const activeRecipient = recipients.find((x) => {
    //   return x.ulid === values.add_recipient_ulid;
    // });

    // const activeRecipientName = activeRecipient && activeRecipient.user ? activeRecipient.user.name : 'UNKNOWN';
    const response = await updateGraphMutation(
      {
        variables: {
          placeUlid: params.location,
          ulid: values.add_recipient_ulid,
          input: {
            reviewType: values.add_recipient_review_type,
            // required: values.add_recipient_required,
          },
        },
        mutationName: 'editResponseNotificationRecipient',
      },
      editResponseNotificationRecipient
    );
    if (response) {
      if (response.data.editResponseNotificationRecipient && response.data.editResponseNotificationRecipient.errors) {
        console.info(JSON.stringify(response.data, null, 2));
        toast({ type: 'error', message: 'Something went wrong, please try again.' });
      } else {
        const newValues = {
          reviewType: values.add_recipient_review_type,
          // required: values.add_recipient_required,
        };

        await setRecipients(
          recipients.map((x) => {
            return x.ulid === values.add_recipient_ulid ? { ...x, ...newValues } : x;
          })
        );
        setActiveEditingNotificationRecipient(initialFormValues);
        hideAddRecipientModal();
      }
    } else {
      setActiveEditingNotificationRecipient(initialFormValues);
      hideAddRecipientModal();
    }
  }

  const [showAddRecipientModal, hideAddRecipientModal] = useModal(() => {
    const addNew = activeEditingNotificationRecipient === initialFormValues;

    return (
      <Formik
        initialValues={activeEditingNotificationRecipient}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          return addNew ? handleAddRecipient(values) : handleEditRecipient(values);
        }}
      >
        {(props: FormikProps<FormValues>) => {
          const { submitForm, isSubmitting } = props;
          return (
            <Modal
              hideModal={hideAddRecipientModal}
              title={addNew ? 'Add Notification Recipient' : 'Edit Notification Recipient'}
              icon={addNew ? 'plus' : 'edit'}
              overflowVisible
              compact
              footer
              cancelable
              submittable={addNew ? 'Add' : 'Save'}
              submitDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              onClose={() => {
                setActiveEditingNotificationRecipient(initialFormValues);
              }}
              handleSubmit={() => {
                return submitForm();
              }}
            >
              <Form>
                <RenderForm params={params} recipients={recipients} addNew={addNew} {...props} />
              </Form>
            </Modal>
          );
        }}
      </Formik>
    );
  }, [activeEditingNotificationRecipient]);

  return (
    <SettingsSubsection>
      <Row>
        <Column>
          <List
            title="Notification Management"
            icon="money-check-edit"
            action={{
              onClick: showAddRecipientModal,
              icon: 'plus',
              content: 'Add',
            }}
          >
            <li>
              <Table noHeader columns={columns} data={tableData} />
            </li>
          </List>
        </Column>
      </Row>
    </SettingsSubsection>
  );
};

export default NotificationManagement;
