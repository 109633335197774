import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import { ResponseOrganization, ResponsePlace } from 'src/async/types';
import { DropDown } from 'src/components';
import { Actions, useStateValue } from 'src/context';
import * as types from 'src/context/actionTypes';
import { sanitizePlaceName, useUrl } from 'src/utils';
import { OrganizationItemComp } from './Organization.styled';

type OrganizationSelectorData = {
  activeOrganization: ResponseOrganization | null;
  organizations: ResponseOrganization[];
  places: ResponsePlace[];
};
type OrganizationSelectorStateType = {
  search: string;
  viewMode: 'organizations' | 'locations';
  isOpen: undefined | boolean;
  dataToShow: any[];
  searchPlaceholder?: string;
};

enum DataTypes {
  locations = 'locations',
  organizations = 'organizations',
}

const initialState: OrganizationSelectorStateType = {
  search: '',
  viewMode: 'organizations',
  isOpen: false,
  searchPlaceholder: DataTypes.organizations,
  dataToShow: [],
};

interface OrganizationSelectorProps {
  data: OrganizationSelectorData;
  onSelect?: (...args: any) => void;
  onReset?: (...args: any) => void;
  showOrganizations: boolean;
}

const OrganizationSelector = (
  props: OrganizationSelectorProps
): ReactElement<{ organizations: ResponseOrganization[] }> | null => {
  const { onSelect, onReset, data, showOrganizations } = props;
  const [{}, dispatch] = useStateValue();
  const { filtersOptions, queryOptions, location, history } = useUrl();

  const [state, setState] = useState(initialState);
  const [primaryTitle, setPrimaryTitle] = useState<string | undefined>(undefined);
  const [secondaryTitle, setSecondaryTitle] = useState('Select Places');
  const updateState = useCallback(
    (newState: Partial<OrganizationSelectorStateType>) => {
      setState({ ...state, ...newState });
    },
    [state]
  );
  /**
   * check Location and organization query Params in url
   * if exists update the state with selected organization and place respectively
   * and dispatch the action to
   * update the context.
   * Handle both organization Manager and System admin.
   *  */
  useEffect(() => {
    const _state = Object.assign({}, state);
    // admin with no active organization
    if (showOrganizations && !data.activeOrganization) {
      _state.dataToShow = data.organizations;
      _state.viewMode = 'organizations';
      _state.searchPlaceholder = 'organizations';
    } else {
      _state.viewMode = 'locations';
      _state.dataToShow = data.places;
      _state.searchPlaceholder = 'locations';
    }
    updateState(_state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOrganizations, data]);

  useEffect(() => {
    const { placeUlid = [] } = filtersOptions;
    const { places } = queryOptions;
    if (!places) {
      setSecondaryTitle('Select Places');
    } else if (typeof places === 'string') {
      setSecondaryTitle(sanitizePlaceName(places));
    } else {
      setSecondaryTitle(`${placeUlid.length} places selected`);
    }

    if (showOrganizations) {
      const title = props.data.activeOrganization?.name;
      setPrimaryTitle(title || 'Select Organization');
    } else {
      setPrimaryTitle(undefined);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filtersOptions, props.data.activeOrganization, showOrganizations, location.search]);

  /**
   * used for passing props to dropdown conditionally.
   * used for System Admin.
   * will toggle the right icon to display close icon
   * will hide the left icon
   * color will be change with background color
   */
  const getExtraProps = useCallback(() => {
    const { placeUlid = [] } = filtersOptions;
    const isDropOpen = props.data.activeOrganization?.name != undefined && placeUlid && placeUlid.length > 0;
    if (!props.showOrganizations) {
      return {
        leftIcon: { icon: 'map-marker-alt', showDefault: true },
        isCloseIcon: placeUlid && placeUlid?.length > 0,
      };
    }
    if (showOrganizations && props.data.activeOrganization) {
      return {
        leftIcon: { icon: 'map-marker-alt', showDefault: false },
        isCloseIcon: true,
      };
    }

    if (isDropOpen) {
      return { isCloseIcon: true };
    }

    return {};

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filtersOptions.placeUlid, data.activeOrganization, showOrganizations]);

  // Reset on Close Icon click
  function handleReset() {
    dispatch({
      type: types.SET_ACTIVE_ORGANIZATION,
      organization: undefined,
    });
    dispatch({
      type: types.SET_ACTIVE_TICKET,
      ticket: undefined,
    });

    updateState(initialState);

    if (onReset) {
      onReset();
    } else if (Object.keys(queryOptions)) {
      const newQs = queryString.stringify(queryOptions, { arrayFormat: 'comma' });
      history.push(`${location.pathname}?${newQs}`);
    } else {
      history.push(location.pathname);
    }
  }

  /**
   * Handle drop down item selection
   * and change the viewmode depend  on userRole
   * update the state, global context, QueryParams
   */
  const getStateKeyValues = (key: boolean, places: ResponsePlace[]) => {
    return {
      viewMode: key ? DataTypes.locations : DataTypes.organizations,
      dataToShow: key ? places : data.organizations,
      searchPlaceholder: key ? DataTypes.locations : DataTypes.organizations,
    };
  };
  const handleSelect = (e: any) => {
    const { viewMode } = state;
    let prevQs = queryString.parse(location.search);
    let dispatchObj: Actions;
    if (viewMode === DataTypes.locations) {
      updateState({
        ...getStateKeyValues(showOrganizations, data.places),
      });
      dispatchObj = {
        type: types.SET_ACTIVE_PLACE,
        place: e,
      };
      prevQs.location = e.ulid;
    } else {
      dispatchObj = {
        type: types.SET_ACTIVE_ORGANIZATION,
        organization: e,
      };
      const { responsePlaces } = e;
      updateState({
        ...getStateKeyValues(!showOrganizations, responsePlaces),
      });
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      const { location, ...rest } = prevQs;
      if (rest.reviewUlid && rest.reviewUlid != e.reviewUlid) {
        delete rest.reviewUlid;
        dispatch({
          type: 'SET_ACTIVE_TICKET',
          ticket: undefined,
        });
      }
      rest.organization = e.ulid;
      prevQs = rest;
    }
    dispatch(dispatchObj);
    if (onSelect) {
      onSelect(e);
    } else {
      history.push(`${location.pathname}?${queryString.stringify(prevQs)}`);
    }
  };
  const secondaryOption = queryOptions.places
    ? typeof queryOptions.places != 'string'
      ? queryOptions.places.map((p) => sanitizePlaceName(p))
      : [queryOptions.places]
    : '';
  return (
    <DropDown
      {...getExtraProps()}
      itemPadding={'0'}
      primaryTitle={primaryTitle}
      secondaryTitle={secondaryTitle}
      selectedOptions={{
        primaryOption: showOrganizations ? data.activeOrganization : undefined,
        secondaryOption,
      }}
      isSearch
      placeholder={state.searchPlaceholder}
      data={state.dataToShow
        .filter((item) => (item.responsePlacesCount > 0 ? item.responsePlacesCount : item.placeName))
        .sort(function (a, b) {
          const textA = a.name?.toUpperCase() || a.placeName?.toUpperCase();
          const textB = b.name?.toUpperCase() || b.placeName?.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })}
      component={OrganizationItemComp}
      onSelect={handleSelect}
      onReset={handleReset}
      selectExternal={(e) => Boolean(e.responseOrganizationUlid)}
    />
  );
};

export default OrganizationSelector;
