import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'reflexbox/styled-components';
import styled from 'styled-components/macro';
import vars from 'src/styles/variables';
import Button from './Button';

export const ErrorBlockWrapper = styled(Flex)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  min-height: 20rem;
  padding: 1rem;
`;

const ErrorBlockHeading = styled.div<{ simple?: boolean }>`
  h3 {
    background-color: ${(props) => (props.simple ? 'white' : vars.$redlighter)};
    color: ${vars.$gray_7};
    padding: 0.5rem 1rem;
    margin: 0;
    border: 1px solid ${(props) => (props.simple ? vars.$border_color : '#f5c6cb')};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  div {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border: ${vars.$border};
    border-top: none;
  }
`;

const ReloadButtonHeader = styled(Button)`
  svg {
    color: ${vars.$gray_7};
  }

  &:hover {
    color: ${vars.$gray_5};
  }
`;

const ExclamationIcon = styled(FontAwesomeIcon)`
  color: ${vars.$red};
  margin-right: 0.5rem;
`;

const ErrorBlockBody = styled.div`
  overflow: auto;
  > div {
    padding: 1rem 1rem 0;
    border: ${vars.$border};
    border-top: 0;
  }
`;

const StyledPre = styled.pre`
  /* overflow: visible; */
  border: ${vars.$border};
  border-top: none;
  margin: 0;
  padding: 1rem;
  height: 100%;
`;

function reloadForm() {
  window.location.replace(window.location.href);
}

const ErrorBlock = (props: { error: any; component?: any; simple?: boolean }): React.ReactElement => {
  const { error, component, simple } = props;

  const statusCode: number | undefined = error.networkError && error.networkError.statusCode;

  return (
    <ErrorBlockWrapper>
      {simple || statusCode === 503 ? (
        <>
          <ErrorBlockHeading simple>
            <h3>
              <span>
                <ExclamationIcon icon="exclamation-triangle" />
                <span>{`Unexpected error${error.networkError ? `: ${error.networkError.statusCode}` : '...'}`}</span>
              </span>
              <Button btnSm iconRight="redo" content="Reload" onClick={reloadForm} />
            </h3>
          </ErrorBlockHeading>
          <ErrorBlockBody>
            <StyledPre>{'Please reload the page and try again. '}</StyledPre>
          </ErrorBlockBody>
        </>
      ) : (
        <>
          <ErrorBlockHeading>
            <h3>
              <span>
                {error.networkError ? `Error: ${error.networkError.statusCode}` : 'Errors'}
                {component ? ` - ${component.name}` : ''}
              </span>

              <ReloadButtonHeader blank iconOnly icon="redo" onClick={reloadForm} tooltip="Reload" />
            </h3>
            <div>
              <ExclamationIcon icon="exclamation-triangle" />
              <span>{'Unexpected error...'}</span>
            </div>
          </ErrorBlockHeading>
          <ErrorBlockBody>
            {Boolean(error && JSON.stringify(error) !== '{}') ? (
              <>
                {Boolean(error.networkError && error.networkError.statusCode === 403) && (
                  <div dangerouslySetInnerHTML={{ __html: `${error.networkError?.bodyText || 'UNKNOWN'}` }} />
                )}
                <StyledPre>{JSON.stringify(error, null, 2)}</StyledPre>
              </>
            ) : (
              <StyledPre>
                {'Return '}
                <a href="/response-console">{'home'}</a>
                {' or reload the page and try again.'}
              </StyledPre>
            )}
          </ErrorBlockBody>
        </>
      )}
    </ErrorBlockWrapper>
  );
};

export default ErrorBlock;
