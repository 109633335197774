import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { ImageProps } from 'src/types';

const Facebook = ({ width = '100%', active, deleted }: ImageProps): ReactElement => (
  <svg
    x="0"
    y="0"
    version="1"
    width={width}
    height={width}
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    enableBackground="new 0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={deleted && active ? '#eb2424' : active ? '#5dc094' : '#ffffff'}
      d="M46.89 36l1.78-11.58H37.56V16.9c0-3.17 1.55-6.26 6.53-6.26h5.05V.78S44.56 0 40.17 0c-9.15 0-15.14 5.55-15.14 15.59v8.83H14.86V36h10.17v28h12.52V36h9.34z"
    />
  </svg>
);

Facebook.propTypes = {
  width: PropTypes.string,
  active: PropTypes.bool,
};

export default Facebook;
