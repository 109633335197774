import React from 'react';
import DatePicker from 'react-datepicker';
import { format, startOfDay } from 'date-fns';
import { useActivateResponsePlace, useDeactivateResponsePlace } from 'src/async';
import { ResponsePlace } from 'src/async/types';
import { List, Toggle, Row, Column, Label, Input, FormGroup } from 'src/components';
import { StyledDatepickerContainer } from 'src/components/Forms/DateInput';
import { LoadingSubsectionPlaceholder, SettingsSubsection } from 'src/views/Settings/settings.styled';
import { LocationSettingsRouteParams } from 'src/views/Settings/SettingsTypes';

function buildActivationDate(start_date?: string): Date {
  const defaultActivationDate = start_date ? new Date(`${start_date}T00:00:00`) : new Date();
  return startOfDay(defaultActivationDate);
}

const ActivationSettings = (props: {
  params: LocationSettingsRouteParams;
  responsePlaceData: ResponsePlace;
  setActivationError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  isResponsePlaceDataLoading: boolean;
}): JSX.Element => {
  const { params, responsePlaceData, setActivationError, setSaving, isResponsePlaceDataLoading } = props;
  const { active } = responsePlaceData;
  const activationDate = buildActivationDate(responsePlaceData?.settings?.start_date);
  const [startDate, setStartDate] = React.useState(activationDate); // reset

  const { mutateAsync: activateResponsePlace, isLoading: isActivateLoading } = useActivateResponsePlace();
  const { mutateAsync: deactivateResponsePlace, isLoading: isDeactivateLoading } = useDeactivateResponsePlace();

  React.useEffect(() => {
    if (responsePlaceData?.settings?.start_date) {
      const activationDate = buildActivationDate(responsePlaceData.settings.start_date);
      setStartDate(activationDate);
    }
  }, [responsePlaceData]);

  React.useEffect(() => {
    setSaving(isActivateLoading || isDeactivateLoading);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isActivateLoading, isDeactivateLoading]);

  async function handleChange() {
    if (active) {
      await deactivateResponsePlace(
        {
          placeUlid: params.location,
        },
        {
          onSuccess: (response) => {
            setActivationError(undefined);
          },
          onError: (err) => {
            console.error(err);
            setActivationError(err.response?.data?.message || err.message);
          },
        }
      );
    } else {
      await activateResponsePlace(
        {
          placeUlid: params.location,
          startDate: format(startDate, 'yyyy-MM-dd'),
        },
        {
          onSuccess: (response) => {
            setActivationError(undefined);
          },
          onError: (err) => {
            console.error(err);
            setActivationError(err.response?.data?.message || err.message);
          },
        }
      );
    }
  }

  if (isResponsePlaceDataLoading) return <LoadingSubsectionPlaceholder />;

  return (
    <SettingsSubsection>
      <List title="Activation" icon="money-check-edit">
        <li>
          <Row>
            <Column width={[1, 1 / 2]}>
              <StyledDatepickerContainer>
                <FormGroup hasError={false}>
                  <Label>{'Start Date'}</Label>
                  <DatePicker
                    name="startDate"
                    placeholderText={'placeholder'}
                    customInput={<Input border />}
                    selected={startDate}
                    disabled={active || false}
                    onChange={(date) => {
                      setStartDate(new Date(date as Date));
                    }}
                  />
                </FormGroup>
              </StyledDatepickerContainer>
            </Column>
            <Column width={[1, 1 / 2]} display="flex" justifyContent="center">
              <Toggle
                onChange={handleChange}
                name={'activation_toggle'}
                labelLeft={active || false ? 'Active' : 'Inactive'}
                checked={active || false}
                hasError={false}
              />
            </Column>
          </Row>
        </li>
      </List>
    </SettingsSubsection>
  );
};

export default ActivationSettings;
