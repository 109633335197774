import { InMemoryCache } from '@apollo/client';
import { uniqBy } from 'lodash';

export const cache = new InMemoryCache({
  typePolicies: {
    // Needed to avoid data lost warning for list on infinite scroll.
    // ReviewResponseTicketCollection: {
    //   keyFields: [['reviewUlid', 'submittedAt']],
    //   fields: {
    //     records: {
    //       merge(existing, incoming, { variables }) {
    //         if (!existing || variables?.pagination.page == 0) {
    //           return incoming;
    //         }

    //         return uniqBy([...existing, ...incoming], (r) => r.reviewUlid);
    //       },
    //     },
    //     meta: {
    //       merge(existing, incoming) {
    //         if (!incoming) {
    //           return existing;
    //         }
    //         return incoming;
    //       },
    //     },
    //   },
    // },
    Query: {
      fields: {
        listReviewResponseTickets: {
          read(existing) {
            return existing;
          },
          merge(existing, incoming, { variables }) {
            if (!existing || variables?.pagination.page == 0) {
              return incoming;
            }
            const newObj = {
              ...existing,
              meta: incoming.meta,
              records: uniqBy([...existing.records, ...incoming.records], (r) => r.reviewUlid),
            };
            return newObj;
          },
        },
      },
    },
    /**
     *
     * The following patch should be avoided, but is good to be aware of.
     *   It resolves an apollo cache warning when the same query is used in
     *   different gql functions and return slightly different objects.
     *     - It should be avoided because it will hide the warning but replace
     *       data with whichever query has been most recently run, which will
     *       likely delete valid values from the cache. - KB
     *
     * Docs: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
     *
     *   Query: {
     *     fields: {
     *       project: {
     *         merge(existing, incoming) {
     *           return incoming;
     *         },
     *       },
     *     },
     *   },
     */
  },
});
